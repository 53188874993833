export const formBox = (theme: any) => ({
  w: "100%",
});
export const leftFormStyle = (theme: any) => ({
  height: "fit-content",
  padding: 6,
  borderRadius: "8px",
  style: {
    shadowColor: "rgba(0, 0, 0, 0.20)",
    shadowOffset: { width: 0, height: 1 },
    shadowOpacity: 1,
    shadowRadius: 3,
  },
  backgroundColor: theme?.appPalette?.grey.white
});

export const headerText = (theme: any) => ({
  fontFamily: theme?.fontFamily,
  fontWeight: 600,
  fontSize: "24px",
  lineHeight: "32px",
  color: theme?.appPalette?.grey?.grey80,
});

export const labelText = (theme: any) => ({
  fontFamily: theme?.fontFamily,
  fontWeight: 600,
  fontSize: "16px",
  lineHeight: "20px",
  color: theme?.appPalette?.grey?.grey80,
});
export const counterText = (theme: any) => ({
  fontFamily: theme?.fontFamily,
  fontWeight: 400,
  fontSize: "16px",
  lineHeight: "20px",
  color: theme?.appPalette?.grey?.grey40,
});
export const optionalText = (theme: any) => ({
  fontFamily: theme?.fontFamily,
  fontWeight: 400,
  fontSize: "14px",
  lineHeight: "16px",
  color: theme?.appPalette?.grey?.grey40,
});
export const textAreaStyle = (theme: any) => ({
  fontFamily: theme?.fontFamily,
  h: "250px",
  fontSize: "16px",
  placeholderTextColor: theme.appPalette?.grey?.grey20,
  color: theme.appPalette?.grey?.grey80,
  borderRadius: 8,
  outlineColor: theme.appPalette?.primary?.primary100,
  autoCompleteType: "text",
  _stack: {
    style: {},
  },
  _hover: {
    borderColor: theme.appPalette?.primary?.primary100,
  },
  _focus: {
    borderColor: theme.appPalette?.primary?.primary100,
    backgroundColor: theme.appPalette?.grey?.white,
    _hover: {
      borderColor: theme.appPalette?.primary?.primary100,
    },
  },
});
export const inputStyle = (theme: any) => ({
  fontSize: "16px",
  fontFamily: theme?.fontFamily,
  placeholderTextColor: theme.appPalette?.grey?.grey20,
  height: "48px",
  color: theme.appPalette?.grey?.grey80,
  borderRadius: 8,
  _stack: {
    style: {},
  },
  _hover: {
    borderColor: theme.appPalette?.primary?.primary100,
  },
  _focus: {
    borderColor: theme.appPalette?.primary?.primary100,
    backgroundColor: theme.appPalette?.grey?.white,
    _hover: {
      borderColor: theme.appPalette?.primary?.primary100,
    },
  },
});
export const errorText = (theme: any) => ({
  marginTop: "4px",
  fontFamily: theme.fontFamily,
  fontWeight: 400,
  fontSize: "14px",
  lineHeight: "16px",
  color: theme.appPalette?.red?.red100,
});
export const ticketNumberStyle = (theme: any) => ({
  width: "fit-content",
  backgroundColor: theme.appPalette?.primary?.primary8,
  px: 4,
  py: 2,
  borderRadius: "8px",
  mb: 6,
});
export const textTicketNumberStyle = (theme: any) => ({
  color: theme.appPalette?.primary?.primary100,
  fontFamily: theme.fontFamily,
  fontSize: "16px",
  fontWeight: 600,
  lineHeight: "20px",
});
