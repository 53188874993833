
// Import Interface (if applicable)
import { LocalStorageType } from "shared-components";
/**
 * Local Storage Setup (Web App)
 * Access the built-in window.localStorage for web storage
 * */
const localStorage = window["localStorage"];

// Local Storage Wrapper (store)
export const store: LocalStorageType = {
  /**
   * @method setItem
   * Define the store object with typed interface (optional)
   * - Set a key-value pair in local storage
   * - Resolve the promise with success
   * */
  setItem: (key: string, value: string) => {
    localStorage.setItem(key, value);
    return Promise.resolve(true);
  },
  /**
   * @method getItem
   * Get a value from local storage by key
   */
  getItem: (key: string) => {
    return localStorage.getItem(key);
  },
  /**
   * @method removeItem
   * Remove a key-value pair from local storage
   */
  removeItem: (key: string) => {
    localStorage.removeItem(key);
  },
  /**
   * @method getAllKeys
   * Get all keys from local storage as an array
   */
  getAllKeys: () => {
    return Object.keys(localStorage);
  },
};
