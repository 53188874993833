import { useEffect, useMemo, useState } from "react";
import {
  AppButton,
  AppInput,
  Box,
  DropDown,
  Flex,
  Pressable,
  Text,
} from "shared-components";
import {
  actionStyle,
  boxItemStyle,
  formBox,
  formStyle,
  gap6,
  headerText,
  informationStyle,
  informationTitleStyle,
  leftFormStyle,
  w50,
} from "./LeadForm.style";
import { useTheme, useToast } from "native-base";
import { ReactSVG } from "react-svg";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import AppActivityLog from "../activity-log/ActivityLog";
import AppLeadDiscussion from "../lead-discussion/LeadDiscussion";
import AppInviteLead from "../invite-lead/InviteLead";
import LeadService from "../../services/lead/leadService";
import { LeadConstants } from "../../constants/lead";
import {
  showNotification,
  showNotificationForVoiso,
} from "../../services/toast/toastService";
import CallService from "../../services/call/callService";
import { ResourceType } from "../../constants/resourceType";
import AppLeadActivityHistory from "../lead-activity-history/LeadActivityHistory";
import AppShowToast from "../show-toast/ShowToast";
import ConvertLeadDrawer from "../convert-lead-drawer/ConvertLeadDrawer";
import { errorText, textHeader16 } from "../../styles/text";
import { useForm, Controller } from "react-hook-form";
import { PHONE_NUMBER_REGEX, REGEX_EMAIL } from "../../constants/regex";
import AssigneeDropdown from "../assignee-dropdown/AssigneeDropdown";
import { OptionItem } from "../../types/dropdownProps";
import AuthorizationService from "../../auth/authorizationService";
import { PERMISSIONS } from "../../constants/permisstion";
import ProtectedContent from "../protected-content/ProtectedContent";
import SendEmailDrawer from "../send-email-drawer/SendEmailDrawer";
import ContactService from "../../services/contact/contactService";
import {EmailTemplate, Language} from "../../types/sendEmailFormProps";
import TenantConfigService from "../../services/tenant-config/tenantConfigService";

const LeadForm = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { leadId } = useParams();
  const toast = useToast();
  const [isExistedEmail, setIsExistedEmail] = useState(false);
  const back = () => {
    navigate("/lead");
  };
  const [activityChips, setActivityChips] = useState([
    {
      label: t("lead-form.comments"),
      route: "comments",
      isActive: true,
      selectChips: (route: string) => {
        changeTab(route);
      },
      children: <AppLeadDiscussion leadId={leadId ?? ""} />,
    },
    {
      label: t("lead-form.history"),
      route: "history",
      isActive: false,
      selectChips: (route: string) => {
        changeTab(route);
      },
      children: <AppLeadActivityHistory leadId={leadId ?? ""} />,
    },
  ]);

  const [isOpenInvite, setIsOpenInvite] = useState(false);
  const [inviteType] = useState<"SMS" | "EMAIL">("EMAIL");
  const [inviteLeadInfo, setInviteLeadInfo] = useState<any>(undefined);
  const [isOpenConvertLeadDrawer, setIsOpenConvertLeadDrawer] = useState(false);
  const [convertedUserInfo, setConvertedUserInfo] = useState<any>({});
  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    getValues,
  } = useForm();
  const [phoneNumberError, setPhoneNumberError] = useState("");
  const [selectedAssignee, setselectedAssignee] = useState({
    text: "",
    value: "",
  });
  const [userInfo, setUserInfo] = useState<any>({});
  const [isOpenSendEmailDrawer, setIsOpenSendEmailDrawer] = useState(false);
  const [emailListing, setEmailListing] = useState<EmailTemplate[]>([]);
  const [supportedLanguages, setSupportedLanguages] = useState<Language[]>([]);
  const hasViewSensitiveDataPermission = useMemo(() => {
    return AuthorizationService.hasPermissions(
      PERMISSIONS.USERS_ROLES.SENSITIVE_DATA.VIEW_SENSITIVE_DATA,
    );
  }, []);
  const closeSendEmailDrawer = () => {
    setIsOpenSendEmailDrawer(false);
  };
  const closeConvertLeadDrawer = () => {
    setIsOpenConvertLeadDrawer(false);
  };

  useEffect(() => {
    if (leadId) {
      LeadService.getLead(leadId).then((res) => {
        const { result } = res.data;
        setValue("firstName", result?.firstName);
        setValue("lastName", result?.lastName);
        setValue("email", result?.email);
        setValue("phoneNumber", result?.phoneNumber);
        setValue("status", result?.status);
        setValue("assigneeId", result?.assignee?.email);
        setInviteLeadInfo({
          email: result?.email,
          phoneNumber: result?.phoneNumber,
          leadId,
        });
        setselectedAssignee({
          text: result?.assignee?.fullName,
          value: result?.assignee?.email,
        });
        setIsExistedEmail(result.isExistedEmail);
      });
    }
  }, [setValue, leadId]);

  useEffect(() => {
    ContactService.getEmailListing('LEAD').then((response) => {
      const {result} = response.data.result;
      if (result) {
        setEmailListing(result);
      }
    });

    TenantConfigService.getSupportedLanguages().then((response) => {
      const {result} = response.data.result;
      if (result) {
        setSupportedLanguages(result);
      }
    });
  }, []);

  const onSubmit = (data: any) => {
    const newData = hasViewSensitiveDataPermission
      ? data
      : {
          firstName: data.firstName,
          lastName: data.lastName,
          status: data.status,
          assigneeId: data.assigneeId,
        };
    if (!phoneNumberError) {
      LeadService.editLead(leadId, newData)
        .then(() => {
          const toastData = {
            message: t("lead-form.msg-edit-success"),
            icon: "/icons/toast/check.svg",
          };
          showNotification(
            toast,
            <AppShowToast
              toastData={toastData}
              onClose={() => {
                toast.closeAll();
              }}
            ></AppShowToast>,
            5000,
          );
        })
        .catch((err) => {
          if (
            err?.response?.data?.messages.includes("phone_number_is_existed")
          ) {
            setPhoneNumberError(
              t("errors.existed", {
                fieldName: t(
                  "user-profile-listing.header-column.phone-number",
                ).toLowerCase(),
              }),
            );
          }
        });
    }
  };

  const validatePhoneNumber = (e: string) => {
    return e.length > 7
      ? PHONE_NUMBER_REGEX.test(e) ||
          t("validate.standard002", {
            field: t("lead-listing.header-column.phone-number"),
          })
      : t("validate.standard004", { minLength: 8 });
  };

  const validateEmail = (e: string) => {
    return (
      REGEX_EMAIL.test(e) ||
      t("validate.standard069", {
        field: t("user-profile-listing.header-column.email"),
      })
    );
  };

  const renderForm = () => {
    return (
      <Box {...informationStyle(theme)}>
        <Box {...informationTitleStyle(theme)}>
          <Text {...textHeader16(theme)}>{t("lead-form.information")}</Text>
        </Box>
        <Box {...boxItemStyle}>
          <div style={w50}>
            <Text {...textHeader16(theme)}>
              {t("user-profile-listing.header-column.first-name")}
            </Text>
            <Controller
              name="firstName"
              control={control}
              render={({ field }) => (
                <AppInput
                  placeholder={t("placeholders", {
                    fieldName: t(
                      "user-profile-listing.header-column.first-name",
                    ).toLowerCase(),
                  })}
                  defaultValue={getValues("firstName")}
                  onChangeText={(text) => {
                    field.onChange(text);
                  }}
                />
              )}
            />
          </div>
          <div style={w50}>
            <Text {...textHeader16(theme)}>
              {t("user-profile-listing.header-column.last-name")}
            </Text>
            <Controller
              name="lastName"
              control={control}
              render={({ field }) => (
                <AppInput
                  placeholder={t("placeholders", {
                    fieldName: t(
                      "user-profile-listing.header-column.last-name",
                    ).toLowerCase(),
                  })}
                  defaultValue={getValues("lastName")}
                  onChangeText={(text) => {
                    field.onChange(text);
                  }}
                />
              )}
            />
          </div>
        </Box>
        {hasViewSensitiveDataPermission && (
          <Box {...boxItemStyle}>
            <div style={w50}>
              <Text {...textHeader16(theme)}>
                {t("lead-listing.header-column.phone-number")}
              </Text>
              <Controller
                name="phoneNumber"
                control={control}
                rules={{
                  required: true,
                  validate: validatePhoneNumber,
                }}
                render={({ field }) => (
                  <AppInput
                    placeholder={t("placeholders", {
                      fieldName: t(
                        "user-profile-listing.header-column.phone-number",
                      ).toLowerCase(),
                    })}
                    defaultValue={getValues("phoneNumber")}
                    onChangeText={(text) => {
                      setPhoneNumberError("");
                      field.onChange(text);
                    }}
                    isInvalid={
                      errors.phoneNumber?.type === "required" ||
                      errors.phoneNumber?.type === "validate" ||
                      !!phoneNumberError
                    }
                  />
                )}
              />
              {errors.phoneNumber?.type === "required" && (
                <Text {...errorText(theme)}>
                  {t("validate.standard001", {
                    field: t("lead-listing.header-column.phone-number"),
                  })}
                </Text>
              )}
              {errors.phoneNumber?.type === "validate" && (
                <Text {...errorText(theme)}>
                  {`${errors.phoneNumber?.message}`}
                </Text>
              )}
              {!!phoneNumberError && (
                <Text {...errorText(theme)}>{phoneNumberError}</Text>
              )}
            </div>
            <div style={w50}>
              <Text {...textHeader16(theme)}>
                {t("user-profile-listing.header-column.email")}
              </Text>
              <Controller
                name="email"
                control={control}
                rules={{
                  required: true,
                  validate: validateEmail,
                }}
                render={({ field }) => (
                  <AppInput
                    placeholder={t("placeholders", {
                      fieldName: t(
                        "user-profile-listing.header-column.email",
                      ).toLowerCase(),
                    })}
                    defaultValue={getValues("email")}
                    onChangeText={(text) => {
                      field.onChange(text);
                    }}
                    isInvalid={
                      errors.email?.type === "required" ||
                      errors.email?.type === "validate"
                    }
                  />
                )}
              />
              {errors.email?.type === "required" && (
                <Text {...errorText(theme)}>
                  {t("validate.standard001", {
                    field: t("lead-listing.header-column.email"),
                  })}
                </Text>
              )}
              {errors.email?.type === "validate" && (
                <Text {...errorText(theme)}>{`${errors.email?.message}`}</Text>
              )}
            </div>
          </Box>
        )}
      </Box>
    );
  };

  const changeTab = (route: string) => {
    const chips = activityChips.map((chip: any) => {
      return {
        ...chip,
        isActive: route === chip.route,
      };
    });
    setActivityChips(chips);
  };

  const handleBack = () => {
    back();
  };

  const sendEmail = () => {
    setUserInfo({
      fullName: getValues("firstName") + ' ' + getValues("lastName"),
      email: getValues("email"),
      phoneNumber: getValues("phoneNumber")?.replace("+", ""),
      userId: leadId,
    })
    setIsOpenSendEmailDrawer(true);
  };

  const convertToProspect = () => {
    setConvertedUserInfo({
      firstName: getValues("firstName"),
      lastName: getValues("lastName"),
      email: getValues("email"),
      phoneNumber: getValues("phoneNumber")?.replace("+", ""),
      status: getValues("status"),
      leadId: leadId,
    });
    setIsOpenConvertLeadDrawer(true);
  };

  const call = () => {
    CallService.callVoiso(
      getValues("phoneNumber"),
      ResourceType.LEAD,
      leadId || "",
    ).catch((reason) => {
      if (reason.response.data.code === "1010022") {
        showNotificationForVoiso(t);
      }
    });
  };

  const header = () => {
    return (
      <Flex
        direction="row"
        justify="space-between"
        alignItems="center"
        height="64px"
      >
        <Flex direction="row" align="center">
          <Box height="24px" mr={3}>
            <Pressable
              testID={"back-btn"}
              onPress={() => {
                handleBack();
              }}
            >
              <ReactSVG src="/icons/back.svg" />
            </Pressable>
          </Box>
          <Text {...headerText(theme)}>{t("user-type.lead")}</Text>
        </Flex>
        <Box {...actionStyle}>
          <ProtectedContent permissions={PERMISSIONS.CONTACTS.PROSPECT.CREATE}>
            {getValues("email") ? (
                <Box>
                  <AppButton
                      variant="secondary"
                      label={t("lead-listing.send-email")}
                      onPress={sendEmail}
                  />
                </Box>
            ) : null}
            <Box>
              <AppButton
                variant="secondary"
                label={t("lead-listing.convert")}
                onPress={convertToProspect}
              />
            </Box>
          </ProtectedContent>
          <Box>
            <AppButton
              variant="secondary"
              label={t("lead-form.btn-submit-edit")}
              onPress={handleSubmit(onSubmit)}
            />
          </Box>
          <Box>
            <AppButton label={t("lead-listing.call")} onPress={call} />
          </Box>
        </Box>
      </Flex>
    );
  };

  const DiscussionBox = () => {
    return (
      <Box {...leftFormStyle(theme)}>
        <AppActivityLog
          activityChips={activityChips}
          textHeaderLabel={t("lead-form.activity-logs")}
          showText={t("lead-form.show")}
        />
      </Box>
    );
  };

  const properties = () => {
    const statusOptions = LeadConstants.status.map(
      ({ label, value }: { label: string; value: string }) => ({
        label,
        value: t(value),
      }),
    );
    return (
      <Box {...informationStyle(theme)}>
        <Box {...informationTitleStyle(theme)}>
          <Text {...textHeader16(theme)}>{t("lead-form.properties")}</Text>
        </Box>
        <Box {...gap6}>
          <Text {...textHeader16(theme)}>{t("lead-form.status")}</Text>
          <Controller
            name="status"
            control={control}
            render={({ field }) => (
              <DropDown
                data={statusOptions}
                defaultOptionLabel={getValues("status")}
                dropDownIcon={"/icons/arrow-down.svg"}
                selectedIcon={"/icons/checked.svg"}
                placeHolder={t("user-profile-listing.select")}
                action={(option: OptionItem) => {
                  field.onChange(option.label);
                }}
              />
            )}
          />
        </Box>
        <Box {...gap6} zIndex="-1">
          <Box flexDirection="row">
            <Text {...textHeader16(theme)}>
              {t("ticket-properties.assignee.label")}
            </Text>
            <Text {...textHeader16(theme)} fontWeight="400" ml={1.5}>
              ({t("lead-form.optional")})
            </Text>
          </Box>
          <Controller
            name="assigneeId"
            control={control}
            render={({ field }) => (
              <AssigneeDropdown
                initSelectedItem={selectedAssignee.value}
                isAll={false}
                placeholder={t("ticket-properties.assignee.placeholder")}
                onSelectedItem={(data) => field.onChange(data.value)}
                onValueChangeAssignee={(data) => setselectedAssignee(data)}
                selectedAssignee={selectedAssignee}
                dropdownIcon={"/icons/arrow-down.svg"}
                checkedIcon={"/icons/check.svg"}
                noDataIcon={"/icons/no-data.svg"}
                resourceType={ResourceType.LEAD}
              />
            )}
          />
        </Box>
      </Box>
    );
  };

  const renderSendEmailDrawer = () => {
    return isOpenSendEmailDrawer ? (
        <SendEmailDrawer
            emailListing={emailListing}
            supportedLanguages={supportedLanguages}
            userInfo={userInfo}
            closeDrawer={closeSendEmailDrawer}
            isOpenDrawer={isOpenSendEmailDrawer}
        />
    ) : undefined;
  };

  const renderConvertLeadDrawer = () => {
    return isOpenConvertLeadDrawer ? (
      <ConvertLeadDrawer
        lead={convertedUserInfo}
        isExistedEmail={isExistedEmail}
        closeDrawer={closeConvertLeadDrawer}
        isOpenDrawer={isOpenConvertLeadDrawer}
        onConvertSuccess={() => {
          navigate("/lead");
        }}
      />
    ) : undefined;
  };

  return (
    <Box marginTop="-24px">
      {renderConvertLeadDrawer()}
      {renderSendEmailDrawer()}
      <AppInviteLead
        leadInfo={inviteLeadInfo}
        type={inviteType}
        isOpenInvite={isOpenInvite}
        onClose={() => setIsOpenInvite(false)}
      />
      {header()}
      <Flex direction="row" {...formBox(theme)}>
        <div style={formStyle}>
          {renderForm()}
          {DiscussionBox()}
        </div>
        <div style={{ width: "30%" }}>{properties()}</div>
      </Flex>
    </Box>
  );
};

export default LeadForm;
