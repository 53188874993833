import TENANT from "../constants/tenant";
import { store } from "../oidc/oidc-store";
import { Configuration } from "../types/configuration";
import { oidcScope } from "./oidc-scope";

export const Config: Configuration = {
  // Static resouces baseURL, remember to end it with a slash (/)
  staticResourcesBaseURL: "/",
  ISDomain: "https://demo.identity.kardinc.com",
  /**
   * DEV-OPS concern
   */
  baseURL: "https://demo.gateway-am.kardinc.com",
  socketServer: "https://demo.api2.kardinc.com:8443",
  socketPath: "/message/notification",  
  tenants: [
    {
      domain: "https://demo.hyt.admin.kardinc.com",
      adminHubDomain: "https://demo.hyt.admin-hub.kardinc.com",
      code: TENANT.HYT,
      oidcConfiguration: {
        // This value typically remains "code" for authorization code flow
        response_type: "code",
        // This value typically remains "openid" for basic OpenID Connect flow
        scope: oidcScope.join(" "),
        /**
         * Client Configuration (replace placeholders with your actual values)
         * Replace with your application's client ID
         * */
        client_id: "LZKvrNFacAgQfK5dsEcozmiH1Poa",
        // Replace with your application's client secret (if used)
        client_secret: "ortjwMLKm1Kj68Sv1ceJfZFjPzwa",
        /**
         * Server Configuration (replace placeholders with your provider's information)
         * Replace with your application's authenticate URI
         * Replace with your OpenID Connect provider's authority or issuer URL
         * */
        redirect_uri: `${window.location.origin}/authenticate`,
        authority: "https://demo.identity.kardinc.com/oauth2/token",
        /**
         * Storage Configuration
         * Use the previously defined store object for token caching
         * */
        storage: store,
        /**
         * Optional
         * Mobile App Configuration (if applicable)
         * Specify the React component for WebView (react-native-webview) for mobile login handling
         * */
        // Set platform ('mobile' or 'web') if using platform-specific configurations
        platform: "web",
      },
    },
    {
      domain: "https://demo.georgia.admin.kardinc.com",
      adminHubDomain: "https://demo.georgia.adminhub.kardinc.com",
      code: TENANT.HYT,
      oidcConfiguration: {
        // This value typically remains "code" for authorization code flow
        response_type: "code",
        // This value typically remains "openid" for basic OpenID Connect flow
        scope: oidcScope.join(" "),
        /**
         * Client Configuration (replace placeholders with your actual values)
         * Replace with your application's client ID
         * */
        client_id: "Hz7dRLajAFnqK1gx8jUp8bcwRToa",
        // Replace with your application's client secret (if used)
        client_secret: "iiEcS0JswZvCg35ojysEEVc_6ZYa",
        /**
         * Server Configuration (replace placeholders with your provider's information)
         * Replace with your application's authenticate URI
         * Replace with your OpenID Connect provider's authority or issuer URL
         * */
        redirect_uri: `${window.location.origin}/authenticate`,
        authority: "https://demo.identity.kardinc.com/oauth2/token",
        /**
         * Storage Configuration
         * Use the previously defined store object for token caching
         * */
        storage: store,
        /**
         * Optional
         * Mobile App Configuration (if applicable)
         * Specify the React component for WebView (react-native-webview) for mobile login handling
         * */
        // Set platform ('mobile' or 'web') if using platform-specific configurations
        platform: "web",
      },
    },    
  ],
};