import React, { useEffect, useRef, useState } from "react";
import { Box, Flex, Pressable, Spinner, Text, useTheme } from "native-base";
import {
  boxItems,
  optionContainer,
  selectContainer,
  styleOption,
  spinnerStyle,
} from "./CountryDropdown.style";
import { ReactSVG } from "react-svg";
import { CountryDropdownProps } from "../../types/countryDropdownProps";
import { OptionItemDTO } from "../../types/optionItemDTO";
import { textBody16, textNodata } from "../../styles/text";
import { useTranslation } from "react-i18next";
import SearchBox from "../search-box/SearchBox";
import CountryService from "../../services/country/countryService";

const CountryDropdown: React.FC<CountryDropdownProps> = ({
  isInvalid,
  initSelectedItem,
  placeholder,
  disabled,
  nodataText,
  dropdownIcon,
  checkedIcon,
  noDataIcon,
  onSelectedItem,
  onClick,
  selectedCountry,
  onValueChangeCountry,
  isScrollBottom,
  isAll = true,
}) => {
  const theme: any = useTheme();
  const [isOpen, setIsOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState<any>();
  const [data, setData] = useState<any[]>([]);
  const [search, setSearch] = useState<string | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const boxUserRef = useRef<HTMLDivElement | null>(null);
  const dropdownRef = useRef<HTMLDivElement | null>(null);
  const [pageIndex, setPageIndex] = useState(1);
  const [isScroll, setIsCroll] = useState(true);
  const [isFetchData, setIsFetchData] = useState(false);
  const pageEndRef = useRef<HTMLDivElement | null>(null);
  const { t } = useTranslation();

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageIndex]);

  const getData = () => {
    if (isScroll || isFetchData) {
      setIsLoading(true);
      const params = {
        keyword: search?.trim(),
        pageSize: 10,
        pageIndex: pageIndex,
        orderBy: "name",
        orderDirection: "ASC",
      };
      CountryService.list(params)
        .then((response) => {
          const { result } = response.data;
          if (result.data?.length > 0) {
            setIsCroll(pageIndex < result.totalPages);
            if (result.data?.length > 0) {
              const newData = result.data;
              if (
                !search &&
                !data.some((item) => item.alpha2Code === "ALL") &&
                isAll
              ) {
                newData.unshift({
                  alpha2Code: "ALL",
                  name: t("ticket-listing-filter.status-options.all"),
                });
              }
              setData((prevData) => [...prevData, ...newData]);
            }
          }
        })
        .finally(() => {
          setIsLoading(false);
          setIsFetchData(false);
          if (boxUserRef.current) {
            boxUserRef.current.scrollTop = boxUserRef.current.scrollHeight;
          }
        });
    }
  };

  useEffect(() => {
    if (initSelectedItem === t("ticket-listing-filter.status-options.all")) {
      setSelectedValue({
        text: initSelectedItem,
        value: "ALL",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initSelectedItem]);

  useEffect(() => {
    if (
      selectedCountry &&
      initSelectedItem !== t("ticket-listing-filter.status-options.all") &&
      initSelectedItem === selectedCountry.value
    ) {
      setSelectedValue({
        text: selectedCountry.text,
        value: selectedCountry.value,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCountry]);

  const handleScroll = () => {
    if (boxUserRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = boxUserRef.current;
      if (scrollTop + clientHeight >= scrollHeight) {
        setPageIndex((prevState) => prevState + 1);
      }
    }
  };

  useEffect(() => {
    if (boxUserRef.current) {
      boxUserRef.current.addEventListener("scroll", handleScroll);
    }
    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      boxUserRef.current?.removeEventListener("scroll", handleScroll);
    };
  }, [isOpen, data]);

  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  useEffect(() => {
    if (isFetchData) {
      getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetchData]);

  const clearData = () => {
    setData([]);
    setPageIndex(1);
    setIsCroll(true);
    setIsFetchData(!(pageIndex > 1));
  };

  const selectOption = (option: OptionItemDTO) => {
    clearData();
    setSearch(undefined);
    setIsOpen(false);
    setSelectedValue(option);
    onClick?.(false);
    if (onSelectedItem) {
      onValueChangeCountry && onValueChangeCountry(option);
      onSelectedItem({ ...option });
    }
  };

  useEffect(() => {
    if (isOpen && pageEndRef.current && isScrollBottom) {
      pageEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  return (
    <Box width="100%" zIndex={"auto"} ref={dropdownRef}>
      <Pressable
        flexDirection="row"
        pointerEvents={disabled ? "none" : "auto"}
        {...selectContainer(theme, isOpen, disabled, isInvalid)}
        onPress={() => {
          setIsOpen(true);
        }}
        testID={"dropdown-with-search-test-id"}
      >
        <Text
          {...textBody16(theme)}
          color={
            selectedValue?.text && !disabled
              ? theme?.appPalette?.grey?.grey80
              : theme?.appPalette?.grey?.grey40
          }
        >
          {selectedValue?.text || placeholder}
        </Text>
        <Box height="24px">
          <ReactSVG
            afterInjection={(svg) => {
              const iconColor = disabled
                ? theme?.appPalette?.grey?.grey20
                : theme?.appPalette?.grey?.grey80;
              const paths = svg.querySelectorAll("path");
              paths.forEach((path) => {
                path.setAttribute("fill", iconColor);
              });
            }}
            src={dropdownIcon}
          />
        </Box>
      </Pressable>
      {isOpen && (
        <Box {...optionContainer(theme)} position={"absolute"}>
          <SearchBox
            mb={2}
            placeholder={t("users-roles.role-search")}
            onKeySearchChange={(event: string | undefined) => {
              clearData();
              setIsLoading(true);
              setSearch(event);
            }}
            maxLength={500}
            autoFocus={true}
          />

          {data && data.length > 0 && (
            <Box {...boxItems()} ref={boxUserRef} testID="box-user-ref">
              {data?.map((option) => (
                <Pressable
                  testID="btn-press"
                  flexDirection="row"
                  key={option.alpha2Code}
                  onPress={() => {
                    selectOption({
                      text: option.name,
                      value: option.alpha2Code,
                    });
                  }}
                  {...styleOption(theme)}
                >
                  <Text {...textBody16(theme)}>{option.name}</Text>
                  {selectedValue?.value === option.alpha2Code && (
                    <ReactSVG src={checkedIcon} />
                  )}
                </Pressable>
              ))}
            </Box>
          )}
          {isLoading && (
            <Box
              marginTop={data?.length > 0 ? "20px" : "37px"}
              marginBottom={data?.length > 0 ? "20px" : "37px"}
            >
              <Spinner {...spinnerStyle(theme)} position="absolute" size="lg" />
            </Box>
          )}
          {!isLoading && data.length === 0 && (
            <Flex direction="column" alignItems="center" p={6}>
              <ReactSVG src={noDataIcon} />
              <Text {...textNodata(theme)}>
                {nodataText ?? t("users-roles.no-data")}
              </Text>
            </Flex>
          )}
        </Box>
      )}
      <div ref={pageEndRef} />
    </Box>
  );
};

export default CountryDropdown;
