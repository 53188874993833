import Api from "../../configs/api";
import ApiClient from "../api-client/apiClient";

const AccountingService = {
    getTransactionListing: (data: any) => {
        const url = Api.GET_TRANSACTION_LISTING;

        const urlSearchParams = new URLSearchParams();
        for (const key in data) {
            if (data.hasOwnProperty(key)) {
                urlSearchParams.append(key, data[key]);
            }
        }
        return ApiClient().post(url, data, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            }
        });
    },
    downloadReceipt: (data: any) => {
        const url = Api.DOWNLOAD_RECEIPT+`?transactionId=${data.transactionId}&timezoneOffset=${data.timezoneOffset}`;
        return ApiClient().post(url, data, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            responseType: 'blob' as 'json'
        });
    },
    downloadReceiptByType: (url: string, id: string) => {
        const downloadUrl = url.replace('{transactionID}', id);
        console.log(downloadUrl);
        console.log(id);
        return ApiClient().get(downloadUrl);
    },
};

export default AccountingService;