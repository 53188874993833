import { ActivityLogHistory, Box } from "shared-components";
import { useEffect, useState } from "react";
import { PaginationProps } from "shared-components/lib/typescript/src/types/tablePaginationProps";
import TicketService from "../../services/ticket/ticketService";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import CommonService from "../../services/common/commonService";

const ActivityHistory = () => {
  const [paginationInfo, setPaginationInfo] = useState<PaginationProps>({
    total: 0,
    pageIndex: 1,
    pageSize: 10,
    rowPerPage: [10, 50, 100],
    totalPages: 0,
  });

  const [activityLogHistory, setActivityLogHistory] = useState<any[]>([]);
  const { t } = useTranslation();
  const textObject = {
    updatedTicketIn: t("ticket-form.activiti-log.updatedTicketIn"),
    receivedA: t("ticket-form.activiti-log.receivedA"),
    createdA: t("ticket-form.activiti-log.createdA"),
    inbound: t("ticket-form.activiti-log.inbound"),
    inboundCall: t("ticket-form.activiti-log.inboundCall"),
    outbound: t("ticket-form.activiti-log.outbound"),
    outboundCall: t("ticket-form.activiti-log.outboundCall"),
    callFrom: t("ticket-form.activiti-log.callFrom"),
    callTo: t("ticket-form.activiti-log.callTo"),
    linkRecord: t("ticket-form.activiti-log.linkRecord"),
    in: t("ticket-form.activiti-log.in"),
    is: t("ticket-form.activiti-log.is"),
    userId: t("ticket-form.activiti-log.userId"),
    fullName: t("ticket-form.activiti-log.fullName"),
    phoneNumber: t("ticket-form.activiti-log.phoneNumber"),
    email: t("ticket-form.activiti-log.email"),
    files: t("ticket-form.activiti-log.files"),
    none: t("ticket-form.activiti-log.none"),
    actionText: {
      UPDATED: t("ticket-form.activiti-log.updated"),
      UPLOADED: t("ticket-form.activiti-log.uploaded"),
      REMOVED: t("ticket-form.activiti-log.removed"),
    },
    titleText: {
      title: t("ticket-form.activiti-log.title"),
      category: t("ticket-form.activiti-log.category"),
      source: t("ticket-form.activiti-log.source"),
      status: t("ticket-form.activiti-log.status"),
      subCategory: t("ticket-form.activiti-log.subCategory"),
      solution: t("ticket-form.activiti-log.solution"),
      description: t("ticket-form.activiti-log.description"),
      record: t("ticket-form.activiti-log.record"),
      userInfo: t("ticket-form.activiti-log.userInfo"),
      assignee: t("ticket-form.activiti-log.assignee"),
      targetDate: t("ticket-form.activiti-log.targetDate"),
      attachmentUploaded: t("ticket-form.activiti-log.attachmentUploaded"),
      attachmentRemoved: t("ticket-form.activiti-log.attachmentRemoved"),
      associatedOrderNumber: t("associated-order.title"),
    },
  };
  const { ticketNumber } = useParams();

  useEffect(() => {
    if (ticketNumber) {
      const params = {
        pageIndex: paginationInfo.pageIndex,
        pageSize: paginationInfo.pageSize,
      };
      TicketService.getActivityLogHistory(ticketNumber, params).then((res) => {
        const { result } = res.data;
        setActivityLogHistory(result.data);
        setPaginationInfo((prevState) => {
          return {
            ...prevState,
            pageIndex: +result.pageIndex,
            pageSize: +result.pageSize,
            total: result.total,
            totalPages: result.totalPages,
          };
        });
      });
    }
  }, [paginationInfo.pageIndex, paginationInfo.pageSize, ticketNumber]);

  const downloadCallRecord = (uuid: string) => {
    TicketService.downloadCallRecord(uuid).then((res) => {
      CommonService.downloadBlobData(res);
    });
  };

  return (
    <Box>
      <ActivityLogHistory
        datas={activityLogHistory}
        paginationInfo={paginationInfo}
        onPaginationChange={(value: PaginationProps) => {
          setPaginationInfo(value);
        }}
        onClickLinkRecord={(uuid: string) => {
          downloadCallRecord(uuid);
        }}
        textObject={textObject}
        rpgTextLeft={t("common.row-per-page")}
        rpgTextRight={t("common.of")}
      />
    </Box>
  );
};

export default ActivityHistory;
