import { RouterType } from "../types/router.types";
import TicketListing from "../scenes/ticket-listing/TicketListing";
import Authenticate from "../scenes/authenticate/Authenticate";
import TicketForm from "../components/ticket-form/TicketForm";
import AppLead from "../components/lead-listing/LeadListing";
import AppUploadHistory from "../components/upload-history-listing/UploadHistoryListing";
import AppProspect from "../components/prospect-listing/ProspectListing";
import AppClientListing from "../components/client-listing/ClientListing";
import { PERMISSIONS } from "../constants/permisstion";
import Unauthorized from "../scenes/unauthorized/Unauthorized";
import LeadForm from "../components/lead-form/LeadForm";
import ProgramForm from "../components/program-form/ProgramForm";
import Programs from "../scenes/programs/Programs";
import UsersAndRoles from "../components/users-roles/UsersAndRoles";
import AppRoleForm from "../components/role-form/AppRoleForm";
import ClientForm from "../components/client-form/ClientForm";
import ProspectForm from "../components/prospect-form/ProspectForm";
import Consumers from "../scenes/consumers/Consumers";
import Merchants from "../scenes/merchants/Merchants";
import Transactions from "../scenes/transactions/Transactions";

export const RoutesDataNoLayout: RouterType[] = [
  {
    path: "/authenticate",
    element: <Authenticate />,
    title: "redirect",
    requiredLogin: false,
  },
  {
    path: "/404",
    element: <Unauthorized />,
    title: "redirect",
    requiredLogin: false,
  },
];

export const RoutesDataWithLayout: RouterType[] = [
  {
    path: "/",
    element: <Transactions />,
    title: "Transactions",
    requiredLogin: true,
    requiredPermission: [PERMISSIONS.ACCOUNTING.TRANSACTION.LISTING],
  },
  {
    path: "transactions",
    element: <Transactions />,
    title: "Transactions",
    requiredLogin: true,
    requiredPermission: [PERMISSIONS.ACCOUNTING.TRANSACTION.LISTING],
  },
  {
    path: "ticket",
    element: <TicketListing />,
    title: "ticket-listing",
    requiredLogin: true,
    requiredPermission: [PERMISSIONS.CUSTOMER_SUPPORT.TICKET.LISTING],
  },
  {
    path: "ticket/create",
    element: <TicketForm />,
    title: "ticket-creation",
    requiredLogin: true,
    requiredPermission: [PERMISSIONS.CUSTOMER_SUPPORT.TICKET.CREATE],
  },
  {
    path: "ticket/edit/:ticketNumber",
    element: <TicketForm />,
    title: "ticket-editing",
    requiredLogin: true,
    requiredPermission: [PERMISSIONS.CUSTOMER_SUPPORT.TICKET.EDIT],
  },
  {
    path: "lead",
    element: <AppLead />,
    title: "lead listing",
    requiredLogin: true,
    requiredPermission: [PERMISSIONS.CONTACTS.LEAD.LISTING],
  },
  {
    path: "lead/upload-history-listing",
    element: <AppUploadHistory />,
    title: "upload history listing",
    requiredLogin: true,
    requiredPermission: [PERMISSIONS.CONTACTS.LEAD.HISTORY_UPLOAD],
  },
  {
    path: "lead/edit/:leadId",
    element: <LeadForm />,
    title: "lead-editing",
    requiredLogin: true,
    requiredPermission: [
      PERMISSIONS.CONTACTS.LEAD.LISTING,
      PERMISSIONS.CONTACTS.LEAD.EDIT,
    ],
  },
  {
    path: "prospect",
    element: <AppProspect />,
    title: "prospect listing",
    requiredLogin: true,
    requiredPermission: [PERMISSIONS.CONTACTS.PROSPECT.LISTING],
  },
  {
    path: "prospect/edit/:prospectId",
    element: <ProspectForm />,
    title: "prospect edit",
    requiredLogin: true,
    requiredPermission: [PERMISSIONS.CONTACTS.PROSPECT.EDIT],
  },
  {
    path: "client",
    element: <AppClientListing />,
    title: "client listing",
    requiredLogin: true,
    requiredPermission: [PERMISSIONS.CONTACTS.CLIENT.LISTING],
  },
  {
    path: "client/edit/:clientId",
    element: <ClientForm />,
    title: "client edit",
    requiredLogin: true,
    requiredPermission: [PERMISSIONS.CONTACTS.CLIENT.EDIT],
  },
  {
    path: "programs",
    element: <Programs />,
    title: "program listing",
    requiredLogin: true,
    requiredPermission: [
      PERMISSIONS.CAUSE2FUND.PROGRAM.VIEW_PROGRAM_AND_PROJECT,
    ],
  },
  {
    path: "programs/create",
    element: <ProgramForm />,
    title: "create program",
    requiredLogin: true,
    requiredPermission: [
      PERMISSIONS.CAUSE2FUND.PROGRAM.MANAGE_PROGRAM_AND_PROJECT,
    ],
  },
  {
    path: "programs/edit-program/:id",
    element: <ProgramForm />,
    title: "edit program",
    requiredLogin: true,
    requiredPermission: [
      PERMISSIONS.CAUSE2FUND.PROGRAM.MANAGE_PROGRAM_AND_PROJECT,
    ],
  },
  {
    path: "users-roles",
    element: <UsersAndRoles />,
    title: "users-and-roles",
    requiredLogin: true,
    requiredPermission: [
      PERMISSIONS.USERS_ROLES.USERS_LISTING,
      PERMISSIONS.USERS_ROLES.ROLES_LISTING,
    ],
  },
  {
    path: "role/create",
    element: <AppRoleForm />,
    title: "Create role",
    requiredLogin: true,
    requiredPermission: [PERMISSIONS.USERS_ROLES.CREATE_ROLES],
  },
  {
    path: "role/edit/:roleId",
    element: <AppRoleForm />,
    title: "Edit role",
    requiredLogin: true,
    requiredPermission: [PERMISSIONS.USERS_ROLES.EDIT_ROLES],
  },
  {
    path: "consumers",
    element: <Consumers />,
    title: "consumer listing",
    requiredLogin: true,
    requiredPermission: [PERMISSIONS.CONSUMERS.LISTING],
  },
  {
    path: "merchants",
    element: <Merchants />,
    title: "merchant listing",
    requiredLogin: true,
    requiredPermission: [PERMISSIONS.MERCHANTS.LISTING],
  },
];

export const FORCE_NAVIGATION_PATH = [
    "/catalog/giftcards",
    "/customer/kyc/list",
    "/product/konnect-pak",
    "/ad-maker",
    "/settings/exchange-rates",
    "/settings/commission-configuration",
    "/accounting/transaction-withdrawal"
]