import { Box, Link, Pressable, Text, useTheme } from "native-base";
import ProgramService from "../../services/program/programService";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { AppButton } from "shared-components";
import CoreTable from "../core-table/CoreTable";
import { Header } from "../../types/table";
import { phoneNumberStyle } from "../lead-listing/LeadListing.style";
import { formatLogoStyle, linkStyle } from "./ProgramListing.style";
import ProtectedContent from "../protected-content/ProtectedContent";
import { PERMISSIONS } from "../../constants/permisstion";

const ProgramListing = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const viewDetails = (id: string) => {
    navigate(`/programs/edit-program/${id}`);
  };

  const theme = useTheme();

  const formatProgramName = (programName: string, item: any) => {
    return (
      <ProtectedContent
        permissions={PERMISSIONS.CAUSE2FUND.PROGRAM.MANAGE_PROGRAM_AND_PROJECT}
        alt={<Text lineHeight={"56px"}>{programName}</Text>}
        children={
          <Pressable onPress={() => viewDetails(item.id)}>
            <Text {...phoneNumberStyle(theme)}>{programName}</Text>
          </Pressable>
        }
      ></ProtectedContent>
    );
  };

  const formatLogo = (logoUrl: string) => {
    return (
      <Box lineHeight="56px" mt={1.5}>
        <img style={formatLogoStyle(theme)} src={logoUrl} alt="" />
      </Box>
    );
  };

  const formatWebsiteUrl = (websiteUrl: string) => {
    return (
      <Link href={websiteUrl} isExternal={true} width="100%">
        <Text lineHeight="56px" {...linkStyle(theme)} isTruncated>
          {websiteUrl}
        </Text>
      </Link>
    );
  };

  const formatSocialUrls = (social: string, item: any) => {
    return (
      <Box mt={1}>
        {item.fbUrl && (
          <Pressable>
            <Link href={item.fbUrl} isExternal={true}>
              <Text {...linkStyle(theme)}>{t("programs.facebook")}</Text>
            </Link>
          </Pressable>
        )}
        {item.twitterUrl && (
          <Pressable>
            <Link href={item.twitterUrl} isExternal={true}>
              <Text {...linkStyle(theme)}>{t("programs.x-twitter")}</Text>
            </Link>
          </Pressable>
        )}
      </Box>
    );
  };

  const formatProtocol = (protocolUrl: string) => {
    return (
      <Pressable>
        <Link href={protocolUrl} isExternal={true}>
          <Text {...linkStyle(theme)} lineHeight="56px">
            {t("programs.view")}
          </Text>
        </Link>
      </Pressable>
    );
  };

  const headers1: Header[] = [
    {
      value: "logoUrl",
      label: t("programs.logo"),
      format: formatLogo,
      width: 160,
    },
    {
      value: "name",
      label: t("programs.program-name"),
      format: formatProgramName,
      width: 272,
    },
    {
      value: "ownerName",
      label: t("programs.owner"),
      width: 180,
    },
    {
      value: "phoneNumber",
      label: t("programs.phone-number"),
      width: 160,
    },
    {
      value: "address",
      label: t("programs.address"),
      width: 240,
    },
    {
      value: "email",
      label: t("programs.email"),
      width: 240,
    },
    {
      value: "websiteUrl",
      label: t("programs.website"),
      format: formatWebsiteUrl,
      width: 216,
    },
    {
      value: "socialURLs",
      label: t("programs.social-urls"),
      format: formatSocialUrls,
      width: 120,
    },
    {
      value: "protocolUrl",
      label: t("programs.protocol"),
      format: formatProtocol,
      width: 100,
    },
  ];

  const fetchDatas = useCallback((params: any) => {
    return ProgramService.getProgramListing(params);
  }, []);

  const actionRow = () => {
    return (
      <>
        <ProtectedContent
          permissions={
            PERMISSIONS.CAUSE2FUND.PROGRAM.MANAGE_PROGRAM_AND_PROJECT
          }
        >
          <Box ml={3}>
            <AppButton
              label={t("users-roles.create")}
              icon="/icons/add-white.svg"
              onPress={() => {
                navigate("/programs/create");
              }}
            />
          </Box>
        </ProtectedContent>
      </>
    );
  };

  return (
    <Box>
      <CoreTable
        headers={headers1}
        fetchDatas={fetchDatas}
        actionRow={actionRow()}
        isBorder={true}
        isSearch={false}
        title={t("programs.program-list")}
      />
    </Box>
  );
};

export default ProgramListing;
