import { showModal } from "../../store/modal/modal";
import store from "../../store";
import i18n from "../../i18n";

const errorResponseHandler = (error: any) => {
  const statusCode = error?.response?.status;
  if (statusCode === 401) {
    handleError401();
  }
  if (statusCode >= 500) {
    handleError500();
  }
  if (error?.code === "ERR_NETWORK") {
    handleErrorNetwork();
  }
};

const handleError401 = () => {
  const payload = {
    type: "SESSTION_EXPIRED",
    modalContent: {
      title: i18n.t("sessionExpired.title"),
      content: i18n.t("sessionExpired.content"),
      confirmText: i18n.t("common.ok"),
    },
  };
  store.dispatch(showModal(payload));
};

const handleError500 = () => {
  const payload = {
    type: "UNKNOWN_ERROR",
    modalContent: {
      content: i18n.t("unknownError"),
      confirmText: i18n.t("common.ok"),
    },
  };
  store.dispatch(showModal(payload));
};

const handleErrorNetwork = () => {
  const payload = {
    type: "INTERNET_ERROR",
    modalContent: {
      content: i18n.t("internetError"),
      confirmText: i18n.t("common.ok"),
    },
  };
  store.dispatch(showModal(payload));
};

export default errorResponseHandler;
