import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import languageEN from "./locales/en-US/translate.json";
import languageES from "./locales/es-CO/translate.json";
import languagePT from "./locales/pt-BR/translate.json";
import { resolveTheme } from "./theme";
import { resolveTenant } from "./tenantResolver";

const tenantConfig = resolveTenant();
const tenantBaseLanguage = resolveTheme(
  tenantConfig?.code,
)?.languageSupported?.find((lng) => lng.isBase);

i18n.use(initReactI18next).init({
  resources: {
    "en-US": languageEN,
    "es-CO": languageES,
    "pt-BR": languagePT,
  },
  lng: navigator.language,
  /* When react i18next not finding any language to as default in borwser */
  fallbackLng: tenantBaseLanguage?.code,
  /* debugger For Development environment */
  debug: false,
  ns: ["translations"],
  defaultNS: "translations",
  keySeparator: ".",
  interpolation: {
    escapeValue: false, // react already safes from xss
    formatSeparator: ",",
  },
});
export default i18n;
