import { InterfaceBoxProps } from "native-base/lib/typescript/components/primitives/Box";
import { textHeader1, textNodata } from "../../styles/text";

export const boxPropertyStyle = () => ({
  // width: '372px',
  height: "68px",
  gap: "8px",
  zIndex: "auto",
});

export const boxPropertyInsideStyle = (): InterfaceBoxProps => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  w: "100%",
});

export const textPropertyStyle = (theme?: any) => ({
  ...textHeader1(theme),
  height: "20px",
});

export const textOptionalPropertyStyle = (theme?: any) => ({
  ...textNodata(theme),
  height: "16px",
  fontWeight: 400,
  marginLeft: "4px",
});
