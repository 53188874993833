import { Box, Header, NotFound, useAppAuth } from 'shared-components';
import { logout } from '../../oidc/oidc-service';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'native-base';

const Unauthorized = () => {
    const { authData } = useAppAuth();
    const theme: any = useTheme();
    const { t } = useTranslation();
    const menu = [
        {
            text: t("common.logout"),
            icon: "/icons/logout.svg",
            action: (): void => {
                logout(authData.id_token);
            },
        },
    ];

    const notFoundProps = {
        title: t('not-found.title'),
        message: t('not-found.message')
    }
    return <Box>
        <Header
            logoUrl={theme?.logo}
            menu={menu}
            defaultUsername={t("username")}
        />
        <NotFound title={notFoundProps.title} message={notFoundProps.message}/>
    </Box>
}
export default Unauthorized;