import {
  Box,
  Flex,
  Image,
  Modal,
  Pressable,
  Spinner,
  Text,
  useTheme,
} from "native-base";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { ReactSVG } from "react-svg";
import { format } from "date-fns";
import { DATE_FORMAT_MM_DD_YYYY } from "../../constants/formatDate";
import AssociatedOrderService from "../../services/associated-order/associatedOrderService";
import {
  borderStyle,
  textBody16,
  textHeader20,
  textNodata,
} from "../../styles/text";
import { AssociatedOrderProps, Order } from "../../types/associatedOrder";
import SearchBox from "../search-box/SearchBox";
import {
  boxItems,
  boxItemStyle,
  boxSelectedValue,
  gap2,
  idStyle,
  optionContainer,
  phoneStyle,
  shadowStyle,
  spinnerStyle,
  gap4,
} from "./AssociatedOrder.style";
import AssociatedOrderDetails from "../associated-order-details/AssociatedOrderDetails";

const AssociatedOrder: React.FC<AssociatedOrderProps> = ({
  initValue,
  ticketNumber,
  onValueChange,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState(initValue);
  const [data, setData] = useState<Order[]>([]);
  const [search, setSearch] = useState<string | undefined>("");
  const [isLoading, setIsLoading] = useState(false);
  const boxUserRef = useRef<HTMLDivElement | null>(null);
  const dropdownRef = useRef<HTMLDivElement | null>(null);
  const [pageIndex, setPageIndex] = useState(1);
  const [isScroll, setIsCroll] = useState(true);
  const [isFetchData, setIsFetchData] = useState(false);
  const [isMargin, setIsMargin] = useState(false);
  const [openOrderDetails, setOpenOrderDetails] = useState(false);

  useEffect(() => {
    setSelectedValue(initValue);
  }, [initValue]);

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageIndex]);

  const getData = () => {
    if ((isScroll || isFetchData) && isOpen) {
      setIsLoading(true);
      AssociatedOrderService.getOrderListing(pageIndex, 10, search)
        .then((response) => {
          const { result } = response.data;
          if (result.data.length > 0) {
            setIsCroll(pageIndex < result.totalPages);
            if (result.data.length > 0) {
              const newData = result.data;
              setData((prevData) => [...prevData, ...newData]);
            }
          }
        })
        .finally(() => {
          setIsLoading(false);
          setIsFetchData(false);
          if (boxUserRef.current) {
            boxUserRef.current.scrollTop = boxUserRef.current.scrollHeight;
          }
        });
    }
  };

  const handleScroll = () => {
    if (boxUserRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = boxUserRef.current;
      if (scrollTop + clientHeight >= scrollHeight) {
        setPageIndex((prevState) => prevState + 1);
      }
    }
  };

  useEffect(() => {
    if (boxUserRef.current) {
      const { scrollHeight, clientHeight } = boxUserRef.current;
      setIsMargin(scrollHeight > clientHeight);
      boxUserRef.current.addEventListener("scroll", handleScroll);
    }
    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      boxUserRef.current?.removeEventListener("scroll", handleScroll);
    };
  }, [isOpen, data]);

  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);

  const clearData = () => {
    setData([]);
    setPageIndex(1);
    setIsCroll(true);
    setIsFetchData(!(pageIndex > 1));
  };

  useEffect(() => {
    if (isFetchData) {
      getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetchData]);

  const openOrderMenu = () => {
    setIsOpen(true);
    clearData();
    setIsLoading(true);
  };

  const formatDate = (timestamp: number) => {
    if (!timestamp) {
      return undefined;
    }
    const date = new Date(timestamp);
    return format(date, DATE_FORMAT_MM_DD_YYYY);
  };

  const renderLoading = () => {
    const margin = data?.length > 0 ? "20px" : "37px";
    return (
      <>
        {isLoading && (
          <Box marginTop={margin} marginBottom={margin}>
            <Spinner {...spinnerStyle(theme)} position="absolute" size="lg" />
          </Box>
        )}
        {!isLoading && data.length === 0 && (
          <Flex direction="column" alignItems="center" p={6}>
            <ReactSVG src={"/icons/no-data.svg"} />
            <Text {...textNodata(theme)}>{t("users-roles.no-data")}</Text>
          </Flex>
        )}
      </>
    );
  };

  const renderAssociatedOrderListing = () => {
    return (
      <>
        {!selectedValue && (
          <Box>
            <SearchBox
              placeholder={t("associated-order.placeholder")}
              onFocus={() => {
                if (!isOpen) {
                  openOrderMenu();
                }
              }}
              onKeySearchChange={(event: string | undefined) => {
                openOrderMenu();
                setSearch(event);
              }}
              autoFocus={isOpen}
            />
            {isOpen && (
              <Box
                {...optionContainer(theme)}
                position={"absolute"}
                ref={dropdownRef}
              >
                {data && data.length > 0 && (
                  <Box {...boxItems()} ref={boxUserRef} testID="box-user-ref">
                    {data.map((item: Order, index: number) => (
                      <React.Fragment key={item?.orderNumber}>
                        <Pressable
                          {...boxItemStyle(
                            theme,
                            data.length - 1 > index,
                            isMargin
                          )}
                          onPress={() => {
                            setIsOpen(false);
                            setSelectedValue(item);
                            setSearch("");
                            onValueChange(item);
                          }}
                        >
                          <Text {...textBody16(theme)}>
                            #{item?.orderNumber}
                          </Text>
                          <Box flexDirection="row" {...gap2}>
                            <Image
                              source={{ uri: "/icons/user.svg" }}
                              w={4}
                              h={4}
                              alt=""
                            />
                            <Text {...phoneStyle(theme)} isTruncated>
                              {item?.fullName}
                            </Text>
                          </Box>
                          <Box flexDirection="row" {...gap2}>
                            <Image
                              source={{ uri: "/icons/time.svg" }}
                              w={4}
                              h={4}
                              alt=""
                            />
                            <Text {...phoneStyle(theme)} isTruncated>
                              {formatDate(item.orderDate)}
                            </Text>
                          </Box>
                          <Box flexDirection="row" {...gap2}>
                            <Image
                              source={{ uri: "/icons/store.svg" }}
                              w={4}
                              h={4}
                              alt=""
                            />
                            <Text {...phoneStyle(theme)} isTruncated>
                              {item?.merchantName}
                            </Text>
                          </Box>
                          <Box flexDirection="row" {...gap2}>
                            <Image
                              source={{ uri: "/icons/configuration.svg" }}
                              w={4}
                              h={4}
                              alt=""
                            />
                            <Text {...phoneStyle(theme)} isTruncated>
                              {item?.productName}
                            </Text>
                          </Box>
                          <Box flexDirection="row" {...gap2}>
                            <Image
                              source={{ uri: "/icons/price.svg" }}
                              w={4}
                              h={4}
                              alt=""
                            />
                            <Text {...phoneStyle(theme)} isTruncated>
                              {item?.orderTotal}
                            </Text>
                          </Box>
                        </Pressable>
                        {data.length > 1 && data.length - 1 > index && (
                          <Box
                            {...borderStyle(theme)}
                            width={isMargin ? "calc(100% - 8px)" : "100%"}
                          ></Box>
                        )}
                      </React.Fragment>
                    ))}
                  </Box>
                )}
                {renderLoading()}
              </Box>
            )}
          </Box>
        )}
      </>
    );
  };

  const unAssociatedOrderWithTicket = () => {
    if (ticketNumber && selectedValue?.orderNumber) {
      AssociatedOrderService.unAssociatedOrderWithTicket(
        ticketNumber,
        selectedValue?.orderNumber
      ).then((res) => {});
    }
  };

  const renderSelectedAssociatedOrder = () => {
    return (
      <>
        {selectedValue && (
          <Pressable
            testID="btn-order"
            onPress={() => setOpenOrderDetails(true)}
            {...boxSelectedValue(theme)}
          >
            <Box {...gap4} w="100%">
              <Text {...idStyle(theme)} mr={6}>
                #{selectedValue?.orderNumber}
              </Text>
              <Box flexDirection="row" {...gap2}>
                <Image source={{ uri: "/icons/user.svg" }} w={4} h={4} alt="" />
                <Text {...phoneStyle(theme)} isTruncated>
                  {selectedValue?.fullName}
                </Text>
              </Box>
              <Box flexDirection="row" {...gap2}>
                <Image source={{ uri: "/icons/time.svg" }} w={4} h={4} alt="" />
                <Text {...phoneStyle(theme)} isTruncated>
                  {formatDate(selectedValue.orderDate)}
                </Text>
              </Box>
              <Box flexDirection="row" {...gap2}>
                <Image
                  source={{ uri: "/icons/store.svg" }}
                  w={4}
                  h={4}
                  alt=""
                />
                <Text {...phoneStyle(theme)} isTruncated>
                  {selectedValue?.merchantName}
                </Text>
              </Box>
              <Box flexDirection="row" {...gap2}>
                <Image
                  source={{ uri: "/icons/configuration.svg" }}
                  w={4}
                  h={4}
                  alt=""
                />
                <Text {...phoneStyle(theme)} isTruncated>
                  {selectedValue?.productName}
                </Text>
              </Box>
              <Box flexDirection="row" {...gap2}>
                <Image
                  source={{ uri: "/icons/price.svg" }}
                  w={4}
                  h={4}
                  alt=""
                />
                <Text {...phoneStyle(theme)} isTruncated>
                  {selectedValue?.orderTotal}
                </Text>
              </Box>
            </Box>
            <Pressable
              position="absolute"
              right="8px"
              top="10px"
              onPress={() => {
                setSelectedValue(undefined);
                unAssociatedOrderWithTicket();
              }}
            >
              <Image source={{ uri: "/icons/close.svg" }} w={6} h={6} alt="" />
            </Pressable>
          </Pressable>
        )}
      </>
    );
  };

  return (
    <Box>
      <Modal
        isOpen={openOrderDetails}
        onClose={() => setOpenOrderDetails(false)}
      >
        <Modal.Content
          minHeight="100vh"
          marginLeft="auto"
          marginRight="0"
          maxWidth="728"
          borderRadius="0"
          overflow="auto"
        >
          {openOrderDetails && (
            <AssociatedOrderDetails
              ticketNumber={ticketNumber}
              id={selectedValue?.orderNumber}
              setOpen={setOpenOrderDetails}
            />
          )}
        </Modal.Content>
      </Modal>

      <Box {...shadowStyle()}>
        <Text {...textHeader20(theme)}>{t("associated-order.title")}</Text>
        {renderAssociatedOrderListing()}
        {renderSelectedAssociatedOrder()}
      </Box>
    </Box>
  );
};

export default AssociatedOrder;
