import { Box } from "shared-components";
import { useCallback, useState } from "react";
import { format } from "date-fns";
import { useTranslation } from "react-i18next";
import { Modal, Pressable, Text, useTheme } from "native-base";
import { handleFiltersConsumerListing } from "../../utils/filters";
import { Header } from "../../types/table";
import {
  dotStyle,
  filterStyle,
  textStyle,
} from "../lead-listing/LeadListing.style";
import { ReactSVG } from "react-svg";
import CoreTable from "../core-table/CoreTable";
import { DATE_TIME_FORMAT_MM_DD_YYYY_H_MM } from "../../constants/formatDate";
import ConsumerListingFilter from "../consumer-listing-filter/ConsumerListingFilter";
import ConsumerService from "../../services/consumer/consumerService";

interface StatusDisplay {
  [key: string]: {
    label: string;
    color: string;
  };
}

const ConsumerListing = () => {
  const { t } = useTranslation();
  const FORMAT_DATE = DATE_TIME_FORMAT_MM_DD_YYYY_H_MM;
  const [isOpenFilter, setIsOpenFilter] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [filters, setFilters] = useState({
    status: "",
    kycStatus: "",
    createdTime: {
      from: "",
      to: "",
    },
    countryCode: "",
    fullName: "",
    email: "",
    phoneNumber: "",
  });
  const [isFilterUsed, setIsFilterUsed] = useState(false);
  const theme: any = useTheme();

  const filterProps = {
    statusOptions: [
      {
        label: "ALL",
        value: t("ticket-listing-filter.status-options.all"),
      },
      {
        label: "ACTIVE",
        value: t("status.active"),
      },
      {
        label: "INACTIVE",
        value: t("status.inactive"),
      },
    ],
    kycLevelOptions: [
      {
        label: "ALL",
        value: t("ticket-listing-filter.status-options.all"),
      },
      {
        label: "LV1",
        value: t("consumers.level-1"),
      },
      {
        label: "LV2",
        value: t("consumers.level-2"),
      },
    ],
    textObject: {
      filter: t("client-listing.filter.title"),
      apply: t("client-listing.filter.apply"),
      clearAll: t("client-listing.filter.clear-all"),
      status: t("ticket-listing-filter.status"),
      kycApprovalDate: t("client-listing.filter.kyc-approval-date"),
      invalidFormatErrMsg: t("errors.standard002", {
        fieldName: t("client-listing.filter.kyc-approval-date"),
      }),
      earlierErrMsg: t("errors.standard093"),
      selectedFromErrMsg: t("errors.standard092", {
        fieldName: t("client-listing.filter.start-date"),
      }),
      selectedToErrMsg: t("errors.standard092", {
        fieldName: t("client-listing.filter.end-date"),
      }),
      selectedRangeOverErrMsg: t("errors.standard094"),
    },
    dropDownIcon: "/icons/arrow-down.svg",
    arrowIcon: "/icons/arrow-long.svg",
    selectedIcon: "/icons/checkmark.svg",
    closeFilterIcon: "/icons/filter-close.svg",
    calendarIcon: "/icons/calendar.svg",
    arrowUp: "/icons/mini-arrow-up.svg",
    arrowDown: "/icons/mini-arrow.svg",
    arrowLeft: "/icons/chevron-left.svg",
    arrowRight: "/icons/chevron-right.svg",
    onFiltersChanged: (filtersChanged: any) => {
      setFilters(filtersChanged);
      setIsOpenFilter(false);
      setIsFilterUsed(filters !== filtersChanged);
    },
  };

  const formatDate = (timestamp: number) => {
    if (!timestamp) {
      return null;
    }
    const date = new Date(timestamp);
    return <Text {...textStyle(theme)}>{format(date, FORMAT_DATE)}</Text>;
  };

  const formatKycLevel = (kycLevel: string) => {
    return (
      <Text {...textStyle(theme)}>
        {kycLevel === "APPROVED_VERIFIED"
          ? t("consumers.level-2")
          : t("consumers.level-1")}
      </Text>
    );
  };

  const statusDisplay: StatusDisplay = {
    ACTIVE: {
      label: t("status.active"),
      color: theme?.appPalette?.green.green100,
    },
    INACTIVE: {
      label: t("status.inactive"),
      color: theme?.appPalette?.red.red100,
    },
  };

  const formatStatus = (status: string) => {
    const statusColor = statusDisplay[status]?.color;
    return (
      <Text color={statusColor} lineHeight="56px">
        {statusDisplay[status]?.label}
      </Text>
    );
  };

  const headers: Header[] = [
    {
      value: "userId",
      label: t("consumers.id"),
      width: 80.1,
    },
    {
      value: "fullName",
      label: t("consumers.full-name"),
      width: 212,
    },
    {
      value: "email",
      label: t("consumers.email"),
      width: 212,
    },
    {
      value: "phoneNumber",
      label: t("consumers.phone-number"),
      width: 212,
    },
    {
      value: "country",
      label: t("consumers.country"),
      width: 212,
    },
    {
      value: "kycStatus",
      label: t("consumers.kyc-level"),
      format: formatKycLevel,
      width: 120,
    },
    {
      value: "createdDate",
      label: t("consumers.created-time"),
      format: formatDate,
      width: 212,
    },
    {
      value: "status",
      label: t("consumers.status"),
      format: formatStatus,
      width: 120,
    },
  ];

  const actionRow = () => {
    return (
      <>
        <Box>
          <Pressable
            testID="filter-icon"
            position="relative"
            {...filterStyle(theme)}
            onPress={() => {
              setIsOpenFilter(true);
            }}
          >
            <ReactSVG src="/icons/filter-icon.svg" />
            {isFilterUsed ? (
              <Box {...dotStyle(theme)} position="absolute" />
            ) : undefined}
          </Pressable>
        </Box>
      </>
    );
  };

  const fetchDatas = useCallback((params: any) => {
    return ConsumerService.list(params);
  }, []);

  const processedFilters = handleFiltersConsumerListing(filters);

  return (
    <Box>
      <Modal isOpen={isOpenFilter} onClose={() => setIsOpenFilter(false)}>
        <Modal.Content
          maxHeight="100vh"
          marginLeft="auto"
          marginRight="0"
          maxWidth="460"
          borderRadius="0"
        >
          {isOpenFilter ? (
            <ConsumerListingFilter
              {...filterProps}
              initFilters={filters}
              selectedCountry={selectedCountry}
              setSelectedCountry={setSelectedCountry}
              onCloseFilter={() => setIsOpenFilter(false)}
            />
          ) : undefined}
        </Modal.Content>
      </Modal>
      <CoreTable
        headers={headers}
        filters={filters}
        processedFilters={processedFilters}
        fetchDatas={fetchDatas}
        actionRow={actionRow()}
        isBorder={true}
        sort={{
          orderBy: "createdDate",
          orderDirection: "DESC",
        }}
        title={t("consumers.title")}
        isFilterUsed={isFilterUsed}
        setIsFilterUsed={setIsFilterUsed}
        maxLength={500}
      />
    </Box>
  );
};

export default ConsumerListing;
