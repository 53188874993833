import React, { useState } from "react";
import {Box, Flex, Pressable, Text, useTheme} from "native-base";
import {borderStyle, gap16, textBody16, textHeader16, textHeader24} from "../../styles/text";
import FilterForm from "../filter-form/FilterForm";
import {
  boxPropertyStyle,
} from "../ticket-listing-filter/TicketListingFilter.style";
import { useTranslation } from "react-i18next";
import {FeeDetails} from "../transaction-listing/TransactionListing";
import {
    filterBodyStyle,
    filterContainerStyle,
    filterHeaderStyle
} from "../filter-form/FilterForm.style";
import {filterStyle} from "../lead-listing/LeadListing.style";
import {ReactSVG} from "react-svg";

export interface FeeDetailProps {
    textObject: FeeDetailText;
    closeFilterIcon: string;
    onCloseFilter?: () => void;
    fee?: FeeDetails;
}

export interface FeeDetailText {
    filter: string;
}
const FeeDetail: React.FC<FeeDetailProps> = ({
                                                fee,

                                                                       textObject,
                                                                       onCloseFilter,
                                                                       closeFilterIcon,
                                                                     }) => {
  const theme = useTheme();

  const { t } = useTranslation();

    const businessCategoryFilter = () => {
        return (
            <Box {...boxPropertyStyle()}>
                {rowData("Remitter", fee?.remitter)}
                {rowData("Beneficiary", fee?.beneficiary)}
                <Box style={borderStyle(theme)} margin={0} marginBottom={"16px"}/>
                {rowData("Total", fee?.total)}
            </Box>
        );
    };
    const rowData = (name: string, value?: string) => {
        return (
            <Box flexDirection={"row"} justifyContent={"space-between"} marginBottom={"16px"}>
                <Text {...textBody16(theme)}>{name}</Text>
                <Text {...textHeader16(theme)}>{value ?? "-"}</Text>
            </Box>
        );
    };

    return (
        <Box {...filterContainerStyle(theme)}>
            <Box {...filterHeaderStyle(theme)}>
                <Flex direction="row" justify="space-between" align="center">
                    <Text {...textHeader24(theme)}>{textObject.filter}</Text>
                    <Pressable
                        testID={"filter-form-close-btn"}
                        onPress={() => onCloseFilter?.()}
                        {...filterStyle(theme)}
                        width="32px"
                        height="32px"
                        marginRight="-12px"
                    >
                        <ReactSVG src={closeFilterIcon} />
                    </Pressable>
                </Flex>
            </Box>
            <Box {...filterBodyStyle()}>
                <Box {...gap16}>
                    {businessCategoryFilter()}

                </Box>
            </Box>

        </Box>
    );
};
export default FeeDetail;
