import ApiClient from "../api-client/apiClient";
import Api from "../../configs/api";
import { ResponseType } from "axios/index";
import { resolveTenant } from "../../tenantResolver";

const getAuthHeaders = () => {
  const tenantConfig = resolveTenant();
  const authData = JSON.parse(
    localStorage.getItem(
      `oidc.at:${tenantConfig.oidcConfiguration.authority}`
    ) ?? "{}"
  );
  return {
    token: authData.access_token,
  };
};

const TicketService = {
  getTicketListing: (data: any) => {
    const url = `${Api.TICKET}${Api.SEARCH}`;
    const headers = getAuthHeaders();
    return ApiClient(headers).post(url, data);
  },

  createTicket: (data: any) => {
    return ApiClient().post(Api.TICKET, data);
  },

  getTicketDetail: (ticketNumber: string) => {
    const url = `${Api.TICKET}/${ticketNumber}`;
    return ApiClient().get(url);
  },

  getCallerInformation: (ticketNumber: string) => {
    const url = `${Api.TICKET}${Api.CALLER_INFORMATION}/${ticketNumber}`;
    const headers = getAuthHeaders();
    return ApiClient(headers).get(url);
  },

  editTicket: (data: any, ticketNumber: string | undefined, token: string) => {
    const url = `${Api.TICKET}/${ticketNumber}`;
    return ApiClient().put(url, data, {
      headers: {
        token,
      },
    });
  },

  addComment: (token: string, ticketId: string, message: string) => {
    const body = {
      message,
    };
    const url = `${Api.TICKET}/${ticketId}${Api.COMMENT_SUB}`;
    return ApiClient().post(url, body, {
      headers: {
        token,
      },
    });
  },

  downloadCallRecord: (call_uuid: string) => {
    const url = `${Api.TICKET}/call/recording/${call_uuid}/download`;
    const responseType: ResponseType = "blob";
    return ApiClient({}, undefined, responseType).post(url);
  },

  fetchComment(ticketId: string, pageIndex: number, pageSize: number) {
    const params = {
      pageIndex,
      pageSize,
      keyword: "",
      orderBy: "createdDate",
      orderDirection: "DESC",
    };
    const url = `${Api.TICKET}/${ticketId}${Api.COMMENT_SUB}`;
    return ApiClient().get(url, { params });
  },
  getActivityLogHistory(id: string, params: any) {
    const url = `${Api.TICKET}/${id}${Api.TICKET_HISTORY}`;
    return ApiClient().get(url, { params });
  },
};

export default TicketService;
