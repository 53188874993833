import { Box, Image, Pressable, Text, Toast, useTheme } from "native-base";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { AppButton, Popup, ToastMessage } from "shared-components";
import AuthorizationService from "../../auth/authorizationService";
import {
  DATE_TIME_FORMAT_EEEE_MM_DD_YYYY_H_MM_A,
  DATE_TIME_FORMAT_MM_DD_YYYY_H_MM_A,
} from "../../constants/formatDate";
import { PERMISSIONS } from "../../constants/permisstion";
import {
  borderStyle,
  oneLinesStyle,
  textBody16,
  textDesc12,
  textHeader16,
  textHeader20,
  textSuccess,
  twoLinesStyle,
} from "../../styles/text";
import {
  bodyStyle,
  boxFlexStyle,
  boxTitleStyle,
  btnStyle,
  gap12,
  gap16,
  gap4,
  headerStyle,
  imageStyle,
  labelStyle,
  toggleStyle,
  totalStyle,
  w100,
  w50,
} from "./AssociatedOrderDetails.style";
import { format } from "date-fns";
import AssociatedOrderService from "../../services/associated-order/associatedOrderService";
import { Target } from "../../constants/targetOrderCall";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHook";
import { hideModal, ModalStore } from "../../store/modal/modal";

interface AssociatedOrderDetailsProps {
  setOpen?: (open: boolean) => void;
  id: string | undefined;
  ticketNumber?: string;
}

const AssociatedOrderDetails: React.FC<AssociatedOrderDetailsProps> = ({
  setOpen,
  id,
  ticketNumber,
}) => {
  const theme: any = useTheme();
  const { t } = useTranslation();
  const [isOrder, setIsOrder] = useState(true);
  const [isCustomer, setIsCustomer] = useState(true);
  const [isMerchant, setIsMerchant] = useState(true);
  const [isDriver, setIsDriver] = useState(true);
  const [data, setData] = useState<any>();
  const FORMAT_DATE = DATE_TIME_FORMAT_MM_DD_YYYY_H_MM_A;
  const callText = "associated-order.call";
  const arrowDown = "/icons/down-arrow.svg";

  const deliveryOptions = {
    PICKUP: "PICKUP",
    DELIVERY: "DELIVERY",
    PAYNOW: "PAYNOW",
  };

  const status: any = {
    NEW: {
      value: t("status.new"),
      color: theme?.appPalette?.warning.warning100,
    },
    READY: {
      value: t("status.ready"),
      color: theme?.appPalette?.info,
    },
    PREPARING: {
      value: t("status.preparing"),
      color: theme?.appPalette?.warning.warning100,
    },
    COMPLETE: {
      value: t("status.completed"),
      color: theme?.appPalette?.success?.success100,
    },
  };

  const deliveryDisplay: { [key: string]: string } = {
    PICKUP: t("associated-order.pickup"),
    DELIVERY: t("associated-order.delivery"),
    PAYNOW: t("associated-order.pay-now"),
  };

  const hasViewSensitiveDataPermission = useMemo(() => {
    return AuthorizationService.hasPermissions(
      PERMISSIONS.USERS_ROLES.SENSITIVE_DATA.VIEW_SENSITIVE_DATA
    );
  }, []);

  useEffect(() => {
    if (id) {
      AssociatedOrderService.getOrderDetails(id).then((res) => {
        setData(res.data.result);
      });
    }
  }, [id]);

  const renderProducts = () => {
    return (
      <Box {...gap16}>
        {data.products.map((item: any, index: number) => (
          <>
            <Box {...gap16} flexDirection="row">
              <Image source={{ uri: item.imageUrl }} {...imageStyle(theme)} />
              <div style={w100}>
                {item.name && (
                  <div
                    style={{
                      ...twoLinesStyle,
                      ...textHeader16(theme),
                    }}
                  >
                    {item.name}
                  </div>
                )}
                {item.description && (
                  <div style={{ ...oneLinesStyle, ...labelStyle(theme) }}>
                    {item.description}
                  </div>
                )}
                <Box
                  flexDirection="row"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Text {...labelStyle(theme)}>x{item.quantity}</Text>
                  <Text {...textBody16(theme)}>{item.totalPrice}</Text>
                </Box>
              </div>
            </Box>
            {data.products.length > 1 && data.products.length - 1 > index && (
              <Box marginLeft="80px">
                <Box {...borderStyle(theme)}></Box>
              </Box>
            )}
          </>
        ))}
        <Box {...totalStyle(theme)}>
          <Box flexDirection="row" justifyContent="flex-end">
            <Box flexDirection="row" justifyContent="flex-end">
              <Text
                width="calc(100% - 200px)"
                textAlign="end"
                {...labelStyle(theme)}
              >
                {t("associated-order.subtotal")}
              </Text>
              <Text width="200px" textAlign="end" {...textBody16(theme)}>
                {data.productTotal}
              </Text>
            </Box>
          </Box>
          <Box flexDirection="row" justifyContent="flex-end">
            <Box flexDirection="row" justifyContent="flex-end">
              <Text
                width="calc(100% - 200px)"
                textAlign="end"
                {...labelStyle(theme)}
              >
                {t("associated-order.tax")}
              </Text>
              <Text width="200px" textAlign="end" {...textBody16(theme)}>
                {data.tax}
              </Text>
            </Box>
          </Box>
          <Box flexDirection="row" justifyContent="flex-end">
            <Box flexDirection="row" justifyContent="flex-end">
              <Text
                width="calc(100% - 200px)"
                textAlign="end"
                {...labelStyle(theme)}
              >
                {t("Coupon")}
              </Text>
              <Text width="200px" textAlign="end" {...textBody16(theme)}>
                {data.amountCouponUsed}
              </Text>
            </Box>
          </Box>
          <Box flexDirection="row" justifyContent="flex-end">
            <Box flexDirection="row" justifyContent="flex-end">
              <Text
                width="calc(100% - 200px)"
                textAlign="end"
                {...labelStyle(theme)}
              >
                {t("Gift Card")}
              </Text>
              <Text width="200px" textAlign="end" {...textBody16(theme)}>
                {data.amountGiftCardUsed}
              </Text>
            </Box>
          </Box>
          <Box flexDirection="row" justifyContent="flex-end">
            <Box flexDirection="row" justifyContent="flex-end">
              <Box width="calc(100% - 200px)" textAlign="end">
                <Text {...labelStyle(theme)} textAlign="end">
                  {t("associated-order.point-redemption")}
                </Text>
                <Text {...textDesc12(theme)} textAlign="end">
                  {t("associated-order.used-points", {
                    value: data.pointUsed,
                  })}
                </Text>
              </Box>
              <Text width="200px" textAlign="end" {...textBody16(theme)}>
                {data.pointRedemption}
              </Text>
            </Box>
          </Box>
        </Box>
        <Box flexDirection="row" justifyContent="flex-end">
          <Box width="calc(100% - 200px)" textAlign="end">
            <Text {...textHeader16(theme)} textAlign="end">
              {t("associated-order.order-total")}
            </Text>
          </Box>
          <Text width="200px" textAlign="end" {...textHeader16(theme)}>
            {data.total}
          </Text>
        </Box>
      </Box>
    );
  };

  const orderInformation = () => {
    return (
      <Box {...gap16}>
        <Pressable
          onPress={() => setIsOrder(!isOrder)}
          {...boxTitleStyle(theme)}
          testID="btn-order"
        >
          <Text {...textHeader16(theme)}>
            {t("associated-order.order-information")}
          </Text>
          <Image {...toggleStyle(!isOrder)} source={{ uri: arrowDown }} />
        </Pressable>
        {isOrder && (
          <Box {...gap16} px={3}>
            <Box {...gap12}>
              <Box {...gap4}>
                <Text {...labelStyle(theme)}>
                  {t("associated-order.order-number")}
                </Text>
                <Text {...textHeader16(theme)}>{data?.orderNumber}</Text>
              </Box>
              <Box {...boxFlexStyle}>
                <div style={w50}>
                  <Text {...labelStyle(theme)}>
                    {t("associated-order.order-time")}
                  </Text>
                  <Text {...textHeader16(theme)}>
                    {format(new Date(data.orderTime), FORMAT_DATE)}
                  </Text>
                </div>
                <div style={w50}>
                  <Text {...labelStyle(theme)}>
                    {t("associated-order.order-status")}
                  </Text>
                  <Text
                    {...textSuccess(theme)}
                    color={status[data.orderStatus]?.color}
                  >
                    {status[data.orderStatus]?.value || data.orderStatus}
                  </Text>
                </div>
              </Box>
            </Box>
            <Box {...borderStyle(theme)}></Box>
            <Box {...gap12}>
              <Box {...gap4}>
                <Text {...labelStyle(theme)} fontWeight="700">
                  {t("associated-order.shipping-information")}
                </Text>
              </Box>
              <Box {...boxFlexStyle}>
                <div style={w50}>
                  <Text {...labelStyle(theme)}>
                    {t("associated-order.full-name")}
                  </Text>
                  <Text {...textHeader16(theme)}>
                    {data.deliveryInfo.receiverName || "-"}
                  </Text>
                </div>
                <div style={{ ...w50, display: "flex" }}>
                  <Box {...gap16} flexDirection="row" alignItems="center">
                    {hasViewSensitiveDataPermission && (
                      <Box {...gap4}>
                        <Text {...labelStyle(theme)}>
                          {t("associated-order.phone-number")}
                        </Text>
                        <Text {...textHeader16(theme)}>
                          {data.deliveryInfo.receiverPhone || "-"}
                        </Text>
                      </Box>
                    )}
                    <Box {...btnStyle}>
                      <AppButton
                        label={t(callText)}
                        onPress={() => orderCall(Target.DELIVER)}
                      />
                    </Box>
                  </Box>
                </div>
              </Box>
              <Box {...boxFlexStyle}>
                <div style={w50}>
                  <Text {...labelStyle(theme)}>
                    {t("associated-order.shipping-method")}
                  </Text>
                  <Text {...textHeader16(theme)}>
                    {deliveryDisplay[data.deliveryInfo.type]}
                  </Text>
                </div>
                {(data.deliveryInfo.type === deliveryOptions.PICKUP ||
                  data.deliveryInfo.type === deliveryOptions.PAYNOW) && (
                  <div style={w50}>
                    <Text {...labelStyle(theme)}>
                      {t("associated-order.pickup-time")}
                    </Text>
                    {data.deliveryInfo?.deliveryTime && (
                      <Text {...textHeader16(theme)}>
                        {format(
                          new Date(data.deliveryInfo?.deliveryTime),
                          DATE_TIME_FORMAT_EEEE_MM_DD_YYYY_H_MM_A
                        )}
                      </Text>
                    )}
                  </div>
                )}
              </Box>
              <Box {...gap4}>
                {data.deliveryInfo.type === deliveryOptions.PICKUP && (
                  <Text {...labelStyle(theme)}>
                    {t("associated-order.pickup-address")}
                  </Text>
                )}
                {data.deliveryInfo.type === deliveryOptions.DELIVERY && (
                  <Text {...labelStyle(theme)}>
                    {t("associated-order.delivery-address")}
                  </Text>
                )}
                {data.deliveryInfo.type === deliveryOptions.PAYNOW && (
                  <Text {...labelStyle(theme)}>
                    {t("associated-order.store-location")}
                  </Text>
                )}
                <Text {...textHeader16(theme)}>
                  {data.deliveryInfo.type === deliveryOptions.PAYNOW
                    ? data.deliveryInfo?.businessLocation
                    : data.deliveryInfo?.receiverAddress || "-"}
                </Text>
              </Box>
            </Box>
            {data.products.length > 0 && (
              <>
                <Box {...borderStyle(theme)}></Box>
                <Box {...gap12}>
                  <Box {...gap4}>
                    <Text {...labelStyle(theme)} fontWeight="700">
                      {t("associated-order.products")}
                    </Text>
                  </Box>
                  {renderProducts()}
                </Box>
              </>
            )}
          </Box>
        )}
      </Box>
    );
  };

  const customerInformation = () => {
    return (
      <>
        {data?.customer && (
          <Box {...gap16}>
            <Pressable
              onPress={() => setIsCustomer(!isCustomer)}
              {...boxTitleStyle(theme)}
              testID="btn-customer"
            >
              <Text {...textHeader16(theme)}>
                {t("associated-order.customer-information")}
              </Text>
              <Image
                source={{ uri: arrowDown }}
                {...toggleStyle(!isCustomer)}
              />
            </Pressable>
            {isCustomer && (
              <Box {...gap16} px={3}>
                <Box {...boxFlexStyle}>
                  <div style={w50}>
                    <Text {...labelStyle(theme)}>
                      {t("associated-order.customer-name")}
                    </Text>
                    <Text {...textHeader16(theme)}>
                      {data?.customer.name || "-"}
                    </Text>
                  </div>
                  {hasViewSensitiveDataPermission && (
                    <div style={w50}>
                      <Text {...labelStyle(theme)}>
                        {t("associated-order.customer-email")}
                      </Text>
                      <Text {...textHeader16(theme)}>
                        {data?.customer.email || "-"}
                      </Text>
                    </div>
                  )}
                </Box>
                {hasViewSensitiveDataPermission && (
                  <Box {...boxFlexStyle}>
                    <Box {...gap4}>
                      <Text {...labelStyle(theme)}>
                        {t("associated-order.customer-phone-number")}
                      </Text>
                      <Text {...textHeader16(theme)}>
                        {data?.customer.phone || "-"}
                      </Text>
                    </Box>
                  </Box>
                )}
                <Box {...btnStyle}>
                  <AppButton
                    label={t(callText)}
                    onPress={() => orderCall(Target.CUSTOMER)}
                  />
                </Box>
              </Box>
            )}
          </Box>
        )}
      </>
    );
  };

  const merchantInformation = () => {
    return (
      <>
        {data?.merchant && (
          <Box {...gap16}>
            <Pressable
              onPress={() => setIsMerchant(!isMerchant)}
              {...boxTitleStyle(theme)}
              testID="btn-merchant"
            >
              <Text {...textHeader16(theme)}>
                {t("associated-order.merchant-information")}
              </Text>
              <Image
                source={{ uri: arrowDown }}
                {...toggleStyle(!isMerchant)}
              />
            </Pressable>
            {isMerchant && (
              <Box {...gap16} px={3}>
                <Box {...boxFlexStyle}>
                  <div style={w50}>
                    <Text {...labelStyle(theme)}>
                      {t("associated-order.merchant-name")}
                    </Text>
                    <Text {...textHeader16(theme)}>
                      {data?.merchant.name || "-"}
                    </Text>
                  </div>
                  {hasViewSensitiveDataPermission && (
                    <div style={w50}>
                      <Text {...labelStyle(theme)}>
                        {t("associated-order.merchant-email")}
                      </Text>
                      <Text {...textHeader16(theme)}>
                        {data?.merchant.email || "-"}
                      </Text>
                    </div>
                  )}
                </Box>
                {hasViewSensitiveDataPermission && (
                  <Box {...boxFlexStyle}>
                    <Box {...gap4}>
                      <Text {...labelStyle(theme)}>
                        {t("associated-order.merchant-phone-number")}
                      </Text>
                      <Text {...textHeader16(theme)}>
                        {data?.merchant.phone || "-"}
                      </Text>
                    </Box>
                  </Box>
                )}
                <Box {...btnStyle}>
                  <AppButton
                    label={t(callText)}
                    onPress={() => orderCall(Target.MERCHANT)}
                  />
                </Box>
              </Box>
            )}
          </Box>
        )}
      </>
    );
  };

  const driverInformation = () => {
    return (
      <>
        {data?.driver && (
          <Box {...gap16}>
            <Pressable
              onPress={() => setIsDriver(!isDriver)}
              {...boxTitleStyle(theme)}
              testID="btn-driver"
            >
              <Text {...textHeader16(theme)}>
                {t("associated-order.driver-information")}
              </Text>
              <Image source={{ uri: arrowDown }} {...toggleStyle(!isDriver)} />
            </Pressable>
            {isDriver && (
              <Box {...gap16} px={3} pb={6}>
                <Box {...boxFlexStyle}>
                  <div style={w50}>
                    <Text {...labelStyle(theme)}>
                      {t("associated-order.driver-name")}
                    </Text>
                    <Text {...textHeader16(theme)}>
                      {data?.driver.name || "-"}
                    </Text>
                  </div>
                  {hasViewSensitiveDataPermission && (
                    <div style={w50}>
                      <Text {...labelStyle(theme)}>
                        {t("associated-order.driver-email")}
                      </Text>
                      <Text {...textHeader16(theme)}>
                        {data?.driver.email || "-"}
                      </Text>
                    </div>
                  )}
                </Box>
                {hasViewSensitiveDataPermission && (
                  <Box {...boxFlexStyle}>
                    <Box {...gap4}>
                      <Text {...labelStyle(theme)}>
                        {t("associated-order.driver-phone-number")}
                      </Text>
                      <Text {...textHeader16(theme)}>
                        {data?.driver.phone || "-"}
                      </Text>
                    </Box>
                  </Box>
                )}
                <Box {...btnStyle}>
                  <AppButton
                    label={t(callText)}
                    onPress={() => orderCall(Target.DRIVER)}
                  />
                </Box>
              </Box>
            )}
          </Box>
        )}
      </>
    );
  };

  const { isShowModal, modalContent } = useAppSelector(ModalStore);
  const dispatch = useAppDispatch();
  const orderCall = (target: string) => {
    const userProfile = JSON.parse(localStorage.getItem("userProfile") ?? "{}");
    if (id && ticketNumber) {
      AssociatedOrderService.orderCall(
        ticketNumber,
        target,
        userProfile?.agentExtension,
        id
      )
        .then((res) => {})
        .catch((reason) => {
          if (reason.response.data.code === "1010022") {
            showNotificationForVoiso();
          }
        });
    }
  };

  const showNotificationForVoiso = () => {
    const toastData = {
      message: t("voiso.agent_logged_off"),
      icon: "/icons/toast/error.svg",
    };
    return (
      <>
        {Toast.show({
          render: () => {
            return (
              <Box marginRight="32px" marginTop="14px">
                <ToastMessage
                  message={toastData.message}
                  icon={toastData.icon}
                  onClose={() => {
                    Toast.closeAll();
                  }}
                />
              </Box>
            );
          },
          placement: 'top-right'
        })}
      </>
    );
  };

  return (
    <Box>
      <Popup
        {...modalContent}
        showPopup={isShowModal}
        onChangeShowPopup={() => {
          dispatch(hideModal());
        }}
      />
      <Box {...headerStyle(theme)}>
        <Text {...textHeader20(theme)} fontWeight="700">
          {t("associated-order.order-details")}
        </Text>
        <Pressable
          onPress={() => setOpen && setOpen(false)}
          m={2.5}
          testID="btn-close"
        >
          <Image source={{ uri: "/icons/close.svg" }} w={6} h={6} />
        </Pressable>
      </Box>
      {data && (
        <Box {...bodyStyle}>
          {orderInformation()}
          {customerInformation()}
          {merchantInformation()}
          {driverInformation()}
        </Box>
      )}
    </Box>
  );
};

export default AssociatedOrderDetails;
