import ApiClient from "../api-client/apiClient";
import Api from "../../configs/api";

const ContactService = {
    getEmailListing: (senderCategory: string) => {
        const url = Api.GET_EMAIL_LISTING;
        const params = {
            senderCategory
        };
        return ApiClient().get(url, {params});
    },

    previewEmail: (data: any) => {
        const url = Api.PREVIEW_MAIL_CONTENT;
        return ApiClient().post(url, data);
    },

    sendEmail: (data: any) => {
        const url = Api.SEND_EMAIL;
        return ApiClient().post(url, data);
    },
};

export default ContactService;