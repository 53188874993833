import { ThemeProvider, AppAuthProvider, Popup } from "shared-components";
import { resolveTenant } from "./tenantResolver";
import { resolveTheme } from "./theme";
import I18nProvider from "./contexts/I18nProvider";
import { BrowserRouter } from "react-router-dom";
import Router from "./routes/Router";
import { hideModal, ModalStore } from "./store/modal/modal";
import { useAppDispatch, useAppSelector } from "./hooks/reduxHook";
import SocketHandler from "./services/socket-handler/SocketHandler";
import { useEffect } from "react";

function App() {
  useEffect(() => {
    const currentPath = window.location.pathname;
    if ('/authenticate' !== currentPath) {
      localStorage.setItem('redirectPath', currentPath);
    }
  }, []);
  const tenantConfig = resolveTenant();
  const { isShowModal, modalContent } = useAppSelector(ModalStore);
  const dispatch = useAppDispatch();

  return (
    <AppAuthProvider authConfigs={{ ...tenantConfig.oidcConfiguration }}>
      <I18nProvider>
        <ThemeProvider theme={resolveTheme(tenantConfig.code)}>
          <BrowserRouter>
            <Popup
              {...modalContent}
              showPopup={isShowModal}
              onChangeShowPopup={() => {
                dispatch(hideModal());
              }}
            />
            <SocketHandler />
            <Router />
          </BrowserRouter>
        </ThemeProvider>
      </I18nProvider>
    </AppAuthProvider>
  );
}

export default App;
