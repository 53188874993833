export class UserConstants {
  static readonly type = [
    {
      label: "MERCHANT",
      value: "user-type.merchant",
    },
    {
      label: "CONSUMER",
      value: "user-type.consumer",
    },
    {
      label: "LEAD",
      value: "user-type.lead",
    },
    {
      label: "GUEST",
      value: "user-type.guest",
    },
  ];
}
