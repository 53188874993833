import ApiClient from "../api-client/apiClient";
import Api from "../../configs/api";
import { ResponseType } from "axios";
import { resolveTenant } from "../../tenantResolver";

const getAuthHeaders = () => {
  const tenantConfig = resolveTenant();
  const authData = JSON.parse(
    localStorage.getItem(
      `oidc.at:${tenantConfig.oidcConfiguration.authority}`
    ) ?? "{}"
  );
  return {
    token: authData.access_token,
  };
};

const ClientService = {
  list: (data: any) => {
    const headers = getAuthHeaders();
    const url = `${Api.CLIENT}${Api.SEARCH}`;
    return ApiClient(headers).post(url, data);
  },
  fetchComment: (clientId: string, pageIndex: number, pageSize: number) => {
    const params = {
      pageIndex,
      pageSize,
    };
    const url = `${Api.CLIENT}/${clientId}${Api.COMMENT_SUB}`;
    return ApiClient().get(url, { params });
  },

  addComment: (clientId: string, message: string, token: string) => {
    const body = { message };
    const url = `${Api.CLIENT}/${clientId}${Api.COMMENT_SUB}`;
    return ApiClient().post(url, body, {
      headers: {
        token,
      },
    });
  },
  getActivityLogHistory(id: string, params: any) {
    const url = `${Api.CLIENT}/${id}${Api.HISTORY}`;
    return ApiClient().get(url, { params });
  },
  downloadCallRecord: (call_uuid: string) => {
    const url = `${Api.CLIENT}/call/recording/${call_uuid}/download`;
    const responseType: ResponseType = "blob";
    return ApiClient({}, undefined, responseType).post(url);
  },
  getClient: (prospectId: string) => {
    const headers = getAuthHeaders();
    const url = `${Api.CLIENT}/${prospectId}`;
    return ApiClient(headers).get(url);
  },
  editClient: (leadNumber: string | undefined, data: any) => {
    const url = `${Api.CLIENT}/${leadNumber}`;
    const tenantConfig = resolveTenant();
    let authData = JSON.parse(
      localStorage.getItem(
        `oidc.at:${tenantConfig.oidcConfiguration.authority}`,
      ) ?? "{}",
    );
    const headers = {
      token: authData.access_token,
    };
    return ApiClient(headers).put(url, data);
  },
};

export default ClientService;
