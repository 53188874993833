import ApiClient from "../api-client/apiClient";
import Api from "../../configs/api";

const ProgramService = {
  getProgramListing: (params: any) => {
    const url = `${Api.PROGRAM}${Api.LISTING}?pageIndex=${params.pageIndex}&pageSize=${params.pageSize}`;
    return ApiClient().get(url);
  },
  getProgramDetails: (id: string) => {
    const url = `${Api.PROGRAM}/${id}`;
    return ApiClient().get(url);
  },
  getProgramOwner: () => {
    const url = Api.GET_PROGRAM_OWNER;
    return ApiClient().get(url);
  },
  createProgram: (body: any) => {
    const url = Api.PROGRAM;
    return ApiClient().post(url, body);
  },
  editProgram: (body: any, id: string) => {
    const url = `${Api.PROGRAM}/${id}`;
    return ApiClient().put(url, body);
  },
};

export default ProgramService;