import { useEffect, useMemo, useState } from "react";
import {
  AppButton,
  AppInput,
  Box,
  DropDown,
  Flex,
  Pressable,
  Text,
} from "shared-components";
import {
  actionStyle,
  boxItemStyle,
  formBox,
  formStyle,
  gap6,
  headerText,
  informationStyle,
  informationTitleStyle,
  leftFormStyle,
  w50,
} from "./ClientForm.style";
import { useTheme, useToast } from "native-base";
import { ReactSVG } from "react-svg";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import AppActivityLog from "../activity-log/ActivityLog";
import ClientService from "../../services/client/clientService";
import { LeadConstants } from "../../constants/lead";
import {
  showNotification,
  showNotificationForVoiso,
} from "../../services/toast/toastService";
import CallService from "../../services/call/callService";
import { ResourceType } from "../../constants/resourceType";
import AppShowToast from "../show-toast/ShowToast";
import { errorText, textHeader16 } from "../../styles/text";
import { useForm, Controller } from "react-hook-form";
import AssigneeDropdown from "../assignee-dropdown/AssigneeDropdown";
import { OptionItem } from "../../types/dropdownProps";
import AuthorizationService from "../../auth/authorizationService";
import { PERMISSIONS } from "../../constants/permisstion";
import AppClientDiscussion from "../client-discussion/ClientDiscussion";
import AppClientActivityHistory from "../client-activity-history/ClientActivityHistory";
import {EmailTemplate, Language} from "../../types/sendEmailFormProps";
import ContactService from "../../services/contact/contactService";
import TenantConfigService from "../../services/tenant-config/tenantConfigService";
import SendEmailDrawer from "../send-email-drawer/SendEmailDrawer";

const ClientForm = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { clientId } = useParams();
  const toast = useToast();
  const back = () => {
    navigate("/client");
  };
  const [activityChips, setActivityChips] = useState([
    {
      label: t("lead-form.comments"),
      route: "comments",
      isActive: true,
      selectChips: (route: string) => {
        changeTab(route);
      },
      children: <AppClientDiscussion clientId={clientId ?? ""} />,
    },
    {
      label: t("lead-form.history"),
      route: "history",
      isActive: false,
      selectChips: (route: string) => {
        changeTab(route);
      },
      children: <AppClientActivityHistory clientId={clientId ?? ""} />,
    },
  ]);
  const {
    handleSubmit,
    control,
    setValue,
    getValues,
    formState: { errors },
  } = useForm();
  const [selectedAssignee, setselectedAssignee] = useState({
    text: "",
    value: "",
  });
  const [userInfo, setUserInfo] = useState<any>({});
  const [isOpenSendEmailDrawer, setIsOpenSendEmailDrawer] = useState(false);
  const [emailListing, setEmailListing] = useState<EmailTemplate[]>([]);
  const [supportedLanguages, setSupportedLanguages] = useState<Language[]>([]);
  const hasViewSensitiveDataPermission = useMemo(() => {
    return AuthorizationService.hasPermissions(
      PERMISSIONS.USERS_ROLES.SENSITIVE_DATA.VIEW_SENSITIVE_DATA
    );
  }, []);

  useEffect(() => {
    if (clientId) {
      ClientService.getClient(clientId).then((res) => {
        const { result } = res.data;
        setValue("firstName", result?.clientFirstName);
        setValue("lastName", result?.clientLastName);
        setValue("email", result?.userEmail);
        setValue("clientEmail", result?.clientEmail);
        setValue("phoneNumber", result?.clientPhoneNumber);
        setValue("status", result?.status);
        setValue("assigneeId", result?.assignee?.email);
        setValue("membershipPackageName", result?.clientPackageType);
        setselectedAssignee({
          text: result?.assignee?.fullName,
          value: result?.assignee?.email,
        });
      });
    }
  }, [setValue, clientId]);

  useEffect(() => {
    ContactService.getEmailListing('CLIENT').then((response) => {
      const {result} = response.data.result;
      if (result) {
        setEmailListing(result);
      }
    });

    TenantConfigService.getSupportedLanguages().then((response) => {
      const {result} = response.data.result;
      if (result) {
        setSupportedLanguages(result);
      }
    });
  }, []);

  const onSubmit = (data: any) => {
    const params = {
      assigneeId: data.assigneeId,
      status: data.status,
    };
    ClientService.editClient(clientId, params).then(() => {
      const toastData = {
        message: t("client-form.client-saved-successfully"),
        icon: "/icons/toast/check.svg",
      };
      showNotification(
        toast,
        <AppShowToast
          toastData={toastData}
          onClose={() => {
            toast.closeAll();
          }}
        ></AppShowToast>,
        5000
      );
    });
  };

  const renderForm = () => {
    return (
      <Box {...informationStyle(theme)}>
        <Box {...informationTitleStyle(theme)}>
          <Text {...textHeader16(theme)}>{t("lead-form.information")}</Text>
        </Box>
        <Box {...boxItemStyle}>
          <div style={w50}>
            <Text {...textHeader16(theme)}>
              {t("user-profile-listing.header-column.first-name")}
            </Text>
            <Controller
              name="firstName"
              control={control}
              render={() => (
                <AppInput
                  defaultValue={getValues("firstName")}
                  isDisabled={true}
                />
              )}
            />
          </div>
          <div style={w50}>
            <Text {...textHeader16(theme)}>
              {t("user-profile-listing.header-column.last-name")}
            </Text>
            <Controller
              name="lastName"
              control={control}
              render={() => (
                <AppInput
                  defaultValue={getValues("lastName")}
                  isDisabled={true}
                />
              )}
            />
          </div>
        </Box>
        {hasViewSensitiveDataPermission && (
          <>
            <Box {...boxItemStyle}>
              <div style={w50}>
                <Text {...textHeader16(theme)}>
                  {t("client-listing.header-column.client-phone-number")}
                </Text>
                <Controller
                  name="phoneNumber"
                  control={control}
                  render={() => (
                    <AppInput
                      defaultValue={getValues("phoneNumber")}
                      isDisabled={true}
                    />
                  )}
                />
              </div>
              <div style={w50}>
                <Text {...textHeader16(theme)}>
                  {t("client-listing.header-column.client-email")}
                </Text>
                <Controller
                  name="clientEmail"
                  control={control}
                  render={() => (
                    <AppInput
                      defaultValue={getValues("clientEmail")}
                      isDisabled={true}
                    />
                  )}
                />
              </div>
            </Box>
            <Box {...boxItemStyle}>
              <div style={w50}>
                <Text {...textHeader16(theme)}>
                  {t("ticket-listing.header-column.email")}
                </Text>
                <Controller
                  name="email"
                  control={control}
                  render={() => (
                    <AppInput
                      defaultValue={getValues("email")}
                      isDisabled={true}
                    />
                  )}
                />
              </div>
              <div style={w50}></div>
            </Box>
          </>
        )}
      </Box>
    );
  };

  const changeTab = (route: string) => {
    const chips = activityChips.map((chip: any) => {
      return {
        ...chip,
        isActive: route === chip.route,
      };
    });
    setActivityChips(chips);
  };

  const handleBack = () => {
    back();
  };

  const call = () => {
    CallService.callVoiso(
      getValues("phoneNumber"),
      ResourceType.CLIENT,
      clientId || ""
    ).catch((reason) => {
      if (reason.response.data.code === "1010022") {
        showNotificationForVoiso(t);
      }
    });
  };

  const closeSendEmailDrawer = () => {
    setIsOpenSendEmailDrawer(false);
  };

  const sendEmail = () => {
    setUserInfo({
      fullName: getValues("firstName") + ' ' + getValues("lastName"),
      email: getValues("email"),
      phoneNumber: getValues("phoneNumber")?.replace("+", ""),
      membershipPackageName: getValues("membershipPackageName"),
      userId: clientId,
    })
    setIsOpenSendEmailDrawer(true);
  };

  const header = () => {
    return (
      <Flex
        direction="row"
        justify="space-between"
        height="64px"
        alignItems="center"
      >
        <Flex direction="row" align="center">
          <Box height="24px" mr={3}>
            <Pressable
              testID={"back-btn"}
              onPress={() => {
                handleBack();
              }}
            >
              <ReactSVG src="/icons/back.svg" />
            </Pressable>
          </Box>
          <Text {...headerText(theme)}>{t("sidebar.contact.client")}</Text>
        </Flex>
        <Box {...actionStyle}>
          <Box>
            <AppButton
                variant="secondary"
                label={t("lead-listing.send-email")}
                onPress={sendEmail}
            />
          </Box>
          <Box>
            <AppButton
              variant="secondary"
              label={t("lead-form.btn-submit-edit")}
              onPress={handleSubmit(onSubmit)}
            />
          </Box>
          <Box>
            <AppButton label={t("lead-listing.call")} onPress={call} />
          </Box>
        </Box>
      </Flex>
    );
  };

  const DiscussionBox = () => {
    return (
      <Box {...leftFormStyle(theme)}>
        <AppActivityLog
          activityChips={activityChips}
          textHeaderLabel={t("lead-form.activity-logs")}
          showText={t("lead-form.show")}
        />
      </Box>
    );
  };

  const properties = () => {
    const statusOptions = LeadConstants.status.map(
      ({ label, value }: { label: string; value: string }) => ({
        label,
        value: t(value),
      })
    );
    return (
      <Box {...informationStyle(theme)}>
        <Box {...informationTitleStyle(theme)}>
          <Text {...textHeader16(theme)}>{t("lead-form.properties")}</Text>
        </Box>
        <Box {...gap6}>
          <Text {...textHeader16(theme)}>{t("lead-form.status")}</Text>
          <Controller
            name="status"
            control={control}
            rules={{
              required: true,
            }}
            render={({ field }) => (
              <DropDown
                data={statusOptions}
                defaultOptionLabel={getValues("status")}
                dropDownIcon={"/icons/arrow-down.svg"}
                selectedIcon={"/icons/checked.svg"}
                placeHolder={t("user-profile-listing.select")}
                action={(option: OptionItem) => {
                  field.onChange(option.label);
                }}
                isInvalid={errors.status?.type === "required"}
              />
            )}
          />
          {errors.status?.type === "required" && (
            <Text {...errorText(theme)}>
              {t("validate.standard025", { field: t("lead-form.status") })}
            </Text>
          )}
        </Box>
        <Box {...gap6} zIndex="-1">
          <Box flexDirection="row">
            <Text {...textHeader16(theme)}>
              {t("ticket-properties.assignee.label")}
            </Text>
            <Text {...textHeader16(theme)} fontWeight="400" ml={1.5}>
              ({t("lead-form.optional")})
            </Text>
          </Box>
          <Controller
            name="assigneeId"
            control={control}
            render={({ field }) => (
              <AssigneeDropdown
                initSelectedItem={selectedAssignee.value}
                isAll={false}
                placeholder={t("ticket-properties.assignee.placeholder")}
                onSelectedItem={(data) => field.onChange(data.value)}
                onValueChangeAssignee={(data) => setselectedAssignee(data)}
                selectedAssignee={selectedAssignee}
                dropdownIcon={"/icons/arrow-down.svg"}
                checkedIcon={"/icons/check.svg"}
                noDataIcon={"/icons/no-data.svg"}
                resourceType={ResourceType.CLIENT}
              />
            )}
          />
        </Box>
      </Box>
    );
  };

  const renderSendEmailDrawer = () => {
    return isOpenSendEmailDrawer ? (
        <SendEmailDrawer
            emailListing={emailListing}
            supportedLanguages={supportedLanguages}
            userInfo={userInfo}
            closeDrawer={closeSendEmailDrawer}
            isOpenDrawer={isOpenSendEmailDrawer}
        />
    ) : undefined;
  };

  return (
    <Box marginTop="-24px">
      {header()}
      {renderSendEmailDrawer()}
      <Flex direction="row" {...formBox(theme)}>
        <div style={formStyle}>
          {renderForm()}
          {DiscussionBox()}
        </div>
        <div style={{ width: "30%" }}>{properties()}</div>
      </Flex>
    </Box>
  );
};

export default ClientForm;
