import {useTranslation} from "react-i18next";
import {Text, useTheme, Image, Modal} from "native-base";
import React, {useCallback, useEffect, useState} from "react";
import {Header} from "../../types/table";
import {AppTooltipID, Box, Pressable} from "shared-components";
import CoreTable from "../core-table/CoreTable";
import AccountingService from "../../services/accounting/accountingService";
import TenantConfigService from "../../services/tenant-config/tenantConfigService";
import {TENANT_CONFIG} from "../../constants/tenantConfig";
import {dotStyle, filterStyle, textStyle} from "../lead-listing/LeadListing.style";
import FeeDetail from "../fee-detail/FeeDetail";
import {ReactSVG} from "react-svg";
import TransactionListingFilter from "../transaction-listing-filter/TransactionListingFilter";
import {handleFiltersTransactionListing} from "../../utils/filters";
import { saveAs } from 'file-saver';
import { format } from "date-fns";
import Api from "../../configs/api";
import {textHeader14} from "../../styles/text";
import {greenStyle, redStyle, yellowStyle} from "./TransactionListing.style";
interface Fee {
    name: string;
    category: string;
    title: string;
    amountFee: number;
    balance: number;
    accountId: number;
    amountFeeVat: number;
    totalFee: number;
    userId: number;
    userType: string;
    currency: string;
}

export interface FeeDetails {
    remitter: string;
    beneficiary: string;
    total: string;
    isVatFee: boolean;
}

const AppTransactions = () => {
    const { t } = useTranslation();
    const theme = useTheme();
    const [isFilterUsed, setIsFilterUsed] = useState(true);
    const [currencyCode, setCurrencyCode] = useState("");
    const [feeDetail, setFeeDetail] = useState<FeeDetails>();
    const [initParams, setInitPrams] = useState({});
    const [isOpenFilter, setIsOpenFilter] = useState(false);
    const [isOpenFeeDetail, setIsOpenFeeDetail] = useState(false);
    const formatBeneficiaryName = (beneficiary: any) => {
        return beneficiary ? <Text {...textStyle(theme)}>{getNameOrCardNumber(beneficiary.name, true)}</Text> : "";
    };

    const formatBeneficiaryCard = (beneficiary: any) => {
        return beneficiary ? <Text {...textStyle(theme)}>{getNameOrCardNumber(beneficiary.name, false)}</Text> : "";
    };

    const formatRemitterName = (remitter: any) => {
        return remitter ? <Text {...textStyle(theme)}>{getNameOrCardNumber(remitter.name, true)}</Text> : "";
    };

    const formatRemitterCard = (remitter: any) => {
        return remitter ? <Text {...textStyle(theme)}>{getNameOrCardNumber(remitter.name, false)}</Text> : "";
    };

    const formatRemitterEmail = (remitter: any) => {
        return <Text {...textStyle(theme)}>{remitter?.email || "-"}</Text>;
    };

    const formatStaffName = (staff: any) => {
        return <Text {...textStyle(theme)}>{staff?.fullName || "-"}</Text>;
    }

    const formatAmount = (amount: number) => {
        return (
            <Box h={"100%"} flexDirection={"row"}
                 justifyContent={'flex-end'}
            >
                <Box flexDirection={"row"} alignItems={"center"}>
                    <Text marginLeft={'4px'} paddingRight={'24px'}>
                        {amount ? formatCurrency(amount) : '-'}
                    </Text>
                </Box>
            </Box>
        )
    };

    const formatFee = (fees: Fee[], transaction: any) => {
        const fee = getFee(fees, true);
        return (
            <Box h={"100%"} flexDirection={"row"}
                 justifyContent={'flex-end'}
            >
                <Box flexDirection={"row"} alignItems={"center"}>
                    {checkFeesDetail(transaction, true) ? btnFeeDetail(transaction, true) : undefined}
                    <Text marginLeft={'4px'} paddingRight={'24px'}>
                        {fee ?? '-'}
                    </Text>
                </Box>
            </Box>
        )
    };


    const formatMoneyHeader = (label: string) => {
        return (
            <Box h={"100%"} flexDirection={"row"}
                 justifyContent={'flex-end'}
            >
                <Box flexDirection={"row"} alignItems={"center"} >
                    <Text marginLeft={'4px'} {...textHeader14(theme)} paddingRight={'24px'}>
                        {label}
                    </Text>
                </Box>
            </Box>
        )
    };

    const formatDownloadHeader = (label: string) => {
        return (
            <Box h={"100%"} flexDirection={"row"}
                 justifyContent={'space-around'}
            >
                <Box flexDirection={"row"} alignItems={"center"} >
                    <Text marginLeft={'4px'} {...textHeader14(theme)}>
                        {label}
                    </Text>
                </Box>
            </Box>
        )
    };
    const get30DaysAgo = () => {
        const now = new Date();
        const startOfDay30DaysAgo = new Date();
        startOfDay30DaysAgo.setDate(now.getDate() - 30);
        startOfDay30DaysAgo.setHours(0, 0, 0, 0);
        return format(startOfDay30DaysAgo, "MM/dd/yyyy");
    }
    const [filters, setFilters] = useState({
        paymentMethod: "",
        status: "SUCCESS",
        category: "",
        createdDate: {
            from: get30DaysAgo(),
            to: format(new Date(), "MM/dd/yyyy"),
        },
        remitterKycLevel: "",
        fromAmount: "",
        toAmount: "",
    });
    const btnFeeDetail = (transaction: any, isFee: boolean)  => {
        return (
            <Pressable
                onPress={() => {
                    setFeeDetail(getFeeDetails(transaction, isFee))
                    setIsOpenFeeDetail(true)
                }}
            >
                <Image source={{ uri: 'icons/infor.svg' }} size={"20px"} alt="" />
            </Pressable>
        )
    }

    const formatFeeVat = (fees: Fee[], transaction: any) => {
        const feeVat = getFee(fees, false);
        return (
            <Box h={"100%"} flexDirection={"row"}
                 justifyContent={'flex-end'}
            >
                <Box flexDirection={"row"} alignItems={"center"}>
                    {checkFeesDetail(transaction, true) ? btnFeeDetail(transaction, false) : undefined}
                    <Text marginLeft={'4px'} paddingRight={'24px'}>
                        {feeVat ?? '-'}
                    </Text>
                </Box>
            </Box>
        )
    };

    type StatusType = {
        displayName: string;
        style: any;
    };

    const statusMap = new Map<string, StatusType>([
        ["SUCCESS", {displayName: t("transaction-listing.filter.option.completed"), style: greenStyle(theme)}],
        ["PENDING", {displayName: t("transaction-listing.filter.option.pending"), style: yellowStyle(theme)}],
        ["FAIL", {displayName: t("transaction-listing.filter.option.failed"), style: redStyle(theme)}],
        ["STARTED", {displayName: t("transaction-listing.filter.option.started"), style: yellowStyle(theme)}],
        ["PROCESSING", {displayName: t("transaction-listing.filter.option.processing"), style: yellowStyle(theme)}],
        ["DELIVERED", {displayName: t("transaction-listing.filter.option.delivered"), style: greenStyle(theme)}],
        ["FAILED", {displayName: t("transaction-listing.filter.option.rejected"), style: redStyle(theme)}],
        ["CANCELLED", {displayName: t("transaction-listing.filter.option.cancelled"), style: redStyle(theme)}],
        ["PARTIALLY_COMPLETE", {displayName: t("transaction-listing.filter.option.partially-completed"), style: yellowStyle(theme)}]
    ]);
    const formatStatus = (status: string) => {
        const statusType = statusMap.get(status);
        return (
            <Box h={"100%"} flexDirection={"row"}>
                <Box flexDirection={"row"} alignItems={"center"}>
                    <Text marginLeft={'4px'} {...statusType?.style}>
                        {statusType?.displayName ?? '-'}
                    </Text>
                </Box>
            </Box>
        );
    }
    const formatReceipt = (fees: Fee[], transaction: any) => {
        let onPressAction: (() => void) | undefined;
        let downloadType: string | undefined;

        if (transaction.paymentMethod === 'CC' && !transaction.accessToken && !isTransTypeShowDowload(transaction) && !isRefundTypeShowDownload(transaction)) {
            onPressAction = () => downloadReceipt(transaction.id);
        } else if (transaction.accessToken && !isTransTypeShowDowload(transaction) && !isRefundTypeShowDownload(transaction)) {
            downloadType = 'PWK';
        } else if (isRefundTypeShowDownload(transaction)) {
            downloadType = 'REFUND';
        } else if (isTransTypeShowDowload(transaction)) {
            downloadType = 'CARD_PURCHASE';
        }

        if (downloadType) {
            onPressAction = () => downloadReceiptTransaction(transaction.id, downloadType);
        }

        return (
            <Box h={"100%"} flexDirection={"row"} justifyContent={"space-evenly"}>
                <Box flexDirection={"row"} alignItems={"center"}>
                    {onPressAction ? (
                        <Pressable onPress={onPressAction}>
                            <Image source={{ uri: 'icons/download.svg' }} size={"20px"} alt="" />
                        </Pressable>
                    ) : (
                        <Text {...textStyle(theme)}>-</Text>
                    )}
                </Box>
            </Box>
        );
    };

    const isTransTypeShowDowload = (item: any) => {
        switch(item.transactionType){
            case 'CardPurchaseTransaction':
            case 'BillPayTransaction':
            case 'TopupTransaction':
            case 'CardLoadTransaction':
            case 'WithdrawTransaction':
            case 'PhonePlanTransaction':
            case 'GiftcardBoughtTransaction':
            case 'TicketPurchaseTransaction':
            case 'KonnectPakTransaction':
            case 'WithdrawAchTransaction':
                return true;
            default:
                return false;
        }
    }
    const isRefundTypeShowDownload = (item: any) => {
        if(item.transactionType === 'RefundTransaction' || item.transactionType === 'GiftCardRefundTransaction'){
            return true;
        }
        return false;
    }

    const downloadReceipt = (id: string) => {
        const fileName = `kanoo_e_receipt_${id}.pdf`;
        const params = { transactionId: id, timezoneOffset: new Date().getTimezoneOffset() };
        AccountingService.downloadReceipt(params).then((value:any)  => {
            console.log(value);
            const blob = new Blob([value.data], {type: 'application/pdf'});
            saveAs(blob, fileName);
        })
    }

    const downloadReceiptTransaction = (id: string, recieptType?: string) => {
         let url;
        if(recieptType === 'REFUND'){
            url = Api.DOWNLOAD_REFUND_RECEIPT
        } else if(recieptType === 'CARD_PURCHASE'){
            url = Api.DOWNLOAD_CARD_PURCHASE_RECEIPT;
        } else {
            url = Api.DOWNLOAD_RECEIPT_PWK_TRANSACTION;
        }
        AccountingService.downloadReceiptByType(url, id).then((data: any) => {
            window.open(data.data.result,'_blank');
        });

    }

    const formatFieldEmpty = (field: string) => {
        return <Text {...textStyle(theme)}>{field ?? "-"}</Text>;
    }

    const formatDate = (field: string) => {
        return field ? <Text {...textStyle(theme)}>{format(field, "MM/dd/yyyy HH:mm")}</Text> : <Text {...textStyle(theme)}>-</Text>;
    }

    const formatInformation = (data: string, item: any) => {
        return data ? (
            <AppTooltipID
                data={data}
                columnName={""}
                width={"164px"}
                isTruncateData={true}
                showCopyIcon={true}
                hasTooltip={true}
            />
        ) : <Text {...textStyle(theme)}>-</Text>;
    }

    const headers: Header[] = [
        {
            value: "referenceId",
            label: t("transaction-listing.header-column.referenceId"),
            format: formatInformation,
            width: 180,
        },
        {
            value: "dest",
            label: t("transaction-listing.header-column.beneficiary"),
            format: formatBeneficiaryName,
            width: 200,
        },
        {
            value: "dest",
            label: t("transaction-listing.header-column.beneficiary-card"),
            format: formatBeneficiaryCard,
            width: 140,
        },
        {
            value: "source",
            label: t("transaction-listing.header-column.remitter"),
            format: formatRemitterName,
            width: 200,
        },
        {
            value: "source",
            label: t("transaction-listing.header-column.remitter-card"),
            format: formatRemitterCard,
            width: 120,
        },
        {
            value: "source",
            label: t("transaction-listing.header-column.remitter-email"),
            format: formatRemitterEmail,
            width: 240,
        },
        {
            value: "amount",
            label: t("transaction-listing.header-column.amount"),
            format: formatAmount,
            formatLabel: formatMoneyHeader(t("transaction-listing.header-column.amount")),
            width: 160,
        },
        {
            value: "fees",
            label: t("transaction-listing.header-column.fee"),
            format: formatFee,
            formatLabel: formatMoneyHeader(t("transaction-listing.header-column.fee")),
            width: 160,
        },
        {
            value: "fees",
            label: t("transaction-listing.header-column.fee-vat"),
            format: formatFeeVat,
            formatLabel: formatMoneyHeader(t("transaction-listing.header-column.fee-vat")),
            width: 160,
        },
        {
            value: "paymentMethod",
            label: t("transaction-listing.header-column.payment-method"),
            format: formatFieldEmpty,
            width: 180,
        },
        {
            value: "transactionStatus",
            label: t("transaction-listing.header-column.status"),
            format: formatStatus,
            width: 160,
        },
        {
            value: "failReasonMessage",
            label: t("transaction-listing.header-column.failed-reason"),
            format: formatFieldEmpty,
            width: 320,
        },
        {
            value: "transactionDate",
            label: t("transaction-listing.header-column.created-time"),
            format: formatDate,
            width: 160,
        },
        {
            value: "orderId",
            label: t("transaction-listing.header-column.orderId"),
            format: formatInformation,
            width: 180,
        },
        {
            value: "id",
            label: t("transaction-listing.header-column.transactionId"),
            format: formatInformation,
            width: 180,
        },
        {
            value: "externalTransactionId",
            label: t("transaction-listing.header-column.external-transactionId"),
            format: formatInformation,
            width: 180,
        },
        {
            value: "decoratedCategory",
            label: t("transaction-listing.header-column.category"),
            format: formatFieldEmpty,
            width: 240,
        },
        {
            value: "paymentFlow",
            label: t("transaction-listing.header-column.payment-flow"),
            format: formatFieldEmpty,
            width: 200,
        },
        {
            value: "staff",
            label: t("transaction-listing.header-column.staff-name"),
            format: formatStaffName,
            width: 200,
        },
        {
            value: "authCode",
            label: t("transaction-listing.header-column.auth-code"),
            format: formatFieldEmpty,
            width: 120,
        },
        {
            value: "downloadReceipt",
            formatLabel: formatDownloadHeader(t("transaction-listing.header-column.download-receipt")),
            label: t("transaction-listing.header-column.download-receipt"),
            format: formatReceipt,
            width: 160,
        },
    ];

    useEffect(() => {
        TenantConfigService.getTenantConfig(TENANT_CONFIG.CURRENCY_CODE).then(
            (response) => {
                const { result } = response.data;
                setCurrencyCode(result);
            }
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const now = new Date();
        const endTimeStamp = now.getTime();
        const startOfDay30DaysAgo = new Date();
        startOfDay30DaysAgo.setDate(now.getDate() - 30);
        startOfDay30DaysAgo.setHours(0, 0, 0, 0);
        const startTimestamp = startOfDay30DaysAgo.getTime();
        setInitPrams({
            startTimestamp:startTimestamp,
            endTimeStamp: endTimeStamp,
            transactionStatus: "SUCCESS",
            type: "All"
        })
    }, []);

    const formatCurrency = (amount: number) => {
        const formattedAmount = amount.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
        return `${currencyCode} ${formattedAmount}`;
    };

    const getNameOrCardNumber = (inputString: string, isName: boolean) => {
        if (!inputString) {
            return "-";
        }
        const parts = inputString.split('/');
        if (parts.length > 1 && isName && parts[0]) {
            return parts[0]
        }
        if (parts.length > 1 && !isName && parts[1]) {
            return parts[1]
        }
        if (parts.length === 1 && isName) {
            return parts[0];
        }
        return "-";
    }

    const getFeeDetails = (item: any, isFee: boolean) : FeeDetails => {
        let remitterFee = 0;
        let beneficiaryFee = 0;
        let remitterFeeVat = 0;
        let beneficiaryFeeVat = 0;
        const fees: Fee[] = item.fees;
        let total = "-";
        if (fees) {
            fees.forEach(fee => {
                if (fee.accountId === item.source.accountId) {
                    remitterFee += fee.amountFee;
                    remitterFeeVat += fee.amountFeeVat;
                }
                if (fee.accountId === item.dest.accountId) {
                    beneficiaryFee += fee.amountFee;
                    beneficiaryFeeVat += fee.amountFeeVat;
                }
            });
            const totalFee = formatCurrency(remitterFee + beneficiaryFee);
            const totalFeeVat = formatCurrency(remitterFeeVat + beneficiaryFeeVat);
            total = isFee ? totalFee : totalFeeVat;
        }

        return {
            total: total,
            remitter: formatCurrency(isFee? remitterFee : remitterFeeVat),
            beneficiary: formatCurrency(isFee? beneficiaryFee : beneficiaryFeeVat),
            isVatFee: !isFee
        };
    }
    const checkFeesPaidByCustomer = (item: any, isFee: boolean) => {
        let totalFee = 0;
        const fees: Fee[] = item.fees;
        fees.forEach(fee => {
            if (fee.userType && fee.accountId === item.source.accountId) {
                if (isFee) {
                    totalFee += fee.amountFee;
                } else {
                    totalFee += fee.amountFeeVat;
                }
            }
        });
        return totalFee > 0;
    }

    const checkFeesDetail = (item: any, isFee: boolean) => {
        if (item.fees !== undefined && item.fees !== null
            && item.fees.length > 0 && item.paymentMethod === 'CC') {
            return checkFeesPaidByCustomer(item, isFee);
        }
        return false;
    }

    const getFee = (fees: Fee[], isFee: boolean) => {
        if (fees) {
            let totalFee = 0;
            let totalFeeVat = 0;
            fees.forEach(fee => {
                if (isFee) {
                    totalFee += fee.amountFee;
                } else {
                    totalFeeVat += fee.amountFeeVat;
                }
            });
            return isFee ? formatCurrency(totalFee) : formatCurrency(totalFeeVat);
        }
        return null;
    }

    const fetchDatas = useCallback(
        (params: any) => {
            params.keywordSearch = params.keyword;
            params.pageIndex = params.pageIndex - 1;
            const request = {
                keywordSearch: params.keyword,
                pageIndex: params.pageIndex,
                pageSize: params.pageSize,
                ...params.filters
            }
            return AccountingService.getTransactionListing({...request, ...initParams});
        },
        [],
    );

    const feeDetailProps = {
        textObject: {
            filter: feeDetail?.isVatFee ? t("transaction-listing.fee-vat-details"): t("transaction-listing.fee-details"),
            apply: t("client-listing.filter.apply"),
            clearAll: t("client-listing.filter.clear-all")
        },
        closeFilterIcon: "/icons/filter-close.svg",
        onFiltersChanged: (filtersChanged: any) => {
            setIsOpenFeeDetail(false);
        },
        fee: feeDetail,
    };


    const processedFilters = handleFiltersTransactionListing(filters);
    const filterProps = {
        statusOptions: [
            {
                label: "ALL",
                value: t("ticket-listing-filter.status-options.all"),
            },
            {
                label: "SUCCESS",
                value: t("transaction-listing.filter.option.completed"),
            },

            {
                label: "PENDING",
                value: t("transaction-listing.filter.option.pending"),
            },

            {
                label: "FAIL",
                value: t("transaction-listing.filter.option.failed"),
            },

            {
                label: "STARTED",
                value: t("transaction-listing.filter.option.started"),
            },

            {
                label: "PROCESSING",
                value: t("transaction-listing.filter.option.processing"),
            },

            {
                label: "DELIVERED",
                value: t("transaction-listing.filter.option.delivered"),
            },

            {
                label: "FAILED",
                value: t("transaction-listing.filter.option.rejected"),
            },

            {
                label: "CANCELLED",
                value: t("transaction-listing.filter.option.cancelled"),
            },

            {
                label: "PARTIALLY_COMPLETE",
                value: t("transaction-listing.filter.option.partially-completed"),
            },
        ],
        categoryOptions: [
            {
                label: "ALL",
                value: t("transaction-listing.filter.option.all-category"),
            },
            {
                label: "AccountVerificationChargeTrans",
                value: t("transaction-listing.filter.option.account-verification-charge"),
            },
            {
                label: "AccountAdjustmentTransaction",
                value: t("transaction-listing.filter.option.admin-account-adjustment"),
            },
            {
                label: "BillPayTransaction",
                value: t("transaction-listing.filter.option.bill-pay"),
            },
            {
                label: "CardLoadTransaction",
                value: t("transaction-listing.filter.option.card-load"),
            },
            {
                label: "CardPurchaseTransaction",
                value: t("transaction-listing.filter.option.card-purchase"),
            },
            {
                label: "WithdrawTransaction",
                value: t("transaction-listing.filter.option.cash-withdraw"),
            },
            {
                label: "PaymentComissionTransaction",
                value: t("transaction-listing.filter.option.commission-send"),
            },
            {
                label: "CouponRedeemTransaction",
                value: t("transaction-listing.filter.option.coupon-redemption"),
            },
            {
                label: "ExternalPaymentTransaction",
                value: t("transaction-listing.filter.option.external-payment"),
            },
            {
                label: "FreeStuffRedeemTransaction",
                value: t("transaction-listing.filter.option.free-stuff-redemption"),
            },
            {
                label: "GiftcardBoughtTransaction",
                value: t("transaction-listing.filter.option.gift-card-buy"),
            },
            {
                label: "GiftCardIssuanceTransaction",
                value: t("transaction-listing.filter.option.gift-card-issuance"),
            },
            {
                label: "GiftCardRedeemTransaction",
                value: t("transaction-listing.filter.option.gift-card-redemption"),
            },
            {
                label: "GiftCardRefundTransaction",
                value: t("transaction-listing.filter.option.gift-card-refund"),
            },
            {
                label: "GiftCardTerminationTransaction",
                value: t("transaction-listing.filter.option.gift-card-termination"),
            },
            {
                label: "InvoicePaymentTransaction",
                value: t("transaction-listing.filter.option.invoice-payment"),
            },
            {
                label: "WholeSalerTransaction",
                value: t("transaction-listing.filter.option.karma-tech-ltd-wholesales-fund"),
            },
            {
                label: "MembershipPaymentTransaction",
                value: t("transaction-listing.filter.option.membership-payment"),
            },
            {
                label: "MerchantCardLoadTransaction",
                value: t("transaction-listing.filter.option.merchant-card-load"),
            },
            {
                label: "FundRequestTransaction",
                value: t("transaction-listing.filter.option.merchant-fund-request"),
            },
            {
                label: "PakJoinTransaction",
                value: t("transaction-listing.filter.option.pak-join"),
            },
            {
                label: "KonnectPakTransaction",
                value: t("transaction-listing.filter.option.pak-purchase"),
            },
            {
                label: "PayrollTransaction",
                value: t("transaction-listing.filter.option.payroll-credit"),
            },
            {
                label: "PhonePlanTransaction",
                value: t("transaction-listing.filter.option.phone-plan-purchase"),
            },
            {
                label: "PointPurchaseTransaction",
                value: t("transaction-listing.filter.option.point-purchase"),
            },
            {
                label: "PointRedeemTransaction",
                value: t("transaction-listing.filter.option.point-redemption"),
            },
            {
                label: "RefundTransaction",
                value: t("transaction-listing.filter.option.purchase-refund"),
            },
            {
                label: "KanooReserveAccountFund",
                value: t("transaction-listing.filter.option.reserve-account-fund"),
            },
            {
                label: "RevenueSharingTransaction",
                value: t("transaction-listing.filter.option.revenue-sharing"),
            },
            {
                label: "KanooSettlePaymentTransaction",
                value: t("transaction-listing.filter.option.settle-payment"),
            },
            {
                label: "SubscriptionFeeTransaction",
                value: t("transaction-listing.filter.option.subscription-fee"),
            },
            {
                label: "FundMerchantTransaction",
                value: t("transaction-listing.filter.option.tenant-fund-merchant"),
            },
            {
                label: "TicketPurchaseTransaction",
                value: t("transaction-listing.filter.option.ticket-purchase"),
            },
            {
                label: "TopupTransaction",
                value: t("transaction-listing.filter.option.top-up"),
            },
            {
                label: "MoneyTransferTransaction",
                value: t("transaction-listing.filter.option.transfer-money"),
            },
            {
                label: "FundUserTransaction",
                value: t("transaction-listing.filter.option.user-fund-by-kard"),
            },
        ],
        paymentMethodOptions: [
            {
                label: "ALL",
                value: t("transaction-listing.filter.option.all"),
            },
            {
                label: "KK",
                value: t("transaction-listing.filter.option.cash-card"),
            },
            {
                label: "CC",
                value: t("transaction-listing.filter.option.credit-card"),
            },
        ],
        remitterKycLevelOptions: [
            {
                label: "ALL",
                value: t("transaction-listing.filter.option.all"),
            },
            {
                label: "LV1",
                value: t("transaction-listing.filter.option.level-1"),
            },
            {
                label: "APPROVED_VERIFIED",
                value: t("transaction-listing.filter.option.level-2"),
            },
            {
                label: "NONE",
                value: t("transaction-listing.filter.option.none"),
            },
        ],
        textObject: {
            filter: t("client-listing.filter.title"),
            apply: t("client-listing.filter.apply"),
            clearAll: t("client-listing.filter.clear-all"),
            status: t("ticket-listing-filter.status"),
            kycApprovalDate: t("client-listing.filter.kyc-approval-date"),
            invalidFormatErrMsg: t("errors.standard002", {
                fieldName: t("client-listing.filter.kyc-approval-date"),
            }),
            earlierErrMsg: t("errors.standard093"),
            minGreaterErrMsg: t("transaction-listing.error-amount"),
            selectedFromErrMsg: t("errors.standard092", {
                fieldName: t("client-listing.filter.start-date"),
            }),
            max: t("transaction-listing.max"),
            min: t("transaction-listing.min"),
            selectedToErrMsg: t("errors.standard092", {
                fieldName: t("client-listing.filter.end-date"),
            }),
            selectedRangeOverErrMsg: t("errors.standard094"),
        },
        currencyCode: currencyCode,
        dropDownIcon: "/icons/arrow-down.svg",
        arrowIcon: "/icons/arrow-long.svg",
        selectedIcon: "/icons/checkmark.svg",
        closeFilterIcon: "/icons/filter-close.svg",
        calendarIcon: "/icons/calendar.svg",
        arrowUp: "/icons/mini-arrow-up.svg",
        arrowDown: "/icons/mini-arrow.svg",
        arrowLeft: "/icons/chevron-left.svg",
        arrowRight: "/icons/chevron-right.svg",
        onFiltersChanged: (filtersChanged: any) => {
            setFilters(filtersChanged);
            setIsOpenFilter(false);
            setIsFilterUsed(filters !== filtersChanged);
        },
    };
    const actionRow = () => {
        return (
            <Box>
                <Pressable
                    testID="filter-icon"
                    position="relative"
                    {...filterStyle(theme)}
                    onPress={() => {
                        setIsOpenFilter(true);
                    }}
                >
                    <ReactSVG src="/icons/filter-icon.svg"/>
                    {isFilterUsed ? (
                        <Box {...dotStyle(theme)} position="absolute"/>
                    ) : undefined}
                </Pressable>
            </Box>
        );
    };

    return (
        <Box>
            <Modal isOpen={isOpenFeeDetail} onClose={() => setIsOpenFeeDetail(false)}>
                <Modal.Content
                    maxHeight="100vh"
                    marginLeft="auto"
                    marginRight="0"
                    maxWidth="460"
                    borderRadius="0"
                >
                    {isOpenFeeDetail ? (
                        <FeeDetail
                            {...feeDetailProps}
                            onCloseFilter={() => setIsOpenFeeDetail(false)}
                        />
                    ) : undefined}
                </Modal.Content>
            </Modal>
            <Modal isOpen={isOpenFilter} onClose={() => setIsOpenFilter(false)}>
                <Modal.Content
                    maxHeight="100vh"
                    marginLeft="auto"
                    marginRight="0"
                    maxWidth="460"
                    borderRadius="0"
                >
                    {isOpenFilter ? (
                        <TransactionListingFilter
                            {...filterProps}
                            initFilters={filters}
                            onCloseFilter={() => setIsOpenFilter(false)}
                        />
                    ) : undefined}
                </Modal.Content>
            </Modal>
            <CoreTable
                headers={headers}
                fetchDatas={fetchDatas}
                filters={filters}
                processedFilters={processedFilters}
                isBorder={true}
                actionRow={actionRow()}
                title={t("transaction-listing.title")}
                isFilterUsed={isFilterUsed}
                setIsFilterUsed={setIsFilterUsed}
            />
        </Box>
    );
};

export default AppTransactions;