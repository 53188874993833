import React, { useState, useEffect, useRef } from "react";
import { Pressable, SearchIcon, useTheme } from "native-base";
import { Input } from "shared-components";
import { searchBoxStyle } from "./SearchBox.style";
import type { SearchProps } from "../../types/searchBoxProps";
import { ReactSVG } from "react-svg";

const SearchBox = ({
  searchValue,
  onKeySearchChange,
  placeholder,
  inputRightElementOnClick,
  autoFocus,
  ...restProps
}: SearchProps) => {
  const clearIcon = "/icons/close-icon.svg";
  const [value, setValue] = useState(searchValue);
  const [displayClearIcon, setDisplayClearIcon] = useState(false);
  const [isFocus, setIsFocus] = React.useState(false);
  const searchRef = useRef<any>(null);
  const theme = useTheme();

  const handleKeyPress = (event: any) => {
    if (event.nativeEvent.key === "Enter" && onKeySearchChange) {
      onKeySearchChange(value);
    }
  };

  useEffect(() => {
    setValue(searchValue);
  }, [searchValue]);

  const handleChange = (text: string) => {
    setValue(text);
    if (inputRightElementOnClick) {
      inputRightElementOnClick(text);
    }
    searchRef.current?.focus();
  };

  useEffect(() => {
    if (autoFocus) {
      searchRef.current?.focus();
    }
  }, [autoFocus])
  return (
    <Input
      {...searchBoxStyle(theme)}
      onFocus={() => setIsFocus(true)}
      onBlur={() => {
        setIsFocus(false);
        autoFocus && searchRef.current?.focus();
      }}
      borderRadius={'999px'}
      placeholder={placeholder}
      InputLeftElement={<img src={'/icons/search.svg'} style={{marginLeft: '12px', marginRight: '-4px'}} />}
      value={value}
      onChangeText={setValue}
      onKeyPress={handleKeyPress}
      InputRightElement={
        (displayClearIcon || isFocus) && value ? (
          <Pressable
            testID="clear-input"
            onHoverIn={() => setDisplayClearIcon(true)}
            onHoverOut={() => setDisplayClearIcon(false)}
            onPress={() => handleChange("")}
            height="20px"
            mr="3"
          >
            <ReactSVG height={"20px"} src={clearIcon} />
          </Pressable>
        ) : undefined
      }
      ref={searchRef}
      {...restProps}
    />
  );
};

export default SearchBox;
