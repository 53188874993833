export const formBox = (theme: any) => ({
  w: "100%",
  gap: "16px",
});

export const headerText = (theme: any) => ({
  fontFamily: theme?.fontFamily,
  fontWeight: 600,
  fontSize: "24px",
  lineHeight: "32px",
  color: theme?.appPalette?.grey?.grey80,
});

export const leftFormStyle = (theme: any) => ({
  w: "100%",
  height: "fit-content",
  mr: 4,
  padding: 6,
  borderRadius: "8px",
  style: {
    shadowColor: "rgba(0, 0, 0, 0.20)",
    shadowOffset: { width: 0, height: 1 },
    shadowOpacity: 1,
    shadowRadius: 3,
  },
  backgroundColor: theme?.appPalette?.grey.white,
});

export const informationStyle = (theme: any) => ({
  w: "100%",
  style: {
    shadowColor: "rgba(0, 0, 0, 0.20)",
    shadowOffset: { width: 0, height: 1 },
    shadowOpacity: 1,
    shadowRadius: 3,
  },
  backgroundColor: theme?.appPalette?.grey.white,
  borderRadius: "8px",
  paddingX: 6,
  paddingY: 6,
  gap: "16px",
});

export const informationTitleStyle = (theme: any) => ({
  borderRadius: "8px",
  paddingX: 3,
  paddingY: "10px",
  backgroundColor: theme?.appPalette?.primary.primary8,
});

export const boxItemStyle: any = {
  w: "100%",
  flexDirection: "row",
  gap: "24px",
};

export const w50: any = {
  width: "50%",
  display: "flex",
  flexDirection: "column",
  gap: "6px",
};

export const actionStyle: any = {
  flexDirection: "row",
  gap: "12px",
};

export const gap6 = {
  gap: "6px",
};

export const formStyle: any = {
  display: "flex",
  flexDirection: "column",
  gap: "16px",
  width: "70%",
};
