import i18n from "../i18n";
import { PERMISSIONS } from "./permisstion";

export const SidebarItems = [
  // Catalog menu
  {
    name: i18n.t("sidebar.catalog.name"),
    icon: "/icons/catalog.svg",
    active: false,
    expand: false,
    path: "",
    subSidebarItems: [
      {
        name: i18n.t("sidebar.catalog.gift-cards"),
        active: false,
        path: "/catalog/giftcards",
        permissions: [PERMISSIONS.CATALOG.GIFT_CARD.LISTING],
      },
    ],
  },

  // Accounting menu
  {
    name: i18n.t("sidebar.accounting.name"),
    icon: "/icons/accounting.svg",
    active: false,
    expand: false,
    path: "",
    subSidebarItems: [
      {
        name: i18n.t("sidebar.accounting.transactions"),
        active: false,
        path: "/transactions",
        permissions: [PERMISSIONS.ACCOUNTING.TRANSACTION.LISTING],
      },
      {
        name: i18n.t("sidebar.accounting.withdrawal_request"),
        active: false,
        path: "/accounting/transaction-withdrawal",
        permissions: [PERMISSIONS.ACCOUNTING.WITHDRAWAL_REQUEST.LISTING],
      },
    ],
  },

  // Customers
  {
    name: i18n.t("sidebar.customers.name"),
    icon: "/icons/customers.svg",
    active: false,
    expand: false,
    path: "",
    subSidebarItems: [
      {
        name: i18n.t("consumers.title"),
        active: false,
        path: "/consumers",
        permissions: [PERMISSIONS.CONSUMERS.LISTING],
      },
      {
        name: i18n.t("merchants.title"),
        active: false,
        path: "/merchants",
        permissions: [PERMISSIONS.MERCHANTS.LISTING],
      },
      {
        name: i18n.t("sidebar.customers.kyc-management"),
        active: false,
        path: "/customer/kyc/list",
        permissions: [PERMISSIONS.CUSTOMER.KYC.LISTING],
      },
    ],
  },

  // Marketing
  {
    name: i18n.t("sidebar.marketing.name"),
    icon: "/icons/marketing.svg",
    active: false,
    expand: false,
    path: "",
    subSidebarItems: [
      {
        name: i18n.t("sidebar.marketing.konnect-pak"),
        active: false,
        path: "/product/konnect-pak",
        permissions: [PERMISSIONS.MARKETING.KONNECT_PAK.LISTING],
      },
      {
        name: i18n.t("sidebar.marketing.ad-maker"),
        active: false,
        path: "/ad-maker",
        permissions: [PERMISSIONS.MARKETING.AD_MAKER.LISTING],
      },
    ],
  },

  // Customer support menu
  {
    name: i18n.t("sidebar.customer-support.name"),
    icon: "/icons/call-center.svg",
    active: false,
    expand: false,
    path: "",
    subSidebarItems: [
      {
        name: i18n.t("sidebar.customer-support.ticket"),
        active: false,
        path: "/ticket",
        permissions: [PERMISSIONS.CUSTOMER_SUPPORT.TICKET.LISTING],
      },
    ],
  },

  // Contact menu
  {
    name: i18n.t("sidebar.contact.name"),
    icon: "/icons/contact.svg",
    active: false,
    expand: false,
    path: "",
    subSidebarItems: [
      {
        name: i18n.t("sidebar.contact.lead"),
        active: false,
        path: "/lead",
        permissions: [PERMISSIONS.CONTACTS.LEAD.LISTING],
      },
      {
        name: i18n.t("sidebar.contact.prospect"),
        active: false,
        path: "/prospect",
        permissions: [PERMISSIONS.CONTACTS.PROSPECT.LISTING],
      },
      {
        name: i18n.t("sidebar.contact.client"),
        active: false,
        path: "/client",
        permissions: [PERMISSIONS.CONTACTS.CLIENT.LISTING],
      },
    ],
  },

  // Program menu
  {
    name: i18n.t("sidebar.cause2fund.name"),
    icon: "/icons/donate.svg",
    active: false,
    expand: false,
    path: "",
    subSidebarItems: [
      {
        name: i18n.t("programs.title"),
        active: false,
        path: "/programs",
        permissions: [PERMISSIONS.CAUSE2FUND.PROGRAM.VIEW_PROGRAM_AND_PROJECT],
      },
    ],
  },

  // User management
  {
    name: i18n.t("sidebar.user-management.name"),
    icon: "/icons/user-setting.svg",
    active: false,
    expand: false,
    path: "",
    subSidebarItems: [
      {
        name: i18n.t("sidebar.user-management.usersRoles"),
        active: false,
        path: "/users-roles",
        permissions: [
          PERMISSIONS.USERS_ROLES.USERS_LISTING,
          PERMISSIONS.USERS_ROLES.ROLES_LISTING,
        ],
      },
      {
        name: i18n.t("sidebar.user-management.exchange-rate"),
        active: false,
        path: "/settings/exchange-rates",
        permissions: [PERMISSIONS.SETTING.EXCHANGE_RATE.LISTING],
      },
      {
        name: i18n.t("sidebar.user-management.commission"),
        active: false,
        path: "/settings/commission-configuration",
        permissions: [PERMISSIONS.SETTING.COMMISSION.LISTING],
      },
    ],
  },
];
