export const linkStyle = (theme: any) => ({
  color: theme?.appPalette?.info,
  fontFamily: theme?.fontFamily
});

export const formatLogoStyle = (theme: any) => ({
  border: "1px solid " + theme?.appPalette?.grey.grey20,
  borderRadius: "4px",
  width: "40px",
  height: "40px",
});
