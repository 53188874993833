import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../index";
import { logout } from "../../oidc/oidc-service";
import { resolveTenant } from "../../tenantResolver";

export const initialState = {
  type: "",
  isShowModal: false,
  modalContent: {} as any,
};

const Modal = createSlice({
  name: "modal",
  initialState,
  reducers: {
    showModal: (state, data) => {
      state.type = data.payload?.type;
      state.modalContent = { ...data.payload?.modalContent };
      state.isShowModal = true;
    },
    hideModal: (state) => {
      state.isShowModal = false;
      if (state.type === "SESSTION_EXPIRED") {
        const tenantConfig = resolveTenant();
        const authData = JSON.parse(
          localStorage.getItem(
            `oidc.at:${tenantConfig.oidcConfiguration.authority}`,
          ) ?? "{}",
        );
        logout(authData?.id_token);
      }
    },
  },
});

export const ModalStore = (state: RootState) => state.modal;
export const { showModal, hideModal } = Modal.actions;
export default Modal.reducer;
