const CommonService = {
  downloadBlobData: (res: any) => {
    const blob = new Blob([res.data], { type: "audio/mpeg" });
    const blobUrl = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = blobUrl;

    // Set the download attribute with the desired file name
    const contentDisposition = res.headers["content-disposition"];
    let fileName = "downloaded_file";
    if (contentDisposition) {
      const match = contentDisposition.match(/filename="?(.+)"?/);
      if (match.length > 1) {
        fileName = match[1];
      }
    }

    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  },
};

export default CommonService;
