export class LeadConstants {
  static readonly status = [
    {
      label: "NEW",
      value: "lead-listing.status.new",
    },
    {
      label: "NO_ANSWER_1_3",
      value: "lead-listing.status.no-answer",
    },
    {
      label: "CALL_AGAIN",
      value: "lead-listing.status.call-again",
    },
    {
      label: "NO_FOUND",
      value: "lead-listing.status.no-found",
    },
    {
      label: "UNDER_18",
      value: "lead-listing.status.under-18",
    },
  ];
}

export class UploadHistoryListingConstants {
  static readonly status = [
    {
      label: "COMPLETED",
      value: "upload-history-listing.status.completed",
    },
    {
      label: "PROCESSING",
      value: "upload-history-listing.status.processing",
    },
    {
      label: "FAILED",
      value: "upload-history-listing.status.failed",
    },
  ];
}
