import React, { useEffect, useState } from 'react';
import {Box, Text, Toast, useTheme} from 'native-base';
import {useTranslation} from "react-i18next";
import {DropdownMenu, SidePanel, ToastMessage} from 'shared-components';
import { sendEmailFormStyle, emailSelectStyle, textStyle, emailContentContainer, errorText, languageSelectStyle } from "./SendEmailForm.style";
import ContactService from "../../services/contact/contactService";
import {DropdownWithSearch} from 'shared-components'
import { OptionItemDTO } from 'shared-components/lib/typescript/src/models/option-item.dto';
import {EmailTemplate, ParamItem, SendEmailFormProps} from "../../types/sendEmailFormProps";

interface OptionItem {
    text: string;
    value: string;
    param: ParamItem[];
}

interface DropdownOptionItem {
    label: string;
    value: string;
}

const CONSTANTS: any = {
    FULL_NAME: 'fullName',
    MEMBERSHIP_PACKAGE_NAME: 'membershipPackageName',
    WITHDRAWAL_AMOUNT: 'withdrawalAmount',
    COUPON_CODE: "couponCode",
};

const SendEmailForm: React.FC<SendEmailFormProps> = ({
                                                         emailListing,
                                                         supportedLanguages,
                                                         userInfo,
                                                         panelLabel,
                                                         onClose,
                                                     }) => {
    const theme = useTheme();
    const {t} = useTranslation();
    const [emails, setEmails] = useState<OptionItem[]>([]);
    const [selectedEmail, setSelectedEmail] = useState<EmailTemplate>();
    const [parameterValues, setParameterValues] = useState<{[key: string]: string | number}>({});
    const [previewContent, setPreviewContent] = useState<string>('');
    const [errorMessage, setErrorMessage] = useState<{[key: string]: string}>({});
    const [languageListing, setLanguageListing] = useState<DropdownOptionItem[]>([]);
    const initData = {
        dropdownOptionItem: {
            label: "",
            value: ""
        },
    }
    const [selectedLanguage, setSelectedLanguage] = useState<DropdownOptionItem>(initData.dropdownOptionItem);
    const [defaultLanguage, setDefaultLanguage] = useState<DropdownOptionItem>(initData.dropdownOptionItem);
    const SPECIAL_PARAMS = [CONSTANTS.FULL_NAME, CONSTANTS.MEMBERSHIP_PACKAGE_NAME];

    useEffect(() => {
        const mappedData = emailListing.map((item: any) => {
            return {
                text: item.templateName,
                value: item.templateCode,
                param: item.parameters
            };
        });
        setEmails(mappedData);

        const mappedLanguageData = supportedLanguages.map((item: any) => {
            const data = {label: item.language, value: item.code};
            if (item.defaultOption) {
                setDefaultLanguage(data);
                setSelectedLanguage(data);
            }
            return data;
        });
        setLanguageListing(mappedLanguageData);
    }, [emailListing, supportedLanguages]);

    useEffect(() => {
        if (selectedEmail) {
            fetchEmailPreview();
        }
    }, [selectedEmail, selectedLanguage]);

    const bodyRequest = {
        templateCode: selectedEmail?.templateCode,
        language: selectedLanguage.value,
        parameters: Object.entries(parameterValues).map(([key, value]) => {
            const data = { param: key, value };
            if (data.param === CONSTANTS.WITHDRAWAL_AMOUNT) {
                data.value = Number(data.value);
            }
            return data;
        }),
    };

    const fetchEmailPreview = () => {
        ContactService.previewEmail(bodyRequest).then((response) => {
            const {result} = response.data.result;
            if (result) {
                setPreviewContent(result);
            }
        }).catch(e => {
            return null;
        });
    };

    const handleEmailSelect = (e: OptionItemDTO) => {
        const params: ParamItem[] = emails.find(email => email.value === e.value)?.param ?? [];
        setPreviewContent('');
        const template = {
            templateCode: e.value,
            parameters: params
        }
        setSelectedEmail(template);
        setErrorMessage({
            templateCode: '',
        });
        populateInitialParameters(template);
    }

    const populateInitialParameters = (selectedEmail: EmailTemplate) => {
        const { fullName, membershipPackageName} = userInfo;
        const updatedValues: { [key: string]: string | number } = {};
        selectedEmail.parameters.forEach((param) => {
            const key = param.key;

            if (key === CONSTANTS.FULL_NAME && fullName) {
                updatedValues[key] = fullName;
            } else if (key === CONSTANTS.MEMBERSHIP_PACKAGE_NAME && membershipPackageName) {
                updatedValues[key] = membershipPackageName;
            } else if (key === CONSTANTS.WITHDRAWAL_AMOUNT) {
                updatedValues[key] = 0;
            } else if (!SPECIAL_PARAMS.includes(key)) {
                updatedValues[key] = "";
            }
        });
        setParameterValues(updatedValues);
    };

    const handleSendEmail = () => {
        if (validate()) {
            ContactService.sendEmail({...bodyRequest, address: userInfo.email}).then((response) => {
                const toastData = {
                    message: t("send-email.send-email-successfully"),
                    icon: "/icons/toast/check.svg",
                };
                onClose();
                return (
                    <>
                        {Toast.show({
                            render: () => {
                                return (
                                    <Box marginRight="28px" marginTop="8px" zIndex={999}>
                                        <ToastMessage
                                            message={toastData.message}
                                            icon={toastData.icon}
                                            onClose={() => {
                                                Toast.closeAll();
                                            }}
                                        />
                                    </Box>
                                );
                            },
                            placement: 'top-right'
                        })}
                    </>
                );
            })
        }
    };

    const handleErrorMessageState = (key: string, message: string) => {
        setErrorMessage((prevState) => ({
            ...prevState,
            [key]: message,
        }));
        setPreviewContent('');
    };

    const validate = () => {
        let isValid = true;
        const templateCode = selectedEmail?.templateCode ?? '';
        const params: ParamItem[] = selectedEmail?.parameters ?? [];

        if (selectedEmail && params.length === 0) {
            return true;
        }
        if (!templateCode) {
            const message = t("errors.standard092", {
                fieldName: t("send-email.email-listing"),
            });
            handleErrorMessageState("templateCode", message);
            isValid = false;
        }

        return isValid;
    };

    return (
        <SidePanel
            width="648px"
            closeFilterIcon="/icons/filter-close.svg"
            labels={panelLabel}
            onClose={() => {
                onClose();
            }}
            onPrimaryButtonPress={() => handleSendEmail()}
            onSecondaryButtonPress={() => {onClose();}}
        >
            <Box style={sendEmailFormStyle}>
                <Box style={emailSelectStyle}>
                    <Text {...textStyle(theme)}>{t("send-email.email-listing")}</Text>
                    <DropdownWithSearch
                        data={emails}
                        dropdownIcon={"/icons/dropdown-arrow.svg"}
                        checkedIcon={"/icons/checked.svg"}
                        placeholder={t("send-email.select-email-template")}
                        onSelectedItem={(e) => handleEmailSelect(e)}
                        noDataIcon={"/icons/no-data.svg"}
                        text={"text"}
                        value={"value"}
                        isInvalid={!!errorMessage["templateCode"]}
                    />
                    {errorMessage["templateCode"] && (
                        <Text {...errorText(theme)}>
                            {errorMessage["templateCode"]}
                        </Text>
                    )}
                </Box>
                <Box style={languageSelectStyle}>
                    <Text {...textStyle(theme)}>{t("send-email.language")}</Text>
                    <DropdownMenu
                        data={languageListing}
                        dropDownIcon={"/icons/dropdown-arrow.svg"}
                        selectedIcon={"/icons/checked.svg"}
                        placeholder={t("programs.select-program-owner")}
                        onValueChange={(e) => {
                            setSelectedLanguage(e);
                        }}
                        defaultOption={defaultLanguage}
                    />
                </Box>
                {previewContent && (
                    <Box>
                        <Text {...textStyle(theme)}>{t("send-email.preview-title")}</Text>
                        <Box {...emailContentContainer}>
                            <div dangerouslySetInnerHTML={{ __html: previewContent }} />
                        </Box>
                    </Box>
                )}
            </Box>
        </SidePanel>
    );
};

export default SendEmailForm;