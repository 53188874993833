import _ from "lodash";

const AuthorizationService = {
  setPermissions: (data: string[]) => {
    localStorage.setItem("permissions", JSON.stringify(data));
  },

  getPermissions: (): { [key: string]: boolean } => {
    const permissions = localStorage.getItem("permissions")
      ? JSON.parse(localStorage.getItem("permissions") || "")
      : [];
    const result: { [key: string]: boolean } = {};
    permissions.forEach((name: string) => {
      result[name] = true;
    });
    return result;
  },

  hasPermissions: (
    permissions: string | string[] | undefined,
    type: "some" | "every" = "some",
  ) => {
    const curPermissions = AuthorizationService.getPermissions();
    if (permissions) {
      if (Array.isArray(permissions) && !_.isEmpty(permissions)) {
        return permissions[type]((per: string) => curPermissions[per]);
      }
      return _.get(curPermissions, permissions, false);
    }
    return true;
  },

  getAllowedResources: (resources?: any[]) => {
    return (
      resources?.filter((resource) =>
        AuthorizationService.hasPermissions(resource?.permissions, "every"),
      ) || []
    );
  },
};
export default AuthorizationService;
