import { Box } from "shared-components";
import AppTransactions from "../../components/transaction-listing/TransactionListing";

const Transactions = () => {
  return (
    <Box>
      <AppTransactions></AppTransactions>
    </Box>
  );
};
export default Transactions;
