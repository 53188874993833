import { Box, Flex, Header, Pressable, useAppAuth } from "shared-components";
import { containerStyle, iconExpandMenu, sidebarStyle } from "./Scenes.style";
import { useTranslation } from "react-i18next";
import { Outlet } from "react-router-dom";
import { logout } from "../oidc/oidc-service";
import { useState } from "react";
import AppSidebar from "./app-sidebar/AppSidebar";
import { ReactSVG } from "react-svg";
import { useTheme } from "native-base";

const Scenes = () => {
  const { authData } = useAppAuth();
  const theme: any = useTheme();
  const { t } = useTranslation();
  const [isExpand, setIsExpand] = useState(true);
  const [isHover, setIsHover] = useState(false);
  const [isHoverExpand, setIsHoverExpand] = useState(false);
  const menu = [
    {
      text: t("common.logout"),
      icon: "/icons/logout.svg",
      action: (): void => {
        logout(authData.id_token);
      },
    },
  ];

  function getWidthSidebar() {
    return isExpand ? "280px" : isHoverExpand ? "280px" : "24px";
  }

  const handleOnMouseEnter = () => {
    if (isExpand) {
      setIsHover(true);
    } else {
      setIsHoverExpand(true);
    }
  };

  const handleOnMouseLeave = () => {
    if (isExpand) {
      setIsHover(false);
    } else {
      setIsHoverExpand(false);
    }
  };

  return (
    <Box>
      <Header
        logoUrl={theme?.logo}
        menu={menu}
        defaultUsername={t("username")}
      />
      <Flex flexDirection="row" justifyContent="flex-end">
        <Box {...sidebarStyle(theme, getWidthSidebar())}>
          <div
            style={{ height: "100%" }}
            onMouseEnter={handleOnMouseEnter}
            onMouseLeave={handleOnMouseLeave}
          >
            {(isExpand || isHoverExpand) && <AppSidebar />}
          </div>
          {(isHover || !isExpand) && (
            <div
              style={{ height: "100%" }}
              onMouseEnter={handleOnMouseEnter}
              onMouseLeave={handleOnMouseLeave}
            >
              <Pressable
                onPress={() => {
                  setIsExpand(!isExpand);
                  setIsHoverExpand(false);
                }}
                {...iconExpandMenu(isExpand)}
              >
                <ReactSVG src="/icons/expand-collapse-icon.svg" />
              </Pressable>
            </div>
          )}
        </Box>
        <Box {...containerStyle(theme, getWidthSidebar())}>
          <Outlet />
        </Box>
      </Flex>
    </Box>
  );
};
export default Scenes;
