import { textBody14 } from "../../styles/text";

export const statusText = (theme: any, status: string) => {
  return {
    ...textBody14(theme),
    lineHeight: "56px",
    color: status
      ? theme?.appPalette?.success?.success100
      : theme?.appPalette?.danger?.danger100,
  };
};

export const createdDateText = (theme: any) => ({
  ...textBody14(theme),
  lineHeight: "56px",
});
