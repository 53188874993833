import { textBody14 } from "../../styles/text";

export const statusText = (theme: any, status: string) => {
  let color = theme?.appPalette?.success?.success100;
  if (status === "INACTIVE") {
    color = theme?.appPalette?.danger?.danger100;
  } else if (status === "INVITED") {
    color = theme?.appPalette?.warning?.warning100;
  }
  return {
    ...textBody14(theme),
    lineHeight: "56px",
    color: color,
  };
};

export const roleText = (theme: any) => ({
  ...textBody14(theme),
  lineHeight: "56px",
});
