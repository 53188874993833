import React from "react";
import { Navigate } from "react-router-dom";
import { useAppAuth } from "shared-components";
import AuthorizationService from "../auth/authorizationService";
import { useAppSelector } from "../hooks/reduxHook";
import { SidebarStore } from "../store/sidebar/sidebar";

const ProtectedRoute = ({
  children,
  permissions,
  path,
}: {
  children: React.JSX.Element;
  permissions?: string[];
  path: string;
}) => {
  const { isLoggedIn } = useAppAuth();
  const { sidebarItems } = useAppSelector(SidebarStore);

  // Navigate to authenticate page when ueser not logged in
  if (!isLoggedIn) {
    return <Navigate to="/authenticate" replace />;
  }

  // Navigate to the first menu when user enters path "/"
  if (path === "/") {
    const path =
      sidebarItems[0]?.path || sidebarItems[0]?.subSidebarItems[0]?.path;
    return <Navigate to={path} replace />;
  }

  // Navigate to page not found when user don't have permission
  if (!AuthorizationService.hasPermissions(permissions)) {
    return <Navigate to="/404" replace />;
  }
  return children;
};

export default ProtectedRoute;
