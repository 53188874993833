import ApiClient from "../api-client/apiClient";
import Api from "../../configs/api";

const MediaLegacyService = {
    uploadFile: (data: any) => {
        return ApiClient().post(Api.UPLOAD_FILE, data);
    },
};

export default MediaLegacyService;
