import { Config } from "./environment";

const Api = {
  BASE_URL: Config.baseURL,
  SEARCH: "/search",
  USER_PROFILE: "/admin-hub/v1/profile/kardengage/me",
  GET_USER_PROFILE_LISTING: "/user-role/v1/profile/search",
  GET_TENANT_USER_PROFILE_LISTING: "/user-role/v1/profile/tenant/search",
  UPLOAD_FILE: "/admin-hub/legacy/v1/media/uploadFile",
  TICKET: "/engagement/v1/ticket",
  CALLER_INFORMATION: "/caller-information",
  COMMENT_SUB: "/comment",
  LEAD: "/engagement/v1/lead",
  PROSPECT: "/engagement/v1/prospect",
  UPLOAD_HISTORY_LISTING: "/bulk/history/search",
  DOWNLOAD_UPLOAD_HISTORY: "/bulk/history/download",
  COUNTRIES: "/admin-hub/legacy/v1/visikard/countries",
  ISLAND: "/admin-hub/legacy/v1/visikard/countries",
  DOWNLOAD_TEMPLATE: "/info-template/download",
  BULK: "/bulk",
  VOISO_CALL: `/call`,
  CLIENT: "/engagement/v1/client",
  CONVERT_LEAD: "/engagement/v1/lead/convert",
  GET_PROFILE_BY_PHONE_NUMBER: "/engagement/v1/contact/information",
  TICKET_HISTORY: "/history",
  LEAD_HISTORY: "/history",
  INVITE_LEAD: "/invite",
  ISLANDS: "/admin-hub/legacy/v1/visikard/api/v1/countries",
  PROGRAM: "/marketing/v1/cause-to-fund/program",
  LISTING: "/listing",
  GET_PROGRAM_OWNER: "/profile/v1/merchants",
  GET_TENANT_CONFIG: "/admin-hub/legacy/v1/visipay/internal/tenantConfig",
  PROJECT: "/marketing/v1/cause-to-fund/project",
  USER: "/user-role/v1/profile",
  ROLE: "/user-role/v1/profile/roles",
  EDIT_ROLE: "/user-role/v1/profile/role/assignment",
  ALL_PERMISSION: "/all-permission",
  GET_USER_PROFOLE_BY_USER_TYPE: "/user-role/v1/profile/search-basic-info",
  USER_INFORMATION_CONTACT: "/user-information/contact",
  HISTORY: "/history",
  ENGAGEMENT_V1: '/engagement/v1',
  ORDER: '/order/v1',
  LIST: '/listing',
  ASSOCIATED_ORDER_WITH_TICKET: '/engagement/v1/ticket/{ticketId}/order/{orderNumber}/associate',
  ORDER_CALL: '/engagement/v1/ticket/order/call',
  GET_CONSUMER_LISTING: '/user-role/v1/profile/search-basic-info',
  GET_MERCHANT_LISTING: '/profile/v1/merchant',
  GET_COUNTRY: '/profile/v1/countries',
  GET_PRICING_PLANS: '/profile/v1/pricing-plans',
  GET_BUSINESS_TYPES: '/profile/v1/business-types',
  GET_EMAIL_LISTING: "/engagement/v1/contact/emails",
  PREVIEW_MAIL_CONTENT: "/engagement/v1/contact/preview-email",
  SEND_EMAIL: "/engagement/v1/contact/send-email",
  GET_SUPPORTED_LANGUAGES: "/engagement/v1/tenant-config/supported-languages",
  GET_TENANT_CONFIGURATION: "/engagement/v1/tenant-config",
  GET_TRANSACTION_LISTING: '/admin-hub/legacy/v1/reporting/transaction/loadTransaction',
  DOWNLOAD_RECEIPT: '/admin-hub/legacy/v1/visikard/payment/internal/payment-receipt/download',
  DOWNLOAD_RECEIPT_PWK_TRANSACTION: '/admin-hub/legacy/v1/visikard/api/receipts/generation/{transactionID}',
  DOWNLOAD_REFUND_RECEIPT: '/admin-hub/legacy/v1/visikard/api/receipts/v2/generation/{transactionID}',
  DOWNLOAD_CARD_PURCHASE_RECEIPT: '/admin-hub/legacy/v1/visikard/api/receipts/v2/generation/purchase/{transactionID}',

};

export default Api;
