import React, { Fragment, useState } from "react";
import { Box, Text, useTheme } from "native-base";
import _ from "lodash";
import moment from "moment/moment";
import { errorText, textHeader1 } from "../../styles/text";
import { DropDown, DatePicker } from "shared-components";
import { OptionItem } from "../../types/dropdownProps";
import FilterForm from "../filter-form/FilterForm";
import { FilterDateDTO } from "../../types/ticketListingFilterDTO";
import { ClientListingFilterProps } from "../../types/clientListingFilterProps";
import { ClientListingFilterDTO } from "../../types/clientListingFilterDTO";
import { DATE_FORMAT_REGEX } from "../../constants/regex";
import {
  boxPropertyInsideStyle,
  boxPropertyStyle,
  textPropertyStyle,
} from "../ticket-listing-filter/TicketListingFilter.style";
import AssigneeDropdown from "../assignee-dropdown/AssigneeDropdown";
import { useTranslation } from "react-i18next";
import { ResourceType } from "../../constants/resourceType";

const ClientListingFilter: React.FC<ClientListingFilterProps> = ({
  initFilters,
  onFiltersChanged,
  dropDownIcon,
  selectedIcon,
  arrowIcon,
  calendarIcon,
  arrowUp,
  arrowDown,
  arrowLeft,
  arrowRight,
  statusOptions,
  textObject,
  onCloseFilter,
  closeFilterIcon,
  selectedAssignee,
  setSelectedAssignee,
}) => {
  const theme = useTheme();
  const [filters, setFilters] = useState<ClientListingFilterDTO>(initFilters);
  const [errorDateMessage, setErrorDateMessage] = useState<string>("");

  const filterChanged = (valueObject: any, key: string) => {
    let filtersTemp = _.cloneDeep(filters);
    if (key === "kycApprovalDate") {
      setErrorDateMessage("");
      filtersTemp[key] = valueObject;
    } else {
      filtersTemp = {
        ...filters,
        [key]: valueObject.label === "ALL" ? "" : valueObject.label,
      };
    }
    setFilters(filtersTemp);
  };

  const clearAll = () => {
    const filtersTemp = {
      status: "",
      kycApprovalDate: {
        from: "",
        to: "",
      },
      assigneeId: "",
    };
    setFilters(filtersTemp);
    setErrorDateMessage("");
  };

  const onApplyFilter = () => {
    if (validateDateRange()) {
      onFiltersChanged(filters);
    }
  };

  const validateDateRange = () => {
    setErrorDateMessage("");
    const { from, to } = filters.kycApprovalDate;
    const regex = DATE_FORMAT_REGEX;

    if (!from && !to) {
      return true;
    }

    if (!from) {
      setErrorDateMessage(textObject.selectedFromErrMsg);
      return false;
    }

    if (!to) {
      setErrorDateMessage(textObject.selectedToErrMsg);
      return false;
    }

    if (!regex.test(from) || !regex.test(to)) {
      setErrorDateMessage(textObject.invalidFormatErrMsg);
      return false;
    }
    const format = "MM/DD/YYYY";
    const momentDate1 = moment(from, format);
    const momentDate2 = moment(to, format);
    if (momentDate1.isAfter(momentDate2)) {
      setErrorDateMessage(textObject.earlierErrMsg);
      return false;
    }
    if (momentDate2.diff(momentDate1, "months") > 12) {
      setErrorDateMessage(textObject.selectedRangeOverErrMsg);
      return false;
    }
    return true;
  };

  const statusFilter = () => {
    const dropdownProps = {
      data: statusOptions,
      dropDownIcon: dropDownIcon,
      selectedIcon: selectedIcon,
    };

    return (
      <Box mb={4} zIndex={"auto"}>
        <Text {...textHeader1(theme)} mb={2}>
          {textObject.status}
        </Text>
        <DropDown
          {...dropdownProps}
          defaultOptionLabel={filters.status || "ALL"}
          action={(option: OptionItem) => {
            filterChanged(option, "status");
          }}
        />
      </Box>
    );
  };

  const datePickerFilter = () => {
    const datePickerProps = {
      arrowIcon: arrowIcon,
      calendarIcon: calendarIcon,
      arrowUp: arrowUp,
      arrowDown: arrowDown,
      arrowLeft: arrowLeft,
      arrowRight: arrowRight,
    };

    return (
      <Box mb={4} zIndex={"auto"}>
        <Text {...textHeader1(theme)} mb={2}>
          {textObject.kycApprovalDate}
        </Text>
        <DatePicker
          {...datePickerProps}
          initDateRange={filters.kycApprovalDate}
          isInvalid={!!errorDateMessage}
          onChangeDateRange={(dateRange: FilterDateDTO) => {
            filterChanged(dateRange, "kycApprovalDate");
          }}
        />
        {errorDateMessage ? (
          <Text mt={2} {...errorText(theme)}>
            {errorDateMessage}
          </Text>
        ) : undefined}
      </Box>
    );
  };

  const { t } = useTranslation();
  const handleAssigneeChange = (option: any) => {
    const value = {
      label: option.value,
    };
    filterChanged?.(value, "assigneeId");
  };
  const renderOptionalAssigneeComponent = (labelText?: string) => {
    return (
      <Box {...boxPropertyStyle()}>
        <Box {...boxPropertyInsideStyle()}>
          <Text {...textPropertyStyle(theme)}>{labelText}</Text>
        </Box>
        <AssigneeDropdown
          initSelectedItem={
            filters.assigneeId || t("ticket-listing-filter.status-options.all")
          }
          onSelectedItem={handleAssigneeChange}
          onValueChangeAssignee={(data) => {
            setSelectedAssignee && setSelectedAssignee(data);
          }}
          selectedAssignee={selectedAssignee}
          dropdownIcon={"/icons/arrow-down.svg"}
          checkedIcon={"/icons/check.svg"}
          noDataIcon={"/icons/no-data.svg"}
          resourceType={ResourceType.CLIENT}
        />
      </Box>
    );
  };

  return (
    <FilterForm
      textObject={textObject}
      closeFilterIcon={closeFilterIcon}
      onClearFilter={() => {
        clearAll();
      }}
      onApplyFilter={onApplyFilter}
      onCloseFilter={onCloseFilter}
    >
      <Fragment>
        {statusFilter()}
        {datePickerFilter()}
        {renderOptionalAssigneeComponent(textObject.assignee)}
      </Fragment>
    </FilterForm>
  );
};
export default ClientListingFilter;
