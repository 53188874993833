import {Route, Routes, useLocation} from "react-router-dom";
import { RouterType } from "../types/router.types";
import {FORCE_NAVIGATION_PATH, RoutesDataNoLayout, RoutesDataWithLayout} from "./RoutesData";
import ProtectedRoute from "./ProtectedRoute";
import Unauthorized from "../scenes/unauthorized/Unauthorized";
import Scenes from "../scenes/Scenes";
import {resolveTenant} from "../tenantResolver";

const Router = () => {

  const tenantConfig = resolveTenant();
  const location = useLocation();
  const currentPath = location.pathname;

  if (FORCE_NAVIGATION_PATH.includes(currentPath)) {
    window.location.href = tenantConfig.adminHubDomain + currentPath;
  }

  const pageRoutesWithLayout = RoutesDataWithLayout.map(
    ({
      path,
      title,
      element,
      requiredPermission,
      requiredLogin,
      children,
    }: RouterType) => {
      return (
        <Route
          key={title}
          path={path}
          element={
            requiredLogin ? (
              <ProtectedRoute permissions={requiredPermission} path={path}>
                {element}
              </ProtectedRoute>
            ) : (
              element
            )
          }
        >
          {children?.map(({ path, requiredPermission, title, element }) => {
            return (
              <Route
                path={path}
                key={title}
                element={
                  <ProtectedRoute permissions={requiredPermission} path={path}>
                    {element}
                  </ProtectedRoute>
                }
              />
            );
          })}
        </Route>
      );
    },
  );

  const pageRouteNoLayout = RoutesDataNoLayout.map(
    ({ path, title, element }: RouterType) => {
      return <Route key={title} path={path} element={element}></Route>;
    },
  );

  return (
    <Routes>
      <Route path="/" element={<Scenes />}>
        {pageRoutesWithLayout}
      </Route>
      {pageRouteNoLayout}
      <Route path="*" element={Unauthorized()} />
    </Routes>
  );
};

export default Router;
