export const filterContainerStyle = (theme: any) => ({
  w: "460px",
  h: "100vh",
  borderWidth: 1,
  borderColor: theme?.appPalette?.grey?.grey20,
});

export const filterHeaderStyle = (theme: any) => ({
  px: 6,
  py: 3,
  borderBottomWidth: 1,
  borderBottomColor: theme?.appPalette?.grey?.grey20,
});

export const filterBodyStyle = () => ({
  p: 6,
  h: "calc(100vh - 124px)",
  overflow: "auto",
});

export const filterFooterStyle = (theme: any) => ({
  px: 6,
  py: 3,
  borderTopWidth: 1,
  borderTopColor: theme?.appPalette?.grey?.grey20,
});

export const clearStyle = (theme: any) => ({
  fontFamily: theme?.fontFamily,
  fontWeight: 600,
  fontSize: "16px",
  lineHeight: "20px",
  color: theme?.appPalette?.primary?.primary100,
});
