import { Box, VStack, Text, useTheme, Spinner, Menu, Image } from "native-base";
import React, { useEffect, useMemo, useState } from "react";
import {
  AppTooltip,
  Flex,
  HStack,
  Pressable,
  TablePagination,
} from "shared-components";
import {
  bodyStyle,
  headerStyle,
  tableStyle,
  textBody,
  textHeader,
  spinnerStyle,
  actionStyle,
  noDataTextStyle,
  noDataBoxStyle,
  titleHeaderBox,
  titleStyle,
  centerStyle,
} from "./CoreTable.style";
import { CoreTableProps, Header } from "../../types/table";
import _ from "lodash";
import { textBody16 } from "../../styles/text";
import { ReactSVG } from "react-svg";
import { useTranslation } from "react-i18next";
import SearchBox from "../search-box/SearchBox";
import { filterStyle } from "../lead-listing/LeadListing.style";
import AuthorizationService from "../../auth/authorizationService";
import useIsTruncated from "../../hooks/useIsTruncatedHook";
const CoreTable: React.FC<CoreTableProps> = ({
  headers,
  fetchDatas,
  actions,
  onClickAction,
  actionRow,
  isFetch,
  sort,
  specialStatus,
  filters,
  searchTooltip,
  isBorder,
  title,
  isFilterUsed,
  setIsFilterUsed,
  processedFilters,
  isCreateSuccess,
  isSearch = true,
  fetchDataListById,
  isMargin = true,
  maxLength,
}) => {
  const theme: any = useTheme();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [isFetchData, setIsFetchData] = useState(false);
  const [datas, setDatas] = useState<any[]>([]);
  const allowedHeaders = AuthorizationService.getAllowedResources(headers);
  const allowedActions = AuthorizationService.getAllowedResources(actions);
  const shouldRenderHeaderAction = useMemo(() => {
    return allowedActions.length > 0;
  }, [allowedActions]);

  const [paging, setPaging] = useState({
    total: 0,
    pageIndex: 1,
    pageSize: 10,
    rowPerPage: [10, 50, 100],
    totalPages: 0,
  });
  const [search, setSearch] = useState("");

  useEffect(() => {
    if (isCreateSuccess) {
      getData(1);
      setPaging((prevState) => ({
        ...prevState,
        pageIndex: 1,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCreateSuccess]);

  useEffect(() => {
    if (isFilterUsed) {
      getData(1);
      setPaging((prevState) => ({
        ...prevState,
        pageIndex: 1,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  useEffect(() => {
    getData(paging.pageIndex);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchDatas, paging.pageIndex, paging.pageSize, search, isFetch]);

  useEffect(() => {
    if (search) {
      setPaging((prevState) => ({
        ...prevState,
        pageIndex: 1,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  const getData = (pageIndex: number) => {
    if (isFetch || !isLoading) {
      const params: any = {
        pageIndex: search ? 1 : pageIndex,
        pageSize: paging.pageSize,
        keyword: search?.trim(),
      };
      if (fetchDataListById) {
        params[`${fetchDataListById.key}`] = fetchDataListById.id;
      }
      if (sort) {
        params["orderBy"] = sort.orderBy;
        params["orderDirection"] = sort.orderDirection;
      }
      if (filters) {
        let appliedFilter = processedFilters;
        setIsFilterUsed && setIsFilterUsed(appliedFilter?.length > 0);
        params["filters"] = appliedFilter;
      }
      setIsLoading(true);
      setIsFetchData(false);
      fetchDatas(params)
        .then((res) => {
          const { result } = res.data;
          setDatas(result?.data || result?.result?.data || result?.content || []);
          setPaging((prevState) => ({
            ...prevState,
            total: result?.total || result.result?.total || result.totalElements,
            totalPages: result?.totalPages || result.result?.totalPages,
          }));
        })
        .finally(() => {
          setIsLoading(false);
          setIsFetchData(true);
        });
    }
  };

  const widthOfTable = useMemo(() => {
    return allowedHeaders.reduce((prev, cur) => {
      const width = cur.width;
      return prev + width;
    }, 0);
  }, [allowedHeaders]);

  const widthOfHeader = (width: number) => {
    return `${(width / widthOfTable) * 100}%`;
  };

  const menuAction = (
    triggerProps: any,
    width: number,
    isDisabled: boolean = false
  ) => {
    return (
      <Box {...centerStyle} width={width}>
        <Pressable
          isDisabled={isDisabled}
          {...filterStyle(theme)}
          w={8}
          h={8}
          {...triggerProps}
        >
          <Image
            source={{ uri: "/icons/action.svg" }}
            h={6}
            w={6}
            alt="action"
          />
        </Pressable>
      </Box>
    );
  };

  const noDataBox = () => {
    if (!isLoading && isFetchData && _.isEmpty(datas)) {
      return (
        <Flex position="absolute" {...noDataBoxStyle()}>
          <Image
            margin="auto"
            source={{ uri: "/icons/no-data.png" }}
            alt="action"
            w={20}
            h={20}
          />
          <Text {...noDataTextStyle(theme)}>{t("common.listing.no-data")}</Text>
        </Flex>
      );
    }
    return undefined;
  };

  const renderHeader = () => {
    return (
        <HStack {...headerStyle(theme)}>
          {allowedHeaders.map((header: Header) => {
            if (header.type !== "ACTION") {
              return header.formatLabel ? (
                  <Box
                      key={header.value}
                      width={header.width}
                      minWidth={widthOfHeader(header.width)}
                  >
                    {header.formatLabel}
                  </Box>
              ) : (
                  <Box
                      key={header.value}
                      width={header.width}
                      minWidth={widthOfHeader(header.width)}
                  >
                    <Text {...textHeader(theme)}>{header.label}</Text>
                  </Box>
              );
            }

            if (shouldRenderHeaderAction) {
              return (
                  <Box
                      key={header.value}
                      {...actionStyle(theme)}
                      width={header.width}
                      h="55px"
                  >
                    <Text {...textHeader(theme)}>
                      {header.label}
                    </Text>
                  </Box>
              );
            }

            return null;
          })}
        </HStack>
    );
  };

  const renderBody = () => {
    if (!isLoading && isFetchData) {
      return (
        <VStack width="max-content" minWidth="100%">
          {datas.map((item) => (
            <HStack key={JSON.stringify(item)} {...bodyStyle(theme)}>
              {allowedHeaders.map((header) => {
                if (header.type === "ACTION") {
                  return (
                    <>
                      {shouldRenderHeaderAction &&
                        getContentAction(header, item)}
                    </>
                  );
                } else {
                  return (
                    <Box
                      overflow="hidden"
                      key={JSON.stringify(item) + header.value}
                      width={header.width}
                      minWidth={widthOfHeader(header.width)}
                    >
                      {getContentBody(header, item)}
                    </Box>
                  );
                }
              })}
            </HStack>
          ))}
        </VStack>
      );
    } else {
      return (
        <div style={{ height: "560px" }}>
          <Spinner {...spinnerStyle(theme)} position="fixed" size="lg" />
        </div>
      );
    }
  };

  function getActionsByStatus(status: string) {
    if (status === specialStatus) {
      return allowedActions?.filter((action) => action.value === specialStatus);
    }
    return allowedActions?.filter((action) => action.value !== specialStatus);
  }

  const getContentAction = (header: Header, item: any) => {
    const filteredActions = specialStatus
      ? getActionsByStatus(item.status?.toString())
      : allowedActions?.filter(
          (action) => action.value !== item.status?.toString()
        );
    const disabled = filteredActions.length === 0;
    return (
      <Box
        key={JSON.stringify(item) + header.value}
        {...actionStyle(theme)}
        width={header.width}
      >
        <Menu
          minW="230"
          padding={"12px"}
          borderRadius={"8px"}
          placement={"bottom right"}
          top={"-16px"}
          right={"36px"}
          trigger={(triggerProps) =>
            menuAction(triggerProps, header.width, disabled)
          }
        >
          {filteredActions?.map((action) => {
            return (
              <Menu.Item
                _focus={{ bg: "#FAFAFA" }}
                key={action.value}
                borderRadius={8}
                paddingRight="0px"
                paddingLeft="0px"
                height={10}
                style={{ autoFocus: false } as any}
                onPress={() => onClickAction?.(action.value, item)}
              >
                <Flex direction="row" padding={"0px"}>
                  <ReactSVG src={action.icon} height={24} width={24} />
                  <Text {...textBody16(theme)} alignContent="center" ml={2}>
                    {action.label}
                  </Text>
                </Flex>
              </Menu.Item>
            );
          })}
        </Menu>
      </Box>
    );
  };

  const getContentBody = (header: Header, item: any) => {
    if (!header.format) {
      return <GetContentText header={header} item={item} />;
    }
    return header.format(item[header.value], item);
  };

  return (
    <Box>
      {isMargin && (
        <Box
          marginTop={"-24px"}
          marginLeft="-12px"
          height="64px"
          justifyContent="center"
        >
          <Text {...titleStyle(theme)}>{title}</Text>
        </Box>
      )}
      <Box {...tableStyle(theme, isBorder)}>
        <Box flexDirection="row" justifyContent="flex-end" alignItems="center">
          <Flex {...titleHeaderBox()}>
            {isSearch && searchTooltip && (
              <AppTooltip
                message={searchTooltip}
                component={
                  <SearchBox
                    maxLength={maxLength}
                    w="280px"
                    defaultValue={""}
                    placeholder={t("ticket-listing.search-placeholder")}
                    onKeySearchChange={(key) => setSearch(key ?? "")}
                  />
                }
              />
            )}
            {isSearch && !searchTooltip && (
              <SearchBox
                maxLength={maxLength}
                w="280px"
                defaultValue={""}
                placeholder={t("ticket-listing.search-placeholder")}
                onKeySearchChange={(key) => setSearch(key ?? "")}
              />
            )}
            {actionRow}
          </Flex>
        </Box>
        <div
          style={{
            position: "relative",
            overflow: "auto",
            width: "100%",
          }}
        >
          {renderHeader()}
          {renderBody()}

          {!isLoading && isFetchData && _.isEmpty(datas) && (
            <Box position="relative" h="204px"></Box>
          )}
        </div>
        {noDataBox()}
        <TablePagination
          rpgTextLeft={t("common.row-per-page")}
          rpgTextRight={t("common.of")}
          paginationInfo={paging}
          onPaginationChange={(value) => setPaging(value)}
        />
      </Box>
    </Box>
  );
};

const GetContentText = ({ header, item }: { header: Header; item: any }) => {
  const theme = useTheme();
  const { textRef, isTruncated } = useIsTruncated(item[header.value]);
  return (
    <>
      {isTruncated && (
        <AppTooltip
          message={item[header.value]}
          component={
            <Text ref={textRef} isTruncated {...textBody(theme)}>
              {item[header.value]}
            </Text>
          }
        />
      )}
      {!isTruncated && (
        <Text ref={textRef} isTruncated {...textBody(theme)}>
          {item[header.value]}
        </Text>
      )}
    </>
  );
};

export default CoreTable;
