export const PERMISSIONS = {
  LOGIN: "LOGIN.LOGIN",
  CATALOG: {
    GIFT_CARD: {
      LISTING: "GIFT_CARDS.ISSUED.LISTING",
    },
  },
  CUSTOMER: {
    KYC: {
      LISTING: "KYC_PROFILES.LISTING",
    },
  },
  MARKETING: {
    KONNECT_PAK: {
      LISTING: "KONNECT_PAK.PAKS_LIST",
    },
    AD_MAKER: {
      LISTING: "AD_MAKER.AD_CAMPAIGNS.LISTING"
    }
  },
  ACCOUNTING: {
    TRANSACTION: {
      LISTING: "TRANSACTION.LISTING.TRANSACTION_LISTING",
    },
    WITHDRAWAL_REQUEST: {
      LISTING: "WITHDRAWAL_REQUEST.LISTING",
    }
  },
  CUSTOMER_SUPPORT: {
    TICKET: {
      LISTING: "CUSTOMER_SUPPORT.TICKETS.LISTING",
      CREATE: "CUSTOMER_SUPPORT.TICKETS.CREATE_TICKET",
      EDIT: "CUSTOMER_SUPPORT.TICKETS.EDIT_TICKET",
    },
  },
  CONTACTS: {
    LEAD: {
      LISTING: "CONTACTS.LEAD.LISTING",
      INVITE: "CONTACTS.LEAD.INVITE_LEAD",
      CREATE: "CONTACTS.LEAD.CREATE_LEAD",
      HISTORY_UPLOAD: "CONTACTS.LEAD.HISTORY_UPLOAD_LEAD",
      EDIT: "CONTACTS.LEAD.EDIT_LEAD",
    },
    PROSPECT: {
      CREATE: "CONTACTS.PROSPECT.PROSPECT_CREATE",
      LISTING: "CONTACTS.PROSPECT.PROSPECT_LIST",
      EDIT: "CONTACTS.PROSPECT.PROSPECT_EDIT",
    },
    CLIENT: {
      LISTING: "CONTACTS.CLIENT.CLIENT_LIST",
      EDIT: "CONTACTS.CLIENT.CLIENT_EDIT",
      CREATE: "CONTACTS.CLIENT.CLIENT_CREATE",
    },
  },
  USERS_ROLES: {
    ROLES_LISTING: "USERS_ROLES.ROLES.LISTING",
    CREATE_ROLES: "USERS_ROLES.ROLES.CREATE_ROLES",
    EDIT_ROLES: "USERS_ROLES.ROLES.EDIT_ROLES",
    DISABLE_ROLES: "USERS_ROLES.ROLES.DISABLE_ROLES",
    ENABLE_ROLES: "USERS_ROLES.ROLES.ENABLE_ROLES",
    DELETE_ROLES: "USERS_ROLES.ROLES.DELETE_ROLES",
    USERS_LISTING: "USERS_ROLES.USERS.LISTING",
    ASSIGN_TO_ROLE: "USERS_ROLES.USERS.ASSIGN_TO_ROLE",
    SENSITIVE_DATA: {
      VIEW_SENSITIVE_DATA: "USERS_ROLES.SENSITIVE_DATA.VIEW_SENSITIVE_DATA",
    },
  },
  CAUSE2FUND: {
    PROGRAM: {
      MANAGE_PROGRAM_AND_PROJECT:
        "CAUSE2FUND.PROGRAM.MANAGE_PROGRAM_AND_PROJECT",
      VIEW_PROGRAM_AND_PROJECT: "CAUSE2FUND.PROGRAM.VIEW_PROGRAM_AND_PROJECT",
    },
  },
  CONSUMERS: {
    LISTING: "CONSUMER.LISTING.CONSUMER_LISTING",
  },
  MERCHANTS: {
    LISTING: "MERCHANT.LISTING.MERCHANT_LISTING",
  },
  SETTING: {
    EXCHANGE_RATE: {
      LISTING: "EXCHANGE_RATES.VIEW",
    },
    COMMISSION: {
      LISTING: "COMMISSION.COMMISSION.LISTING",
    },
  }
};
