import ApiClient from "../api-client/apiClient";
import Api from "../../configs/api";
import { resolveTenant } from "../../tenantResolver";

const UserService = {
  list: (data: any) => {
    const url = `${Api.USER}${Api.SEARCH}`;
    const tenantConfig = resolveTenant();
    let authData = JSON.parse(
      localStorage.getItem(
        `oidc.at:${tenantConfig.oidcConfiguration.authority}`,
      ) ?? "{}",
    );
    const headers = {
      token: authData.access_token,
    };
    return ApiClient(headers).post(url, data);
  },
};

export default UserService;
