import React, { Fragment, useState } from "react";
import { Box, Text, useTheme } from "native-base";
import _ from "lodash";
import moment from "moment/moment";
import { errorText, gap16, gap6, textHeader1 } from "../../styles/text";
import { DropDown, DatePicker, AppInput } from "shared-components";
import { OptionItem } from "../../types/dropdownProps";
import FilterForm from "../filter-form/FilterForm";
import { FilterDateDTO } from "../../types/ticketListingFilterDTO";
import {
  boxPropertyInsideStyle,
  boxPropertyStyle,
  textPropertyStyle,
} from "../ticket-listing-filter/TicketListingFilter.style";
import CountryDropdown from "../country-dropdown/CountryDropdown";
import { useTranslation } from "react-i18next";
import {
  ConsumerListingFilterDTO,
  ConsumerListingFilterProps,
} from "../../types/consumerListingFilterProps";

const ConsumerListingFilter: React.FC<ConsumerListingFilterProps> = ({
  initFilters,
  onFiltersChanged,
  dropDownIcon,
  selectedIcon,
  arrowIcon,
  calendarIcon,
  arrowUp,
  arrowDown,
  arrowLeft,
  arrowRight,
  statusOptions,
  kycLevelOptions,
  textObject,
  onCloseFilter,
  closeFilterIcon,
  selectedCountry,
  setSelectedCountry,
}) => {
  const theme = useTheme();
  const [filters, setFilters] = useState<ConsumerListingFilterDTO>(initFilters);
  const [errorDateMessage, setErrorDateMessage] = useState<string>("");
  const filterChanged = (valueObject: any, key: string) => {
    let filtersTemp = _.cloneDeep(filters);
    if (key === "createdTime") {
      setErrorDateMessage("");
      filtersTemp[key] = valueObject;
    } else {
      filtersTemp = {
        ...filters,
        [key]: valueObject.label === "ALL" ? "" : valueObject.label,
      };
    }
    if (typeof valueObject === "string") {
      filtersTemp = {
        ...filters,
        [key]: valueObject,
      };
    }
    setFilters(filtersTemp);
  };

  const clearAll = () => {
    const filtersTemp = {
      status: "",
      kycStatus: "",
      createdTime: {
        from: "",
        to: "",
      },
      countryCode: "",
      fullName: "",
      email: "",
      phoneNumber: "",
    };
    setFilters(filtersTemp);
    setErrorDateMessage("");
  };

  const onApplyFilter = () => {
    if (validateDateRange()) {
      onFiltersChanged(filters);
    }
  };

  const validateDateRange = () => {
    setErrorDateMessage("");
    const { from, to } = filters.createdTime;
    if (!from && !to) {
      return true;
    }

    const format = "MM/DD/YYYY";
    const momentDate1 = moment(from, format);
    const momentDate2 = moment(to, format);
    if (momentDate1.isAfter(momentDate2)) {
      setErrorDateMessage(textObject.earlierErrMsg);
      return false;
    }
    return true;
  };

  const statusFilter = () => {
    const dropdownProps = {
      data: statusOptions,
      dropDownIcon: dropDownIcon,
      selectedIcon: selectedIcon,
    };

    return (
      <Box mb={4} zIndex={"auto"}>
        <Text {...textHeader1(theme)} mb={1.5}>
          {textObject.status}
        </Text>
        <DropDown
          {...dropdownProps}
          defaultOptionLabel={filters.status || "ALL"}
          action={(option: OptionItem) => {
            filterChanged(option, "status");
          }}
        />
      </Box>
    );
  };

  const kycLevelFilter = () => {
    const dropdownProps = {
      data: kycLevelOptions,
      dropDownIcon: dropDownIcon,
      selectedIcon: selectedIcon,
    };

    return (
      <Box mb={4} zIndex={"auto"}>
        <Text {...textHeader1(theme)} mb={1.5}>
          {t("consumers.kyc-level")}
        </Text>
        <DropDown
          {...dropdownProps}
          defaultOptionLabel={filters.kycStatus || "ALL"}
          action={(option: OptionItem) => {
            filterChanged(option, "kycStatus");
          }}
        />
      </Box>
    );
  };

  const { t } = useTranslation();
  const handleCountryChange = (option: any) => {
    const value = {
      label: option.value,
    };
    filterChanged?.(value, "countryCode");
  };
  const countryFilter = () => {
    return (
      <Box {...boxPropertyStyle()} marginBottom="22px">
        <Box {...boxPropertyInsideStyle()}>
          <Text {...textPropertyStyle(theme)}>{t("consumers.country")}</Text>
        </Box>
        <CountryDropdown
          initSelectedItem={
            filters.countryCode || t("ticket-listing-filter.status-options.all")
          }
          onSelectedItem={handleCountryChange}
          onValueChangeCountry={(data) => {
            setSelectedCountry && setSelectedCountry(data);
          }}
          selectedCountry={selectedCountry}
          dropdownIcon={"/icons/arrow-down.svg"}
          checkedIcon={"/icons/checkmark.svg"}
          noDataIcon={"/icons/no-data.svg"}
        />
      </Box>
    );
  };

  const datePickerFilter = () => {
    const datePickerProps = {
      arrowIcon: arrowIcon,
      calendarIcon: calendarIcon,
      arrowUp: arrowUp,
      arrowDown: arrowDown,
      arrowLeft: arrowLeft,
      arrowRight: arrowRight,
    };

    return (
      <Box mb={4} zIndex={"auto"}>
        <Text {...textHeader1(theme)} mb={1.5}>
          {t("consumers.created-time")}
        </Text>
        <DatePicker
          {...datePickerProps}
          initDateRange={filters.createdTime}
          isInvalid={!!errorDateMessage}
          onChangeDateRange={(dateRange: FilterDateDTO) => {
            filterChanged(dateRange, "createdTime");
          }}
        />
        {errorDateMessage ? (
          <Text mt={2} {...errorText(theme)}>
            {errorDateMessage}
          </Text>
        ) : undefined}
      </Box>
    );
  };

  const searchBox = () => {
    return (
      <Box {...gap16}>
        <Box {...gap6}>
          <Text {...textHeader1(theme)}>{t("consumers.full-name")}</Text>
          <AppInput
            placeholder={t("consumers.search-by-full-name")}
            defaultValue={filters.fullName}
            onChangeText={(text) => filterChanged(text, "fullName")}
            maxLength={200}
          ></AppInput>
        </Box>
        <Box {...gap6}>
          <Text {...textHeader1(theme)}>{t("consumers.email")}</Text>
          <AppInput
            placeholder={t("consumers.search-by-email")}
            defaultValue={filters.email}
            onChangeText={(text) => {
              filterChanged(text, "email");
            }}
            maxLength={200}
          ></AppInput>
        </Box>
        <Box {...gap6}>
          <Text {...textHeader1(theme)}>{t("consumers.phone-number")}</Text>
          <AppInput
            placeholder={t("consumers.search-by-phone-number")}
            defaultValue={filters.phoneNumber}
            onChangeText={(text: string) => {
              filterChanged(text, "phoneNumber");
            }}
            maxLength={200}
          ></AppInput>
        </Box>
      </Box>
    );
  };

  return (
    <FilterForm
      textObject={textObject}
      closeFilterIcon={closeFilterIcon}
      onClearFilter={() => {
        clearAll();
      }}
      onApplyFilter={onApplyFilter}
      onCloseFilter={onCloseFilter}
    >
      <Fragment>
        {statusFilter()}
        {kycLevelFilter()}
        {countryFilter()}
        {datePickerFilter()}
        {searchBox()}
      </Fragment>
    </FilterForm>
  );
};
export default ConsumerListingFilter;
