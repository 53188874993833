import { useEffect, useMemo, useRef, useState } from "react";
import {
  AppButton,
  Box,
  CallerInformation,
  FilesUploader,
  Flex,
  Input,
  Popup,
  Pressable,
  RichTextEditor,
  Text,
  ToastMessage,
  useAppAuth,
} from "shared-components";
import {
  counterText,
  errorText,
  formBox,
  headerText,
  inputStyle,
  labelText,
  leftFormStyle,
  optionalText,
} from "./TicketForm.style";
import { Spacer, useTheme, useToast } from "native-base";
import { ReactSVG } from "react-svg";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { TicketPropertiesDTO } from "shared-components/lib/typescript/src/models/ticketPropertiesDTO";
import { Error } from "shared-components/src/types/ticketPropertiesProps";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import AppTicketProperties from "../ticket-properties/TicketProperties";
import debounce from "lodash/debounce";
import TicketService from "../../services/ticket/ticketService";
import MediaLegacyService from "../../services/media-legacy/mediaLegacyService";
import { ToastProps } from "shared-components/lib/typescript/src/types/toastProps";
import _ from "lodash";
import { PopupProps } from "shared-components/lib/typescript/src/ui-components/custom-components/popup/Popup";
import isEqual from "lodash/isEqual";
import AppTicketActivityLog from "../ticket-activity-log/TicketActivityLog";
import { parse, startOfDay } from "date-fns";
import { UserConstants } from "../../constants/user";
import moment from "moment/moment";
import { SingleDatePicker } from "shared-components/lib/typescript/src/types/ticketPropertiesProps";
import CallService from "../../services/call/callService";
import { ResourceType } from "../../constants/resourceType";
import LeadService from "../../services/lead/leadService";
import { showNotificationForVoiso } from "../../services/toast/toastService";
import UserInformationDropdown from "../user-information-dropdown/UserInformationDropdown";
import AssociatedOrder from "../associated-order/AssociatedOrder";
import AssociatedOrderService from "../../services/associated-order/associatedOrderService";
import useMediaQuery from "../../hooks/useMediaQuery";

interface TicketFormValues {
  title: string;
  description?: string;
  solution?: string;
  attachment: File[];
  email?: string;
  phoneNumber?: string;
  status?: string;
  category?: string;
  subCategory?: string;
  assigneeId?: string;
  source?: string;
  targetDate?: string;
}

interface FileAttachmentDTO {
  fileName: string;
  fileUrl: string;
}

interface TicketRequest {
  title: string;
  description?: string;
  solution?: string;
  attachment: FileAttachmentDTO[];
  email?: string;
  phoneNumber?: string;
  status?: string;
  category?: string;
  subCategory?: string;
  assigneeId?: string;
  source?: string;
  targetDate?: number;
}

export interface CallerInformationDTO {
  phoneNumber: string;
  contactType?: string;
  fullName?: string;
  email?: string;
  lastCallDate?: number;
  avatar?: string;
}

const TicketForm = () => {
  const theme = useTheme();
  const toast = useToast();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { ticketNumber } = useParams();
  const { authData } = useAppAuth();
  const location = useLocation();
  const back = () => {
    navigate("/ticket", { replace: true });
  };
  const {
    register,
    trigger,
    setValue,
    handleSubmit,
    formState: { errors, isSubmitted },
  } = useForm();
  const [errorMsg, setErrorMsg] = useState<Error[]>([]);
  const [ticketFormValues, setTicketFormValues] = useState<TicketFormValues>({
    title: "",
    status: "NEW",
    category: "",
    attachment: [],
    description: "",
    solution: undefined,
    source: undefined,
    targetDate: "",
  });
  const [oldValues, setOldValues] = useState<TicketRequest>({
    title: "",
    status: "NEW",
    category: "",
    attachment: [],
    description: "",
    solution: undefined,
    source: undefined,
    assigneeId: undefined,
    phoneNumber: undefined,
    subCategory: undefined,
    email: undefined,
  });
  const [ticketProperties, setTicketProperties] = useState<any>({
    status: "NEW",
    category: "",
    subCategory: undefined,
    assignee: undefined,
    assigneeName: undefined,
    source: undefined,
    targetDate: "",
  });
  const singleDatePicker: SingleDatePicker = {
    arrowIcon: "/icons/arrow-long.svg",
    calendarIcon: "/icons/calendar.svg",
    arrowUp: "/icons/mini-arrow-up.svg",
    arrowDown: "/icons/mini-arrow.svg",
    arrowLeft: "/icons/chevron-left.svg",
    arrowRight: "/icons/chevron-right.svg",
  };
  const [showAlertBack, setShowAlertBack] = useState(false);
  const [activityChips, setActivityChips] = useState([
    {
      label: t("ticket-form.comments"),
      route: "comments",
      isActive: true,
      selectChips: (route: string) => {
        changeTab(route);
      },
    },
    {
      label: t("ticket-form.history"),
      route: "history",
      isActive: false,
      selectChips: (route: string) => {
        changeTab(route);
      },
    },
  ]);
  const [resetUploadFiles, setResetUploadFiles] = useState(false);

  // const [userInfo, setUserInfo] = useState({
  //   userId: "",
  //   fullName: "",
  //   phone: "",
  //   email: "",
  //   country: "",
  // });
  const [initFiles, setInitFiles] = useState<FileAttachmentDTO[]>([]);
  const [loadedFiles, setLoadedFiles] = useState<FileAttachmentDTO[]>([]);
  const [callerInfo, setCallerInfo] = useState<CallerInformationDTO>({
    phoneNumber: "",
    email: "",
    fullName: "",
    lastCallDate: undefined,
    contactType: "",
  });
  const [userType, setUserType] = useState<any>(undefined);
  const sourceRef = useRef(null);
  const [isEditUser, setIsEditUser] = useState<boolean | undefined>(undefined);
  const [isEditSuccess, setIsEditSuccess] = useState<boolean | undefined>(
    undefined
  );
  const [orderNumber, setOrderNumber] = useState("");
  const [associatedOrder, setAssociatedOrder] = useState();
  const btnRef = useRef<any>();
  const changeTab = (route: string) => {
    const chips = activityChips.map((chip: any) => {
      return {
        ...chip,
        isActive: route === chip.route,
      };
    });
    setActivityChips(chips);
  };

  const isNotEmptyOrWhitespace = (value: string) => value.trim() !== "";
  const validationRules = {
    title: {
      required: {
        value: true,
        message: t("errors.standard001", {
          fieldName: t("ticket-form.label.title"),
        }),
      },
      validate: (value: string) =>
        isNotEmptyOrWhitespace(value) ||
        t("errors.standard001", {
          fieldName: t("ticket-form.label.title"),
        }),
    },
    category: {
      required: {
        value: true,
        message: t("errors.standard001", {
          fieldName: t("ticket-properties.category.label"),
        }),
      },
    },
  };
  useEffect(() => {
    register("category", validationRules.category);
  }, [register, validationRules.category]);
  useEffect(() => {
    if (ticketFormValues) {
      const { title, category } = ticketFormValues;
      const fieldsToUpdate = { title, category };
      Object.entries(fieldsToUpdate).forEach(([fieldName, fieldValue]) => {
        setValue(fieldName as keyof TicketFormValues, fieldValue);
      });
      if (isSubmitted) {
        trigger().then(() => {
          const error: Error[] = [];
          Object.entries(fieldsToUpdate).forEach(([fieldName, fieldValue]) => {
            setValue(fieldName as keyof TicketFormValues, fieldValue);
            if (!_.isEmpty(errors[fieldName]?.message)) {
              error.push({
                fieldName: fieldName,
                message: errors[fieldName]?.message as string,
              });
            }
          });
          setErrorMsg(error);
        });
      }
    }
  }, [ticketFormValues, isSubmitted, errors, setValue, trigger]);

  const isEdit = useMemo(() => {
    return ticketNumber;
  }, [ticketNumber]);

  useEffect(() => {
    getTicketDetail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ticketNumber]);

  useEffect(() => {
    if (isEditSuccess !== undefined) {
      getTicketDetail();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditSuccess]);

  const getTicketDetail = () => {
    if (ticketNumber) {
      TicketService.getTicketDetail(ticketNumber)
        .then((res) => {
          const { result } = res.data;
          sourceRef.current = result?.source;
          setResetUploadFiles(true);
          setTicketFormValues({
            title: result?.title,
            attachment: [],
            description: result?.description,
            solution: result?.solution,
            status: result?.status,
            category: result?.category,
            subCategory: result?.subCategory,
            assigneeId: result?.assignee?.email,
            source: result?.source,
            targetDate: result?.targetDate,
          });
          // setUserInfo({
          //   userId: result?.userInfor?.userId,
          //   fullName: result?.userInfor?.fullName,
          //   phone: result?.userInfor?.phoneNumber,
          //   email: result?.userInfor?.email,
          //   country: result?.userInfor?.country,
          // });
          setTicketProperties({
            status: result.status,
            category: result?.category,
            subCategory: result?.subCategory,
            assignee: result?.assignee?.email,
            assigneeName: result?.assignee?.fullName,
            source: result?.source,
            targetDate: formatDate(result?.targetDate),
          });
          setInitFiles(result.attachment as FileAttachmentDTO[]);
          setLoadedFiles(result.attachment as FileAttachmentDTO[]);
          setOldValues({
            title: result?.title,
            description: result?.description,
            solution: result?.solution,
            attachment: [...result.attachment] as FileAttachmentDTO[],
            status: result?.status,
            category: result?.category,
            subCategory: result?.subCategory,
            assigneeId: result?.assignee?.email,
            source: result?.source,
          } as TicketRequest);
          setAssociatedOrder(result?.associatedOrderInfo);
        })
        .finally(() => {
          setTimeout(() => {
            setResetUploadFiles(false);
          }, 0);
        });
      TicketService.getCallerInformation(ticketNumber)
        .then((res) => {
          const { result } = res.data;
          setCallerInfo({
            phoneNumber: result?.callerInformation?.phoneNumber,
            avatar: result?.callerInformation?.avatar,
            email: result?.callerInformation?.email,
            fullName: result?.callerInformation?.fullName,
            lastCallDate: result?.callerInformation?.lastCallDate,
            contactType: result?.callerInformation?.contactType,
          });
          const contactType = result?.callerInformation?.contactType;
          setUserType(
            contactType === "MERCHANT" ||
              contactType === "CONSUMER" ||
              contactType === "LEAD" ||
              contactType === "GUEST"
              ? "IN_SYSTEM"
              : "UNKNOWN"
          );
        })
        .finally(() => {
          setIsEditSuccess(undefined);
        });
    }
  };

  const dateToTimestamp = (dateStr: string): number => {
    return startOfDay(parse(dateStr, "MM/dd/yyyy", new Date())).getTime();
  };

  const formatDate = (dateStr: string): string => {
    return dateStr ? moment(dateStr).format("MM/DD/YYYY") : "";
  };

  const onSubmit = async () => {
    const urlFiles: FileAttachmentDTO[] = [];
    if (ticketFormValues.attachment) {
      for (const file of ticketFormValues.attachment) {
        const formData = new FormData();
        formData.append("file", file);
        await MediaLegacyService.uploadFile(formData).then((res: any) => {
          if (res.data.result) {
            urlFiles.push({
              fileName: file.name,
              fileUrl: res.data.result.url,
            });
          }
        });
      }
    }
    const attachment = _.concat(urlFiles, initFiles);
    const ticketRequest = {
      title: ticketFormValues.title,
      description: ticketFormValues.description,
      solution: ticketFormValues.solution,
      attachment: attachment,
      email: ticketFormValues.email,
      phoneNumber: ticketFormValues.phoneNumber,
      status: ticketFormValues.status,
      category: ticketFormValues.category,
      subCategory: ticketFormValues.subCategory,
      assigneeId: ticketFormValues.assigneeId,
      source: ticketFormValues.source,
      userId: userId,
      orderNumber: orderNumber,
    } as TicketRequest;
    if (ticketFormValues.targetDate) {
      ticketRequest.targetDate = dateToTimestamp(ticketFormValues.targetDate);
    }
    if (isEdit) {
      TicketService.editTicket(
        ticketRequest,
        ticketNumber,
        authData.access_token
      )
        .then(() => {
          const toastData = {
            message: t("ticket-form.msg-edit-success"),
            icon: "/icons/toast/check.svg",
          };
          showToast(toast, toastData);
          setLoadedFiles(attachment);
          setOldValues(ticketRequest);
        })
        .finally(() => {
          setIsEditUser(false);
          setIsEditSuccess(userId !== null);
          setUserId(null);
        })
        .catch(() => {});
    } else {
      TicketService.createTicket(ticketRequest).then(() => {
        const toastData = {
          message: t("ticket-form.msg-create-success"),
          icon: "/icons/toast/check.svg",
        };
        showToast(toast, toastData);
        setOldValues(ticketRequest);
        back();
      });
    }
  };

  function closeAll() {
    toast.closeAll();
  }

  const template = (args: ToastProps) => (
    <Box marginRight="32px" marginTop="14px">
      <ToastMessage
        message={args.message}
        icon={args.icon}
        title={args.title}
        onClose={closeAll}
      />
    </Box>
  );

  const showToast = (toast: any, args: ToastProps) => {
    toast.show({
      render: () => {
        return template(args);
      },
      placement: "top-right",
    });
  };

  const getError = (fieldName: string) => {
    if (!_.isEmpty(errors[fieldName]?.message)) {
      return (
        <Text {...errorText(theme)}>
          {" "}
          {errors[fieldName]?.message as string}{" "}
        </Text>
      );
    }
    return undefined;
  };

  const getTicketProperties = (ticketPropertiesValue: TicketPropertiesDTO) => {
    setTicketProperties(ticketPropertiesValue);
    const ticketValue = ticketFormValues;
    if (ticketValue) {
      ticketValue.status = ticketPropertiesValue.status;
      ticketValue.category = ticketPropertiesValue.category;
      ticketValue.targetDate = ticketPropertiesValue.targetDate;
      if (ticketPropertiesValue.subCategory) {
        ticketValue.subCategory = ticketPropertiesValue.subCategory;
      }
      ticketValue.assigneeId = ticketPropertiesValue.assignee;
      ticketValue.source = ticketPropertiesValue.source;
      setTicketFormValues((prevState) => ({ ...prevState, ...ticketValue }));
    }
  };

  const callVoiso = (phoneNumber: string) => {
    CallService.callVoiso(
      phoneNumber,
      ResourceType.TICKET,
      ticketNumber || ""
    ).catch((reason) => {
      if (reason.response.data.code === "1010022") {
        showNotificationForVoiso(t);
      }
    });
  };

  const showAlert = () => {
    // const content = isEdit ?
    const modal = {
      content: t("ticket-form.alert-content"),
      typeButton: "danger",
      confirmText: t("ticket-form.alert-exit"),
      cancelText: t("ticket-form.alert-cancel"),
    } as PopupProps;
    return (
      <Popup
        {...modal}
        showPopup={showAlertBack}
        onChangeShowPopup={(e) => setShowAlertBack(e)}
        confirmAction={back}
      ></Popup>
    );
  };

  const handleBack = () => {
    const ticketRequest = {
      title: ticketFormValues.title,
      description: ticketFormValues.description,
      solution: ticketFormValues.solution,
      attachment: loadedFiles,
      status: ticketFormValues.status,
      category: ticketFormValues.category,
      subCategory: ticketFormValues.subCategory,
      assigneeId: ticketFormValues.assigneeId,
      source: ticketFormValues.source,
    } as TicketRequest;

    const removeUndefined = (obj: any) => {
      return Object.fromEntries(
        Object.entries(obj).filter(([_, v]) => v !== undefined)
      );
    };

    const cleanedTicketRequest = removeUndefined(ticketRequest);
    const cleanedOldValues = removeUndefined(oldValues);

    if (
      isEqual(cleanedTicketRequest, cleanedOldValues) &&
      ticketFormValues.attachment.length ===
        loadedFiles.length - initFiles.length
    ) {
      back();
    }
    setShowAlertBack(true);
  };

  const header = () => {
    return (
      <Flex
        direction="row"
        justify="space-between"
        height="64px"
        alignItems="center"
      >
        <Flex direction="row" align="center">
          <Box height="24px" mr={3}>
            <Pressable
              testID={"back-btn"}
              onPress={() => {
                handleBack();
              }}
            >
              <ReactSVG src="/icons/back.svg" />
            </Pressable>
          </Box>
          <Text {...headerText(theme)}>
            {isEdit
              ? t("ticket-form.title.edit")
              : t("ticket-form.title.create")}
          </Text>
        </Flex>
        <Box>
          <AppButton
            ref={btnRef}
            label={
              isEdit
                ? t("ticket-form.btn-submit-edit")
                : t("ticket-form.btn-submit-create")
            }
            onPress={handleSubmit(onSubmit)}
          />
        </Box>
      </Flex>
    );
  };

  const handleChange = debounce((text: string) => {
    setTicketFormValues((prevState) => ({
      ...prevState,
      title: text,
    }));
  }, 300);

  const handleDescriptionChange = debounce((text: string) => {
    setTicketFormValues((prevState) => ({
      ...prevState,
      description: text,
    }));
  }, 300);

  const titleBox = () => {
    return (
      <Box mb={4}>
        {/*{isEdit ? (*/}
        {/*  <Box {...ticketNumberStyle(theme)}>*/}
        {/*    <Text {...textTicketNumberStyle(theme)}>#{ticketNumber}</Text>*/}
        {/*  </Box>*/}
        {/*) : undefined}*/}
        <Flex direction="row" justify="space-between" align="center">
          <Text {...labelText(theme)} mb={2}>
            {t("ticket-form.label.title")}
          </Text>
          <Text {...counterText(theme)}>
            {ticketFormValues.title.length}/250
          </Text>
        </Flex>
        <Input
          placeholder={t("ticket-form.placeholder.title")}
          {...register("title", validationRules.title)}
          maxLength={250}
          defaultValue={ticketFormValues.title}
          onChangeText={(text) => {
            handleChange(text);
          }}
          isInvalid={!!errors["title"] && !!errors["title"]?.message}
          {...inputStyle(theme)}
        />
        {getError("title")}
      </Box>
    );
  };

  const DescriptionBox = () => {
    return (
      <Box mb={4}>
        <Text {...labelText(theme)} mb={2}>
          {t("ticket-form.label.description")}{" "}
          <Text {...optionalText(theme)}>({t("ticket-form.optional")})</Text>
        </Text>
        <RichTextEditor
          placeholder={t("ticket-form.placeholder.description")}
          content={ticketFormValues.description}
          setHTMLContent={(text) => {
            handleDescriptionChange(text);
          }}
        />
      </Box>
    );
  };

  const FilesUploaderBox = () => {
    const fileSize = 5;
    const maxQuantity = 20;
    const fileTypeAllows = [
      ".xls",
      ".xlsx",
      ".xml",
      ".zip",
      ".java",
      ".log",
      ".txt",
      ".csv",
      ".doc",
      ".docx",
      ".xltx",
      ".ppt",
      ".pptx",
      ".pdf",
      ".html",
      ".jpeg",
      ".jpg",
      ".png",
    ];
    const filesUploaderProps = {
      textObject: {
        dropText: t("ticket-form.upload-file.drop-file"),
        orText: t("ticket-form.upload-file.or"),
        buttonText: t("ticket-form.upload-file.select-file"),
        maxSizeText: t("ticket-form.upload-file.max-size", {
          maxSize: fileSize,
        }),
        errorMaxSizeText: t("errors.standard081", {
          maxSize: fileSize,
        }),
        errorMaxQuantityText: t("errors.standard082"),
      },
      deleteIcon: "/icons/close.svg",
      fileSize: fileSize,
      maxQuantity: maxQuantity,
      fileType: fileTypeAllows.join(", "),
    };
    return (
      <Box mb={4}>
        <Text {...labelText(theme)} mb={2}>
          {t("ticket-form.label.attachment")}{" "}
          <Text {...optionalText(theme)}>({t("ticket-form.optional")})</Text>
        </Text>
        <FilesUploader
          {...filesUploaderProps}
          onUploadFiles={(files: any) => {
            ticketFormValues.attachment = files;
          }}
          onChangeInitFiles={(files: FileAttachmentDTO[]) => {
            setInitFiles(files);
            setLoadedFiles(files);
          }}
          initFiles={initFiles}
          resetUploadFiles={resetUploadFiles}
        />
      </Box>
    );
  };

  const DiscussionBox = () => {
    return (
      <AppTicketActivityLog
        activityChips={activityChips}
        textHeaderLabel={t("ticket-form.activity-logs")}
        showText={t("ticket-form.show")}
        ticketId={ticketNumber || ""}
      />
    );
  };

  // const userInfoBox = () => {
  //   return (
  //     <Box mr={4} mb={4}>
  //       <AppUserInformation
  //         getUserInfo={(user: any) => {
  //           ticketFormValues.email = user.email;
  //           ticketFormValues.phoneNumber = user.phoneNumber;
  //         }}
  //         userInformation={userInfo}
  //       />
  //     </Box>
  //   );
  // };

  const getUserType = (callerInfo: any) => {
    const userType = UserConstants.type.find(
      ({ label }: { label: string }) => callerInfo.contactType === label
    );
    return userType ? t(userType.value) : null;
  };

  const convertToUserInfo = (
    callerInfo: any,
    userType: "IN_SYSTEM" | "UNKNOWN"
  ) => {
    return {
      avatar: callerInfo?.avatar ?? "/icons/unknown-avatar.svg",
      fullName:
        userType === "IN_SYSTEM" ? callerInfo?.fullName : t("common.unknown"),
      type:
        userType === "IN_SYSTEM"
          ? getUserType(callerInfo)
          : t("common.unknown"),
      phoneNumber: callerInfo?.phoneNumber,
      email: callerInfo?.email,
      lastCall: callerInfo?.lastCallDate
        ? t("voiso.last-call", {
            dateTime: moment(callerInfo?.lastCallDate).format(
              "MM/DD/YYYY h:mmA"
            ),
          })
        : undefined,
    };
  };
  const [keySearch, setKeySearch] = useState<string | undefined>("");
  const [isFetchData, setIsFetchData] = useState(false);
  const [userId, setUserId] = useState(null);
  useEffect(() => {
    if (userId !== null && btnRef.current) {
      btnRef.current.click();
    }
  }, [userId]);
  const boxRef = useRef<HTMLDivElement | null>(null);
  const callerInfoBox = () => {
    if (
      isEdit &&
      (location?.state?.source === "CALL" || sourceRef?.current === "CALL")
    ) {
      const textObject = {
        title: t("User Information"),
        associateTickets: t(
          "call-information.edit-ticket.inbound-call.associateTickets"
        ),
        callBtnLabel: t(
          "call-information.edit-ticket.inbound-call.callBtnLabel"
        ),
        editBtnLabel: t(
          "call-information.edit-ticket.inbound-call.editBtnLabel"
        ),
      };

      const createLeadFormProps = {
        labels: {
          firstName: t("call-information.edit-ticket.labels.first-name"),
          lastName: t("call-information.edit-ticket.labels.last-name"),
          email: t("call-information.edit-ticket.labels.email"),
          phoneNumber: t("ticket-listing.header-column.phone-number"),
        },
        btnLabel: t("call-information.edit-ticket.btnLabel"),
        optionalLabel: t("call-information.edit-ticket.optional"),
        placeholder: {
          firstName: t("call-information.edit-ticket.placeholder.first-name"),
          lastName: t("call-information.edit-ticket.placeholder.last-name"),
          email: t("call-information.edit-ticket.placeholder.email"),
        },
        errorMessageValue: {
          emptyFirstName: t("errors.standard001", {
            fieldName: t(
              "user-profile-listing.header-column.first-name"
            ).toLowerCase(),
          }),
          emptyLastName: t("errors.standard001", {
            fieldName: t(
              "user-profile-listing.header-column.last-name"
            ).toLowerCase(),
          }),
        },
        initData: {
          firstName: "",
          lastName: "",
          email: "",
          phoneNumber: callerInfo?.phoneNumber,
        },
        btnSave: t("role-form.btn-submit-edit"),
        btnCancel: t("role-form.alert-cancel"),
        onChangeData: () => {},
      };

      return (
        <Box ref={boxRef}>
          <Box
            position="relative"
            width="calc(100% - 48px)"
            marginLeft="24px"
            zIndex={"auto"}
            top="66px"
          >
            <UserInformationDropdown
              keySearch={keySearch}
              isFetchData={isFetchData}
              setIsFetchData={setIsFetchData}
              onValueChange={(data) => {
                setUserId(data.userId);
                setIsEditUser(undefined);
              }}
              maxHeight={
                boxRef.current?.clientHeight
                  ? `calc(${boxRef.current?.clientHeight}px - 150px)`
                  : ""
              }
            />
          </Box>
          <CallerInformation
            type={userType}
            userInfo={convertToUserInfo(callerInfo, userType)}
            onClose={() => {}}
            onClickPhone={(phoneNumber: string) => callVoiso(phoneNumber)}
            textObject={textObject}
            isTicketDetails={isEdit !== ""}
            viewAssociateTickets={(callerInfo: CallerInformationDTO) => {
              navigate("/ticket", {
                state: { ticketId: ticketNumber },
              });
            }}
            isEditUser={isEditUser}
            onKeySearchChange={(text) => {
              setKeySearch(text);
              setIsFetchData(true);
            }}
            isModal={false}
            createLeadFormProps={createLeadFormProps}
            enableForm={true}
            onPressAction={(data?: any) => {
              setIsEditUser(undefined);
              LeadService.createGuestContact({
                ...data,
                ticketId: ticketNumber,
              })
                .then(() => {
                  const toastData = {
                    message: t("lead-creation-form.create-success"),
                    icon: "/icons/toast/check.svg",
                  };
                  showToast(toast, toastData);
                  setUserType("IN_SYSTEM");
                  setCallerInfo({
                    ...callerInfo,
                    contactType: "LEAD",
                    phoneNumber: callerInfo?.phoneNumber,
                    email: `${data.email}`,
                    fullName: `${data.firstName} ${data.lastName}`,
                  });
                })
                .finally(() => {
                  setIsEditUser(false);
                  setIsEditSuccess(true);
                })
                .catch(() => {});
            }}
            style={{
              layoutBox: {
                w: "100%",
                marginTop: "0px",
                marginRight: "0px",
                marginLeft: "0px",
              },
            }}
          />
        </Box>
      );
    }
    return <></>;
  };
  const ticketPropertiesBox = () => {
    return (
      <Box backgroundColor="white" zIndex="-1">
        <AppTicketProperties
          errors={errorMsg}
          onSelected={getTicketProperties}
          validateForm={true}
          ticketProperties={ticketProperties}
          singleDatePicker={singleDatePicker}
        />
      </Box>
    );
  };

  const associatedOrderWithTicket = (
    ticketNumber: string,
    orderNumber: string
  ) => {
    AssociatedOrderService.associatedOrderWithTicket(
      ticketNumber,
      orderNumber
    ).then((res) => {
      setAssociatedOrder(res.data?.result.result);
    });
  };

  const isLargeScreen = useMediaQuery("(min-width: 1400px)");

  return (
    <Box marginTop="-24px">
      {showAlert()}
      {header()}
      <div
        style={{
          display: "flex",
          gap: "16px",
          alignItems: "start",
        }}
      >
        <div style={{ width: isLargeScreen ? "70%" : "60%" }}>
          <Box {...leftFormStyle(theme)}>
            {titleBox()}
            {DescriptionBox()}
            {FilesUploaderBox()}
            {isEdit ? DiscussionBox() : undefined}
          </Box>
        </div>
        <div style={{ width: isLargeScreen ? "30%" : "40%" }}>
          <Box>
            {callerInfoBox()}
            {/*{userInfoBox()}*/}
            <AssociatedOrder
              initValue={associatedOrder}
              ticketNumber={ticketNumber}
              onValueChange={(data) => {
                setOrderNumber(data.orderNumber);
                if (ticketNumber) {
                  associatedOrderWithTicket(ticketNumber, data.orderNumber);
                }
              }}
            />
            {ticketPropertiesBox()}
          </Box>
        </div>
      </div>
    </Box>
  );
};

export default TicketForm;
