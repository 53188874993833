import React, { useState, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import LeadService from "../../services/lead/leadService";
import { Modal, useToast } from "native-base";
import { UserInformationForm } from "shared-components";
import MediaLegacyService from "../../services/media-legacy/mediaLegacyService";
import { showToast } from "../../services/toast/toastService";
import AuthorizationService from "../../auth/authorizationService";
import { PERMISSIONS } from "../../constants/permisstion";

interface ConvertLeadDrawerProp {
  lead: UserInformationFormDTO;
  isOpenDrawer: boolean;
  closeDrawer: () => void;
  onConvertSuccess: () => void;
  isExistedEmail?: boolean;
}

export interface UserInformationFormDTO {
  leadId: string;
  email: string;
  firstName: string;
  lastName: string;
  publicName: string;
  birthDay: string;
  island: string;
  city: string;
  region: string;
  gender: string;
  streetAddress: string;
  country: string;
  phoneNumber: string;
}

export interface KycDocumentDTO {
  documentType: string;
  frontImage: File | undefined;
  backImage: File | undefined;
  country: string;
  city: string;
  streetAddress: string;
  documentId: string;
  countryName: string;
}

export interface MappingError {
  key: string;
  value: string;
}

const ConvertLeadDrawer: React.FC<ConvertLeadDrawerProp> = ({
  lead,
  isOpenDrawer,
  closeDrawer,
  onConvertSuccess,
  isExistedEmail,
}) => {
  const [countries, setCountries] = useState<any[]>([]);
  const [islands, setIslands] = useState<any[]>([]);
  const [error, setError] = useState<MappingError>();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { t } = useTranslation();
  const toast = useToast();
  const hasViewSensitiveDataPermission = useMemo(() => {
    return AuthorizationService.hasPermissions(
      PERMISSIONS.USERS_ROLES.SENSITIVE_DATA.VIEW_SENSITIVE_DATA
    );
  }, []);
  const userInformationFormLabel = {
    requiredEmailError: t("convert-lead.user-form.error.required.email"),
    requiredFirstNameError: t(
      "convert-lead.user-form.error.required.first-name",
    ),
    requiredLastNameError: t("convert-lead.user-form.error.required.last-name"),
    requiredPhoneNumberError: t(
      "convert-lead.user-form.error.required.phone-number",
    ),
    requiredGenderError: t("convert-lead.user-form.error.required.gender"),
    requiredBirthDayError: t("convert-lead.user-form.error.required.birthday"),
    requiredIslandError: t("convert-lead.user-form.error.required.island"),
    requiredCountryError: t("convert-lead.user-form.error.required.country"),
    requiredFrontImageError: t(
      "convert-lead.user-form.error.required.front-image",
    ),
    requiredBackImageError: t(
      "convert-lead.user-form.error.required.back-image",
    ),
    requiredCityError: t("convert-lead.user-form.error.required.city"),
    requiredDocIdError: t("convert-lead.user-form.error.required.doc-id"),
    requiredStreetAddressError: t(
      "convert-lead.user-form.error.required.street-address",
    ),
    emailPlaceholder: t("convert-lead.user-form.placeholder.email"),
    firstNamePlaceholder: t("convert-lead.user-form.placeholder.first-name"),
    lastNamePlaceholder: t("convert-lead.user-form.placeholder.last-name"),
    publicNamePlaceholder: t("convert-lead.user-form.placeholder.public-name"),
    phoneNumberPlaceholder: t(
      "convert-lead.user-form.placeholder.phone-number",
    ),
    countryPlaceholder: t("convert-lead.user-form.placeholder.country"),
    islandPlaceholder: t("convert-lead.user-form.placeholder.island"),
    inValidEmailError: t("convert-lead.user-form.error.invalid-email"),
    minLengthPhoneNumberError: t(
      "convert-lead.user-form.error.min-length-phone-number",
    ),
    minLengthDocIdError: t("convert-lead.user-form.error.min-length-doc-id"),
    continueToUploadKyc: t("convert-lead.user-form.continue-to-upload-kyc"),
    optional: t("convert-lead.user-form.optional"),
    email: t("convert-lead.user-form.label.email"),
    firstName: t("convert-lead.user-form.label.first-name"),
    lastName: t("convert-lead.user-form.label.last-name"),
    publicName: t("convert-lead.user-form.label.public-name"),
    phoneNumber: t("convert-lead.user-form.label.phone-number"),
    gender: t("convert-lead.user-form.label.gender"),
    male: t("convert-lead.user-form.label.male"),
    female: t("convert-lead.user-form.label.female"),
    preferNotToTell: t("convert-lead.user-form.label.prefer-not-to-tell"),
    dateOfBirth: t("convert-lead.user-form.label.date-of-birth"),
    country: t("convert-lead.user-form.label.country"),
    island: t("convert-lead.user-form.label.island"),
    restrictedInformation: t("lead-form.restricted-information"),
    overrideIt: t("lead-form.override-it"),
    restore: t("lead-form.restore"),
    emailTooltip: t("lead-form.email-tooltip"),
    phoneNumberTooltip: t("lead-form.phone-number-tooltip")
  };

  const kycDocumentText = {
    passport: t("convert-lead.kyc-form.passport"),
    driverLicense: t("convert-lead.kyc-form.driver-license"),
    residencePermit: t("convert-lead.kyc-form.residence-permit"),
    identityCard: t("convert-lead.kyc-form.identity-card"),
    documentRules: [
      t("convert-lead.kyc-form.document-rules.rule-01"),
      t("convert-lead.kyc-form.document-rules.rule-02"),
      t("convert-lead.kyc-form.document-rules.rule-03"),
      t("convert-lead.kyc-form.document-rules.rule-04"),
    ],
    uploadedDocumentsMustBe: t(
      "convert-lead.kyc-form.uploaded-documents-must-be",
    ),
    attachment: t("convert-lead.kyc-form.attachment"),
    front: t("convert-lead.kyc-form.front"),
    backSide: t("convert-lead.kyc-form.back-side"),
    addressInformation: t("convert-lead.kyc-form.address-information"),
    identityInformation: t("convert-lead.kyc-form.identity-information"),
    identityCode: t("convert-lead.kyc-form.identity-code"),
    country: t("convert-lead.kyc-form.country"),
    streetAddress: t("convert-lead.kyc-form.street-address"),
    city: t("convert-lead.kyc-form.city"),
    cityPlaceholder: t("convert-lead.kyc-form.placeholder.city"),
    countryPlaceholder: t("convert-lead.kyc-form.placeholder.country"),
    streetAddressPlaceholder: t(
      "convert-lead.kyc-form.placeholder.street-address",
    ),
    identityCodePlaceholder: t(
      "convert-lead.kyc-form.placeholder.identity-code",
    ),
    indetityDesc: t("convert-lead.kyc-form.indetity-desc"),
  };

  const previewImageText = {
    maxLengthError: t("convert-lead.upload-file.max-length-error"),
    maxSizeError: t("convert-lead.upload-file.max-size-error"),
    invalidFileError: t("convert-lead.upload-file.invalid-file-error"),
    selectedFile: t("convert-lead.upload-file.selected-file"),
    fileWithMaxSize: t("convert-lead.upload-file.file-with-max-size"),
    dropFileToUpload: t("convert-lead.upload-file.drop-file-to-upload"),
    or: t("convert-lead.upload-file.or"),
  };
  const panelLabel = {
    title: t("convert-lead.title"),
    primaryButton: t("convert-lead.submit-btn"),
    secondaryButton: t("convert-lead.cancel-btn"),
  };

  useEffect(() => {
    LeadService.getCountries().then((res) => {
      const { result } = res.data;
      const countryList = result.map((country: any) => ({
        ...country,
        label: country.alpha2Code,
        value: country.name,
      }));
      setCountries(countryList);
    });
  }, []);

  const submitConvert = (userInfo: UserInformationFormDTO) => {
    setIsSubmitting(true);
    LeadService.convertLead(userInfo)
      .then(() => {
        handleSuccessfully();
      })
      .catch((reason) => {
        convertError(reason);
      });
  };

  const submitConvertAndKyc = async (
    userInfo: UserInformationFormDTO,
    kycDocument: KycDocumentDTO,
  ) => {
    setIsSubmitting(true);
    const frontImageUrl = await uploadFile(kycDocument.frontImage);
    const backImageUrl = await uploadFile(kycDocument.backImage);

    const kycInfo = {
      documentType: kycDocument.documentType,
      frontImageUrl: frontImageUrl,
      backImageUrl: backImageUrl,
      country: kycDocument.country,
      city: kycDocument.city,
      streetAddress: kycDocument.streetAddress,
      documentId: kycDocument.documentId,
      countryName: kycDocument.countryName,
    };
    const data = {
      ...userInfo,
      kycInfo,
    };
    LeadService.convertLead(data)
      .then(() => {
        handleSuccessfully();
      })
      .catch((reason) => {
        convertError(reason);
      });
  };

  const handleSuccessfully = () => {
    const toastData = {
      message: t("convert-lead.notification-successfully"),
      icon: "/icons/toast/check.svg",
    };
    setIsSubmitting(false);
    showToast(toast, toastData);
    onConvertSuccess();
    closeDrawer();
  };

  const convertError = (error: any) => {
    setIsSubmitting(false);
    const errorMsg: MappingError = {
      key: error.response.data.field,
      value: error.response.data.error,
    };
    setError(errorMsg);
  };
  const uploadFile = async (file?: File) => {
    if (!file) {
      return null;
    }
    let url = null;
    const formData = new FormData();
    formData.append("file", file);
    await MediaLegacyService.uploadFile(formData).then((res: any) => {
      if (res.data.result) {
        url = res.data.result.url;
      }
    });
    return url;
  };
  const getIslands = (country: string) => {
    const countryId = countries.find(
      (item: any) => item.alpha2Code === country,
    )?.id;
    LeadService.getIslands(countryId.toString()).then((res) => {
      const { result } = res.data;
      const islandsList = result.map((item: any) => {
        return {
          ...item,
          value: item.name,
          label: item.name,
        };
      });
      setIslands(islandsList);
    });
  };

  return (
    <Modal isOpen={isOpenDrawer} onClose={() => closeDrawer()}>
      <Modal.Content
        maxHeight="100vh"
        marginLeft="auto"
        marginRight="0"
        maxWidth="560"
        borderRadius="0"
      >
        <UserInformationForm
          defaultUserInfo={lead}
          panelLabel={panelLabel}
          countries={countries}
          islands={islands}
          hasViewSensitiveDataPermission={hasViewSensitiveDataPermission}
          isExistedEmail={isExistedEmail}
          textObject={userInformationFormLabel}
          kycDocumentText={kycDocumentText}
          previewImageText={previewImageText}
          onConvertLead={submitConvert}
          onConvertLeadWithKyc={submitConvertAndKyc}
          onClose={closeDrawer}
          mappingErrors={error}
          onFetchIslands={(country: string) => getIslands(country)}
          disableConvertButton={isSubmitting}
        />
      </Modal.Content>
    </Modal>
  );
};

export default ConvertLeadDrawer;
