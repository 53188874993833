import React, { useState } from "react";
import { Box, Text, useTheme } from "native-base";
import _ from "lodash";
import moment from "moment/moment";
import { errorText, gap16, textHeader1 } from "../../styles/text";
import {DropDown, DatePicker, InputMoneyRange} from "shared-components";
import { OptionItem } from "../../types/dropdownProps";
import FilterForm from "../filter-form/FilterForm";
import { FilterDateDTO } from "../../types/ticketListingFilterDTO";
import { useTranslation } from "react-i18next";
import {TransactionListingFilterDTO, TransactionListingFilterProps} from "../../types/transactionListingFilterProps";
import {format} from "date-fns";

const TransactionListingFilter: React.FC<TransactionListingFilterProps> = ({
    paymentMethodOptions,
    statusOptions,
    categoryOptions,
    remitterKycLevelOptions,
    currencyCode,
    initFilters,
    onFiltersChanged,
    dropDownIcon,
    selectedIcon,
    arrowIcon,
    calendarIcon,
    arrowUp,
    arrowDown,
    arrowLeft,
    arrowRight,
    textObject,
    onCloseFilter,
    closeFilterIcon,
    selectedCountry,
    setSelectedCountry,
    selectedBusinessCategory,
    setSelectedBusinessCategory,
}) => {
  const theme = useTheme();
  const [filters, setFilters] = useState<TransactionListingFilterDTO>(initFilters);
  const [errorDateMessage, setErrorDateMessage] = useState<string>("");
    const [errorAmountMessage, setErrorAmountMessage] = useState<string>("");
  const filterChanged = (valueObject: any, key: string) => {
    let filtersTemp = _.cloneDeep(filters);
    if (key === "createdDate") {
      setErrorDateMessage("");
      filtersTemp[key] = valueObject;
    } else if (key === "fromAmount" || key === "toAmount") {
        setErrorAmountMessage("");
    }
    else {
      filtersTemp = {
        ...filters,
        [key]: valueObject.label === "ALL" ? "" : valueObject.label,
      };
    }
    if (typeof valueObject === "string") {
      filtersTemp = {
        ...filters,
        [key]: valueObject,
      };
    }
    setFilters(filtersTemp);
  };

  const clearAll = () => {
    const filtersTemp = {
        paymentMethod: "",
        status: "SUCCESS",
        category: "",
        createdDate: {
            from: get30DaysAgo(),
            to: format(new Date(), "MM/dd/yyyy"),
        },
        remitterKycLevel: "",
        fromAmount: "",
        toAmount: "",
    };
    setFilters(filtersTemp);
    setErrorDateMessage("");
  };
    const get30DaysAgo = () => {
        const now = new Date();
        const startOfDay30DaysAgo = new Date();
        startOfDay30DaysAgo.setDate(now.getDate() - 30);
        startOfDay30DaysAgo.setHours(0, 0, 0, 0);
        return format(startOfDay30DaysAgo, "MM/dd/yyyy");
    }
  const onApplyFilter = () => {
    if (validateDateRange() && validateAmountRange()) {
      onFiltersChanged(filters);
    }
  };

  const validateDateRange = () => {
    setErrorDateMessage("");
    const { from, to } = filters.createdDate;
    if (!from && !to) {
      return true;
    }

    const format = "MM/DD/YYYY";
    const momentDate1 = moment(from, format);
    const momentDate2 = moment(to, format);
    if (momentDate1.isAfter(momentDate2)) {
      setErrorDateMessage(textObject.earlierErrMsg);
      return false;
    }
    return true;
  };

  const validateAmountRange = () => {
    setErrorAmountMessage("");
    if (!filters.fromAmount && !filters.toAmount) {
        return true;
    }
      console.log(filters);
      console.log(filters.fromAmount > filters.toAmount);
    if (filters.fromAmount && filters.toAmount && Number(filters.fromAmount) > Number(filters.toAmount)) {
        setErrorAmountMessage(textObject.minGreaterErrMsg);
        return false;
    }
    return true;
    }

  const statusFilter = () => {
    const dropdownProps = {
      data: statusOptions,
      dropDownIcon: dropDownIcon,
      selectedIcon: selectedIcon,
    };

    return (
      <Box mt={2} zIndex={"auto"}>
        <Text {...textHeader1(theme)} mb={1.5}>
          {t("transaction-listing.filter.status")}
        </Text>
        <DropDown
          {...dropdownProps}
          defaultOptionLabel={filters.status || "ALL"}
          action={(option: OptionItem) => {
            filterChanged(option, "status");
          }}
        />
      </Box>
    );
  };

  const categoryFilter = () => {
    const dropdownProps = {
      data: categoryOptions,
      dropDownIcon: dropDownIcon,
      selectedIcon: selectedIcon,
    };
    return (
      <Box mt={2} zIndex={"auto"}>
        <Text {...textHeader1(theme)} mb={1.5}>
          {t("transaction-listing.filter.category")}
        </Text>
        <DropDown
          {...dropdownProps}
          defaultOptionLabel={filters.category || "ALL"}
          action={(option: OptionItem) => {
            filterChanged(option, "category");
          }}
        />
      </Box>
    );
  };


  const amountFilter = () => {
    return (
        <Box mt={2} zIndex={"auto"}>
          <Text {...textHeader1(theme)} mb={1.5}>
            {t("transaction-listing.filter.amount")}
          </Text>
          <InputMoneyRange
              fromPlaceholder={textObject.min}
              toPlaceholder={textObject.max}
              currencyCode={currencyCode}
              thousandSeparator={true}
              defaultFromValue={filters.fromAmount}
              defaultToValue={filters.toAmount}
              onChangeFrom={(value) => {
                  filterChanged(value, "fromAmount");
              }}
              onChangeTo={(value) => {
                  filterChanged(value, "toAmount");
              }}
          />
            {errorAmountMessage ? (
                <Text mt={2} {...errorText(theme)}>
                    {errorAmountMessage}
                </Text>
            ) : undefined}
        </Box>
    );
  };

  const { t } = useTranslation();

  const remitterKycLevelFilter = () => {
    const dropdownProps = {
      data: remitterKycLevelOptions,
      dropDownIcon: dropDownIcon,
      selectedIcon: selectedIcon,
    };

    return (
        <Box mt={2} zIndex={"auto"}>
          <Text {...textHeader1(theme)} mb={1.5}>
            {t("transaction-listing.filter.remitter-kyc-level")}
          </Text>
          <DropDown
              {...dropdownProps}
              defaultOptionLabel={filters.remitterKycLevel || "ALL"}
              action={(option: OptionItem) => {
                filterChanged(option, "remitterKycLevel");
              }}
          />
        </Box>
    );
  };

  const paymentMethodFilter = () => {
    const dropdownProps = {
      data: paymentMethodOptions,
      dropDownIcon: dropDownIcon,
      selectedIcon: selectedIcon,
    };

    return (
        <Box mt={2} zIndex={"auto"}>
          <Text {...textHeader1(theme)} mb={1.5}>
            {t("transaction-listing.filter.payment-method")}
          </Text>
          <DropDown
              {...dropdownProps}
              defaultOptionLabel={filters.paymentMethod || "ALL"}
              action={(option: OptionItem) => {
                filterChanged(option, "paymentMethod");
              }}
          />
        </Box>
    );
  };

  const datePickerFilter = () => {
    const datePickerProps = {
      arrowIcon: arrowIcon,
      calendarIcon: calendarIcon,
      arrowUp: arrowUp,
      arrowDown: arrowDown,
      arrowLeft: arrowLeft,
      arrowRight: arrowRight,
    };

    return (
      <Box zIndex="-1">
        <Text {...textHeader1(theme)} mb={1.5}>
          {t("transaction-listing.filter.created-time")}
        </Text>
        <DatePicker
          {...datePickerProps}
          initDateRange={filters.createdDate}
          isInvalid={!!errorDateMessage}
          onChangeDateRange={(dateRange: FilterDateDTO) => {
            filterChanged(dateRange, "createdDate");
          }}
        />
        {errorDateMessage ? (
          <Text mt={2} {...errorText(theme)}>
            {errorDateMessage}
          </Text>
        ) : undefined}
      </Box>
    );
  };


  return (
    <FilterForm
      textObject={textObject}
      closeFilterIcon={closeFilterIcon}
      onClearFilter={() => {
        clearAll();
      }}
      onApplyFilter={onApplyFilter}
      onCloseFilter={onCloseFilter}
    >
      <Box {...gap16}>
        {paymentMethodFilter()}
        {statusFilter()}
        {categoryFilter()}

        {datePickerFilter()}
        {remitterKycLevelFilter()}
        {amountFilter()}
      </Box>
    </FilterForm>
  );
};
export default TransactionListingFilter;
