import TicketService from "../../services/ticket/ticketService";
import { useCallback, useState } from "react";
import { AppButton, AppTooltipID, Box } from "shared-components";
import { format } from "date-fns";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { Modal, Pressable, Text, useTheme } from "native-base";
import { CsTicketConstants } from "../../constants/csTicket";
import { handleFiltersHaveDateRange } from "../../utils/filters";
import CallService from "../../services/call/callService";
import { ResourceType } from "../../constants/resourceType";
import { showNotificationForVoiso } from "../../services/toast/toastService";
import CoreTable from "../core-table/CoreTable";
import {
  dotStyle,
  filterStyle,
  phoneNumberStyle,
  textStyle,
} from "../lead-listing/LeadListing.style";
import { ReactSVG } from "react-svg";
import { Header, TableAction } from "../../types/table";
import { PERMISSIONS } from "../../constants/permisstion";
import TicketListingFilter from "../ticket-listing-filter/TicketListingFilter";
import ProtectedContent from "../protected-content/ProtectedContent";
import AuthorizationService from "../../auth/authorizationService";
import { DATE_TIME_FORMAT_MM_DD_YYYY_H_MM_A } from "../../constants/formatDate";
const AppTicket = () => {
  const { t } = useTranslation();
  const FORMAT_DATE = DATE_TIME_FORMAT_MM_DD_YYYY_H_MM_A;
  const theme = useTheme();
  const [isOpenFilter, setIsOpenFilter] = useState(false);
  const [filters, setFilters] = useState({
    status: "",
    source: "",
    category: "",
    createdDate: {
      from: "",
      to: "",
    },
    assigneeId: "",
  });
  const [selectedAssignee, setSelectedAssignee] = useState();
  const [isFilterUsed, setIsFilterUsed] = useState(false);
  const location = useLocation();
  const filterProps = {
    statusOptions: [
      {
        label: "ALL",
        value: t("ticket-listing-filter.status-options.all"),
      },
      {
        label: "NEW",
        value: t("ticket-listing-filter.status-options.new"),
      },
      {
        label: "PENDING",
        value: t("ticket-listing-filter.status-options.pending"),
      },
      {
        label: "IN_PROGRESS",
        value: t("ticket-listing-filter.status-options.in-progress"),
      },
      {
        label: "WAITING_FOR_CUSTOMER",
        value: t("ticket-listing-filter.status-options.waiting-for-customer"),
      },
      {
        label: "ESCALATION",
        value: t("ticket-listing-filter.status-options.escalation"),
      },
      {
        label: "RESOLVED",
        value: t("ticket-listing-filter.status-options.resolved"),
      },
    ],
    sourceOptions: [
      {
        label: "ALL",
        value: t("ticket-listing-filter.source-options.all"),
      },
      {
        label: "EMAIL",
        value: t("ticket-listing-filter.source-options.email"),
      },
      {
        label: "CALL",
        value: t("ticket-listing-filter.source-options.call"),
      },
      {
        label: "OTHER",
        value: t("ticket-listing-filter.source-options.other"),
      },
    ],
    categoryOptions: [
      {
        label: "ALL",
        value: t("ticket-listing-filter.category-options.all"),
      },
      {
        label: "CUSTOMER_SUPPORT",
        value: t("ticket-listing-filter.category-options.customer-support"),
      },
      {
        label: "SALES",
        value: t("ticket-listing-filter.category-options.sales"),
      },
    ],
    textObject: {
      filter: t("ticket-listing-filter.title"),
      status: t("ticket-listing-filter.status"),
      source: t("ticket-listing-filter.source"),
      category: t("ticket-listing-filter.category"),
      apply: t("ticket-listing-filter.apply"),
      clearAll: t("ticket-listing-filter.clear-all"),
      createdDate: t("ticket-listing-filter.created-date"),
      invalidFormatErrMsg: t("errors.standard002", {
        fieldName: t("ticket-listing-filter.created-date"),
      }),
      earlierErrMsg: t("errors.standard093"),
      selectedFromErrMsg: t("errors.standard092", {
        fieldName: t("ticket-listing-filter.start-date"),
      }),
      selectedToErrMsg: t("errors.standard092", {
        fieldName: t("ticket-listing-filter.end-date"),
      }),
      selectedRangeOverErrMsg: t("errors.standard094"),
      all: t("ticket-listing-filter.status-options.all"),
      assignee: t("prospect-listing.header-column.assignee"),
    },
    dropDownIcon: "/icons/arrow-down.svg",
    selectedIcon: "/icons/checked.svg",
    closeFilterIcon: "/icons/filter-close.svg",
    arrowIcon: "/icons/arrow-long.svg",
    calendarIcon: "/icons/calendar.svg",
    arrowUp: "/icons/mini-arrow-up.svg",
    arrowDown: "/icons/mini-arrow.svg",
    arrowLeft: "/icons/chevron-left.svg",
    arrowRight: "/icons/chevron-right.svg",
    onFiltersChanged: (filtersChanged: any) => {
      setFilters(filtersChanged);
      setIsOpenFilter(false);
      setIsFilterUsed(filters !== filtersChanged);
    },
  };

  const callVoiso = (item: any) => {
    CallService.callVoiso(
      item?.phoneNumber,
      ResourceType.TICKET,
      item?.ticketNumber,
    ).catch((reason) => {
      if (reason.response.data.code === "1010022") {
        showNotificationForVoiso(t);
      }
    });
  };

  const formatDate = (timestamp: number) => {
    const date = new Date(timestamp);
    return <Text {...textStyle(theme)}>{format(date, FORMAT_DATE)}</Text>;
  };
  const navigate = useNavigate();
  const navigateCreateTicket = () => {
    navigate("/ticket/create");
  };

  const navigateEditTicket = (data: any) => {
    navigate(`/ticket/edit/${data.ticketNumber}`, {
      state: {
        source: data.source,
      },
    });
  };

  const formatSource = (sourceValue?: string) => {
    const source = CsTicketConstants.sourceOptions.find(
      (src) => src.label === sourceValue,
    );
    return source ? <Text {...textStyle(theme)}>{t(source.value)}</Text> : "";
  };
  const formatStatus = (statusValue?: string) => {
    const status = CsTicketConstants.statusOptions.find(
      (st) => st.label === statusValue,
    );
    return status ? <Text {...textStyle(theme)}>{t(status.value)}</Text> : "";
  };

  const formatPhoneNumber = (phoneNumber: string, item: any) => {
    return (
      <Pressable onPress={() => callVoiso(item)}>
        <Text {...phoneNumberStyle(theme)}>{phoneNumber}</Text>
      </Pressable>
    );
  };

  const formatTicketNumber = (ticketNumber: string, item: any) => {
    return (
      <AppTooltipID
        data={ticketNumber}
        columnName={""}
        width={"164px"}
        isTruncateData={true}
        showCopyIcon={true}
        hasTooltip={true}
        onPress={() => {
          if (
            AuthorizationService.hasPermissions([
              PERMISSIONS.CUSTOMER_SUPPORT.TICKET.EDIT,
            ])
          ) {
            navigate(`/ticket/edit/${item?.ticketNumber}`, {
              state: {
                source: item?.source,
              },
            });
          }
        }}
      />
    );
  };

  const headers: Header[] = [
    {
      value: "ticketNumber",
      label: t("ticket-listing.header-column.ticket-number"),
      format: formatTicketNumber,
      width: 164,
    },
    {
      value: "title",
      label: t("ticket-listing.header-column.title"),
      width: 274,
    },
    {
      value: "status",
      label: t("ticket-listing.header-column.status"),
      format: formatStatus,
      width: 164,
    },
    {
      value: "fullName",
      label: t("ticket-listing.header-column.full-name"),
      width: 274,
    },
    {
      value: "phoneNumber",
      label: t("ticket-listing.header-column.phone-number"),
      width: 274,
      format: formatPhoneNumber,
      permissions: [PERMISSIONS.USERS_ROLES.SENSITIVE_DATA.VIEW_SENSITIVE_DATA],
    },
    {
      value: "email",
      label: t("ticket-listing.header-column.email"),
      width: 274,
      permissions: [PERMISSIONS.USERS_ROLES.SENSITIVE_DATA.VIEW_SENSITIVE_DATA],
    },
    {
      value: "source",
      label: t("ticket-listing.header-column.source"),
      format: formatSource,
      width: 164,
    },
    {
      value: "assigneeName",
      label: t("ticket-listing.header-column.assignee"),
      width: 240,
    },
    {
      value: "createdDate",
      label: t("ticket-listing.header-column.created-date"),
      format: formatDate,
      width: 274,
    },
    {
      value: "modifiedDate",
      label: t("ticket-listing.header-column.modified-date"),
      format: formatDate,
      width: 274,
    },
    {
      value: "action",
      label: t("ticket-listing.header-column.action"),
      type: "ACTION",
      width: 100,
    },
  ];

  const actions: TableAction[] = [
    {
      icon: "/icons/edit.svg",
      label: t("common.edit"),
      value: "EDIT_TICKET",
      permissions: [PERMISSIONS.CUSTOMER_SUPPORT.TICKET.EDIT],
    },
  ];

  const fetchDatas = useCallback(
    (params: any) => {
      if (location?.state?.ticketId) {
        params.ticketId = location.state.ticketId;
        location.state = null;
        window.history.replaceState(null, location.pathname);
      }
      return TicketService.getTicketListing(params);
    },
    [location],
  );

  const handleAction = (code: string, data: any) => {
    if (code === "EDIT_TICKET") {
      navigateEditTicket(data);
    }
  };

  const actionRow = () => {
    return (
      <>
        <Box>
          <Pressable
            position="relative"
            {...filterStyle(theme)}
            onPress={() => {
              setIsOpenFilter(true);
            }}
          >
            <ReactSVG src="/icons/filter-icon.svg" />
            {isFilterUsed ? (
              <Box {...dotStyle(theme)} position="absolute" />
            ) : undefined}
          </Pressable>
        </Box>
        <ProtectedContent
          permissions={PERMISSIONS.CUSTOMER_SUPPORT.TICKET.CREATE}
        >
          <Box>
            <AppButton
              label={t("ticket-listing.btn-create")}
              onPress={() => {
                navigateCreateTicket();
              }}
              icon={"/icons/create.svg"}
            />
          </Box>
        </ProtectedContent>
      </>
    );
  };

  const processedFilters = handleFiltersHaveDateRange(filters, "createdDate");
  return (
    <Box>
      <Modal isOpen={isOpenFilter} onClose={() => setIsOpenFilter(false)}>
        <Modal.Content
          maxHeight="100vh"
          marginLeft="auto"
          marginRight="0"
          maxWidth="460"
          borderRadius="0"
        >
          {isOpenFilter ? (
            <TicketListingFilter
              {...filterProps}
              initFilters={filters}
              selectedAssignee={selectedAssignee}
              setSelectedAssignee={setSelectedAssignee}
              onCloseFilter={() => setIsOpenFilter(false)}
            />
          ) : undefined}
        </Modal.Content>
      </Modal>
      <CoreTable
        headers={headers}
        filters={filters}
        processedFilters={processedFilters}
        onClickAction={(code, data) => handleAction(code, data)}
        fetchDatas={fetchDatas}
        actions={actions}
        actionRow={actionRow()}
        isBorder={true}
        title={t("ticket-listing.title")}
        isFilterUsed={isFilterUsed}
        setIsFilterUsed={setIsFilterUsed}
      />
    </Box>
  );
};

export default AppTicket;
