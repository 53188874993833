import { Box } from "shared-components";
import { useCallback, useEffect, useState } from "react";
import { format } from "date-fns";
import { useTranslation } from "react-i18next";
import { Modal, Pressable, Text, useTheme } from "native-base";
import { handleFiltersMerchantListing } from "../../utils/filters";
import { Header } from "../../types/table";
import {
  dotStyle,
  filterStyle,
  textStyle,
} from "../lead-listing/LeadListing.style";
import { ReactSVG } from "react-svg";
import CoreTable from "../core-table/CoreTable";
import { DATE_FORMAT_MM_DD_YYYY } from "../../constants/formatDate";
import MerchantListingFilter from "../merchant-listing-filter/MerchantListingFilter";
import MerchantService from "../../services/merchant/merchantService";
import { OptionItem } from "../../types/dropdownProps";
import { CurrentPlan } from "../../constants/currentPlan";

interface StatusDisplay {
  [key: string]: {
    label: string;
    color: string;
  };
}

const MerchantListing = () => {
  const { t } = useTranslation();
  const FORMAT_DATE = DATE_FORMAT_MM_DD_YYYY;
  const [isOpenFilter, setIsOpenFilter] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedBusinessCategory, setSelectedBusinessCategory] = useState("");
  const [filters, setFilters] = useState({
    businessCategory: "",
    status: "",
    currentPlan: "",
    expiryDate: {
      from: "",
      to: "",
    },
    countryCode: "",
    businessName: "",
    email: "",
  });
  const [isFilterUsed, setIsFilterUsed] = useState(false);
  const theme: any = useTheme();

  const [currentPlanOptions, setDataCurrentPlanOptions] = useState<
    OptionItem[]
  >([
    {
      label: "ALL",
      value: t("ticket-listing-filter.status-options.all"),
    },
  ]);

  useEffect(() => {
    MerchantService.getPricingPlans().then((res) => {
      const mappingData = res.data?.result?.map((item: any) => {
        return {
          label: item.code,
          value: t(CurrentPlan[item.name]) || item.name,
        };
      });
      setDataCurrentPlanOptions((prevState) => [...prevState, ...mappingData]);
    });
  }, []);

  const filterProps = {
    statusOptions: [
      {
        label: "ALL",
        value: t("ticket-listing-filter.status-options.all"),
      },
      {
        label: "ACTIVE",
        value: t("status.active"),
      },
      {
        label: "INACTIVE",
        value: t("status.inactive"),
      },
      {
        label: "UNKNOWN",
        value: t("status.pending"),
      },
    ],
    currentPlanOptions: currentPlanOptions,
    textObject: {
      filter: t("client-listing.filter.title"),
      apply: t("client-listing.filter.apply"),
      clearAll: t("client-listing.filter.clear-all"),
      status: t("ticket-listing-filter.status"),
      kycApprovalDate: t("client-listing.filter.kyc-approval-date"),
      invalidFormatErrMsg: t("errors.standard002", {
        fieldName: t("client-listing.filter.kyc-approval-date"),
      }),
      earlierErrMsg: t("errors.standard093"),
      selectedFromErrMsg: t("errors.standard092", {
        fieldName: t("client-listing.filter.start-date"),
      }),
      selectedToErrMsg: t("errors.standard092", {
        fieldName: t("client-listing.filter.end-date"),
      }),
      selectedRangeOverErrMsg: t("errors.standard094"),
    },
    dropDownIcon: "/icons/arrow-down.svg",
    arrowIcon: "/icons/arrow-long.svg",
    selectedIcon: "/icons/checkmark.svg",
    closeFilterIcon: "/icons/filter-close.svg",
    calendarIcon: "/icons/calendar.svg",
    arrowUp: "/icons/mini-arrow-up.svg",
    arrowDown: "/icons/mini-arrow.svg",
    arrowLeft: "/icons/chevron-left.svg",
    arrowRight: "/icons/chevron-right.svg",
    onFiltersChanged: (filtersChanged: any) => {
      setFilters(filtersChanged);
      setIsOpenFilter(false);
      setIsFilterUsed(filters !== filtersChanged);
    },
  };

  const formatDate = (timestamp: number) => {
    if (!timestamp) {
      return null;
    }
    const date = new Date(timestamp);
    return <Text {...textStyle(theme)}>{format(date, FORMAT_DATE)}</Text>;
  };

  const statusDisplay: StatusDisplay = {
    ACTIVE: {
      label: t("status.active"),
      color: theme?.appPalette?.green.green100,
    },
    INACTIVE: {
      label: t("status.inactive"),
      color: theme?.appPalette?.red.red100,
    },
    UNKNOWN: {
      label: t("status.pending"),
      color: theme?.appPalette?.warning.warning100,
    },
  };


  const formatCurrentPlan = (currentPlan: string) => {
    return <Text {...textStyle(theme)}>{t(CurrentPlan[currentPlan]) || currentPlan}</Text>
  }

  const formatStatus = (status: string) => {
    const statusColor = statusDisplay[status]?.color;
    return (
      <Text color={statusColor} lineHeight="56px">
        {statusDisplay[status]?.label}
      </Text>
    );
  };

  const headers: Header[] = [
    {
      value: "businessName",
      label: t("merchants.business-name"),
      width: 278,
    },
    {
      value: "businessCategory",
      label: t("merchants.business-category"),
      width: 278,
    },
    {
      value: "email",
      label: t("merchants.email"),
      width: 278,
    },
    {
      value: "pricingPlan",
      label: t("merchants.current-plan"),
      format: formatCurrentPlan,
      width: 160,
    },
    {
      value: "country",
      label: t("merchants.country"),
      width: 278,
    },
    {
      value: "expireDate",
      label: t("merchants.plan-expiry-date"),
      format: formatDate,
      width: 160,
    },
    {
      value: "status",
      label: t("merchants.status"),
      format: formatStatus,
      width: 160,
    },
  ];

  const actionRow = () => {
    return (
      <>
        <Box>
          <Pressable
            testID="filter-icon"
            position="relative"
            {...filterStyle(theme)}
            onPress={() => {
              setIsOpenFilter(true);
            }}
          >
            <ReactSVG src="/icons/filter-icon.svg" />
            {isFilterUsed ? (
              <Box {...dotStyle(theme)} position="absolute" />
            ) : undefined}
          </Pressable>
        </Box>
      </>
    );
  };

  const fetchDatas = useCallback((params: any) => {
    return MerchantService.list(params);
  }, []);

  const processedFilters = handleFiltersMerchantListing(filters);

  return (
    <Box>
      <Modal isOpen={isOpenFilter} onClose={() => setIsOpenFilter(false)}>
        <Modal.Content
          maxHeight="100vh"
          marginLeft="auto"
          marginRight="0"
          maxWidth="460"
          borderRadius="0"
        >
          {isOpenFilter ? (
            <MerchantListingFilter
              {...filterProps}
              initFilters={filters}
              selectedCountry={selectedCountry}
              setSelectedCountry={setSelectedCountry}
              selectedBusinessCategory={selectedBusinessCategory}
              setSelectedBusinessCategory={setSelectedBusinessCategory}
              onCloseFilter={() => setIsOpenFilter(false)}
            />
          ) : undefined}
        </Modal.Content>
      </Modal>
      <CoreTable
        headers={headers}
        filters={filters}
        processedFilters={processedFilters}
        fetchDatas={fetchDatas}
        actionRow={actionRow()}
        isBorder={true}
        sort={{
          orderBy: "createdDate",
          orderDirection: "DESC",
        }}
        title={t("merchants.title")}
        isFilterUsed={isFilterUsed}
        setIsFilterUsed={setIsFilterUsed}
        maxLength={500}
      />
    </Box>
  );
};

export default MerchantListing;
