export const oidcScope: string[] = [
  "us_roles_listing",
  "us_roles_create",
  "us_roles_edit",
  "us_roles_disable",
  "us_roles_enable",
  "us_roles_delete",
  "cs_tickets_listing",
  "cs_tickets_create",
  "cs_tickets_edit",
  "admin_tenant2",
  "openid",
  "contact_lead_listing",
  "contact_lead_create",
  "contact_lead_edit",
  "contact_lead_invite",
  "contact_lead_history_upload",
  "contact_prospect_list",
  "contact_prospect_create",
  "contact_prospect_edit",
  "contact_client_list",
  "contact_client_create",
  "contact_client_edit",
  "login_scope",
  "gc_listing",
  "gc_distribution_listing",
  "gc_issued_listing",
  "gc_distributes",
  "gc_issued_export",
  "tk_listing",
  "tk_update_stock_quantity",
  "tk_update_status",
  "tk_create",
  "tk_clone",
  "tk_edit",
  "tk_delete",
  "tk_deliver_giveaway",
  "tk_bulk_issuance_listing",
  "tk_issuance_report_listing",
  "tk_issuance_report_export",
  "tk_issuance_report_deactivate",
  "kp_paks_list",
  "kp_create_pak",
  "kp_edit_pak",
  "kp_sub_pak_list",
  "kp_create_sub_pak",
  "kp_edit_sub_pak",
  "kp_delete_sub_pak",
  "kp_close_pak",
  "kp_run_pak",
  "kp_pause_pak",
  "kp_resume_pak",
  "kp_open_registration",
  "kp_close_registration",
  "kp_send_invitation_link",
  "kp_join_pak",
  "adm_units_listing",
  "adm_units_get_code",
  "adm_units_update_status",
  "adm_campaigns_listing",
  "adm_campaigns_create",
  "adm_campaigns_update",
  "adm_campaigns_update_status",
  "adm_audiences_listing",
  "wr_listing",
  "wr_approve_request",
  "wr_reject_request",
  "kyc_listing",
  "kyc_export",
  "kyc_approve",
  "kyc_reject",
  "ws_threshold_listing",
  "ws_threshold_create",
  "ws_threshold_edit",
  "ws_threshold_delete",
  "us_users_listing",
  "us_users_assign_role",
  "offline_access",
];
