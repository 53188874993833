export const sendEmailFormStyle: any = {
    display: "flex",
    flexDirection: "column",
    gap: "16px",
    width: "100%",
};

export const emailSelectStyle: any = {
    display: "flex",
    flexDirection: "column",
    gap: "6px",
    width: "100%",
    zIndex: 2,
};

export const languageSelectStyle: any = {
    display: "flex",
    flexDirection: "column",
    gap: "6px",
    width: "100%",
    zIndex: 1,
};

export const textStyle = (theme: any) => ({
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "20px",
    color: theme?.appPalette?.grey.grey80,
    fontFamily: theme?.fontFamily,
    whiteSpace: "nowrap",
});

export const emailContentContainer: any = {
    width: "600px",
    height: "700px",
    overflowY: 'auto',
};

export const errorText = (theme: any) => ({
    fontFamily: theme?.fontFamily,
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '16px',
    color: theme?.appPalette?.red?.red100,
});