import { Modal, useToast } from 'native-base';
import { InviteLead } from 'shared-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { showToast } from '../../services/toast/toastService';
import LeadService from '../../services/lead/leadService';

interface InviteLeadPopupProps {
  leadInfo: any;
  type: "EMAIL" | "SMS";
  isOpenInvite: boolean;
  onClose: () => void;
}

const AppInviteLead: React.FC<InviteLeadPopupProps> = ({
  leadInfo,
  type,
  isOpenInvite,
  onClose,
}) => {
  const { t } = useTranslation();
  const toast = useToast();

  const inviteLeadProps = {
    textObject: {
      title: t("lead-invitation.title"),
      consumerLabel: t("lead-invitation.consumers"),
      businessLabel: t("lead-invitation.businesses"),
      inviteButton: t("lead-invitation.invite"),
    },
    consumerIcon: "/icons/consumer-icon.png",
    businessIcon: "/icons/business-icon.png",
  };

  const sendInvite = (group: 'CONSUMERS' | 'BUSINESSES') => {
    const inviteTo = leadInfo.leadId;
    const payload = {
      inviteTo: [inviteTo],
      inviteMethod: type,
      group: group,
    };
    LeadService.invite(payload).then(() => {
      onClose();
      const toastData = {
        message: t('lead-invitation.sent-successfully'),
        icon: '/icons/toast/check.svg',
      };
      showToast(toast, toastData);
    });
  };

  return (
    <Modal isOpen={isOpenInvite}>
      <Modal.Content maxHeight="100vh" maxWidth="476px" borderRadius="12px">
        <InviteLead
          onClose={() => onClose()}
          textObject={inviteLeadProps.textObject}
          consumerIcon={inviteLeadProps.consumerIcon}
          businessIcon={inviteLeadProps.businessIcon}
          onInviteConsumer={() => {
            sendInvite("CONSUMERS");
          }}
          onInviteBusiness={() => {
            sendInvite("BUSINESSES");
          }}
        />
      </Modal.Content>
    </Modal>
  );
};

export default AppInviteLead;
