import { Box } from "native-base";
import MerchantListing from "../../components/merchant-listing/MerchantListing";

const Merchants = () => {
  return (
    <Box>
      <MerchantListing />
    </Box>
  );
};

export default Merchants;
