export const programTitleStyle = (theme: any) => ({
  fontWeight: 600,
  fontSize: "24px",
  lineHeight: "32px",
  color: theme?.appPalette?.grey.grey80,
  fontFamily: theme?.fontFamily,
  marginLeft: "12px",
});

export const informationStyle = () => ({
  width: "70%",
  borderRadius: "8px",
  padding: "24px",
  gap: "24px",
  style: {
    shadowOffset: { width: 0, height: 1 },
    shadowOpacity: 1,
    shadowRadius: 3,
    shadowColor: "#00000033",
  },
  backgroundColor: "white",
});

export const socialNetworkStyle = () => ({
  borderRadius: "8px",
  padding: "24px",
  gap: "24px",
  style: {
    shadowOffset: { width: 0, height: 1 },
    shadowOpacity: 1,
    shadowRadius: 3,
    shadowColor: "#00000033",
  },
  backgroundColor: "white",
});

export const protocolStyle = () => ({
  borderRadius: "8px",
  padding: "24px",
  gap: "16px",
  style: {
    shadowOffset: { width: 0, height: 1 },
    shadowOpacity: 1,
    shadowRadius: 3,
    shadowColor: "#00000033",
  },
  backgroundColor: "white",
});

export const socialNetworkTitleStyle = (theme: any) => ({
  height: "78px",
  paddingTop: "10px",
  paddingBottom: "10px",
  paddingLeft: "12px",
  paddingRight: "12px",
  gap: "2px",
  borderRadius: "8px",
  backgroundColor: theme?.appPalette?.primary.primary8,
});

export const descStyle = (theme: any) => ({
  fontSize: "14px",
  fontWeight: 400,
  lineHeight: "18px",
  color: theme?.appPalette?.grey.grey60,
});

export const boxWrapper: any = {
  flexDirection: "row",
  gap: "16px",
  alignItems: "start",
  marginTop: "10px",
};

export const generalInformationTitleStyle = (theme: any) => ({
  height: "40px",
  justifyContent: "center",
  paddingLeft: "12px",
  paddingRight: "12px",
  borderRadius: "8px",
  background: theme?.appPalette?.primary.primary8,
});

export const textStyle = (theme: any) => ({
  fontWeight: 700,
  fontSize: "16px",
  lineHeight: "20px",
  color: theme?.appPalette?.grey.grey80,
  fontFamily: theme?.fontFamily,
  whiteSpace: "nowrap",
});
export const gap24 = {
  gap: "24px",
};

export const gap16 = {
  gap: "16px",
};

export const gap6 = {
  gap: "6px",
};

export const width50: any = {
  display: "flex",
  flexDirection: "column",
  gap: "6px",
  width: "50%",
};

export const programNameStyle: any = {
  display: "flex",
  flexDirection: "column",
  gap: "6px",
  width: "60%",
};

export const programOwnerStyle: any = {
  display: "flex",
  flexDirection: "column",
  gap: "6px",
  width: "40%",
};

export const errorStyle = (theme: any) => ({
  fontWeight: 400,
  fontSize: "14px",
  lineHeight: "16px",
  color: theme.appPalette?.danger.danger100,
  fontFamily: theme?.fontFamily,
});

export const directlyStyle: any = {
  flexDirection: "row",
  gap: "8px",
};

export const gap6DivStyle: any = {
  display: "flex",
  flexDirection: "column",
  gap: "6px",
};

export const blockStyle: any = {
  width: "30%",
  display: "flex",
  flexDirection: "column",
  gap: "16px",
};

export const checkboxStyle = (theme: any) => ({
  accentColor: theme?.appPalette?.primary?.primary100,
});

export const optionalStyle = (theme: any) => ({
  fontSize: "16px",
  lineHeight: "20px",
  color: theme?.appPalette?.grey?.grey80,
});
