import Axios, {ResponseType} from "axios";
import Api from "../../configs/api";
import { resolveTenant } from "../../tenantResolver";
import errorResponseHandler from "./errorResponseHandler";

function ApiClient(headers?: any, baseURL?: string, responseType?: ResponseType) {
  const tenantConfig = resolveTenant();
  let authData = JSON.parse(
    localStorage.getItem(
      `oidc.at:${tenantConfig.oidcConfiguration.authority}`,
    ) ?? "{}",
  );

  let configHeader = {
    'x-tenant': tenantConfig.code,
    'Access-Control-Max-Age': 86400,
    deviceId: 'VISIKARD-XXXX-XXXX-WEB',
    Authorization: `Bearer ${authData.access_token}`,
    ...headers,
  };

  const apiClient = Axios.create({
    baseURL: baseURL ?? Api.BASE_URL,
    headers: configHeader,
  });

  if (responseType) {
    apiClient.defaults.responseType = responseType;
  }

  apiClient.interceptors.response.use(
    async (response) => {
      return response;
    },
    (error) => {
      errorResponseHandler(error);
      return Promise.reject(error);
    },
  );
  return apiClient;
}

export default ApiClient;
