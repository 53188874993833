import ApiClient from "../api-client/apiClient";
import Api from "../../configs/api";
import { resolveTenant } from "../../tenantResolver";

const getAuthHeaders = () => {
  const tenantConfig = resolveTenant();
  const authData = JSON.parse(
    localStorage.getItem(
      `oidc.at:${tenantConfig.oidcConfiguration.authority}`,
    ) ?? "{}",
  );
  return {
    token: authData.access_token,
  };
};

const RoleService = {
  list: (data: any) => {
    const headers = getAuthHeaders();
    const url = `${Api.ROLE}${Api.SEARCH}`;
    return ApiClient(headers).post(url, data);
  },
  deleteRole: (id: string) => {
    const url = `${Api.ROLE}/${id}`;
    return ApiClient().delete(url);
  },
  updateRole: (id: string, status: number) => {
    const url = `${Api.ROLE}/${id}/status`;
    return ApiClient().put(url, { status: status });
  },
  editRole: (idProfile: string, roleIds: string[]) => {
    const headers = getAuthHeaders();
    return ApiClient(headers).post(Api.EDIT_ROLE, { idProfile, roleIds });
  },
  getAllPermissions: () => {
    const url = `${Api.ROLE}${Api.ALL_PERMISSION}`;
    const headers = getAuthHeaders();
    return ApiClient(headers).get(url);
  },
  createRole: (data: any) => {
    const url = `${Api.ROLE}`;
    const headers = getAuthHeaders();
    return ApiClient(headers).post(url, data);
  },
  getRoleDetails: (id: string) => {
    const url = `${Api.ROLE}/${id}`;
    const headers = getAuthHeaders();
    return ApiClient(headers).get(url);
  },
  editRoleDetails: (id: string, data: any) => {
    const url = `${Api.ROLE}/${id}`;
    const headers = getAuthHeaders();
    return ApiClient(headers).put(url, data);
  },
};

export default RoleService;
