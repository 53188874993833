import { ToastProps } from 'shared-components/lib/typescript/src/types/toastProps';
import { Box, ToastMessage, ToastWithAction } from 'shared-components';
import React from 'react';
import AppShowToast from "../../components/show-toast/ShowToast";
import {Toast} from "native-base";

const template = (args: ToastProps) => (
  <Box marginRight="32px" marginTop="14px">
    <ToastMessage
      message={args.message}
      icon={args.icon}
      title={args.title}
      onClose={() => {}}
    />
  </Box>
);

export const showToast = (toast: any, args: ToastProps) => {
  toast.show({
    render: () => {
      return template(args);
    },
    placement: "top-right",
  });
};

export const showNotification = (
  toast: any,
  component: React.JSX.Element,
  duration: null | number = null,
) => {
  toast.show({
    render: () => {
      return (
        <Box marginRight="32px" marginTop="14px">
          {component}
        </Box>
      );
    },
    placement: "top-right",
    duration: duration,
  });
};

export const showNotificationForVoiso = (t: any) => {
    const toastData = {
      message: t("voiso.agent_logged_off"),
      icon: "/icons/toast/error.svg",
    };
    Toast.show({
      render: () => {
        return (
          <AppShowToast
            toastData={toastData}
            onClose={() => {
              Toast.closeAll();
            }}
          ></AppShowToast>
        );
      },
      placement: "top-right",
    });
};

export const showToastEmptyPhoneOrEmail = (navigate: any, t: any, toast: any, lead: any, type: 'EMAIL' | 'SMS', isEditable: boolean = true) => {
  const toastSMS = {
    title: t("lead-invitation.emptyPhoneTitle"),
    body: t("lead-invitation.emptyPhoneContent"),
  };
  const toastEmail = {
    title: t("lead-invitation.emptyEmailTitle"),
    body: t("lead-invitation.emptyEmailContent"),
  };
  const toastData = type === "SMS" ? toastSMS : toastEmail;
  const toastComponent = () => {
    return (
        <ToastWithAction
            {...toastData}
            secondaryButton={{
                label: t('lead-invitation.cancel'),
                onPress: () => {
                    toast.closeAll();
                }
            }}
            primaryButton={isEditable ? {
                label: t('lead-invitation.edit'),
                onPress: () => {
                    navigate(`/lead/${lead.leadId}`);
                    toast.closeAll();
                }
            } : undefined}
            onClose={() => {
                toast.closeAll();
            }}
        />
    );
  };
  showNotification(toast, toastComponent(), 5000);
};
