import * as React from "react";
import { useCallback, useEffect, useState } from "react";
import { Box, Text, useTheme } from "native-base";
import { DropDown } from "shared-components";
import {
  CsTicketOption,
  Error,
  TicketPropertiesSharedProps,
  TicketPropertiesDTO,
} from "../../types/ticketPropertiesSharedProps";
import {
  boxContainerStyle,
  boxPropertyInsideStyle,
  boxPropertyStyle,
  shadowStyle,
  textErrorStyle,
  textHeaderStyle,
  textOptionalPropertyStyle,
  textPropertyStyle,
} from "./TicketPropertiesShared.style";
import { OptionItem } from "../../types/dropdownProps";
import { OptionItemDTO } from "../../types/optionItemDTO";
import { SingleDatePicker } from "shared-components";
import AssigneeDropdown from "../assignee-dropdown/AssigneeDropdown";
import { ResourceType } from "../../constants/resourceType";

export class CsTicketConstants {
  static readonly statusOptions = [
    { label: "NEW", value: "New" },
    { label: "PENDING", value: "Pending" },
    {
      label: "IN_PROGRESS",
      value: "In-Progress",
    },
    {
      label: "WAITING_FOR_CUSTOMER",
      value: "Waiting for Customer",
    },
    { label: "ESCALATION", value: "Escalation" },
    { label: "RESOLVED", value: "Resolved" },
  ];

  static readonly subCategoryOptions = [
    {
      label: "TRANSACTION_ISSUE",
      value: "Transaction issue",
    },
    {
      label: "MERCHANT_ISSUE",
      value: "Merchant issue",
    },
    {
      label: "ACCOUNT_ISSUE",
      value: "Account issue",
    },
  ];

  static readonly categoryOptions = [
    {
      label: "CUSTOMER_SUPPORT",
      value: "Customer support",
      sub: this.subCategoryOptions,
    },
    {
      label: "SALES",
      value: "Sales",
      sub: [],
    },
  ];

  static readonly sourceOptions = [
    { label: "CALL", value: "Call" },
    { label: "EMAIL", value: "Email" },
    { label: "ORDER", value: "Order" },
    { label: "OTHER", value: "Other" },
  ];
}

const TicketPropertiesShared: React.FC<TicketPropertiesSharedProps> = ({
  singleDatePicker,
  textHeaderLabel,
  optionalText,
  ticketPropertiesLabel,
  ticketProperties,
  assigneeData,
  errors,
  validateForm,
  action,
  csTicketConstants,
  ...props
}) => {
  const theme = useTheme() as any;
  const dataStatusOptions =
    csTicketConstants?.statusOptions ?? CsTicketConstants.statusOptions;
  const dataCategoryOptions =
    csTicketConstants?.categoryOptions ?? CsTicketConstants.categoryOptions;
  const dataSubCategoryOptions =
    csTicketConstants?.subCategoryOptions ??
    CsTicketConstants.subCategoryOptions;
  const dataSourceOptions =
    csTicketConstants?.sourceOptions ?? CsTicketConstants.sourceOptions;

  useEffect(() => {
    if (
      ticketProperties?.category &&
      ticketProperties.category !== "CUSTOMER_SUPPORT"
    ) {
      action?.({ ...ticketProperties, subCategory: "" });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ticketProperties?.category]);

  const getErrorMessage = useCallback(
    (fieldName: keyof TicketPropertiesDTO) => {
      if (!validateForm) {
        return "";
      }
      const errorMessages = errors?.find(
        (error: Error) => error.fieldName === fieldName,
      );
      return errorMessages?.message ?? "";
    },
    [errors, validateForm],
  );

  const getSubCategoryOptions = useCallback(() => {
    if (ticketProperties?.category) {
      return (
        (dataCategoryOptions as CsTicketOption[])?.find(
          (categoryOptions: CsTicketOption) =>
            categoryOptions.label === ticketProperties.category,
        )?.sub ?? []
      );
    }
    return [];
  }, [dataCategoryOptions, ticketProperties]);

  const handleDropdownChange = (field: keyof TicketPropertiesDTO) => {
    return (option: OptionItem) => {
      action?.({ ...ticketProperties, [field]: option.label });
    };
  };

  const handleAssigneeChange = (option: OptionItemDTO) => {
    action?.({ ...ticketProperties, assignee: option.value });
  };

  const renderComponent = (
    fieldName: keyof TicketPropertiesDTO,
    labelText: string,
    placeHolder: string,
    dataOptions: OptionItem[],
    defaultOptionLabel: string,
    errorMessage: string,
  ) => {
    return (
      <Box {...boxPropertyStyle(errorMessage)}>
        <Text {...textPropertyStyle(theme)}>{labelText}</Text>
        <DropDown
          data={dataOptions}
          defaultOptionLabel={defaultOptionLabel}
          dropDownIcon={"/icons/arrow-down.svg"}
          selectedIcon={"/icons/checked.svg"}
          placeHolder={placeHolder}
          isInvalid={errorMessage !== ""}
          action={handleDropdownChange(fieldName)}
        />
        {errorMessage !== "" && (
          <Text {...textErrorStyle(theme)}>{errorMessage}</Text>
        )}
      </Box>
    );
  };

  const datePickerComponent = (
    labelText: string,
    errorMessage: string,
    optionalText?: string,
  ) => {
    const singleDatePickerProps = {
      arrowIcon: singleDatePicker?.arrowIcon,
      calendarIcon: singleDatePicker?.calendarIcon,
      arrowUp: singleDatePicker?.arrowUp,
      arrowDown: singleDatePicker?.arrowDown,
      arrowLeft: singleDatePicker?.arrowLeft,
      arrowRight: singleDatePicker?.arrowRight,
      initDate: ticketProperties?.targetDate,
    };

    return (
      <Box {...boxPropertyStyle(errorMessage)}>
        <Box {...boxPropertyInsideStyle()}>
          <Text {...textPropertyStyle(theme)}>{labelText}</Text>
          <Text {...textOptionalPropertyStyle(theme)}>
            ({optionalText ?? "Optional"})
          </Text>
        </Box>
        <SingleDatePicker
          {...singleDatePickerProps}
          isInvalid={!!errorMessage}
          onChangeDateEvent={(date: string) => {
            action?.({ ...ticketProperties, targetDate: date });
          }}
        />
        {errorMessage !== "" && (
          <Text {...textErrorStyle(theme)}>{errorMessage}</Text>
        )}
      </Box>
    );
  };

  const renderOptionalComponent = (
    fieldName: keyof TicketPropertiesDTO,
    labelText: string,
    placeHolder: string,
    dataOptions: OptionItem[],
    defaultOptionLabel: string,
    errorMessage: string,
    optionalText?: string,
  ) => {
    return (
      <Box {...boxPropertyStyle(errorMessage)}>
        <Box {...boxPropertyInsideStyle()}>
          <Text {...textPropertyStyle(theme)}>{labelText}</Text>
          <Text {...textOptionalPropertyStyle(theme)}>
            ({optionalText ?? "Optional"})
          </Text>
        </Box>
        <DropDown
          data={dataOptions}
          defaultOptionLabel={defaultOptionLabel}
          dropDownIcon={"/icons/arrow-down.svg"}
          selectedIcon={"/icons/checked.svg"}
          placeHolder={placeHolder}
          isInvalid={errorMessage !== ""}
          action={handleDropdownChange(fieldName)}
        />
        {errorMessage !== "" && (
          <Text {...textErrorStyle(theme)}>{errorMessage}</Text>
        )}
      </Box>
    );
  };
  const [selectedAssignee, setselectedAssignee] = useState({
    text: ticketProperties.assigneeName,
    value: ticketProperties.assignee,
  });
  useEffect(() => {
    if (!selectedAssignee.value) {
      setselectedAssignee({
        text: ticketProperties.assigneeName,
        value: ticketProperties.assignee,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ticketProperties]);

  const renderOptionalAssigneeComponent = (
    labelText: string,
    placeHolder: string,
    defaultOptionLabel: any,
    errorMessage: string,
    optionalText?: string,
  ) => {
    return (
      <Box {...boxPropertyStyle(errorMessage)}>
        <Box {...boxPropertyInsideStyle()}>
          <Text {...textPropertyStyle(theme)}>{labelText}</Text>
          <Text {...textOptionalPropertyStyle(theme)}>
            ({optionalText ?? "Optional"})
          </Text>
        </Box>
        <AssigneeDropdown
          isScrollBottom={true}
          isAll={false}
          initSelectedItem={defaultOptionLabel.assignee}
          onSelectedItem={handleAssigneeChange}
          onValueChangeAssignee={(data) => setselectedAssignee(data)}
          selectedAssignee={selectedAssignee}
          placeholder={placeHolder}
          dropdownIcon={"/icons/arrow-down.svg"}
          checkedIcon={"/icons/check.svg"}
          noDataIcon={"/icons/no-data.svg"}
          resourceType={ResourceType.TICKET}
        />
      </Box>
    );
  };
  return (
    <Box {...boxContainerStyle()} {...shadowStyle()} {...props}>
      <Text {...textHeaderStyle(theme)}>
        {textHeaderLabel ?? "Ticket Properties"}
      </Text>
      {renderComponent(
        "status",
        ticketPropertiesLabel?.status?.textLabel ?? "Status",
        ticketPropertiesLabel?.status?.textPlaceHolder ?? "Select status",
        dataStatusOptions,
        ticketProperties?.status ?? "",
        getErrorMessage("status"),
      )}
      {renderComponent(
        "category",
        ticketPropertiesLabel?.category?.textLabel ?? "Category",
        ticketPropertiesLabel?.category?.textPlaceHolder ?? "Select category",
        dataCategoryOptions,
        ticketProperties?.category ?? "",
        getErrorMessage("category"),
      )}
      {getSubCategoryOptions() &&
        getSubCategoryOptions().length > 0 &&
        renderOptionalComponent(
          "subCategory",
          ticketPropertiesLabel?.subCategory?.textLabel ?? "Sub Category",
          ticketPropertiesLabel?.subCategory?.textPlaceHolder ??
            "Select sub category",
          dataSubCategoryOptions,
          ticketProperties?.subCategory ?? "",
          getErrorMessage("subCategory"),
          optionalText,
        )}
      {renderOptionalAssigneeComponent(
        ticketPropertiesLabel?.assignee?.textLabel ?? "Assignee",
        ticketPropertiesLabel?.assignee?.textPlaceHolder ?? "Select assignee",
        ticketProperties,
        getErrorMessage("assignee"),
        optionalText,
      )}
      {renderOptionalComponent(
        "source",
        ticketPropertiesLabel?.source?.textLabel ?? "Source",
        ticketPropertiesLabel?.source?.textPlaceHolder ?? "Select source",
        dataSourceOptions,
        ticketProperties?.source ?? "",
        getErrorMessage("source"),
        optionalText,
      )}
      {datePickerComponent(
        ticketPropertiesLabel?.targetDate ?? "Target Date",
        getErrorMessage("targetDate"),
        optionalText,
      )}
    </Box>
  );
};

export default TicketPropertiesShared;
