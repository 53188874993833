import { Config } from "../configs/environment";
import { resolveTenant } from "../tenantResolver";

export const logout = (token: string) => {
  if (token) {
    const tenantConfig = resolveTenant();
    localStorage.removeItem(
      `oidc.at:${tenantConfig.oidcConfiguration.authority}`,
    );
    const params = {
      id_token_hint: token,
      post_logout_redirect_uri: `${window.location.origin}/authenticate`,
      state: "logout",
    } as any;
    const paramsStr = Object.keys(params)
      .map((key) => `${key}=${params[key]}`)
      .join("&");
    window.location.replace(`${Config.ISDomain}/oidc/logout?${paramsStr}`);
  }
};
