import { Header, TableAction } from "../../types/table";
import CoreTable from "../core-table/CoreTable";
import UserService from "../../services/user/userService";
import { useCallback, useEffect, useState } from "react";
import { Text } from "shared-components";
import { Modal, useTheme, useToast } from "native-base";
import { roleText, statusText } from "./userListing.style";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import EditUserDrawer from "../edit-user-drawer/EditUserDrawer";
import { OptionItemDTO } from "shared-components/lib/typescript/src/models/option-item.dto";
import { showToast } from "../../services/toast/toastService";
import { PERMISSIONS } from "../../constants/permisstion";

const UserListing = () => {
  const { t } = useTranslation();
  const toast = useToast();
  const formatStatus = (status: string) => {
    const mappingStatus = {
      ACTIVE: t("users-roles.active"),
      INACTIVE: t("users-roles.inactive"),
      INVITED: t("users-roles.invited"),
    };
    return (
      <Text {...statusText(theme, status)}>{_.get(mappingStatus, status)}</Text>
    );
  };
  const formatRoles = (roles: any[]) => {
    if (_.isEmpty(roles)) {
      return <Text {...roleText(theme)}>{t("users-roles.none")}</Text>;
    }
    return (
      <Text {...roleText(theme)} isTruncated pr={3}>
        {roles.map((role) => role?.name).join(", ")}
      </Text>
    );
  };
  const headers: Header[] = [
    {
      label: t("users-roles.first-name"),
      value: "firstName",
      width: 240,
    },
    {
      label: t("users-roles.last-name"),
      value: "lastName",
      width: 240,
    },
    {
      label: t("users-roles.email"),
      value: "email",
      width: 376,
    },
    {
      label: t("users-roles.role"),
      value: "roleData",
      width: 376,
      format: formatRoles,
    },
    {
      label: t("users-roles.status"),
      value: "status",
      width: 240,
      format: formatStatus,
    },
    {
      label: t("users-roles.action"),
      value: "action",
      width: 100,
      type: "ACTION",
    },
  ];

  const actions: TableAction[] = [
    // {
    //   label: t("users-roles.resend-invitation"),
    //   icon: "/icons/send-by-email.svg",
    //   value: "INVITED",
    // },
    {
      label: t("users-roles.edit"),
      icon: "/icons/edit.svg",
      value: "EDIT",
      permissions: [PERMISSIONS.USERS_ROLES.ASSIGN_TO_ROLE],
    },
  ];
  const theme = useTheme();

  const fetchDatas = useCallback((params: any) => {
    return UserService.list(params);
  }, []);

  const [open, setOpen] = useState(false);
  const [selectedRole, setSelectedRole] = useState<OptionItemDTO[]>([]);
  const [id, setId] = useState("");
  const [isFetch, setIsFetch] = useState(false);

  useEffect(() => {
    if (isFetch) {
      setIsFetch(false);
    }
  }, [isFetch]);

  const handleAction = (code: string, data: any) => {
    setSelectedRole(data.roleData);
    setId(data.id);
    if (code === "EDIT") {
      setOpen(true);
    }
  };

  const editUser = () => {
    return (
      <Modal isOpen={open} onClose={() => setOpen(false)}>
        <Modal.Content
          maxHeight="100vh"
          marginLeft="auto"
          marginRight="0"
          maxWidth="560"
          borderRadius="0"
        >
          {open && (
            <EditUserDrawer
              closeModal={(isFetchData, message) => {
                setIsFetch(isFetchData);
                setOpen(false);
                if (message) {
                  showToastMessage(message);
                }
              }}
              id={id}
              setOpen={setOpen}
              initData={selectedRole}
            />
          )}
        </Modal.Content>
      </Modal>
    );
  };

  const showToastMessage = (message: string) => {
    const toastData = {
      message,
      icon: "/icons/toast/check.svg",
    };
    showToast(toast, toastData);
  };

  return (
    <>
      {editUser()}
      <CoreTable
        headers={headers}
        isFetch={isFetch}
        sort={{
          orderBy: "createdDate",
          orderDirection: "DESC",
        }}
        fetchDatas={fetchDatas}
        actions={actions}
        onClickAction={(code, data) => handleAction(code, data)}
        // actionRow={actionRow()}
        specialStatus={"INVITED"}
        isMargin={false}
      />
    </>
  );
};

export default UserListing;
