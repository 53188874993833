import TENANT from "./constants/tenant";
const fontFamily = 'Avenir LT Pro';
export const themeColors = [
  {
    fontFamily,
    tenant: TENANT.HYT,
    appPalette: {
      grey: {
        black: "#000000",
        grey80: "#333333",
        grey60: "#666666",
        grey40: "#999999",
        grey20: "#CCCCCC",
        grey12: "#E0E0E0",
        grey8: "#EBEBEB",
        grey4: "#F5F5F5",
        grey2: "#F8F8F8",
        white: "#FFFFFF",
      },
      primary: {
        primary150: "#283CB3",
        primary100: "#2C42C5",
        primary80: "#5668D1",
        primary60: "#808EDC",
        primary40: "#ABB3E8",
        primary20: "#D5D9F3",
        primary12: "#E2E4F7",
        primary8: "#EEF0FB",
        primary4: "#F7F8FD",
      },
      blue: {
        blue100: "#0073E6",
        blue8: "#E6F1FD",
      },
      red: {
        red100: "#E70000",
      },
      green: {
        green100: "#03A271",
        green8: "#E6F6F1",
      },
      yellow: {
        yellow100: "#FF9F0A",
      },
      info: "#1E7CD7",
      danger: {
        danger100: "#E70000",
      },
      success: {
        success100: "#03A271",
      },
      warning: {
        warning100: "#FF9F0A",
        warning8: "#FFF5E7",
      },
    },
  },
  {
    fontFamily,
    tenant: TENANT.KARD,
    appPalette: {
      grey: {
        black: "#000000",
        grey80: "#333333",
        grey60: "#666666",
        grey40: "#999999",
        grey20: "#CCCCCC",
        grey12: "#E0E0E0",
        grey8: "#EBEBEB",
        grey4: "#F5F5F5",
        grey2: "#F8F8F8",
        white: "#FFFFFF",
      },
      primary: {
        primary150: "#1B71C4",
        primary100: "#1E7CD7",
        primary80: "#4B96DF",
        primary60: "#78B0E7",
        primary40: "#A5CBEF",
        primary20: "#D2E5F7",
        primary12: "#E0EDFA",
        primary8: "#EDF5FC",
        primary4: "#F6FAFE",
      },
      blue: {
        blue100: "#0073E6",
        blue8: "#E6F1FD",
      },
      red: {
        red100: "#E70000",
      },
      green: {
        green100: "#03A271",
        green8: "#E6F6F1",
      },
      yellow: {
        yellow100: "#FF9F0A",
      },
      info: "#1E7CD7",
      danger: {
        danger100: "#E70000",
      },
      success: {
        success100: "#03A271",
      },
      warning: {
        warning100: "#FF9F0A",
        warning8: "#FFF5E7",
      },
    },
  },
  {
    fontFamily,
    tenant: TENANT.KANOO,
    appPalette: {
      grey: {
        black: "#000000",
        grey80: "#333333",
        grey60: "#666666",
        grey40: "#999999",
        grey20: "#CCCCCC",
        grey12: "#E0E0E0",
        grey8: "#EBEBEB",
        grey4: "#F5F5F5",
        grey2: "#F8F8F8",
        white: "#FFFFFF",
      },
      primary: {
        primary150: "#612974",
        primary100: "#723088",
        primary80: "#8E59A0",
        primary60: "#AA83B8",
        primary40: "#C7ACCF",
        primary20: "#E3D6E7",
        primary12: "#ECE3EF",
        primary8: "#F4EFF6",
        primary4: "#FAF7FA",
      },
      blue: {
        blue100: "#0073E6",
        blue8: "#E6F1FD",
      },
      red: {
        red100: "#E70000",
      },
      green: {
        green100: "#03A271",
        green8: "#E6F6F1",
      },
      yellow: {
        yellow100: "#FF9F0A",
      },
      info: "#1E7CD7",
      danger: {
        danger100: "#E70000",
      },
      success: {
        success100: "#03A271",
      },
      warning: {
        warning100: "#FF9F0A",
        warning8: "#FFF5E7",
      },
    },
  },
];
