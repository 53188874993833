import { useCallback, useEffect, useState } from "react";
import { AppButton, Box } from "shared-components";
import { format } from "date-fns";
import { useTranslation } from "react-i18next";
import { LeadConstants } from "../../constants/lead";
import LeadService from "../../services/lead/leadService";
import { Modal, Pressable, Text, useTheme } from "native-base";
import CreateLeadDrawer from "../create-lead-drawer/CreateLeadDrawer";
import { handleFiltersHaveDateRange } from "../../utils/filters";
import CallService from "../../services/call/callService";
import { useNavigate } from "react-router-dom";
import { ResourceType } from "../../constants/resourceType";
import AppInviteLead from "../invite-lead/InviteLead";
import { showNotificationForVoiso } from "../../services/toast/toastService";
import ConvertLeadDrawer from "../convert-lead-drawer/ConvertLeadDrawer";
import { Header, TableAction } from "../../types/table";
import CoreTable from "../core-table/CoreTable";
import { ReactSVG } from "react-svg";
import { dotStyle, filterStyle, phoneNumberStyle, textStyle } from "./LeadListing.style";
import LeadListingFilter from "../lead-listing-filter/LeadListingFilter";
import { PERMISSIONS } from "../../constants/permisstion";
import ProtectedContent from "../protected-content/ProtectedContent";
import { DATE_TIME_FORMAT_MM_DD_YYYY_H_MM_A } from "../../constants/formatDate";

const AppLead = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const FORMAT_DATE = DATE_TIME_FORMAT_MM_DD_YYYY_H_MM_A;
  const [isOpenFilter, setIsOpenFilter] = useState(false);
  const [selectedAssignee, setSelectedAssignee] = useState();
  const [filters, setFilters] = useState({
    status: "",
    createdDate: {
      from: "",
      to: "",
    },
    assigneeId: "",
  });
  const [isFilterUsed, setIsFilterUsed] = useState(false);
  const [key, setKey] = useState(0);
  const [isOpenCreateLeadDrawer, setIsOpenCreateLeadDrawer] = useState(false);
  const [isOpenConvertLeadDrawer, setIsOpenConvertLeadDrawer] = useState(false);
  const [typeDrawer, setTypeDrawer] = useState<"SINGLE" | "BULK">("SINGLE");
  const [isOpenInvite, setIsOpenInvite] = useState(false);
  const [inviteType] = useState<"SMS" | "EMAIL">("EMAIL");
  const [userInfo, setUserInfo] = useState<any>({});
  const [inviteLeadInfo] = useState<any>(undefined);
  const theme = useTheme();
  const [isCreateSuccess, setIsCreateSuccess] = useState(false);
  const [isExistedEmail, setIsExistedEmail] = useState(false);
  useEffect(() => {
    if (isCreateSuccess) {
      setIsCreateSuccess(false);
    }
  }, [isCreateSuccess]);

  const formatStatus = (statusValue?: string) => {
    const status = LeadConstants.status.find((st) => st.label === statusValue);
    return status ? <Text {...textStyle(theme)}>{t(status.value)}</Text> : "";
  };

  const formatDate = (timestamp: number) => {
    if (!timestamp) {
      return undefined;
    }

    const date = new Date(timestamp);
    if (isNaN(date.getTime())) {
      return undefined;
    }

    return <Text {...textStyle(theme)}>{format(date, FORMAT_DATE)}</Text>;
  };

  const navigateLeadDetail = (lead: any) => {
    navigate(`/lead/edit/${lead.leadId}`);
  };

  const callVoiso = (lead: any) => {
    CallService.callVoiso(
      lead.phoneNumber,
      ResourceType.LEAD,
      lead.leadId,
    ).catch((reason) => {
      if (reason.response.data.code === "1010022") {
        showNotificationForVoiso(t);
      }
    });
  };
  const openConvertForm = (data: any) => {
    setUserInfo(buildLeadInfo(data));
    setIsOpenConvertLeadDrawer(true);
  };

  const buildLeadInfo = (data: any) => {
    return {
      leadId: data.leadId,
      email: data.email || "",
      firstName: data.firstName || "",
      lastName: data.lastName || "",
      phoneNumber: data.phoneNumber?.replace("+", "") || "",
      publicName: "",
      birthDay: "",
      island: "",
      city: "",
      region: "",
      gender: "",
      streetAddress: "",
      country: "",
    };
  };

  const filterProps = {
    statusOptions: [
      {
        label: "ALL",
        value: t("ticket-listing-filter.status-options.all"),
      },
      {
        label: "NEW",
        value: t("ticket-listing-filter.status-options.new"),
      },
      {
        label: "NO_ANSWER_1_3",
        value: t("lead-listing.filter.no-answer"),
      },
      {
        label: "CALL_AGAIN",
        value: t("lead-listing.filter.call-again"),
      },
      {
        label: "NO_FOUND",
        value: t("lead-listing.filter.no-found"),
      },
      {
        label: "UNDER_18",
        value: t("lead-listing.filter.under-18"),
      },
    ],
    textObject: {
      filter: t("ticket-listing-filter.title"),
      status: t("ticket-listing-filter.status"),
      apply: t("ticket-listing-filter.apply"),
      clearAll: t("ticket-listing-filter.clear-all"),
      createdDate: t("ticket-listing-filter.created-date"),
      invalidFormatErrMsg: t("errors.standard002", {
        fieldName: t("ticket-listing-filter.created-date"),
      }),
      earlierErrMsg: t("errors.standard093"),
      selectedFromErrMsg: t("errors.standard092", {
        fieldName: t("ticket-listing-filter.start-date"),
      }),
      selectedToErrMsg: t("errors.standard092", {
        fieldName: t("ticket-listing-filter.end-date"),
      }),
      selectedRangeOverErrMsg: t("errors.standard094"),
      assignee: t("prospect-listing.header-column.assignee"),
    },
    dropDownIcon: "/icons/arrow-down.svg",
    selectedIcon: "/icons/checked.svg",
    closeFilterIcon: "/icons/filter-close.svg",
    arrowIcon: "/icons/arrow-long.svg",
    calendarIcon: "/icons/calendar.svg",
    arrowUp: "/icons/mini-arrow-up.svg",
    arrowDown: "/icons/mini-arrow.svg",
    arrowLeft: "/icons/chevron-left.svg",
    arrowRight: "/icons/chevron-right.svg",
    onFiltersChanged: (filtersChanged: any) => {
      setFilters(filtersChanged);
      setIsOpenFilter(false);
      setIsFilterUsed(filters !== filtersChanged);
    },
  };

  const closeCreateLeadDrawer = () => {
    setIsOpenCreateLeadDrawer(false);
  };

  const closeConvertLeadDrawer = () => {
    setIsOpenConvertLeadDrawer(false);
  };

  const formatPhoneNumber = (phoneNumber: string, item: any) => {
    return (
      <Pressable onPress={() => callVoiso(item)}>
        <Text {...phoneNumberStyle(theme)}>{phoneNumber}</Text>
      </Pressable>
    );
  };

  const headers: Header[] = [
    {
      value: "fullName",
      label: t("lead-listing.header-column.full-name"),
      width: 246,
    },
    {
      value: "phoneNumber",
      label: t("lead-listing.header-column.phone-number"),
      format: formatPhoneNumber,
      width: 246,
      permissions: [PERMISSIONS.USERS_ROLES.SENSITIVE_DATA.VIEW_SENSITIVE_DATA],
    },
    {
      value: "email",
      label: t("lead-listing.header-column.email"),
      width: 246,
      permissions: [PERMISSIONS.USERS_ROLES.SENSITIVE_DATA.VIEW_SENSITIVE_DATA],
    },
    {
      value: "assigneeName",
      label: t("ticket-listing.header-column.assignee"),
      width: 237,
    },
    {
      value: "status",
      label: t("lead-listing.header-column.status"),
      width: 164,
      format: formatStatus,
    },
    {
      value: "createdDate",
      label: t("lead-listing.header-column.created-date"),
      width: 164,
      format: formatDate,
    },
    {
      value: "invitedDate",
      label: t("lead-listing.header-column.invited-date"),
      width: 164,
      format: formatDate,
    },
    {
      value: "creator",
      label: t("lead-listing.header-column.creator"),
      width: 246,
    },
    {
      value: "action",
      label: t("ticket-listing.header-column.action"),
      type: "ACTION",
      width: 100,
    },
  ];

  const actions: TableAction[] = [
    {
      icon: "/icons/edit.svg",
      label: t("common.edit"),
      value: "EDIT",
      permissions: [PERMISSIONS.CONTACTS.LEAD.EDIT],
    },
    {
      icon: "/icons/phone.svg",
      label: t("lead-listing.call"),
      value: "CALL",
    },
    {
      icon: "/icons/convert-lead.svg",
      label: t("lead-listing.convert"),
      value: "CONVERT",
      permissions: [PERMISSIONS.CONTACTS.PROSPECT.CREATE],
    },
  ];

  const fetchDatas = useCallback((params: any) => {
    return LeadService.getLeadListing(params);
  }, []);

  const handleAction = (code: string, data: any) => {
    switch (code) {
      case "EDIT":
        navigateLeadDetail(data);
        break;
      case "CALL":
        callVoiso(data);
        break;
      case "CONVERT":
        setIsExistedEmail(data.isExistedEmail);
        openConvertForm(data);
        break;
      default:
        break;
    }
  };

  const actionRow = () => {
    return (
      <>
        <Box>
          <Pressable
            testID="filter-icon"
            position="relative"
            {...filterStyle(theme)}
            onPress={() => {
              setIsOpenFilter(true);
            }}
          >
            <ReactSVG src="/icons/filter-icon.svg" />
            {isFilterUsed ? (
              <Box {...dotStyle(theme)} position="absolute" />
            ) : undefined}
          </Pressable>
        </Box>
        <ProtectedContent permissions={PERMISSIONS.CONTACTS.LEAD.CREATE}>
          <Box>
            <Pressable
              position="relative"
              {...filterStyle(theme)}
              onPress={() => {
                setIsOpenCreateLeadDrawer(true);
                setTypeDrawer("SINGLE");
              }}
            >
              <ReactSVG src="/icons/add.svg" />
            </Pressable>
          </Box>
          <Box>
            <AppButton
              label={t("create-bulk-leads.import")}
              onPress={() => {
                setIsOpenCreateLeadDrawer(true);
                setTypeDrawer("BULK");
              }}
              icon={"/icons/upload.svg"}
            />
          </Box>
        </ProtectedContent>
      </>
    );
  };

  const processedFilters = handleFiltersHaveDateRange(filters, "createdDate");

  return (
    <Box>
      <AppInviteLead
        leadInfo={inviteLeadInfo}
        type={inviteType}
        isOpenInvite={isOpenInvite}
        onClose={() => setIsOpenInvite(false)}
      />

      <Modal isOpen={isOpenFilter} onClose={() => setIsOpenFilter(false)}>
        <Modal.Content
          maxHeight="100vh"
          marginLeft="auto"
          marginRight="0"
          maxWidth="460"
          borderRadius="0"
        >
          {isOpenFilter ? (
            <LeadListingFilter
              {...filterProps}
              initFilters={filters}
              selectedAssignee={selectedAssignee}
              setSelectedAssignee={setSelectedAssignee}
              onCloseFilter={() => setIsOpenFilter(false)}
            />
          ) : undefined}
        </Modal.Content>
      </Modal>
      {isOpenCreateLeadDrawer ? (
        <CreateLeadDrawer
          isOpenDrawer={isOpenCreateLeadDrawer}
          closeDrawer={closeCreateLeadDrawer}
          type={typeDrawer}
          onCreateSuccess={() => {
            setIsCreateSuccess(true);
            setKey(key + 1);
          }}
        />
      ) : undefined}
      {isOpenConvertLeadDrawer ? (
        <ConvertLeadDrawer
          lead={userInfo}
          closeDrawer={closeConvertLeadDrawer}
          isOpenDrawer={isOpenConvertLeadDrawer}
          isExistedEmail={isExistedEmail}
          onConvertSuccess={() => {
            setIsCreateSuccess(true);
            setKey(key + 1);
          }}
        />
      ) : undefined}
      <CoreTable
        headers={headers}
        filters={filters}
        processedFilters={processedFilters}
        onClickAction={(code, data) => handleAction(code, data)}
        fetchDatas={fetchDatas}
        actions={actions}
        actionRow={actionRow()}
        isBorder={true}
        isCreateSuccess={isCreateSuccess}
        searchTooltip={t("lead-listing.search-tooltip")}
        title={t("lead-listing.title")}
        isFilterUsed={isFilterUsed}
        setIsFilterUsed={setIsFilterUsed}
      />
    </Box>
  );
};

export default AppLead;
