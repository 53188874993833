import { Modal, useToast } from "native-base";
import React, { useEffect, useState } from "react";
import { CreateLeadProps } from "../../types/createLeadProps";
import { CreateBulkLeads, CreateLeadPanel } from "shared-components";
import { useTranslation } from "react-i18next";
import LeadService from "../../services/lead/leadService";
import { showToast } from "../../services/toast/toastService";

const CreateLeadDrawer: React.FC<CreateLeadProps> = ({
  isOpenDrawer,
  type,
  closeDrawer,
  onCreateSuccess,
}) => {
  const { t } = useTranslation();
  const toast = useToast();

  const initErrors = {
    firstName: "",
    lastName: "",
    phoneNumber: "",
    email: "",
  };
  const [countries, setCountries] = useState<any[]>([]);
  const [errors, setErrors] = useState(initErrors);

  const createLeadFormProps = {
    labels: {
      firstName: t("user-profile-listing.header-column.first-name"),
      lastName: t("user-profile-listing.header-column.last-name"),
      phoneNumber: t("lead-listing.header-column.phone-number"),
      email: t("user-profile-listing.header-column.email"),
    },
    placeholder: {
      firstName: t("placeholders", {
        fieldName: t(
          "user-profile-listing.header-column.first-name",
        ).toLowerCase(),
      }),
      lastName: t("placeholders", {
        fieldName: t(
          "user-profile-listing.header-column.last-name",
        ).toLowerCase(),
      }),
      phoneNumber: t("placeholders", {
        fieldName: t(
          "user-profile-listing.header-column.phone-number",
        ).toLowerCase(),
      }),
      email: t("placeholders", {
        fieldName: t("user-profile-listing.header-column.email").toLowerCase(),
      }),
    },
    clearIcon: "/icons/close-icon.svg",
    errorMessageValue: {
      emptyFirstName: t("errors.standard001", {
        fieldName: t(
          "user-profile-listing.header-column.first-name",
        ).toLowerCase(),
      }),
      emptyLastName: t("errors.standard001", {
        fieldName: t(
          "user-profile-listing.header-column.last-name",
        ).toLowerCase(),
      }),
      emptyPhoneNumber: t("errors.standard001", {
        fieldName: t(
          "user-profile-listing.header-column.phone-number",
        ).toLowerCase(),
      }),
      emptyEmail: t("errors.standard001", {
        fieldName: t("user-profile-listing.header-column.email").toLowerCase(),
      }),
      existsPhoneNumber: t("errors.existed", {
        fieldName: t(
          "user-profile-listing.header-column.phone-number",
        ).toLowerCase(),
      }),
      existsEmail: t("errors.existed", {
        fieldName: t("user-profile-listing.header-column.email").toLowerCase(),
      }),
      invalidEmail: t("errors.standard002", {
        fieldName: t("user-profile-listing.header-column.email"),
      }),
      invalidPhoneNumber: t("errors.standard002", {
        fieldName: t(
          "user-profile-listing.header-column.phone-number",
        ).toLowerCase(),
      }),
      phoneNotReachMinLength: t("errors.standard004", {
        minLength: 8,
      }),
    },
    initData: {
      firstName: "",
      lastName: "",
      phoneNumber: "",
      email: "",
      callingCode: "",
    },
    onChangeData: () => {},
  };
  const sidePanelProps = {
    closeFilterIcon: "/icons/filter-close.svg",
    labels: {
      title: t("lead-creation-form.title"),
      primaryButton: t("ticket-form.btn-submit-create"),
      secondaryButton: t("ticket-discussion.button.cancel"),
    },
    width: "560px",
    onClose: () => {
      closeDrawer();
    },
    onPrimaryButtonPress: () => {},
    onSecondaryButtonPress: () => {
      closeDrawer();
    },
  };

  const sideCreateBulkPanelProps = {
    closeFilterIcon: "/icons/filter-close.svg",
    width: "560px",
    labels: {
      title: t("create-bulk-leads.title"),
      primaryButton: t("ticket-form.btn-submit-create"),
      secondaryButton: t("ticket-discussion.button.cancel"),
    },
    onClose: () => {
      closeDrawer();
    },
    onPrimaryButtonPress: () => {},
    onSecondaryButtonPress: () => {
      closeDrawer();
    },
  };
  const headerTemplate = {
    email: "Email",
    firstName: "First Name",
    lastName: "Last Name",
    phoneNumber: "Phone number",
    failedReason: "Fail reason",
  };
  const translateText = {
    errorFileEmpty: t("create-bulk-leads.errorFileEmpty"),
    errorFileType: t("create-bulk-leads.errorFileType"),
    errorFileSize: t("create-bulk-leads.errorFileSize"),
    errorFileLength: t("create-bulk-leads.errorFileLength"),
    errorFileTemplate: t("create-bulk-leads.errorFileTemplate"),
    errorInvalidFirstName: t("create-bulk-leads.errorInvalidFirstName"),
    errorInvalidLastName: t("create-bulk-leads.errorInvalidLastName"),
    errorRequiredPhoneNumber: t("create-bulk-leads.errorRequiredPhoneNumber"),
    errorInvalidPhoneNumber: t("create-bulk-leads.errorInvalidPhoneNumber"),
    errorRequiredEmail: t("create-bulk-leads.errorRequiredEmail"),
    errorInvalidEmail: t("create-bulk-leads.errorInvalidEmail"),
    maxSizeFileText: t("create-bulk-leads.maxSizeFileText"),
    dropFileText: t("create-bulk-leads.dropFileText"),
    orText: t("create-bulk-leads.orText"),
    selectFileText: t("create-bulk-leads.selectFileText"),
    uploadFileText: t("create-bulk-leads.uploadFileText"),
    downloadTemplateText: t("create-bulk-leads.downloadTemplateText"),
    downloadFileFailText: t("create-bulk-leads.downloadFileFailText"),
    linkFileFailText: t("create-bulk-leads.linkFileFailText"),
    downloadIcon: "/icons/download.svg",
    closeIcon: "/icons/close.svg",
    altCloseIcon: t("create-bulk-leads.errorInvalidFirstName"),
    altDownloadIcon: t("create-bulk-leads.errorInvalidFirstName"),
  };

  useEffect(() => {
    LeadService.getCountries().then((res) => {
      setCountries(res?.data?.result);
    });
  }, []);

  const createSingleLead = (data: any) => {
    LeadService.create(data)
      .then(() => {
        closeDrawer();
        onCreateSuccess();
        const toastData = {
          message: t("lead-creation-form.create-success"),
          icon: "/icons/toast/check.svg",
        };
        showToast(toast, toastData);
      })
      .catch((err) => {
        if (err?.response?.data?.messages.includes("phone_number_is_existed")) {
          setErrors((prevState) => ({
            ...prevState,
            phoneNumber: t("errors.existed", {
              fieldName: t(
                "user-profile-listing.header-column.phone-number",
              ).toLowerCase(),
            }),
          }));
        }
      });
  };

  const createBulkLeads = (file: File) => {
    const bodyFormData = new FormData();
    bodyFormData.append("file", file);
    LeadService.createBulk(bodyFormData).then(() => {
      closeDrawer();
      onCreateSuccess();
      const toastData = {
        message: t("create-bulk-leads.create-success"),
        icon: "/icons/toast/check.svg",
      };
      showToast(toast, toastData);
    });
  };

  const downloadTemplate = () => {
    LeadService.downloadTemplate().then((res) => {
      const contentDisposition = res.headers["content-disposition"];
      const regex = /filename=([^;\n\r]*)/;
      const match = regex.exec(contentDisposition);
      let filename = "";
      if (match && match.length > 1) {
        filename = match[1];
      }
      const csvDataUri =
        "data:text/csv;charset=utf-8," + encodeURIComponent(res?.data);
      const link = document.createElement("a");
      link.setAttribute("href", csvDataUri);
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
  };

  const contentPanel = () => {
    if (type === "SINGLE") {
      return (
        <CreateLeadPanel
          createLeadFormProps={{
            ...createLeadFormProps,
            errors: errors,
            countries: countries,
          }}
          sidePanelProps={sidePanelProps}
          onCreate={(data) => createSingleLead(data)}
        />
      );
    } else if (type === "BULK") {
      return (
        <CreateBulkLeads
          sidePanelProps={sideCreateBulkPanelProps}
          headerTemplate={headerTemplate}
          translateText={translateText}
          onCreateBulkLeads={(fileCsv) => {
            createBulkLeads(fileCsv);
          }}
          onDownloadTemplate={() => {
            downloadTemplate();
          }}
        />
      );
    } else {
      return undefined;
    }
  };

  return (
    <Modal isOpen={isOpenDrawer} onClose={() => closeDrawer()}>
      <Modal.Content
        maxHeight="100vh"
        marginLeft="auto"
        marginRight="0"
        maxWidth="560"
        borderRadius="0"
      >
        {isOpenDrawer ? contentPanel() : undefined}
      </Modal.Content>
    </Modal>
  );
};

export default CreateLeadDrawer;
