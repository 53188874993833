import Api from "../../configs/api";
import { resolveTenant } from "../../tenantResolver";
import ApiClient from "../api-client/apiClient";

const getAuthHeaders = () => {
  const tenantConfig = resolveTenant();
  const authData = JSON.parse(
    localStorage.getItem(
      `oidc.at:${tenantConfig.oidcConfiguration.authority}`
    ) ?? "{}"
  );
  return {
    token: authData.access_token,
  };
};

const AssociatedOrderService = {
  getOrderListing: (
    pageIndex: number,
    pageSize: number,
    keyword: string | undefined
  ) => {
    const params = {
      keyword,
      pageIndex,
      pageSize,
    };
    return ApiClient().get(Api.ORDER + Api.LIST, { params });
  },
  getOrderDetails: (id: string) => {
    return ApiClient().get(`${Api.ORDER}/${id}`);
  },
  associatedOrderWithTicket: (ticketId: string, orderNumber: string) => {
    const api = Api.ASSOCIATED_ORDER_WITH_TICKET.replace(
      "{ticketId}",
      ticketId
    ).replace("{orderNumber}", orderNumber);
    return ApiClient().post(api);
  },
  unAssociatedOrderWithTicket: (ticketId: string, orderNumber: string) => {
    const api = Api.ASSOCIATED_ORDER_WITH_TICKET.replace(
      "{ticketId}",
      ticketId
    ).replace("{orderNumber}", orderNumber);
    return ApiClient().delete(api);
  },
  orderCall: (resourceId: string, target: string, agent: string, orderNumber: string) => {
    const body = {resourceId, target, agent, orderNumber}
    const headers = getAuthHeaders();
    return ApiClient(headers).post(Api.ORDER_CALL, body);
  }
};

export default AssociatedOrderService;
