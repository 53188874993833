import { Sidebar } from "shared-components";
import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHook";
import { setSibarItems, SidebarStore } from "../../store/sidebar/sidebar";
import {resolveTenant} from "../../tenantResolver";
import {FORCE_NAVIGATION_PATH} from "../../routes/RoutesData";

const AppSidebar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { sidebarItems } = useAppSelector(SidebarStore);
  const dispatch = useAppDispatch();
  const tenantConfig = resolveTenant();

  const clickMenu = (path: string) => {
    if (FORCE_NAVIGATION_PATH.includes(path)) {
      window.location.href = tenantConfig.adminHubDomain + path;
    } else {
      navigate(path);
    }
  };

  useEffect(() => {
    const sidebar = sidebarItems.map((item) => {
      const newSubItems = item.subSidebarItems.map((subItem: any) => {
        return {
          ...subItem,
          active: location.pathname.includes(subItem.path),
        };
      });
      return {
        ...item,
        expand:
          item.expand || newSubItems.some((subItem: any) => subItem.active),
        subSidebarItems: newSubItems,
      };
    });
    dispatch(setSibarItems(sidebar));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  return (
    <Sidebar
      sidebarItems={sidebarItems}
      onClick={(path: string) => clickMenu(path)}
    ></Sidebar>
  );
};

export default AppSidebar;
