import React, { useEffect, useRef, useState } from "react";
import { Box, Text, Image, useTheme, Pressable } from "native-base";
import { useTranslation } from "react-i18next";
import { useForm, Controller } from "react-hook-form";
import {
  errorStyle,
  footerStyle,
  gap16,
  gap24,
  gap6Style,
  textStyle,
  titleStyle,
  width50,
  wrapperStyle,
} from "./ProjectForm.style";
import {
  AppButton,
  AppInput,
  ImageCropper,
  MultipleImageUploader,
  SingleDatePicker,
  Textarea,
  UploadFile,
  InputMoney,
  Popup,
} from "shared-components";
import { ProgramOwner } from "../program-form/ProgramForm";
import { REGEX_BASE64_IMAGE } from "../../constants/regex";
import { isSuccess } from "../../constants/statusCode";
import { TENANT_CONFIG } from "../../constants/tenantConfig";
import TenantConfigService from "../../services/tenant-config/tenantConfigService";
import ProjectService from "../../services/project/projectService";
import { getTime, parse, format } from "date-fns";
import { PopupProps } from "shared-components/lib/typescript/src/ui-components/custom-components/popup/Popup";
import { validate } from "./validators";
interface ProjectFormProps {
  selectedProgramOwner: ProgramOwner;
  programId: string;
  projectId?: string;
  closeModal: (isFetchData: boolean, content?: string) => void;
  isHideDatepicker?: boolean;
}

const ProjectForm: React.FC<ProjectFormProps> = ({
  selectedProgramOwner,
  programId,
  projectId,
  closeModal,
  isHideDatepicker,
}) => {
  const theme: any = useTheme();
  const { t } = useTranslation();
  const {
    handleSubmit,
    control,
    formState: { errors, isSubmitted },
    setValue,
    watch,
    getValues,
  } = useForm();
  const [logo, setLogo] = useState("");
  const [banner, setBanner] = useState("");
  const [logoUrl, setLogoUrl] = useState("");
  const [bannerUrl, setBannerUrl] = useState("");
  const [protocol, setProtocol] = useState<any>("");
  const [protocolUrl, setProtocolUrl] = useState<any>("");
  const [protocolName, setProtocolName] = useState("");
  const [logoErrMsg, setLogoErrMsg] = useState("");
  const [bannerErrMsg, setBannerErrMsg] = useState("");
  const [protocolErrMsg, setProtocolErrMsg] = useState("");
  const maxFiles = 10;
  const [projectPictures, setProjectPictures] = useState<any>();
  const [submitted, setSubmitted] = useState(false);
  const [currencyCode, setCurrencyCode] = useState("");
  const [imageUrls, setImageUrls] = useState([]);
  const [showModalError, setShowModalError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const handleValidate = () => {
    setLogoErrMsg(
      !logo
        ? t("validate.standard070", { field: t("programs.logo-image") })
        : ""
    );
    setBannerErrMsg(
      !banner
        ? t("validate.standard070", { field: t("programs.banner-image") })
        : ""
    );
    setProtocolErrMsg(
      !protocol
        ? t("validate.standard070", { field: t("programs.protocol") })
        : ""
    );
    return validate(
      logo,
      banner,
      protocol,
      selectedProgramOwner?.ownerUserId,
      endDateErrorMsg
    );
  };
  useEffect(() => {
    TenantConfigService.getTenantConfig(TENANT_CONFIG.CURRENCY_CODE).then(
      (response) => {
        const { result } = response.data;
        setCurrencyCode(result);
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isSubmitted) {
      handleValidate();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmitted]);

  useEffect(() => {
    if (projectId) {
      ProjectService.getProjectDetails(projectId).then((response) => {
        const { result } = response.data;
        if (result) {
          setLogo(result.logoUrl);
          setBanner(result.bannerUrl);
          setLogoUrl(result.logoUrl);
          setBannerUrl(result.bannerUrl);
          setValue("name", result.name);
          setValue("summary", result.summary);
          setValue("coBenefit", result.coBenefit);
          setValue("currentFundingAmount", result.currentFundingAmount);
          setValue("fundingGoal", result.fundingGoal);
          setValue("location", result.location);
          setValue("startDate", result.startDate);
          setValue("endDate", result.endDate);
          setImageUrls(result.albumUrls);
          setProtocolName(result.protocolFileName);
          setProtocol(result.protocolUrl);
          setProtocolUrl(result.protocolUrl);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId]);

  const isBase64Image = (str: string) => {
    return REGEX_BASE64_IMAGE.test(str);
  };

  const prepareFormData = (data: any) => {
    const formData = new FormData();
    formData.append("programId", programId);
    formData.append("name", data.name.trim());
    formData.append("summary", data.summary.trim());
    formData.append("location", data.location.trim());
    formData.append("fundingGoal", data.fundingGoal);
    formData.append("coBenefit", data.coBenefit.trim());
    formData.append("startDate", data.startDate);
    formData.append("endDate", data.endDate);
    return formData;
  };

  const appendImagesAndUrls = (formData: FormData) => {
    formData.append(
      "logoBase64",
      isBase64Image(logo) ? logo.replace(REGEX_BASE64_IMAGE, "") : ""
    );
    formData.append(
      "bannerBase64",
      isBase64Image(banner) ? banner.replace(REGEX_BASE64_IMAGE, "") : ""
    );
    formData.append("logoUrl", logoUrl);
    formData.append("bannerUrl", bannerUrl);
    formData.append(
      "protocolFile",
      typeof protocol !== "string" ? protocol : ""
    );
    projectPictures.files.forEach((file: File) => {
      formData.append("albumFiles", file);
    });
    formData.append("protocolFileName", protocolName);
    formData.append("protocolUrl", protocolUrl);
    const albumUrls = projectPictures.imageUrls.filter(
      (item: string) => !isBase64Image(item)
    );
    formData.append("albumUrls", albumUrls);
  };

  const handleResponse = (response: any, successMessage: string) => {
    if (isSuccess(response.data.code)) {
      closeModal(true, t(successMessage));
    } else {
      setShowModalError(true);
      setErrorMsg(response.data.message);
    }
    setSubmitted(false);
  };

  const onSubmit = (data: any) => {
    if (!submitted) {
      setSubmitted(true);
      const formData = prepareFormData(data);

      if (!!projectId) {
        appendImagesAndUrls(formData);
        ProjectService.editProject(formData, projectId).then(
          (response) =>
            handleResponse(response, "project.project-updated-successfully"),
          () => setSubmitted(false)
        );
      } else {
        formData.append("protocolFile", protocol ? protocol : "");
        formData.append("logoBase64", logo.replace(REGEX_BASE64_IMAGE, ""));
        formData.append("bannerBase64", banner.replace(REGEX_BASE64_IMAGE, ""));
        projectPictures.files.forEach((file: File) => {
          formData.append("albumFiles", file);
        });
        ProjectService.createProject(formData).then(
          (response) =>
            handleResponse(response, "project.project-created-successfully"),
          () => setSubmitted(false)
        );
      }
    }
  };

  const showError = () => {
    const modal = {
      content: errorMsg,
      confirmText: t("common.ok"),
    } as PopupProps;
    return (
      <Popup
        {...modal}
        showPopup={showModalError}
        onChangeShowPopup={(e) => setShowModalError(e)}
      ></Popup>
    );
  };

  const singleDatePickerProps = {
    calendarIcon: "/icons/calendar.svg",
    calendarIconDisabled: "/icons/calendar-disabled.svg",
    arrowUp: "/icons/mini-arrow-up.svg",
    arrowDown: "/icons/mini-arrow.svg",
    arrowLeft: "/icons/chevron-left.svg",
    arrowRight: "/icons/chevron-right.svg",
  };

  const getTimestamp = (date: string) => {
    const parsedDate = parse(date, "MM/dd/yyyy", new Date());
    const timestamp = getTime(parsedDate);
    return timestamp;
  };

  const stripTime = (date: Date) => {
    return new Date(date.getFullYear(), date.getMonth(), date.getDate());
  };

  const startDate = useRef({});
  const endDate = useRef({});
  startDate.current = watch("startDate");
  endDate.current = watch("endDate");
  const [endDateErrorMsg, setEndDateErrorMsg] = useState("");
  useEffect(() => {
    if (isSubmitted) {
      if (
        stripTime(new Date(getValues("endDate"))) <
        stripTime(new Date(getValues("startDate")))
      ) {
        setEndDateErrorMsg(
          t("validate.standard093", {
            endField: t("project.end-date"),
            startField: t("project.start-date"),
          })
        );
        return;
      }
      if (stripTime(new Date(getValues("endDate"))) < stripTime(new Date())) {
        setEndDateErrorMsg(t("validate.standard094"));
        return;
      }
      setEndDateErrorMsg("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate.current, endDate.current, isSubmitted]);

  const form = () => {
    return (
      <Box paddingX="24px" paddingY="24px" marginBottom="64px" {...gap16}>
        <Box {...gap6Style}>
          <Text {...textStyle(theme)}>{t("project.project-name")}</Text>
          <Controller
            name="name"
            control={control}
            rules={{
              required: true,
            }}
            render={({ field }) => (
              <AppInput
                defaultValue={getValues("name")}
                placeholder={t("project.enter-project-name")}
                maxLength={250}
                onChangeText={(text: string) => {
                  field.onChange(text);
                }}
                isInvalid={errors.name?.type === "required"}
              />
            )}
          />
          {errors.name?.type === "required" && (
            <Text {...errorStyle(theme)}>
              {t("validate.standard070", { field: t("project.project-name") })}
            </Text>
          )}
        </Box>
        <Box flexDirection="row" {...gap16}>
          <div style={width50}>
            <Text {...textStyle(theme)}>{t("project.project-owner")}</Text>
            <AppInput
              isDisabled={true}
              defaultValue={selectedProgramOwner.ownerName}
            />
          </div>
          <div style={{ ...width50, position: "relative" }}>
            <Text {...textStyle(theme)}>{t("project.funding-goal")}</Text>
            <Controller
              name="fundingGoal"
              control={control}
              rules={{
                required: true,
              }}
              render={({ field }) => (
                <InputMoney
                  prefix={""}
                  defaultValue={getValues("fundingGoal")}
                  currencyCode={currencyCode}
                  thousandSeparator={true}
                  placeholder={t("project.enter-gold")}
                  isInvalid={errors.fundingGoal?.type === "required"}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  onChange={(text: string) => {
                    field.onChange(text);
                  }}
                />
              )}
            />
            {errors.fundingGoal?.type === "required" && (
              <Text {...errorStyle(theme)}>
                {t("validate.standard070", {
                  field: t("project.funding-goal"),
                })}
              </Text>
            )}
          </div>
        </Box>
        <Box flexDirection="row" {...gap16}>
          <div style={width50}>
            <Text {...textStyle(theme)}>{t("project.start-date")}</Text>
            {!isHideDatepicker && (
              <Controller
                name="startDate"
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field }) => (
                  <SingleDatePicker
                    {...singleDatePickerProps}
                    initDate={
                      getValues("startDate")
                        ? format(new Date(getValues("startDate")), "dd/MM/yyyy")
                        : ""
                    }
                    isInvalid={errors.startDate?.type === "required"}
                    onChangeDateEvent={(date: string) => {
                      const timestamp = getTimestamp(date);
                      field.onChange(timestamp);
                    }}
                  />
                )}
              />
            )}
            {errors.startDate?.type === "required" && (
              <Text {...errorStyle(theme)}>
                {t("validate.standard070", { field: t("project.start-date") })}
              </Text>
            )}
          </div>
          <div style={width50}>
            <Text {...textStyle(theme)}>{t("project.end-date")}</Text>
            <Controller
              name="endDate"
              control={control}
              render={({ field }) => (
                <SingleDatePicker
                  {...singleDatePickerProps}
                  initDate={
                    getValues("endDate")
                      ? format(new Date(getValues("endDate")), "dd/MM/yyyy")
                      : ""
                  }
                  isInvalid={!!endDateErrorMsg}
                  onChangeDateEvent={(date: string) => {
                    const timestamp = getTimestamp(date);
                    field.onChange(timestamp);
                  }}
                />
              )}
            />
            {endDateErrorMsg && (
              <Text {...errorStyle(theme)}>{endDateErrorMsg}</Text>
            )}
          </div>
        </Box>
        <Box {...gap6Style}>
          <Text {...textStyle(theme)}>{t("project.location")}</Text>
          <Controller
            name="location"
            control={control}
            rules={{
              required: true,
            }}
            render={({ field }) => (
              <AppInput
                defaultValue={getValues("location")}
                placeholder={t("project.enter-location")}
                maxLength={250}
                onChangeText={(text: string) => {
                  field.onChange(text);
                }}
                isInvalid={errors.location?.type === "required"}
              />
            )}
          />
          {errors.location?.type === "required" && (
            <Text {...errorStyle(theme)}>
              {t("validate.standard070", { field: t("project.location") })}
            </Text>
          )}
        </Box>
        <Box {...gap6Style}>
          <Text {...textStyle(theme)}>{t("programs.summary")}</Text>
          <Controller
            name="summary"
            control={control}
            rules={{
              required: true,
            }}
            render={({ field }) => (
              <Textarea
                defaultValue={getValues("summary")}
                placeholder={t("programs.enter-summary")}
                hasError={errors.summary?.type === "required"}
                onValueChange={(e) => {
                  field.onChange(e);
                }}
              />
            )}
          />
          {errors.summary?.type === "required" && (
            <Text {...errorStyle(theme)}>
              {t("validate.standard070", { field: t("programs.summary") })}
            </Text>
          )}
        </Box>
        <Box {...gap6Style}>
          <Text {...textStyle(theme)}>{t("project.co-benefits")}</Text>
          <Controller
            name="coBenefit"
            control={control}
            rules={{
              required: true,
            }}
            render={({ field }) => (
              <Textarea
                defaultValue={getValues("coBenefit")}
                placeholder={t("project.enter-co-benefits")}
                hasError={errors.coBenefit?.type === "required"}
                onValueChange={(e) => {
                  field.onChange(e);
                }}
              />
            )}
          />
          {errors.coBenefit?.type === "required" && (
            <Text {...errorStyle(theme)}>
              {t("validate.standard070", { field: t("project.co-benefits") })}
            </Text>
          )}
        </Box>
        <Box flexDirection="row" {...gap24}>
          <Box {...gap6Style}>
            <Text {...textStyle(theme)}>{t("programs.logo-image")}</Text>
            <ImageCropper
              width={200}
              height={200}
              allowedTypes={["image/jpeg", "image/png", "image/jpg"]}
              dropLabel={t("programs.crop")}
              uploadLabel={t("programs.upload")}
              btnCancelLabel={t("lead-invitation.cancel")}
              btnDropLabel={t("programs.crop-and-save")}
              minCroppedWidth={428}
              minCroppedHeight={246}
              maxCroppedWidth={1284}
              maxCroppedHeight={738}
              errorMsg={{
                standard008: t("validate.standard008", {
                  maxDimension: "1284x738px",
                }),
                standard067: t("validate.standard067", {
                  minDimension: "428x246px",
                }),
                standard070: t("validate.standard070", {
                  field: t("programs.logo-image"),
                }),
                standard081: t("validate.standard081", { maxSize: "5MB" }),
                standard091: t("validate.standard091"),
              }}
              defaultErrMsg={logoErrMsg}
              maxSize={5}
              uploadIcon={"/icons/plus.svg"}
              closePopupIcon={"/icons/close.svg"}
              closeIcon={"/icons/close-image.png"}
              aspectRatio={1}
              defaultImageUrl={logo}
              onValueChange={(base64) => {
                setLogo(base64);
              }}
            />
          </Box>
          <Box {...gap6Style}>
            <Text {...textStyle(theme)}>{t("programs.banner-image")}</Text>
            <ImageCropper
              width={320}
              height={200}
              allowedTypes={["image/jpeg", "image/png", "image/jpg"]}
              dropLabel={t("programs.crop")}
              uploadLabel={t("programs.upload")}
              btnCancelLabel={t("lead-invitation.cancel")}
              btnDropLabel={t("programs.crop-and-save")}
              minCroppedWidth={428}
              minCroppedHeight={246}
              maxCroppedWidth={1284}
              maxCroppedHeight={738}
              errorMsg={{
                standard008: t("validate.standard008", {
                  maxDimension: "1284x738px",
                }),
                standard067: t("validate.standard067", {
                  minDimension: "428x246px",
                }),
                standard070: t("validate.standard070", {
                  field: t("programs.logo-image"),
                }),
                standard081: t("validate.standard081", { maxSize: "5MB" }),
                standard091: t("validate.standard091"),
              }}
              maxSize={5}
              uploadIcon={"/icons/plus.svg"}
              closeIcon={"/icons/close-image.png"}
              closePopupIcon={"/icons/close.svg"}
              defaultErrMsg={bannerErrMsg}
              aspectRatio={16 / 9}
              defaultImageUrl={banner}
              onValueChange={(base64) => {
                setBanner(base64);
              }}
            />
          </Box>
        </Box>
        <Box {...gap6Style}>
          <Box flexDirection="row" alignItems="center">
            <Text {...textStyle(theme)}>{t("project.project-pictures")}</Text>
            <Text>
              {" "}
              ({`${projectPictures?.imageUrls?.length || "0"}/${maxFiles}`})
            </Text>
          </Box>
          <MultipleImageUploader
            width={160}
            height={120}
            maxCroppedWidth={1920}
            maxCroppedHeight={1080}
            errorMsg={{
              standard008: t("validate.standard008", {
                maxDimension: "1920x1080px",
              }),
              standard081: t("validate.standard081", { maxSize: "5MB" }),
              standard082: t("validate.standard082"),
              standard091: t("validate.standard091"),
            }}
            maxSize={5}
            maxFiles={10}
            uploadLabel={"Upload"}
            uploadIcon={"/icons/plus.svg"}
            closeIcon={"/icons/close-image.png"}
            allowedTypes={["image/jpeg", "image/png", "image/jpg"]}
            defaultImageUrls={imageUrls}
            onValueChange={(data) => {
              setProjectPictures(data);
            }}
          />
        </Box>
        <Box {...gap6Style}>
          <Text {...textStyle(theme)}>{t("programs.protocol")}</Text>
          <UploadFile
            allowedTypes={["application/pdf"]}
            uploadIcon={"/icons/download-icon.svg"}
            closeIcon={"/icons/close.svg"}
            label={t("create-bulk-leads.uploadFileText")}
            maxSize={5}
            defaultErrMsg={protocolErrMsg}
            description={t("programs.upload-pdf-max-size")}
            errorMsg={{
              standard070: t("validate.standard070", {
                field: t("programs.protocol"),
              }),
              standard081: t("validate.standard081", { maxSize: "5MB" }),
              standard091: t("validate.standard091"),
            }}
            defaultFileName={protocolName}
            onValueChange={(file: File | null) => {
              setProtocol(file);
            }}
          />
        </Box>
      </Box>
    );
  };

  return (
    <>
      {showError()}
      <Box height="100vh" overflow="auto" position="relative">
        <Box {...wrapperStyle(theme)}>
          <Text {...titleStyle(theme)}>{t("project.title")}</Text>
          <Pressable onPress={() => closeModal(false)}>
            <Image
              source={{ uri: "/icons/close.svg" }}
              w={6}
              h={6}
              alt="close"
            />
          </Pressable>
        </Box>
        {form()}
        <Box>
          <Box {...footerStyle(theme)}>
            <Box>
              <AppButton
                label={
                  projectId
                    ? t("ticket-form.btn-submit-edit")
                    : t("ticket-form.btn-submit-create")
                }
                onPress={handleSubmit(onSubmit)}
              />
            </Box>
            <Box>
              <AppButton
                variant="secondary"
                label={t("ticket-form.alert-cancel")}
                onPress={() => closeModal(false)}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default ProjectForm;
