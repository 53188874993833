import { useCallback, useState } from "react";
import { Box } from "shared-components";
import { format } from "date-fns";
import { useTranslation } from "react-i18next";
import ProspectService from "../../services/prospect/prospectService";
import { Modal, Pressable, Text, useTheme } from "native-base";
import CallService from "../../services/call/callService";
import { ResourceType } from "../../constants/resourceType";
import { showNotificationForVoiso } from "../../services/toast/toastService";
import CoreTable from "../core-table/CoreTable";
import { Header, TableAction } from "../../types/table";
import {
  dotStyle,
  filterStyle,
  phoneNumberStyle,
  textStyle,
} from "../lead-listing/LeadListing.style";
import { ReactSVG } from "react-svg";
import { handleFiltersProspectListing } from "../../utils/filters";
import ProspectListingFilter from "../prospect-listing-filter/ProspectListingFilter";
import { useNavigate } from "react-router-dom";
import { PERMISSIONS } from "../../constants/permisstion";
import { LeadConstants } from "../../constants/lead";

const AppProspect = () => {
  const { t } = useTranslation();
  const FORMAT_DATE = "MM/dd/yyyy";
  const [isOpenFilter, setIsOpenFilter] = useState(false);
  const [selectedAssignee, setSelectedAssignee] = useState();
  const [filters, setFilters] = useState({
    status: "",
    createdDate: {
      from: "",
      to: "",
    },
    assigneeId: "",
  });
  const [isFilterUsed, setIsFilterUsed] = useState(false);
  const navigate = useNavigate();

  const callVoiso = (prospectPhoneNumber: string, item: any) => {
    CallService.callVoiso(
      prospectPhoneNumber,
      ResourceType.PROSPECT,
      item.prospectId,
    ).catch((reason) => {
      if (reason.response.data.code === "1010022") {
        showNotificationForVoiso(t);
      }
    });
  };

  const formatDate = (timestamp: number) => {
    const date = new Date(timestamp);
    return <Text {...textStyle(theme)}>{format(date, FORMAT_DATE)}</Text>;
  };

  const filterProps = {
    statusOptions: [
      {
        label: "ALL",
        value: t("ticket-listing-filter.status-options.all"),
      },
      {
        label: "NEW",
        value: t("ticket-listing-filter.status-options.new"),
      },
      {
        label: "NO_ANSWER_1_3",
        value: t("lead-listing.filter.no-answer"),
      },
      {
        label: "CALL_AGAIN",
        value: t("lead-listing.filter.call-again"),
      },
      {
        label: "NO_FOUND",
        value: t("lead-listing.filter.no-found"),
      },
      {
        label: "UNDER_18",
        value: t("lead-listing.filter.under-18"),
      },
    ],
    textObject: {
      filter: t("prospect-listing-filter.title"),
      status: t("ticket-listing-filter.status"),
      registrationType: t("prospect-listing-filter.registration-type"),
      apply: t("ticket-listing-filter.apply"),
      clearAll: t("ticket-listing-filter.clear-all"),
      createdDate: t("ticket-listing-filter.created-date"),
      invalidFormatErrMsg: t("errors.standard002", {
        fieldName: t("ticket-listing-filter.created-date"),
      }),
      earlierErrMsg: t("errors.standard093"),
      selectedFromErrMsg: t("errors.standard092", {
        fieldName: t("ticket-listing-filter.start-date"),
      }),
      selectedToErrMsg: t("errors.standard092", {
        fieldName: t("ticket-listing-filter.end-date"),
      }),
      selectedRangeOverErrMsg: t("errors.standard094"),
      assignee: t("prospect-listing.header-column.assignee"),
    },
    dropDownIcon: "/icons/arrow-down.svg",
    selectedIcon: "/icons/checked.svg",
    closeFilterIcon: "/icons/filter-close.svg",
    arrowIcon: "/icons/arrow-long.svg",
    calendarIcon: "/icons/calendar.svg",
    arrowUp: "/icons/mini-arrow-up.svg",
    arrowDown: "/icons/mini-arrow.svg",
    arrowLeft: "/icons/chevron-left.svg",
    arrowRight: "/icons/chevron-right.svg",
    onFiltersChanged: (filtersChanged: any) => {
      setFilters(filtersChanged);
      setIsOpenFilter(false);
      setIsFilterUsed(filters !== filtersChanged);
    },
  };

  const theme = useTheme();

  const formatPhoneNumber = (phoneNumber: string, item: any) => {
    return (
      <Pressable onPress={() => callVoiso(phoneNumber, item)}>
        <Text {...phoneNumberStyle(theme)}>{phoneNumber}</Text>
      </Pressable>
    );
  };

  const formatStatus = (statusValue?: string) => {
    const status = LeadConstants.status.find((st) => st.label === statusValue);
    return status ? <Text {...textStyle(theme)}>{t(status.value)}</Text> : "";
  };

  const headers: Header[] = [
    {
      value: "prospectName",
      label: t("prospect-listing.header-column.prospect-name"),
      width: 246,
    },
    {
      value: "prospectPhoneNumber",
      label: t("prospect-listing.header-column.prospect-phone-number"),
      format: formatPhoneNumber,
      width: 246,
      permissions: [PERMISSIONS.USERS_ROLES.SENSITIVE_DATA.VIEW_SENSITIVE_DATA],
    },
    {
      value: "prospectEmail",
      label: t("prospect-listing.header-column.prospect-email"),
      width: 246,
      permissions: [PERMISSIONS.USERS_ROLES.SENSITIVE_DATA.VIEW_SENSITIVE_DATA],
    },
    {
      value: "userEmail",
      label: t("prospect-listing.header-column.user-email"),
      width: 246,
      permissions: [PERMISSIONS.USERS_ROLES.SENSITIVE_DATA.VIEW_SENSITIVE_DATA],
    },
    {
      value: "assigneeName",
      label: t("ticket-listing.header-column.assignee"),
      width: 237,
    },
    {
      value: "status",
      label: t("lead-listing.header-column.status"),
      width: 164,
      format: formatStatus,
    },
    {
      value: "createdDate",
      label: t("prospect-listing.header-column.created-date"),
      width: 164,
      format: formatDate,
    },
    {
      value: "action",
      label: t("ticket-listing.header-column.action"),
      type: "ACTION",
      width: 100,
    },
  ];

  const actionRow = () => {
    return (
      <>
        <Box>
          <Pressable
            position="relative"
            {...filterStyle(theme)}
            onPress={() => {
              setIsOpenFilter(true);
            }}
          >
            <ReactSVG src="/icons/filter-icon.svg" />
            {isFilterUsed ? (
              <Box {...dotStyle(theme)} position="absolute" />
            ) : undefined}
          </Pressable>
        </Box>
      </>
    );
  };

  const fetchDatas = useCallback((params: any) => {
    return ProspectService.getProspectListing(params);
  }, []);

  const processedFilters = handleFiltersProspectListing(filters);

  const actions: TableAction[] = [
    {
      icon: "/icons/edit.svg",
      label: t("common.edit"),
      value: "EDIT",
      permissions: [PERMISSIONS.CONTACTS.PROSPECT.EDIT],
    },
  ];

  const handleAction = (code: string, data: any) => {
    switch (code) {
      case "EDIT":
        navigateProspectDetail(data);
        break;
      default:
        break;
    }
  };

  const navigateProspectDetail = (prospect: any) => {
    navigate(`/prospect/edit/${prospect.prospectId}`);
  };

  return (
    <Box>
      <Modal isOpen={isOpenFilter} onClose={() => setIsOpenFilter(false)}>
        <Modal.Content
          maxHeight="100vh"
          marginLeft="auto"
          marginRight="0"
          maxWidth="460"
          borderRadius="0"
        >
          {isOpenFilter ? (
            <ProspectListingFilter
              {...filterProps}
              initFilters={filters}
              selectedAssignee={selectedAssignee}
              setSelectedAssignee={setSelectedAssignee}
              onCloseFilter={() => setIsOpenFilter(false)}
            />
          ) : undefined}
        </Modal.Content>
      </Modal>
      <CoreTable
        headers={headers}
        filters={filters}
        processedFilters={processedFilters}
        fetchDatas={fetchDatas}
        onClickAction={(code, data) => handleAction(code, data)}
        actions={actions}
        actionRow={actionRow()}
        isBorder={true}
        searchTooltip={t("prospect-listing.search-tooltip")}
        title={t("prospect-listing.title")}
        isFilterUsed={isFilterUsed}
        setIsFilterUsed={setIsFilterUsed}
      />
    </Box>
  );
};

export default AppProspect;
