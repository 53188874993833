import ApiClient from "../api-client/apiClient";
import Api from "../../configs/api";
import { resolveTenant } from "../../tenantResolver";

const getAuthHeaders = () => {
  const tenantConfig = resolveTenant();
  const authData = JSON.parse(
    localStorage.getItem(
      `oidc.at:${tenantConfig.oidcConfiguration.authority}`
    ) ?? "{}"
  );
  return {
    token: authData.access_token,
  };
};
export const ProfileService = {
  getUserProfile: (token: string) => {
    return ApiClient().get(Api.USER_PROFILE, {
      headers: {
        token: token,
      },
    });
  },

  getUserProfileListing: (data: any) => {
    return ApiClient().post(Api.GET_USER_PROFILE_LISTING, data);
  },
  getTenantUserProfileListing: (data: any) => {
    return ApiClient().post(Api.GET_TENANT_USER_PROFILE_LISTING, data);
  },

  getProfileByPhoneNumber: (phoneNumber: string) => {
    const data = {
      phoneNumber,
    };
    const headers = getAuthHeaders();
    return ApiClient(headers).post(Api.GET_PROFILE_BY_PHONE_NUMBER, data);
  },
  getUserProfileByUserType: (data: any) => {
    const headers = getAuthHeaders();
    return ApiClient(headers).post(Api.GET_USER_PROFOLE_BY_USER_TYPE, data);
  },

};

export default ProfileService;
