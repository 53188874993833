import { Box } from "shared-components";
import ProgramListing from "../../components/program-listing/ProgramListing";
const Programs = () => {
  return (
    <Box>
      <ProgramListing></ProgramListing>
    </Box>
  );
};

export default Programs;
