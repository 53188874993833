import ApiClient from "../api-client/apiClient";
import Api from "../../configs/api";

const ProjectService = {
  getProjectListing: (params: any) => {
    const url = `${Api.PROJECT}${Api.LISTING}?programId=${params.programId}&pageIndex=${params.pageIndex}&pageSize=${params.pageSize}`;
    return ApiClient().get(url);
  },
  getProjectDetails: (id: string) => {
    const url = `${Api.PROJECT}/${id}`;
    return ApiClient().get(url);
  },
  createProject: (body: any) => {
    return ApiClient().post(Api.PROJECT, body);
  },
  editProject: (body: any, id: string) => {
    const url = `${Api.PROJECT}/${id}`;
    return ApiClient().put(url, body);
  },
  updateStatusProject: (status: string, id: string) => {
    const url = `${Api.PROJECT}/status/${id}?status=${status}`;
    return ApiClient().put(url);
  },
};

export default ProjectService;
