import ApiClient from "../api-client/apiClient";
import Api from "../../configs/api";

export const CountryService = {
  list: (data: any) => {
    const api = `${Api.GET_COUNTRY}?pageIndex=${data.pageIndex}&pageSize=${data.pageSize}&keyword=${data.keyword ? data.keyword : ""}&orderBy=${data.orderBy}&orderDirection=${data.orderDirection}`;
    return ApiClient().get(api);
  },
};

export default CountryService;
