import { Box } from "shared-components";
import AppTicket from "../../components/ticket/Ticket";

const TicketListing = () => {
  return (
    <Box>
      <AppTicket></AppTicket>
    </Box>
  );
};
export default TicketListing;
