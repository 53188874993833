import { useEffect, useMemo, useState } from "react";
import {
  AppButton,
  Box,
  RoleForm,
  Flex,
  Popup,
  Pressable,
  Text,
  ToastMessage,
} from "shared-components";
import { useTheme, useToast } from "native-base";
import { ReactSVG } from "react-svg";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { ToastProps } from "shared-components/lib/typescript/src/types/toastProps";
import { PopupProps } from "shared-components/lib/typescript/src/ui-components/custom-components/popup/Popup";
import { textHeader24 } from "../../styles/text";
import RoleService from "../../services/role/roleService";
import isEqual from "lodash/isEqual";

interface RoleDTO {
  name: string;
  permissions: number[];
}

interface ErrorMessage {
  name?: string;
  permissions?: string;
}

const AppRoleForm = () => {
  const theme = useTheme();
  const toast = useToast();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { roleId } = useParams();
  const back = () => {
    navigate("/users-roles", { replace: true, state: { activeTab: "ROLE" } });
  };
  const [permissionGroups, setPermissionGroups] = useState<any[]>([]);
  const [roleForm, setRoleForm] = useState<RoleDTO>({
    name: "",
    permissions: [],
  });
  const [initRoleForm, setInitRoleForm] = useState<RoleDTO>({
    name: "",
    permissions: [],
  });
  const [errorMsg, setErrorMsg] = useState<ErrorMessage>({
    name: "",
    permissions: "",
  });
  const [showAlertBack, setShowAlertBack] = useState(false);

  const isEdit = useMemo(() => {
    return roleId;
  }, [roleId]);

  useEffect(() => {
    RoleService.getAllPermissions().then((res) => {
      setPermissionGroups(
        res.data?.map((item: any) => {
          return {
            name: item?.name,
            permissions: item?.permissions?.map((permission: any) => ({
              name: permission?.name,
              code: permission?.code,
              dependsOn: permission?.dependOn,
            })),
          };
        }),
      );
    });
  }, []);

  useEffect(() => {
    if (roleId) {
      RoleService.getRoleDetails(roleId)
        .then((res) => {
          if (res.data) {
            setInitRoleForm({
              name: res.data?.name,
              permissions: res.data?.permissions,
            });
          }
        })
        .catch(() => {});
    }
  }, [roleId]);

  const onSubmit = async () => {
    if (validate(roleForm)) {
      if (isEdit) {
        RoleService.editRoleDetails(roleId as any, roleForm)
          .then(() => {
            const toastData = {
              message: t("role-form.msg-edit-success"),
              icon: "/icons/toast/check.svg",
            };
            showToast(toast, toastData);
            back();
          })
          .catch(() => {});
      } else {
        RoleService.createRole(roleForm)
          .then(() => {
            const toastData = {
              message: t("role-form.msg-create-success"),
              icon: "/icons/toast/check.svg",
            };
            showToast(toast, toastData);
            back();
          })
          .catch(() => {});
      }
    }
  };

  const closeAll = () => {
    toast.closeAll();
  };

  const handleErrorMessageState = (key: string, message: string) => {
    setErrorMsg((prevState) => ({
      ...prevState,
      [key]: message,
    }));
  };

  const template = (args: ToastProps) => (
    <Box marginRight="32px" marginTop="14px">
      <ToastMessage
        message={args.message}
        icon={args.icon}
        title={args.title}
        onClose={closeAll}
      />
    </Box>
  );

  const showToast = (toast: any, args: ToastProps) => {
    toast.show({
      render: () => {
        return template(args);
      },
      placement: "top-right",
    });
  };

  const showAlert = () => {
    const modal = {
      content: t("role-form.alert-content"),
      typeButton: "danger",
      confirmText: t("role-form.alert-exit"),
      cancelText: t("role-form.alert-cancel"),
    } as PopupProps;
    return (
      <Popup
        {...modal}
        showPopup={showAlertBack}
        onChangeShowPopup={(e) => setShowAlertBack(e)}
        confirmAction={back}
      />
    );
  };

  const handleBack = () => {
    if (
      !isEdit &&
      ((roleForm.name && roleForm.name !== "") ||
        roleForm.permissions?.length > 0)
    ) {
      setShowAlertBack(true);
    } else if (isEdit && !isEqual(roleForm, initRoleForm)) {
      setShowAlertBack(true);
    } else {
      back();
    }
  };

  const header = () => {
    return (
      <Flex
        direction="row"
        justify="space-between"
        height="64px"
        alignItems="center"
      >
        <Flex direction="row" align="center">
          <Box height="24px" mr={3}>
            <Pressable
              testID={"back-btn"}
              onPress={() => {
                handleBack();
              }}
            >
              <ReactSVG src="/icons/back.svg" />
            </Pressable>
          </Box>
          <Text {...textHeader24(theme)}>
            {isEdit ? t("role-form.title.edit") : t("role-form.title.create")}
          </Text>
        </Flex>
        <Box>
          <AppButton
            label={
              isEdit
                ? t("role-form.btn-submit-edit")
                : t("role-form.btn-submit-create")
            }
            onPress={onSubmit}
          />
        </Box>
      </Flex>
    );
  };

  const defaultRoleForm = useMemo(() => {
    return {
      data: {
        permissionGroups: permissionGroups,
      },
      initData: initRoleForm,
      labels: {
        name: t("role-form.label.name"),
        fullPermissions: t("role-form.full-permissions"),
        permissions: t("role-form.label.permissions"),
      },
      placeholder: {
        name: t("role-form.placeholder.name"),
      },
      errors: errorMsg,
      clearIcon: "/icons/close-icon.svg",
      deleteIcon: "/icons/close.svg",
      noDataIcon: "/icons/no-data.svg",
      onChangeData: (data?: any) => {
        setErrorMsg({ name: "", permissions: "" });
        setRoleForm(data);
      },
    } as any;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initRoleForm, permissionGroups, errorMsg]);

  const validate = (form: RoleDTO) => {
    let isValid = true;
    if (form.name === "") {
      isValid = false;
      handleErrorMessageState(
        "name",
        t("errors.standard001", {
          fieldName: t("role-form.label.name").toLowerCase(),
        }),
      );
    } else if (form.permissions.length === 0) {
      isValid = false;
      handleErrorMessageState(
        "permissions",
        t("errors.standard001", {
          fieldName: t("role-form.label.permissions"),
        }).toLowerCase(),
      );
    }
    return isValid;
  };

  return (
    <Box marginTop="-24px">
      {showAlert()}
      {header()}
      <RoleForm {...defaultRoleForm} />
    </Box>
  );
};

export default AppRoleForm;
