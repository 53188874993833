import React, { Fragment } from "react";
import { ActivityLog, Box } from "shared-components";
import { ActivityChips } from "shared-components/lib/typescript/src/types/activityLogProps";

export interface AppActivityLogProps {
  showText: string;
  textHeaderLabel?: string;
  activityChips: ActivityChips[];
}

const AppActivityLog: React.FC<AppActivityLogProps> = ({
  textHeaderLabel,
  activityChips,
  showText,
}) => {
  return (
    <>
      <ActivityLog
        textHeaderLabel={textHeaderLabel}
        activityChips={activityChips}
        showText={showText}
      />
      <Box marginBottom={"16px"}></Box>
      {activityChips.map((value) => {
        if (value.isActive) {
          return <Fragment key={`${value?.label}`}>{value?.children}</Fragment>;
        }
        return <Fragment key={`${value?.label}`}></Fragment>;
      })}
    </>
  );
};

export default AppActivityLog;
