import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ProfileService from "../../services/profile/profileService";
import { TicketPropertiesDTO } from "shared-components/src/models/ticketPropertiesDTO";
import {
  CsTicketOption,
  Error,
} from "shared-components/src/types/ticketPropertiesProps";
import { UserProfileModel } from "../../components/user-profile-listing/UserProfileListing";
import { CsTicketConstants } from "../../constants/csTicket";
import { SingleDatePicker } from "shared-components/lib/typescript/src/types/ticketPropertiesProps";
import TicketPropertiesShared from "../ticket-properties-shared/TicketPropertiesShared";
import { ResourceType } from "../../constants/resourceType";

export interface AppTicketPropertiesProps {
  ticketProperties: TicketPropertiesDTO;
  errors?: Error[];
  validateForm?: boolean;
  onSelected?: (ticketProperties: TicketPropertiesDTO) => any;
  singleDatePicker: SingleDatePicker;
}

const AppTicketProperties: React.FC<AppTicketPropertiesProps> = ({
  errors,
  validateForm,
  onSelected,
  ticketProperties,
  singleDatePicker,
}) => {
  const [assigneeData, setAssigneeData] = useState<any>({
    options: [],
    text: "fullName",
    value: "email",
  });
  const { t } = useTranslation();
  const ticketPropertiesLabel = {
    status: {
      textLabel: t("ticket-properties.status.label"),
      textPlaceHolder: t("ticket-properties.status.placeholder"),
    },
    category: {
      textLabel: t("ticket-properties.category.label"),
      textPlaceHolder: t("ticket-properties.category.placeholder"),
    },
    subCategory: {
      textLabel: t("ticket-properties.subCategory.label"),
      textPlaceHolder: t("ticket-properties.subCategory.placeholder"),
    },
    assignee: {
      textLabel: t("ticket-properties.assignee.label"),
      textPlaceHolder: t("ticket-properties.assignee.placeholder"),
    },
    source: {
      textLabel: t("ticket-properties.source.label"),
      textPlaceHolder: t("ticket-properties.source.placeholder"),
    },
    targetDate: t("ticket-properties.targetDate.label"),
  };

  const csTicketConstants = {
    statusOptions: CsTicketConstants.statusOptions.map(
      (option: CsTicketOption) => ({
        label: option.label,
        value: t(option.value),
      }),
    ),
    categoryOptions: CsTicketConstants.categories.map(
      (option: CsTicketOption) => ({
        label: option.label,
        value: t(option.value),
        sub: option.sub?.map((subOption: CsTicketOption) => ({
          label: subOption.label,
          value: t(subOption.value),
        })),
      }),
    ),
    subCategoryOptions: CsTicketConstants.subCategoryOptions.map(
      (option: CsTicketOption) => ({
        label: option.label,
        value: t(option.value),
      }),
    ),
    sourceOptions: CsTicketConstants.sourceOptions.map(
      (option: CsTicketOption) => ({
        label: option.label,
        value: t(option.value),
      }),
    ),
  };
  useEffect(() => {
    const params = {
      orderBy: "fullName",
      orderDirection: "ASC",
      status: "ACTIVE",
      resourceType: ResourceType.TICKET.toUpperCase(),
    };
    ProfileService.getTenantUserProfileListing(params).then((response) => {
      const { result } = response.data;
      if (result.data?.length > 0) {
        const assigneeOptions = result.data.map((item: UserProfileModel) => ({
          email: item.email,
          fullName: item.fullName,
        }));
        setAssigneeData((prevState: any) => ({
          ...prevState,
          options: assigneeOptions,
        }));
      }
    });
  }, []);
  return (
    <TicketPropertiesShared
      singleDatePicker={singleDatePicker}
      textHeaderLabel={t("ticket-properties.title")}
      optionalText={t("ticket-properties.optional-text")}
      ticketPropertiesLabel={ticketPropertiesLabel}
      ticketProperties={ticketProperties}
      assigneeData={assigneeData}
      errors={errors}
      validateForm={validateForm}
      action={onSelected}
      csTicketConstants={csTicketConstants}
    ></TicketPropertiesShared>
  );
};

export default AppTicketProperties;
