import React, { useState } from "react";
import { Box, Text, useTheme } from "native-base";
import _ from "lodash";
import moment from "moment/moment";
import { errorText, gap16, gap6, textHeader1 } from "../../styles/text";
import { DropDown, DatePicker, AppInput } from "shared-components";
import { OptionItem } from "../../types/dropdownProps";
import FilterForm from "../filter-form/FilterForm";
import { FilterDateDTO } from "../../types/ticketListingFilterDTO";
import {
  boxPropertyInsideStyle,
  boxPropertyStyle,
  textPropertyStyle,
} from "../ticket-listing-filter/TicketListingFilter.style";
import { useTranslation } from "react-i18next";
import { ResourceType } from "../../constants/resourceType";
import {
  MerchantListingFilterDTO,
  MerchantListingFilterProps,
} from "../../types/merchantListingFilterProps";
import CountryDropdown from "../country-dropdown/CountryDropdown";
import BusinessCategory from "../business-category/BusinessCategory";

const MerchantListingFilter: React.FC<MerchantListingFilterProps> = ({
  initFilters,
  onFiltersChanged,
  dropDownIcon,
  selectedIcon,
  arrowIcon,
  calendarIcon,
  arrowUp,
  arrowDown,
  arrowLeft,
  arrowRight,
  statusOptions,
  currentPlanOptions,
  textObject,
  onCloseFilter,
  closeFilterIcon,
  selectedCountry,
  setSelectedCountry,
  selectedBusinessCategory,
  setSelectedBusinessCategory,
}) => {
  const theme = useTheme();
  const [filters, setFilters] = useState<MerchantListingFilterDTO>(initFilters);
  const [errorDateMessage, setErrorDateMessage] = useState<string>("");
  const filterChanged = (valueObject: any, key: string) => {
    let filtersTemp = _.cloneDeep(filters);
    if (key === "expiryDate") {
      setErrorDateMessage("");
      filtersTemp[key] = valueObject;
    } else {
      filtersTemp = {
        ...filters,
        [key]: valueObject.label === "ALL" ? "" : valueObject.label,
      };
    }
    if (typeof valueObject === "string") {
      filtersTemp = {
        ...filters,
        [key]: valueObject,
      };
    }
    setFilters(filtersTemp);
  };

  const clearAll = () => {
    const filtersTemp = {
      businessCategory: "",
      status: "",
      currentPlan: "",
      expiryDate: {
        from: "",
        to: "",
      },
      countryCode: "",
      businessName: "",
      email: "",
    };
    setFilters(filtersTemp);
    setErrorDateMessage("");
  };

  const onApplyFilter = () => {
    if (validateDateRange()) {
      onFiltersChanged(filters);
    }
  };

  const validateDateRange = () => {
    setErrorDateMessage("");
    const { from, to } = filters.expiryDate;
    if (!from && !to) {
      return true;
    }

    const format = "MM/DD/YYYY";
    const momentDate1 = moment(from, format);
    const momentDate2 = moment(to, format);
    if (momentDate1.isAfter(momentDate2)) {
      setErrorDateMessage(textObject.earlierErrMsg);
      return false;
    }
    return true;
  };

  const statusFilter = () => {
    const dropdownProps = {
      data: statusOptions,
      dropDownIcon: dropDownIcon,
      selectedIcon: selectedIcon,
    };

    return (
      <Box mt={2} zIndex={"auto"}>
        <Text {...textHeader1(theme)} mb={1.5}>
          {textObject.status}
        </Text>
        <DropDown
          {...dropdownProps}
          defaultOptionLabel={filters.status || "ALL"}
          action={(option: OptionItem) => {
            filterChanged(option, "status");
          }}
        />
      </Box>
    );
  };

  const currentPlanFilter = () => {
    const dropdownProps = {
      data: currentPlanOptions,
      dropDownIcon: dropDownIcon,
      selectedIcon: selectedIcon,
    };
    return (
      <Box mt={2} zIndex={"auto"}>
        <Text {...textHeader1(theme)} mb={1.5}>
          {t("merchants.current-plan")}
        </Text>
        <DropDown
          {...dropdownProps}
          defaultOptionLabel={filters.currentPlan || "ALL"}
          action={(option: OptionItem) => {
            filterChanged(option, "currentPlan");
          }}
        />
      </Box>
    );
  };

  const { t } = useTranslation();
  const handleCountryChange = (option: any) => {
    const value = {
      label: option.value,
    };
    filterChanged?.(value, "countryCode");
  };
  const countryFilter = () => {
    return (
      <Box {...boxPropertyStyle()}>
        <Box {...boxPropertyInsideStyle()}>
          <Text {...textPropertyStyle(theme)}>{t("merchants.country")}</Text>
        </Box>
        <CountryDropdown
          initSelectedItem={
            filters.countryCode || t("ticket-listing-filter.status-options.all")
          }
          onSelectedItem={handleCountryChange}
          onValueChangeCountry={(data) => {
            setSelectedCountry && setSelectedCountry(data);
          }}
          selectedCountry={selectedCountry}
          dropdownIcon={"/icons/arrow-down.svg"}
          checkedIcon={"/icons/checkmark.svg"}
          noDataIcon={"/icons/no-data.svg"}
        />
      </Box>
    );
  };

  const handleBussinessCategoryChange = (option: any) => {
    const value = {
      label: option.value,
    };
    filterChanged?.(value, "businessCategory");
  };
  const businessCategoryFilter = () => {
    return (
      <Box {...boxPropertyStyle()}>
        <Box {...boxPropertyInsideStyle()}>
          <Text {...textPropertyStyle(theme)}>{t("merchants.business-category")}</Text>
        </Box>
        <BusinessCategory
          initSelectedItem={
            filters.businessCategory ||
            t("ticket-listing-filter.status-options.all")
          }
          onSelectedItem={handleBussinessCategoryChange}
          onValueChangeBusinessCategory={(data) => {
            setSelectedBusinessCategory && setSelectedBusinessCategory(data);
          }}
          selectedBusinessCategory={selectedBusinessCategory}
          dropdownIcon={"/icons/arrow-down.svg"}
          checkedIcon={"/icons/checkmark.svg"}
          noDataIcon={"/icons/no-data.svg"}
        />
      </Box>
    );
  };

  const datePickerFilter = () => {
    const datePickerProps = {
      arrowIcon: arrowIcon,
      calendarIcon: calendarIcon,
      arrowUp: arrowUp,
      arrowDown: arrowDown,
      arrowLeft: arrowLeft,
      arrowRight: arrowRight,
    };

    return (
      <Box zIndex="-1">
        <Text {...textHeader1(theme)} mb={1.5}>
          {t("merchants.plan-expiry-date")}
        </Text>
        <DatePicker
          {...datePickerProps}
          initDateRange={filters.expiryDate}
          isInvalid={!!errorDateMessage}
          onChangeDateRange={(dateRange: FilterDateDTO) => {
            filterChanged(dateRange, "expiryDate");
          }}
        />
        {errorDateMessage ? (
          <Text mt={2} {...errorText(theme)}>
            {errorDateMessage}
          </Text>
        ) : undefined}
      </Box>
    );
  };

  const searchBox = () => {
    return (
      <Box zIndex="-1" {...gap16}>
        <Box {...gap6}>
          <Text {...textHeader1(theme)}>{t("merchants.business-name")}</Text>
          <AppInput
            placeholder={t("merchants.search-by-business-name")}
            defaultValue={filters.businessName}
            onChangeText={(text) => filterChanged(text, "businessName")}
            maxLength={200}
          ></AppInput>
        </Box>
        <Box {...gap6}>
          <Text {...textHeader1(theme)}>{t("merchants.email")}</Text>
          <AppInput
            placeholder={t("merchants.search-by-email")}
            defaultValue={filters.email}
            onChangeText={(text) => {
              filterChanged(text, "email");
            }}
            maxLength={200}
          ></AppInput>
        </Box>
      </Box>
    );
  };

  return (
    <FilterForm
      textObject={textObject}
      closeFilterIcon={closeFilterIcon}
      onClearFilter={() => {
        clearAll();
      }}
      onApplyFilter={onApplyFilter}
      onCloseFilter={onCloseFilter}
    >
      <Box {...gap16}>
        {businessCategoryFilter()}
        {statusFilter()}
        {countryFilter()}
        {currentPlanFilter()}
        {datePickerFilter()}
        {searchBox()}
      </Box>
    </FilterForm>
  );
};
export default MerchantListingFilter;
