import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { TicketDiscussion, useAppAuth } from "shared-components";
import TicketService from "../../services/ticket/ticketService";
import { ButtonLabel } from "shared-components/src/types/discussionProps";
import { PaginationProps } from "shared-components/lib/typescript/src/types/tablePaginationProps";

export interface AppTicketDiscussionProps {
  ticketId: string;
  initialComment?: any;
  showCommentBox?: boolean;
}

const AppTicketDiscussion: React.FC<AppTicketDiscussionProps> = ({
  ticketId,
  initialComment,
  showCommentBox,
}) => {
  const [comments, setComments] = useState<any[]>([]);
  const [paginationInfo, setPaginationInfo] = useState<PaginationProps>({
    total: 0,
    pageIndex: 1,
    pageSize: 10,
    rowPerPage: [10, 50, 100],
    totalPages: 0,
  });
  const { authData } = useAppAuth();
  const userProfile = useMemo(() => {
    return JSON.parse(localStorage.getItem("userProfile") ?? "{}");
  }, []);
  const { t } = useTranslation();
  const buttonLabel: ButtonLabel = {
    save: t("ticket-discussion.button.save"),
    cancel: t("ticket-discussion.button.cancel"),
  };

  const fetchComment = useCallback(
    async (paginationInfo: PaginationProps) => {
      try {
        const response = await TicketService.fetchComment(
          ticketId,
          paginationInfo.pageIndex,
          paginationInfo.pageSize,
        );
        const { result } = response.data?.result;
        if (result.data?.length > 0) {
          setComments([...result.data]);
          setPaginationInfo((prevState) => {
            return {
              ...prevState,
              pageIndex: +result.pageIndex,
              pageSize: +result.pageSize,
              total: result.total,
              totalPages: result.totalPages,
            };
          });
          return result;
        }
        return null;
      } catch (error) {
        return null;
      }
    },
    [ticketId],
  );

  useEffect(() => {
    if (ticketId) {
      fetchComment(paginationInfo).catch(() => {});
    }
  }, [ticketId]);

  const handlePaginationChange = (value: PaginationProps) => {
    fetchComment(value).catch(() => {});
  };

  const handleAddComment = async (message: string) => {
    try {
      TicketService.addComment(authData.access_token, ticketId, message).catch(
        () => {},
      );
      const totalComments = paginationInfo.total + 1;
      const newPaginationInfo = {
        ...paginationInfo,
        pageIndex: paginationInfo.pageIndex,
        pageSize: paginationInfo.pageSize,
        total: totalComments,
        totalPages: Math.ceil(totalComments / paginationInfo.pageSize),
      };
      if (paginationInfo.pageIndex === 1) {
        const comment = {
          message: message,
          createdDate: new Date(),
          createdBy: {
            fullName: userProfile?.fullName,
            avatar: userProfile?.avatar,
          },
        };
        const newComments = [comment, ...comments];
        setComments(newComments);
      }
      setPaginationInfo(newPaginationInfo);
    } catch (error) {}
  };

  return (
    <TicketDiscussion
      paginationInfo={paginationInfo}
      onPaginationChange={handlePaginationChange}
      textPlaceHolder={t("ticket-discussion.placeholder")}
      textNoData={t("ticket-discussion.no-comment")}
      buttonLabel={buttonLabel}
      comments={comments}
      initialComment={initialComment}
      showCommentBox={showCommentBox}
      action={handleAddComment}
    />
  );
};

export default AppTicketDiscussion;
