/*
 * Copyright © 2024 Kard Inc. All rights reserved.
 */

import React from "react";
import { I18nextProvider } from "react-i18next";
import I18n from "../i18n";

const I18nProvider = ({ children }: { children: React.JSX.Element }) => {
  return <I18nextProvider i18n={I18n}>{children}</I18nextProvider>;
};

export default I18nProvider;
