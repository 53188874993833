import { Text, useAppAuth } from "shared-components";
import {useEffect, useMemo} from "react";
import { useNavigate } from "react-router-dom";
import ProfileService from "../../services/profile/profileService";
import AuthorizationService from "../../auth/authorizationService";
import { SidebarItems } from "../../constants/sidebarItems";
import { useAppDispatch } from "../../hooks/reduxHook";
import _ from "lodash";
import { setSibarItems } from "../../store/sidebar/sidebar";
import {resolveTenant} from "../../tenantResolver";
import USER_TYPE from "../../constants/userType";

const Authenticate = () => {
  const navigate = useNavigate();
  const { isLoggedIn, authData } = useAppAuth();
  const dispatch = useAppDispatch();
  const tenantConfig = resolveTenant();

  const setSibarAndNavigateTheFirstMenu = () => {
    // Filter sidebar items by permissions
    const sidebar = SidebarItems.map((item) => {
      return {
        ...item,
        subSidebarItems: item.subSidebarItems.filter((subItem) =>
          AuthorizationService.hasPermissions(subItem.permissions),
        ),
      };
    }).filter((item) => !_.isEmpty(item.subSidebarItems) || item.path);
    dispatch(setSibarItems(sidebar));

    // navigate by redirect path for first authentication
    const redirectPath = localStorage.getItem("redirectPath");
    if (redirectPath) {
        localStorage.removeItem("redirectPath");
        navigate(redirectPath);
    } else {
        navigate("/", {replace: true});
    }
  };

  const userProfile = useMemo(() => {
      return JSON.parse(localStorage.getItem("userProfile") ?? "{}");
  }, []);

  const verifyRedirectAdminSite = () => {
      if (USER_TYPE.MERCHANT === userProfile?.userType) {
          window.location.href = tenantConfig.adminHubDomain;
      }
  };

  useEffect(() => {
    if (authData?.access_token) {
      ProfileService.getUserProfile(authData.access_token)
        .then((result) => {
          const data = result.data.result;
          const {
            avatar,
            fullName,
            agentId,
            agentPhoneNumber,
            agentExtension,
            userType,
          } = data;
          localStorage.setItem(
            "userProfile",
            JSON.stringify({
              avatar,
              fullName,
              agentId,
              agentPhoneNumber,
              agentExtension,
              userType,
            }),
          );
          AuthorizationService.setPermissions(data.capabilities);
        })
        .finally(() => {
          if (isLoggedIn) {
            localStorage.setItem("isAvailable", "true");
            verifyRedirectAdminSite();
            setSibarAndNavigateTheFirstMenu();
          }
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn, navigate, authData]);
  return <Text></Text>;
};
export default Authenticate;
