import { Config } from "./configs/environment";

export const resolveTenant = () => {
    const currentDomain = window.location.host;

  // Find the corresponding tenant code for the current domain
  const matchedTenant = Config.tenants.find(
    (tenant) => currentDomain.indexOf(tenant.domain) !== -1,
  );

  if (matchedTenant) {
    return matchedTenant;
  } else {
    return Config.tenants[0];
  }
};
