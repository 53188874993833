import React from "react";
import {Box, Modal} from "native-base";
import SendEmailForm from '../send-email-form/SendEmailForm';
import {useTranslation} from "react-i18next";
import {EmailTemplate, Language} from "../../types/sendEmailFormProps";

interface SendEmailDrawerProps {
    emailListing: EmailTemplate[];
    supportedLanguages:Language[];
    userInfo: UserInformationDTO;
    currencyCode?: string;
    maxMoneyInput?: string;
    isOpenDrawer: boolean;
    closeDrawer: () => void;

}

export interface UserInformationDTO {
    userId: string;
    email: string;
    fullName?: string;
    membershipPackageName?: string;
    phoneNumber: string;
}

const SendEmailDrawer: React.FC<SendEmailDrawerProps> = ({
    emailListing,
    supportedLanguages,
    userInfo,
    isOpenDrawer,
    closeDrawer,
}) => {
    const { t } = useTranslation();
    const panelLabel = {
        title: t("send-email.title"),
        primaryButton: t("send-email.send-btn"),
        secondaryButton: t("send-email.cancel-btn"),
    };

    return (
        <Modal isOpen={isOpenDrawer} onClose={() => closeDrawer()}>
            <Modal.Content
                maxHeight="100vh"
                marginLeft="auto"
                marginRight="0"
                maxWidth="648"
                borderRadius="0"
            >
                <Box>
                    <SendEmailForm
                        emailListing={emailListing}
                        supportedLanguages={supportedLanguages}
                        userInfo={userInfo}
                        panelLabel={panelLabel}
                        onClose={closeDrawer}
                    />
                </Box>
            </Modal.Content>
        </Modal>
    );
};

export default SendEmailDrawer;