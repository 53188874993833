export const wrapperStyle: any = (theme: any) => ({
  flexDirection: "row",
  alignItems: "center",
  height: "56px",
  justifyContent: "space-between",
  paddingLeft: "24px",
  paddingRight: "24px",
  borderBottomWidth: "1px",
  borderBottomStyle: "solid",
  borderBottomColor: theme?.appPalette?.grey.grey20,
});

export const textStyle = (theme: any) => ({
  fontWeight: 700,
  fontSize: "16px",
  lineHeight: "20px",
  color: theme?.appPalette?.grey.grey80,
  fontFamily: theme?.fontFamily,
  whiteSpace: "nowrap",
});

export const gap6Style: any = {
  display: "flex",
  flexDirection: "column",
  gap: "6px",
};

export const errorStyle = (theme: any) => ({
  fontWeight: 400,
  fontSize: "14px",
  lineHeight: "16px",
  color: theme.appPalette?.danger.danger100,
  fontFamily: theme?.fontFamily,
});

export const titleStyle = (theme: any) => ({
  fontSize: "24px",
  fontWeight: 700,
  lineHeight: "28px",
  color: theme?.appPalette?.grey.grey80,
});

export const gap24 = {
  gap: "24px",
};

export const gap16 = {
  gap: "16px",
};

export const width50: any = {
  display: "flex",
  flexDirection: "column",
  gap: "6px",
  width: "50%",
};

export const footerStyle: any = (theme: any) => ({
  flexDirection: "row",
  justifyContent: "space-between",
  position: "fixed",
  bottom: "0",
  height: "64px",
  alignItems: "center",
  background: theme?.appPalette?.grey.white,
  paddingLeft: "24px",
  paddingRight: "24px",
  width: "720px",
  borderTopWidth: "1px",
  borderStyle: "solid",
  borderColor: theme?.appPalette?.grey.grey20,
});

export const currencyStyle: any = (theme: any) => ({
  position: "absolute",
  top: "35px",
  left: "14px",
});
