export const boxContainerStyle: any = {
  width: "100%",
  bgColor: "white",
  borderRadius: "8px",
  padding: "12px",
  position: "absolute",
  top: "44px",
  minHeight: "100px",
  zIndex: 1,
  style: {
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 1,
    shadowRadius: 8,
    shadowColor: "#00000033",
  },
};

export const boxStyle = (maxHeight: string | undefined) => ({
  maxHeight: maxHeight ? maxHeight : "290px",
  width: "100%",
  overflow: "auto",
  bgColor: "white",
});

export const boxItemStyle = (
  theme: any,
  isBorder: boolean,
  isMargin: boolean
) => ({
  padding: "8px",
  gap: "2px",
  borderBottomWidth: isBorder ? 1 : 0,
  borderColor: theme?.appPalette?.grey.grey12,
  marginRight: isMargin ? "8px" : "0",
  cursor: "pointer",
  _hover: {
    backgroundColor: theme?.appPalette?.grey?.grey4,
  },
});

export const phoneStyle = (theme: any) => ({
  fontFamily: theme?.fontFamily,
  fontWeight: 400,
  fontSize: "12px",
  lineHeight: "14px",
  color: theme?.appPalette?.grey?.grey60,
});

export const spinnerStyle = (theme: any) => ({
  color: theme.appPalette?.primary.primary100,
  top: "70%",
  left: "50%",
  transform: "translate(-70%, -50%)",
});
