import React, { useEffect, useState } from "react";
import { Box, UploadHistoryListing } from "shared-components";
import { format } from "date-fns";
import { useTranslation } from "react-i18next";
import LeadService from "../../services/lead/leadService";
import { UserProfileModel } from "../user-profile-listing/UserProfileListing";
import { UploadHistoryListingConstants } from "../../constants/lead";

interface UploadHistoryModel {
  id: string;
  originalFileName: string;
  uploadedDate: number;
  totalRecord: number;
  successfulRecord: number;
  failedRecord: number;
  completionDate: number;
  uploadedStaff: UserProfileModel;
  status: string;
}

interface UploadHistoryDisplay {
  originalFileName: string;
  uploadedDate: string;
  totalRecords: number;
  successfulRecords: number;
  failedRecords: number;
  completionDate: string;
  uploadedStaff: string;
  status: string;
}

enum UploadRecordType {
  TOTAL = "TOTAL",
  SUCCESSFUL = "SUCCESSFUL",
  FAILED = "FAILED",
}

interface DataAction {
  [fieldName: string]: (
    uploadLeadHistoryId: string,
    type: UploadRecordType,
  ) => any;
}

interface RowPerPage {
  pageSize: number;
  pageIndex: number;
  total: number;
  rowPerPage: number[];
}

const AppUploadHistory = () => {
  const { t } = useTranslation();
  const headers = [
    t("upload-history-listing.header-column.original-file-name"),
    t("upload-history-listing.header-column.uploaded-date"),
    t("upload-history-listing.header-column.total-records"),
    t("upload-history-listing.header-column.successful-records"),
    t("upload-history-listing.header-column.failed-records"),
    t("upload-history-listing.header-column.completion-date"),
    t("upload-history-listing.header-column.uploaded-staff"),
    t("upload-history-listing.header-column.status"),
  ];
  const uploadHistoryIcon = {
    downloadIcon: "/icons/download-btn.png",
    altDownloadIcon: t("upload-history-listing.item.alt-download-icon"),
    sortIcon: "/icons/sort.png",
    altSortIcon: t("upload-history-listing.item.alt-sort-icon"),
  };
  const FORMAT_DATE = "dd/MM/yyyy";
  const [data, setData] = useState<UploadHistoryDisplay[]>([]);
  const [dataActions, setDataActions] = useState<DataAction[]>([]);
  const [currentPageSize, setCurrentPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [totalElement, setTotalElement] = useState(0);
  const [state, setState] = useState("SHOW_DATA");
  const [rowPerPage, setRowPerPage] = useState<RowPerPage>({
    pageSize: 10,
    pageIndex: 1,
    total: 0,
    rowPerPage: [10, 50, 100],
  });

  const [keyword, setKeyword] = useState("");

  const downloadUploadLeadHistory = (
    uploadLeadHistoryId: string,
    type: UploadRecordType,
  ) => {
    LeadService.downloadBulkLead(uploadLeadHistoryId, type)
      .then((response) => {
        const contentDisposition = response.headers["content-disposition"];
        const regex = /filename=([^;\n\r]*)/;
        const match = regex.exec(contentDisposition);
        let filename = "";
        if (match && match.length > 1) {
          filename = match[1];
        }
        const csvDataUri =
          "data:text/csv;charset=utf-8," + encodeURIComponent(response?.data);
        const link = document.createElement("a");
        link.setAttribute("href", csvDataUri);
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    const body = {
      pageIndex: currentPage,
      pageSize: currentPageSize,
      keyword: keyword,
      orderBy: "createdDate",
      orderDirection: "DESC",
    };

    const displayStatus = (statusValue?: string) => {
      const status = UploadHistoryListingConstants.status.find(
        (st: any) => st.label === statusValue,
      );
      return status ? t(status.value) : "";
    };

    const convertUploadHistory = (
      data: UploadHistoryModel,
    ): UploadHistoryDisplay => {
      return {
        originalFileName: data.originalFileName,
        uploadedDate: formatDate(data.uploadedDate),
        totalRecords: data.totalRecord,
        successfulRecords: data.successfulRecord,
        failedRecords: data.failedRecord,
        completionDate: formatDate(data.completionDate),
        uploadedStaff: data?.uploadedStaff?.fullName,
        status: displayStatus(data.status),
      };
    };

    setState("SHOW_LOADING");

    LeadService.getUploadHistoryListing(body)
      .then((response) => {
        const { result } = response.data;
        setTotalElement(result.total);
        setTotalPage(result.totalPages);
        if (result.data) {
          if (result.data.length > 0) {
            const data: UploadHistoryModel[] = result.data;
            const dataRes: UploadHistoryDisplay[] = [];
            const dataActions: DataAction[] = [];
            data.forEach((item) => {
              const uploadHistoryDisplay = convertUploadHistory(item);
              dataRes.push(uploadHistoryDisplay);
              dataActions.push({
                totalRecords: () => {
                  downloadUploadLeadHistory(item.id, UploadRecordType.TOTAL);
                },
                successfulRecords: () => {
                  downloadUploadLeadHistory(
                    item.id,
                    UploadRecordType.SUCCESSFUL,
                  );
                },
                failedRecords: () => {
                  downloadUploadLeadHistory(item.id, UploadRecordType.FAILED);
                },
                status: () => {
                  return item.status;
                },
              });
            });
            setData(dataRes);
            setDataActions(dataActions);
            setState("SHOW_DATA");
          } else {
            setState("SHOW_NO_DATA");
          }
        }
      })
      .catch((error) => {
        setData([]);
        setState("SHOW_NO_DATA");
        setTotalElement(0);
        setTotalPage(0);
      });
  }, [t, currentPage, currentPageSize, keyword]);

  useEffect(() => {
    setRowPerPage((preRowPerPage) => {
      return {
        ...preRowPerPage,
        ...{
          pageSize: currentPageSize,
          pageIndex: currentPage,
          total: totalElement,
          rowPerPage: [10, 50, 100],
        },
      };
    });
  }, [currentPageSize, currentPage, totalElement]);

  const formatDate = (timestamp: number) => {
    const date = new Date(timestamp);
    return format(date, FORMAT_DATE);
  };

  return (
    <Box>
      <UploadHistoryListing
        title={t("upload-history-listing.title")}
        pgDefaultPage={currentPage}
        pgTotalPages={totalPage}
        pgOnValueChange={(e) => {
          setCurrentPage(e);
        }}
        data={data}
        dataActions={dataActions}
        state={state}
        rpgTextLeft={t("common.row-per-page")}
        rpgTextRight={t("common.of")}
        rpgInfo={rowPerPage}
        rpgOnValueChange={(e) => {
          setCurrentPage(1);
          setCurrentPageSize(e);
        }}
        noDataText={t("common.listing.no-data")}
        headers={headers}
        searchPlaceholder={t("common.search-placeholder")}
        searchTooltip={""}
        disabledSearchTooltip={true}
        uploadHistoryIcon={uploadHistoryIcon}
        onKeySearchChange={(text: string | undefined) => {
          setKeyword(text ?? "");
        }}
      />
    </Box>
  );
};

export default AppUploadHistory;
