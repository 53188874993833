import { useState, useEffect, useRef } from "react";

const useIsTruncated = (text: string) => {
  const textRef = useRef<HTMLDivElement>(null);
  const [isTruncated, setIsTruncated] = useState(false);

  useEffect(() => {
    const element = textRef.current;
    if (element) {
      setIsTruncated(element.scrollWidth > element.clientWidth);
    }
  }, [text]);

  return { textRef, isTruncated };
};

export default useIsTruncated;
