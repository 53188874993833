import React from "react";
import { Box, ToastMessage, ToastWithAction } from "shared-components";
import { ToastProps } from "shared-components/lib/typescript/src/types/toastProps";

export interface AppShowToastProps {
  toastData: any;
  primaryAction?: any;
  secondaryAction?: any;
  onClose?: any;
}

const AppShowToast: React.FC<AppShowToastProps> = ({
  toastData,
  primaryAction,
  secondaryAction,
  onClose,
}) => {
  const toastComponent = () => {
    if (primaryAction && secondaryAction) {
      return (
        <ToastWithAction
          {...toastData}
          primaryButton={primaryAction}
          secondaryButton={secondaryAction}
          onClose={onClose}
        />
      );
    }
    if (primaryAction && !secondaryAction) {
      return (
        <ToastWithAction
          {...toastData}
          primaryButton={primaryAction}
          onClose={onClose}
        />
      );
    }
    if (!primaryAction && secondaryAction) {
      return (
        <ToastWithAction
          {...toastData}
          secondaryButton={secondaryAction}
          onClose={onClose}
        />
      );
    }
    return template(toastData, onClose);
  };

  const template = (args: ToastProps, onClose: any) => (
    <Box marginRight="32px" marginTop="14px">
      <ToastMessage
        message={args.message}
        icon={args.icon}
        title={args.title}
        onClose={onClose}
      />
    </Box>
  );
  return toastComponent();
};

export default AppShowToast;
