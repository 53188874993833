import React from "react";
import { ActivityLog, Box } from "shared-components";
import { ActivityChips } from "shared-components/lib/typescript/src/types/activityLogProps";
import AppTicketDiscussion from "../ticket-discussion/TicketDiscussion";
import ActivityHistory from "../activity-history/ActivityHistory";

export interface AppTicketActivityLogProps {
  ticketId: string;
  showText: string;
  textHeaderLabel?: string;
  activityChips: ActivityChips[];
}

const AppTicketActivityLog: React.FC<AppTicketActivityLogProps> = ({
  ticketId,
  textHeaderLabel,
  activityChips,
  showText,
}) => {
  return (
    <>
      <ActivityLog
        textHeaderLabel={textHeaderLabel}
        activityChips={activityChips}
        showText={showText}
      />
      <Box marginBottom={"16px"}></Box>
      {activityChips.map((value) => {
        if (value.isActive && value.route === "comments") {
          return (
            <AppTicketDiscussion
              key={`${activityChips}${value.route}`}
              ticketId={ticketId}
            ></AppTicketDiscussion>
          );
        }
        if (value.isActive && value.route === "history") {
          return <ActivityHistory key={`${activityChips}${value.route}`} />;
        }
        return undefined;
      })}
    </>
  );
};

export default AppTicketActivityLog;
