import React from "react";
import { Box, Flex, Pressable, Text, useTheme } from "native-base";
import { textHeader24 } from "../../styles/text";
import {
  clearStyle,
  filterBodyStyle,
  filterContainerStyle,
  filterFooterStyle,
  filterHeaderStyle,
} from "./FilterForm.style";
import { ReactSVG } from "react-svg";
import { FilterFormProps } from "../../types/filterFormProps";
import { AppButton } from "shared-components";
import { filterStyle } from "../lead-listing/LeadListing.style";

const FilterForm: React.FC<FilterFormProps> = ({
  onClearFilter,
  onApplyFilter,
  onCloseFilter,
  closeFilterIcon,
  textObject,
  children,
}) => {
  const theme = useTheme();

  const clearAll = () => {
    onClearFilter?.();
  };

  const applyFilters = () => {
    onApplyFilter?.();
  };

  return (
    <Box {...filterContainerStyle(theme)}>
      <Box {...filterHeaderStyle(theme)}>
        <Flex direction="row" justify="space-between" align="center">
          <Text {...textHeader24(theme)}>{textObject.filter}</Text>
          <Pressable
            testID={"filter-form-close-btn"}
            onPress={() => onCloseFilter?.()}
            {...filterStyle(theme)}
            width="32px"
            height="32px"
            marginRight="-12px"
          >
            <ReactSVG src={closeFilterIcon} />
          </Pressable>
        </Flex>
      </Box>
      <Box {...filterBodyStyle()}>{children}</Box>
      <Box {...filterFooterStyle(theme)}>
        <Flex direction="row" justify="space-between" align="center">
          <Box>
            <AppButton
              label={textObject.apply}
              onPress={() => {
                applyFilters();
              }}
            />
          </Box>
          <Pressable onPress={() => clearAll()}>
            <Text {...clearStyle(theme)}>{textObject.clearAll}</Text>
          </Pressable>
        </Flex>
      </Box>
    </Box>
  );
};

export default FilterForm;
