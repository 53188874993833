export const textHeader14 = (theme: any) => ({
  fontFamily: theme?.fontFamily,
  fontWeight: 600,
  fontSize: "14px",
  lineHeight: "16px",
  color: theme?.appPalette?.grey?.grey80,
});

export const textHeader20 = (theme: any) => ({
  fontFamily: theme?.fontFamily,
  fontWeight: 600,
  fontSize: "20px",
  lineHeight: "24px",
  color: theme?.appPalette?.grey?.grey80,
});

export const textHeader24 = (theme: any) => ({
  fontFamily: theme?.fontFamily,
  fontWeight: 600,
  fontSize: "24px",
  lineHeight: "32px",
  color: theme?.appPalette?.grey?.grey80,
});

export const textBody14 = (theme: any) => ({
  fontFamily: theme?.fontFamily,
  fontWeight: 400,
  fontSize: "14px",
  lineHeight: "16px",
  color: theme?.appPalette?.grey?.grey80,
});
export const textBody16 = (theme: any) => ({
  fontFamily: theme?.fontFamily,
  fontWeight: 400,
  fontSize: "16px",
  lineHeight: "20px",
  color: theme?.appPalette?.grey?.grey80,
});

export const textHeader1 = (theme: any) => ({
  fontFamily: theme?.fontFamily,
  fontWeight: 600,
  fontSize: "16px",
  lineHeight: "20px",
  color: theme?.appPalette?.grey?.grey80,
});

export const textNodata = (theme: any) => ({
  fontFamily: theme?.fontFamily,
  fontWeight: 600,
  fontSize: "14px",
  lineHeight: "16px",
  color: theme?.appPalette?.grey?.grey40,
});

export const errorText = (theme: any) => ({
  fontFamily: theme?.fontFamily,
  fontWeight: 400,
  fontSize: "14px",
  lineHeight: "16px",
  color: theme?.appPalette?.red?.red100,
});

export const textHeader16 = (theme: any) => ({
  fontFamily: theme?.fontFamily,
  fontWeight: 700,
  fontSize: "16px",
  lineHeight: "20px",
  color: theme?.appPalette?.grey?.grey80,
});

export const textSuccess = (theme: any) => ({
  fontFamily: theme?.fontFamily,
  fontWeight: 700,
  fontSize: "16px",
  lineHeight: "20px",
  color: theme?.appPalette?.success?.success100,
});

export const borderStyle: any = (theme: any) => ({
  borderBottomWidth: 1,
  borderBottomStyle: "solid",
  borderBottomColor: theme?.appPalette?.grey?.grey12,
});

export const textDesc12 = (theme: any) => ({
  fontFamily: theme?.fontFamily,
  fontWeight: 400,
  fontSize: "12px",
  lineHeight: "14px",
  color: theme?.appPalette?.grey?.grey60,
});

export const twoLinesStyle: any = {
  WebkitBoxOrient: "vertical",
  display: "-webkit-box",
  WebkitLineClamp: 2,
  textOverflow: "ellipsis",
  overflow: "hidden",
};

export const oneLinesStyle: any = {
  WebkitBoxOrient: "vertical",
  display: "-webkit-box",
  WebkitLineClamp: 1,
  textOverflow: "ellipsis",
  overflow: "hidden",
};

export const gap6 = {
  gap: "6px",
};

export const gap16 = {
  gap: "16px",
};

