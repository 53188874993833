export const shadowStyle = () => ({
  borderRadius: "8px",
  gap: "16px",
  padding: "24px",
  w: "100%",
  background: "white",
  mb: 4,
  style: {
    shadowColor: "rgba(0, 0, 0, 0.20)",
    shadowOffset: { width: 0, height: 1 },
    shadowOpacity: 1,
    shadowRadius: 3,
  },
});

export const optionContainer = (theme: any) => ({
  top: "48px",
  width: "100%",
  padding: "12px",
  gap: "8px",
  borderWidth: 1,
  borderColor: theme.appPalette?.grey?.grey8,
  borderRadius: 8,
  zIndex: "auto",
  shadow: 1,
  bgColor: theme?.appPalette?.grey?.white,
});
export const boxItems = () => ({
  maxHeight: "632px",
  gap: "8px",
  overflow: "auto",
});

export const spinnerStyle = (theme: any) => ({
  color: theme.appPalette?.primary.primary100,
  top: "70%",
  left: "50%",
  transform: "translate(-70%, -50%)",
});

export const boxItemStyle = (
  theme: any,
  isBorder: boolean,
  isMargin: boolean
) => ({
  gap: "4px",
  padding: "8px",
  borderRadius: "4px",
  marginRight: isMargin ? "8px" : "0",
  cursor: "pointer",
  _hover: {
    backgroundColor: theme?.appPalette?.grey?.grey4,
  },
});

export const phoneStyle = (theme: any) => ({
  fontFamily: theme?.fontFamily,
  fontWeight: 400,
  fontSize: "14px",
  lineHeight: "18px",
  color: theme?.appPalette?.grey?.grey60,
});

export const boxSelectedValue: any = (theme: any) => ({
  py: 3,
  pl: 4,
  pr: 2,
  borderWidth: 1,
  borderStyle: "solid",
  borderColor: theme?.appPalette?.grey.grey12,
  borderRadius: "8px",
  flexDirection: "row",
  alignItems: "start",
  justifyContent: "space-between",
  position: "relative",
});

export const gap2 = {
  gap: "2px",
};

export const textSelectStyle = (theme: any) => ({
  color: theme?.appPalette?.grey.grey60,
  fontSize: "14px",
  lineHeight: "18px",
  fontFamily: theme?.fontFamily,
});

export const idStyle = (theme: any) => ({
  color: theme?.appPalette?.info,
  fontSize: "16px",
  lineHeight: "18px",
  fontFamily: theme?.fontFamily,
});

export const gap4 = {
  gap: '4px'
}
