import { Header, TableAction } from "../../types/table";
import { useCallback, useEffect, useState } from "react";
import RoleService from "../../services/role/roleService";
import CoreTable from "../core-table/CoreTable";
import { AppButton, Box, Text, Popup } from "shared-components";
import { createdDateText, statusText } from "./userLising.style";
import _ from "lodash";
import { useTheme, useToast } from "native-base";
import { formatDate } from "../../utils/formatDate";
import { useTranslation } from "react-i18next";
import { PopupProps } from "shared-components/lib/typescript/src/ui-components/custom-components/popup/Popup";
import { showToast } from "../../services/toast/toastService";
import { useNavigate } from "react-router-dom";
import ProtectedContent from "../protected-content/ProtectedContent";
import { PERMISSIONS } from "../../constants/permisstion";

const RoleListing = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const toast = useToast();
  const [id, setId] = useState("");
  const [isFetch, setIsFetch] = useState(false);
  const [open, setOpen] = useState(false);

  const navigate = useNavigate();

  const formatStatus = (status: string) => {
    const mappingStatus = {
      0: t("users-roles.inactive"),
      1: t("users-roles.active"),
    };
    return (
      <Text {...statusText(theme, status)}>{_.get(mappingStatus, status)}</Text>
    );
  };
  const formatCreatedDate = (date: string) => {
    return <Text {...createdDateText(theme)}>{formatDate(date)}</Text>;
  };
  const headers: Header[] = [
    {
      label: t("users-roles.name"),
      value: "name",
      width: 940,
    },
    {
      label: t("users-roles.status"),
      value: "status",
      width: 240,
      format: formatStatus,
    },
    {
      label: t("users-roles.created-date"),
      value: "createdDate",
      width: 240,
      format: formatCreatedDate,
    },
    {
      label: t("users-roles.action"),
      value: "action",
      width: 100,
      type: "ACTION",
    },
  ];
  const actions: TableAction[] = [
    {
      label: t("users-roles.edit"),
      icon: "/icons/edit.svg",
      value: "edit",
      permissions: [PERMISSIONS.USERS_ROLES.EDIT_ROLES],
    },
    {
      label: t("users-roles.deactivate"),
      icon: "/icons/deactivate.svg",
      value: "0",
      permissions: [PERMISSIONS.USERS_ROLES.DISABLE_ROLES],
    },
    {
      label: t("users-roles.activate"),
      icon: "/icons/activate.svg",
      value: "1",
      permissions: [PERMISSIONS.USERS_ROLES.ENABLE_ROLES],
    },
    {
      label: t("users-roles.delete"),
      icon: "/icons/trash.svg",
      value: "delete",
      permissions: [PERMISSIONS.USERS_ROLES.DELETE_ROLES],
    },
  ];

  const fetchDatas = useCallback((params: any) => {
    return RoleService.list(params);
  }, []);

  const actionRow = () => {
    return (
      <ProtectedContent permissions={PERMISSIONS.USERS_ROLES.CREATE_ROLES}>
        <Box ml={3}>
          <AppButton
            label={t("users-roles.create")}
            icon="/icons/add-white.svg"
            onPress={() => {
              navigate("/role/create");
            }}
          />
        </Box>
      </ProtectedContent>
    );
  };

  const handleAction = (action: string, id: string) => {
    setId(id);
    switch (action) {
      case "0":
        updateRole(id, 0);
        break;
      case "1":
        updateRole(id, 1);
        break;
      case "delete":
        setOpen(true);
        break;
      case "edit":
        navigate(`/role/edit/${id}`);
        break;
      default:
        break;
    }
  };

  const updateRole = (id: string, status: number) => {
    RoleService.updateRole(id, status).then(() => {
      setIsFetch(true);
      const toastData = {
        message:
          status === 1
            ? t("users-roles.role-activated-successfully")
            : t("users-roles.role-deactivated-successfully"),
        icon: "/icons/toast/check.svg",
      };
      showToast(toast, toastData);
    });
  };

  const deleteRole = () => {
    RoleService.deleteRole(id).then(() => {
      setIsFetch(true);
      const toastData = {
        message: t("users-roles.role-deleted-successfully"),
        icon: "/icons/toast/check.svg",
      };
      showToast(toast, toastData);
    });
  };

  const deleteConfirmation = () => {
    const modal = {
      title: t("users-roles.confirmation"),
      hasCloseIcon: true,
      content: t("users-roles.delete-confirm"),
      typeButton: "danger",
      confirmText: t("users-roles.delete"),
      cancelText: t("ticket-form.alert-cancel"),
    } as PopupProps;
    return (
      <Popup
        {...modal}
        showPopup={open}
        onChangeShowPopup={(e) => setOpen(e)}
        confirmAction={deleteRole}
      ></Popup>
    );
  };

  useEffect(() => {
    if (isFetch) {
      setIsFetch(false);
    }
  }, [isFetch]);

  return (
    <>
      {deleteConfirmation()}
      <CoreTable
        isFetch={isFetch}
        headers={headers}
        fetchDatas={fetchDatas}
        onClickAction={(code, data) => handleAction(code, data.id)}
        actionRow={actionRow()}
        actions={actions}
        isMargin={false}
      />
    </>
  );
};

export default RoleListing;
