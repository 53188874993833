import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../index";

export const initialState = {
  sidebarItems: JSON.parse(
    localStorage.getItem("sidebarItems") ?? "[]",
  ) as any[],
};

const Sidebar = createSlice({
  name: "sidebar",
  initialState,
  reducers: {
    setSibarItems: (state, data) => {
      state.sidebarItems = [...data.payload];
      localStorage.setItem("sidebarItems", JSON.stringify(data.payload));
    },
  },
});

export const SidebarStore = (state: RootState) => state.sidebar;
export const { setSibarItems } = Sidebar.actions;
export default Sidebar.reducer;
