export const bodyStyle = {
  p: 6,
  gap: "24px",
};

export const boxTitleStyle: any = (theme: any) => ({
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
  borderRadius: "8px",
  py: 2.5,
  px: 3,
  backgroundColor: theme?.appPalette?.primary.primary8,
});

export const gap16 = {
  gap: "16px",
};

export const gap12 = {
  gap: "12px",
};

export const gap4 = {
  gap: "4px",
};

export const boxFlexStyle: any = {
  flexDirection: "row",
  justifyContent: "space-between",
  gap: "24px",
  alignItems: "start",
};

export const w50: any = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  width: "50%",
  gap: "4px",
};

export const w100: any = {
  display: "flex",
  flexDirection: "column",
  width: "100%",
  gap: "2px",
};

export const labelStyle = (theme: any) => ({
  color: theme?.appPalette?.grey.grey60,
  fontSize: "16px",
  lineHeight: "20px",
  fontFamily: theme?.fontFamily,
});

export const imageStyle = (theme: any) => ({
  width: "64px",
  height: "64px",
  borderRadius: "8px",
  borderWidth: 1,
  borderStyle: "solid",
  borderColor: theme?.appPalette?.grey.grey12,
});

export const totalStyle = (theme: any) => ({
  py: 3,
  borderBottomWidth: "1px",
  borderTopWidth: "1px",
  borderStyle: "solid",
  borderColor: theme?.appPalette?.grey.grey12,
  gap: "8px",
});

export const btnStyle = {
  width: "fit-content",
  minWidth: "80px",
};

export const toggleStyle = (isCollapse: boolean) => ({
  w: 6,
  h: 6,
  style: {
    transform: [{ rotate: isCollapse ? "0" : "180deg" }],
    transitionDuration: "0.3s",
    transitionTimingFunction: "ease-out",
  } as any,
});

export const headerStyle: any = (theme: any) => ({
  flexDirection: "row",
  alignItems: "center",
  height: "56px",
  justifyContent: "space-between",
  paddingRight: "12px",
  paddingLeft: "24px",
  borderBottomWidth: 1,
  borderBottomStyle: "solid",
  borderBottomColor: theme?.appPalette?.grey.grey12,
});
