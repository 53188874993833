export const selectContainer = (
  theme: any,
  isOpen: boolean,
  disabled: any,
  isInvalid: any
) => {
  let borderColor = theme.appPalette?.grey?.grey20;
  if (isOpen) {
    borderColor = theme.appPalette?.primary?.primary100;
  } else if (isInvalid) {
    borderColor = theme.appPalette?.danger?.danger100;
  }
  return {
    height: '48px',
    paddingLeft: '16px',
    paddingRight: '16px',
    borderColor: borderColor,
    borderWidth: 1,
    borderRadius: '8px',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: disabled
      ? theme.appPalette?.grey?.grey8
      : 'transparent',
    _hover: {
      borderColor: theme.appPalette?.primary?.primary100,
    },
  };
};

export const optionContainer = (theme: any) => ({
  top: '54px',
  width: '100%',
  padding: '12px',
  borderWidth: 1,
  borderColor: theme.appPalette?.grey?.grey8,
  borderRadius: 8,
  zIndex: 1,
  shadow: 1,
  bgColor: theme?.appPalette?.grey?.white,
  maxHeight: '400px',
});
export const boxItems = () => ({
  maxHeight: '250px',
  overflow: 'auto',
});

export const styleOption = (theme: any) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '10px',
  height: '48px',
  borderRadius: 8,
  _hover: {
    backgroundColor: theme.appPalette?.grey?.grey4,
  },
});

export const spinnerStyle = (theme: any) => ({
  color: theme.appPalette?.primary.primary100,
  top: "70%",
  left: "50%",
  transform: "translate(-70%, -50%)",
});
