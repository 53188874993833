import { socket } from "../../socket";
import {
  HANG_UP_CALL,
  INBOUND_CALL,
  INBOUND_RINGING_CALL,
} from "../../constants/socketEvent";
import { useNavigate } from "react-router-dom";
import { Toast } from "native-base";
import { Box, CallerInformation } from "shared-components";
import { useTranslation } from "react-i18next";
import ProfileService from "../profile/profileService";
import { useEffect } from "react";
import moment from "moment";
import { UserConstants } from "../../constants/user";
import AuthorizationService from "../../auth/authorizationService";
import { PERMISSIONS } from "../../constants/permisstion";

const SocketHandler = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  let userProfile: any = {};
  let hasPermissionEditTicket = true;

  const getUserProfile = () => {
    userProfile = JSON.parse(localStorage.getItem("userProfile") ?? "{}");
    hasPermissionEditTicket = AuthorizationService.hasPermissions(
      PERMISSIONS.CUSTOMER_SUPPORT.TICKET.EDIT
    );
  };

  useEffect(() => {
    socket.on(INBOUND_CALL, (data) => {
      getUserProfile();
      if (
        `${userProfile?.agentId}` === `${data?.agentId}` &&
        hasPermissionEditTicket
      ) {
        localStorage.setItem("isAvailable", "false");
        Toast.closeAll();
        navigate(`/ticket/edit/${data?.ticketId}`, {
          state: { source: "CALL" },
        });
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const getUserType = (userInfo: any) => {
      const userType = UserConstants.type.find(
        ({ label }: { label: string }) => userInfo.userType === label
      );
      return userType ? t(userType.value) : null;
    };

    const mappingUserInfo = (
      userInfo: any,
      userType: "IN_SYSTEM" | "UNKNOWN"
    ) => {
      return {
        avatar: userInfo?.avatar ?? "/icons/unknown-avatar.svg",
        fullName:
          userType === "IN_SYSTEM" ? userInfo?.fullName : t("common.unknown"),
        type:
          userType === "IN_SYSTEM"
            ? getUserType(userInfo)
            : t("common.unknown"),
        phoneNumber: userInfo?.phoneNumber,
        email: userInfo?.email,
        lastCall: userInfo?.lastCall
          ? t("voiso.last-call", {
              dateTime: moment(userInfo?.lastCall).format("MM/DD/YYYY h:mmA"),
            })
          : undefined,
      };
    };
    socket.on(INBOUND_RINGING_CALL, (data) => {
      getUserProfile();
      const isAvailable = JSON.parse(
        localStorage.getItem("isAvailable") ?? "true"
      );
      if (
        data?.agentPhoneNumber === userProfile?.agentPhoneNumber &&
        hasPermissionEditTicket &&
        isAvailable
      ) {
        Toast.closeAll();
        ProfileService.getProfileByPhoneNumber(data?.callerPhoneNumber).then(
          (res) => {
            const { result } = res.data.result;
            const userType =
              result?.userType === "MERCHANT" ||
              result?.userType === "CONSUMER" ||
              result?.userType === "GUEST" ||
              result?.userType === "LEAD"
                ? "IN_SYSTEM"
                : "UNKNOWN";

            const createLeadFormProps = {
              labels: {
                firstName: "",
                lastName: "",
                email: "",
                phoneNumber: "",
              },
              btnLabel: "",
              optionalLabel: "",
              placeholder: {
                firstName: "",
                lastName: "",
                email: "",
              },
              errorMessageValue: {
                emptyFirstName: "",
                emptyLastName: "",
              },
              initData: {
                firstName: "",
                lastName: "",
                email: "",
                phoneNumber: "",
              },
              onChangeData: () => {},
            };

            const notificationComponent = () => {
              const userInfo = mappingUserInfo(result, userType);
              return (
                <CallerInformation
                  type={userType}
                  userInfo={userInfo}
                  onClose={() => {
                    Toast.closeAll();
                  }}
                  textObject={{
                    title: t("voiso.incoming-call"),
                    callBtnLabel: "",
                    editBtnLabel: "",
                    associateTickets: t(
                      "call-information.edit-ticket.inbound-call.associateTickets"
                    ),
                  }}
                  createLeadFormProps={createLeadFormProps}
                  enableForm={false}
                />
              );
            };
            Toast.show({
              render: () => {
                return (
                  <Box marginRight="32px" marginTop="14px">
                    {notificationComponent()}
                  </Box>
                );
              },
              placement: "top-right",
              duration: null,
            });
          }
        );
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    socket.on(HANG_UP_CALL, (data) => {
      getUserProfile();
      if (userProfile?.agentExtension === data?.agentExtension) {
        localStorage.setItem("isAvailable", "true");
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <></>;
};

export default SocketHandler;
