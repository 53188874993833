export class CsTicketConstants {
  static readonly statusOptions = [
    { label: "NEW", value: "ticket-properties.option.status.new" },
    { label: "PENDING", value: "ticket-properties.option.status.pending" },
    {
      label: "IN_PROGRESS",
      value: "ticket-properties.option.status.in-progress",
    },
    {
      label: "WAITING_FOR_CUSTOMER",
      value: "ticket-properties.option.status.waiting-for-customer",
    },
    {
      label: "ESCALATION",
      value: "ticket-properties.option.status.escalation",
    },
    { label: "RESOLVED", value: "ticket-properties.option.status.resolved" },
  ];

  static readonly subCategoryOptions = [
    {
      label: "TRANSACTION_ISSUE",
      value: "ticket-properties.option.sub-category.transaction-issue",
    },
    {
      label: "MERCHANT_ISSUE",
      value: "ticket-properties.option.sub-category.merchant-issue",
    },
    {
      label: "ACCOUNT_ISSUE",
      value: "ticket-properties.option.sub-category.account-issue",
    },
  ];

  static readonly categories = [
    {
      label: "CUSTOMER_SUPPORT",
      value: "ticket-properties.option.category.customer-support",
      sub: this.subCategoryOptions,
    },
    {
      label: "SALES",
      value: "ticket-properties.option.category.sales",
      sub: [],
    },
  ];

  static readonly sourceOptions = [
    { label: "CALL", value: "ticket-properties.option.source.call" },
    { label: "EMAIL", value: "ticket-properties.option.source.email" },
    { label: "ORDER", value: "ticket-properties.option.source.order" },
    { label: "OTHER", value: "ticket-properties.option.source.other" },
  ];
}
