import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { LeadDiscussion, useAppAuth } from "shared-components";
import { ButtonLabel } from "shared-components/src/types/discussionProps";
import { PaginationProps } from "shared-components/lib/typescript/src/types/tablePaginationProps";
import LeadService from "../../services/lead/leadService";

export interface AppLeadDiscussionProps {
  leadId: string;
  initialComment?: any;
  showCommentBox?: boolean;
}

const AppLeadDiscussion: React.FC<AppLeadDiscussionProps> = ({
  leadId,
  initialComment,
  showCommentBox,
}) => {
  const { authData } = useAppAuth();
  const [comments, setComments] = useState<any[]>([]);
  const userProfile = useMemo(() => {
    return JSON.parse(localStorage.getItem("userProfile") ?? "{}");
  }, []);
  const [paginationInfo, setPaginationInfo] = useState<PaginationProps>({
    total: 0,
    pageIndex: 1,
    pageSize: 10,
    rowPerPage: [10, 50, 100],
    totalPages: 0,
  });
  const { t } = useTranslation();
  const buttonLabel: ButtonLabel = {
    save: t("lead-discussion.button.save"),
    cancel: t("lead-discussion.button.cancel"),
  };

  const fetchComment = useCallback(
    async (paginationInfo: PaginationProps) => {
      try {
        const response = await LeadService.fetchComment(
          leadId,
          paginationInfo.pageIndex,
          paginationInfo.pageSize,
        );
        const { result } = response.data?.result;
        if (result.data?.length > 0) {
          setComments([...result.data]);
          setPaginationInfo((prevState) => {
            return {
              ...prevState,
              pageIndex: +result.pageIndex,
              pageSize: +result.pageSize,
              total: result.total,
              totalPages: result.totalPages,
            };
          });
          return result;
        }
        return null;
      } catch (e) {
        return null;
      }
    },
    [paginationInfo.pageIndex, paginationInfo.pageSize, leadId],
  );

  useEffect(() => {
    if (leadId) {
      fetchComment(paginationInfo).catch(() => {});
    }
  }, [leadId]);

  const handlePaginationChange = (value: PaginationProps) => {
    fetchComment(value).catch(() => {});
  };

  const handleAddComment = async (message: string) => {
    try {
      LeadService.addComment(leadId, message, authData.access_token).catch(
        () => {},
      );
      const total = paginationInfo.total + 1;
      const updatePaginationInfo = {
        ...paginationInfo,
        pageIndex: paginationInfo.pageIndex,
        pageSize: paginationInfo.pageSize,
        total: total,
        totalPages: Math.ceil(total / paginationInfo.pageSize),
      };
      if (paginationInfo.pageIndex === 1) {
        const comment = {
          message: message,
          createdDate: new Date(),
          createdBy: {
            fullName: userProfile?.fullName,
            avatar: userProfile?.avatar,
          },
        };
        const newComments = [comment, ...comments];
        setComments(newComments);
      }
      setPaginationInfo(updatePaginationInfo);
    } catch (error) {}
  };

  return (
    <LeadDiscussion
      paginationInfo={paginationInfo}
      onPaginationChange={handlePaginationChange}
      textPlaceHolder={t("lead-discussion.placeholder")}
      textNoData={t("lead-discussion.no-comment")}
      buttonLabel={buttonLabel}
      comments={comments}
      initialComment={initialComment}
      showCommentBox={showCommentBox}
      action={handleAddComment}
    />
  );
};

export default AppLeadDiscussion;
