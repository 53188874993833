import Api from '../../configs/api';
import ApiClient from '../api-client/apiClient';
import { resolveTenant } from '../../tenantResolver';

const CallService = {

  callVoiso: (phoneNumber: string, resourceType: string, resourceId: string) => {
    const userProfile = JSON.parse(localStorage.getItem("userProfile") ?? "{}");
    const url = `${Api.ENGAGEMENT_V1}/${resourceType}${Api.VOISO_CALL}`;
    const tenantConfig = resolveTenant();
    const authData = JSON.parse(
      localStorage.getItem(
        `oidc.at:${tenantConfig.oidcConfiguration.authority}`,
      ) ?? "{}",
    );
    const data = {
      agent: userProfile.agentExtension,
      phoneNumber: phoneNumber,
      resourceId
    };
    const headers = {
      token: authData.access_token,
    };
    return ApiClient(headers).post(url, data);
  },
}

export default CallService;
