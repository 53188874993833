import { Box, Pressable, Text, useTheme, useToast } from "native-base";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { Popup, AppButton } from "shared-components";
import { format } from "date-fns";
import { Modal } from "native-base";
import ProjectForm from "../project-form/ProjectForm";
import { ProgramOwner } from "../program-form/ProgramForm";
import ProjectService from "../../services/project/projectService";
import { PopupProps } from "shared-components/lib/typescript/src/ui-components/custom-components/popup/Popup";
import { isSuccess } from "../../constants/statusCode";
import { showToast } from "../../services/toast/toastService";
import { Header, TableAction } from "../../types/table";
import CoreTable from "../core-table/CoreTable";
import { formatLogoStyle } from "../program-listing/ProgramListing.style";
import { phoneNumberStyle } from "../lead-listing/LeadListing.style";

interface ProjectListingProps {
  programId: string;
  selectedProgramOwner: ProgramOwner;
}

interface StatusDisplay {
  [key: string]: {
    label: string;
    color: string;
  };
}

const ProjectListing: React.FC<ProjectListingProps> = ({
  programId,
  selectedProgramOwner,
}) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [projectId, setProjectId] = useState("");
  const theme: any = useTheme();
  const statusDisplay: StatusDisplay = {
    ENABLE: {
      label: t("project.enable"),
      color: theme?.appPalette?.green.green100,
    },
    DISABLE: {
      label: t("project.disable"),
      color: theme?.appPalette?.red.red100,
    },
  };
  const [isFetchData, setIsFetchData] = useState(false);
  const [showModalError, setShowModalError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const FORMAT_DATE = "dd/MM/yyyy";

  const getDuration = (startDate: number, endDate: number) => {
    const start = format(new Date(startDate), FORMAT_DATE);
    const end = format(new Date(endDate), FORMAT_DATE);
    return `${start} - ${end}`;
  };

  const viewDetails = (id: string) => {
    setOpen(true);
    setProjectId(id);
  };

  const disableProject = () => {
    ProjectService.updateStatusProject("DISABLE", projectId).then(
      (response) => {
        if (isSuccess(response.data.code)) {
          setIsFetchData(true);
        } else {
          setShowModalError(true);
          setErrorMsg(response.data.message);
        }
      }
    ).finally(() => {
      setTimeout(() => {
        setProjectId("");
        setIsFetchData(false);
      })
    });
  };

  const enableProject = (id: string) => {
    ProjectService.updateStatusProject("ENABLE", id).then((response) => {
      if (isSuccess(response.data.code)) {
        setIsFetchData(true);
      } else {
        setShowModalError(true);
        setErrorMsg(response.data.message);
      }
    }).finally(() => {
      setTimeout(() => {
        setProjectId("");
        setIsFetchData(false);
      })
    });
  };

  const showPopupBack = () => {
    const modal = {
      content: t("project.disable-project-desc"),
      typeButton: "danger",
      confirmText: t("project.disable"),
      cancelText: t("ticket-form.alert-cancel"),
    } as PopupProps;
    return (
      <Popup
        {...modal}
        showPopup={showPopup}
        onChangeShowPopup={(e) => setShowPopup(e)}
        confirmAction={disableProject}
      ></Popup>
    );
  };

  const showError = () => {
    const modal = {
      content: errorMsg,
      confirmText: t("common.ok"),
    } as PopupProps;
    return (
      <Popup
        {...modal}
        showPopup={showModalError}
        onChangeShowPopup={(e) => setShowModalError(e)}
      ></Popup>
    );
  };
  const toast = useToast();
  const showToastMessage = (message: string) => {
    const toastData = {
      message,
      icon: "/icons/toast/check.svg",
    };
    showToast(toast, toastData);
  };

  const fetchDatas = useCallback((params: any) => {
    return ProjectService.getProjectListing(params);
  }, []);

  const formatProjectName = (projectName: string, item: any) => {
    return (
      <Pressable onPress={() => viewDetails(item.id)}>
        <Text {...phoneNumberStyle(theme)}>{projectName}</Text>
      </Pressable>
    );
  };

  const formatLogo = (logoUrl: string) => {
    return (
      <Box lineHeight="56px" mt={1.5}>
        <img style={formatLogoStyle(theme)} src={logoUrl} alt="" />
      </Box>
    );
  };

  const formatStatus = (status: string) => {
    const statusColor = statusDisplay[status]?.color;
    return (
      <Text color={statusColor} lineHeight="56px">
        {statusDisplay[status]?.label}
      </Text>
    );
  };

  const formatDuration = (duration: string, item: any) => {
    return (
      <Text lineHeight="56px">{getDuration(item.startDate, item.endDate)}</Text>
    );
  };

  const headers: Header[] = [
    {
      label: t("programs.logo"),
      value: "logoUrl",
      format: formatLogo,
      width: 160,
    },
    {
      label: t("project.project-name"),
      value: "name",
      format: formatProjectName,
      width: 648,
    },
    {
      label: t("project.status"),
      value: "status",
      format: formatStatus,
      width: 164,
    },
    {
      label: t("project.duration"),
      value: "duration",
      format: formatDuration,
      width: 264,
    },
    {
      label: t("project.funding-goal"),
      value: "fundingGoalFormatted",
      width: 164,
    },
    {
      label: t("project.current-fund-amount"),
      value: "currentFundingAmountFormatted",
      width: 164,
    },
    {
      label: t("users-roles.action"),
      value: "action",
      width: 100,
      type: "ACTION",
    },
  ];

  const actions: TableAction[] = [
    {
      label: t("project.edit"),
      icon: "/icons/edit.svg",
      value: "EDIT_TICKET",
    },
    {
      label: t("project.disable"),
      icon: "/icons/disable.svg",
      value: "DISABLE",
    },
    {
      label: t("project.enable"),
      icon: "/icons/enable.svg",
      value: "ENABLE",
    },
  ];

  const handleAction = (code: string, id: string) => {
    setProjectId(id);
    if (code === "EDIT_TICKET") {
      setOpen(true);
    } else if (code === "DISABLE") {
      setShowPopup(true);
    } else {
      enableProject(id);
    }
  };

  const actionRow = () => {
    return (
      <Box ml={3}>
        <AppButton
          variant="secondary"
          label={t("users-roles.create")}
          icon="/icons/add-icon.svg"
          onPress={() => setOpen(true)}
        />
      </Box>
    );
  };

  return (
    <Box>
      {showPopupBack()}
      {showError()}
      <CoreTable
        headers={headers}
        isFetch={isFetchData}
        isBorder={true}
        isSearch={false}
        fetchDatas={fetchDatas}
        onClickAction={(code, data) => handleAction(code, data.id)}
        actionRow={actionRow()}
        fetchDataListById={{
          key: "programId",
          id: programId,
        }}
        title={t("project.project-list")}
        actions={actions}
      />
      <Modal isOpen={open}>
        <Modal.Content
          maxHeight="100vh"
          marginLeft="auto"
          marginRight="0"
          maxWidth="728"
          borderRadius="0"
        >
          {open && (
            <ProjectForm
              programId={programId}
              projectId={projectId}
              selectedProgramOwner={selectedProgramOwner}
              closeModal={(isFetchData, message) => {
                if (isFetchData) {
                  setIsFetchData(isFetchData);
                  setTimeout(() => {
                    setIsFetchData(false);
                  })
                }
                setOpen(false);
                setProjectId("");
                if (message) {
                  showToastMessage(message);
                }
              }}
            />
          )}
        </Modal.Content>
      </Modal>
    </Box>
  );
};

export default ProjectListing;
