import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { MultipleSelector, SidePanel } from "shared-components";
import { OptionItemDTO } from "shared-components/lib/typescript/src/models/option-item.dto";
import RoleService from "../../services/role/roleService";
interface EditUserDrawerProps {
  id: string;
  setOpen: (open: boolean) => void;
  initData: OptionItemDTO[];
  closeModal: (isFetchData: boolean, content?: string) => void;
}

const EditUserDrawer: React.FC<EditUserDrawerProps> = ({
  id,
  setOpen,
  initData,
  closeModal,
}) => {
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const [selectedRole, setSelectedRole] = useState<any[]>([]);

  useEffect(() => {
    setSelectedRole(initData);
  }, [initData]);

  useEffect(() => {
    getRoleListing();
  }, []);

  const getRoleListing = () => {
    const body = {
      keyword: "",
      pageSize: 10000,
      pageIndex: 1,
      orderBy: "name",
      orderDirection: "ASC",
      status: 1,
    };
    RoleService.list(body).then((res) => {
      const mappedData = res.data.result.data.map((item: any) => {
        return {
          id: item.id,
          name: item.name,
        };
      });
      setData(mappedData);
    });
  };

  const sidePanelProps = {
    closeFilterIcon: "/icons/filter-close.svg",
    labels: {
      title: t("users-roles.edit-user"),
      primaryButton: t("ticket-form.btn-submit-edit"),
      secondaryButton: t("ticket-discussion.button.cancel"),
    },
    width: "560px",
    onClose: () => {
      setOpen(false);
    },
    onPrimaryButtonPress: () => {},
    onSecondaryButtonPress: () => {
      setOpen(false);
    },
  };

  const multipleSelectorProps = {
    data: data,
    initData: initData,
    text: "name",
    value: "id",
    dropdownIcon: "/icons/arrow-down.svg",
    deleteIcon: "/icons/close.svg",
    isInvalid: false,
    noDataIcon: "/icons/no-data.svg",
    textObject: {
      placeholderSearch: t("users-roles.role-search"),
      title: t("users-roles.role"),
      footer: t("users-roles.role-footer"),
      selectRole: t("users-roles.select-role"),
      noDataText: t("users-roles.no-data"),
    },
  };

  const editRole = () => {
    const roleIds = selectedRole?.map((item) => item.id) || [];
    RoleService.editRole(id, roleIds).then((_) => {
      closeModal(true, t("users-roles.updated-user-successfully"));
    });
  };

  return (
    <SidePanel {...sidePanelProps} onPrimaryButtonPress={editRole}>
      <MultipleSelector
        {...multipleSelectorProps}
        onChange={(selectedItems: OptionItemDTO[]) => {
          setSelectedRole(selectedItems);
        }}
      />
    </SidePanel>
  );
};

export default EditUserDrawer;
