import { useTheme, useToast } from "native-base";
import { useEffect, useState } from "react";
import { Controller, useForm, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import {
  AppButton,
  Box,
  Popup,
  Pressable,
  Text,
  Image,
  AppInput,
  ImageCropper,
  UploadFile,
  DropdownMenu,
  Textarea,
} from "shared-components";
import { REGEX_BASE64_IMAGE, REGEX_EMAIL } from "../../constants/regex";
import { isSuccess } from "../../constants/statusCode";
import ProgramService from "../../services/program/programService";
import {
  programTitleStyle,
  informationStyle,
  boxWrapper,
  generalInformationTitleStyle,
  textStyle,
  gap16,
  gap6,
  errorStyle,
  gap24,
  width50,
  programNameStyle,
  programOwnerStyle,
  directlyStyle,
  socialNetworkStyle,
  protocolStyle,
  blockStyle,
  gap6DivStyle,
  checkboxStyle,
  optionalStyle,
} from "./ProgramForm.style";
import { showToast } from "../../services/toast/toastService";
import { PopupProps } from "shared-components/lib/typescript/src/ui-components/custom-components/popup/Popup";
import ProjectListing from "../project-listing/ProjectListing";
import { validate } from "./validators";
export interface ProgramOwner {
  ownerUserId: string;
  ownerName: string;
}

interface OptionItem {
  label: string;
  value: string;
}

const ProgramForm = () => {
  const theme = useTheme();
  const toast = useToast();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();
  const {
    handleSubmit,
    control,
    formState: { errors, isSubmitted },
    setValue,
    getValues,
  } = useForm();
  const [isDirectFunding, setIsDirectFunding] = useState(false);
  const [logo, setLogo] = useState("");
  const [banner, setBanner] = useState("");
  const [logoUrl, setLogoUrl] = useState("");
  const [bannerUrl, setBannerUrl] = useState("");
  const [protocol, setProtocol] = useState<any>("");
  const [protocolUrl, setProtocolUrl] = useState<any>("");
  const [protocolName, setProtocolName] = useState("");
  const [helpline, setHelpline] = useState("");
  const [logoErrMsg, setLogoErrMsg] = useState("");
  const [bannerErrMsg, setBannerErrMsg] = useState("");
  const [protocolErrMsg, setProtocolErrMsg] = useState("");
  const [selectedProgramOwner, setSelectedProgramOwner] =
    useState<ProgramOwner>();
  const [defaultOption, setDefaultOption] = useState<OptionItem>();
  const [programOwner, setProgramOwner] = useState<OptionItem[]>([]);
  const [submitted, setSubmitted] = useState(false);
  const [showModalError, setShowModalError] = useState(false);
  const [showBack, setShowBack] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [isValueChanges, setIsValueChanges] = useState(false);
  const back = () => {
    navigate("/programs");
  };

  const handleValidate = () => {
    setLogoErrMsg(
      !logo
        ? t("validate.standard070", { field: t("programs.logo-image") })
        : ""
    );
    setBannerErrMsg(
      !banner
        ? t("validate.standard070", { field: t("programs.banner-image") })
        : ""
    );
    setProtocolErrMsg(
      !protocol
        ? t("validate.standard070", { field: t("programs.protocol") })
        : ""
    );
    return validate(logo, banner, protocol, selectedProgramOwner?.ownerUserId);
  };

  useEffect(() => {
    if (isSubmitted) {
      handleValidate();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmitted]);

  const validateMail = (e: string) => {
    return (
      REGEX_EMAIL.test(e) ||
      t("validate.standard069", { field: t("programs.email") })
    );
  };

  const validateHelpline = (e: string) => {
    return (
      e.length > 9 ||
      t("validate.standard069", { field: t("programs.helpline") })
    );
  };

  const isBase64Image = (str: string) => {
    return REGEX_BASE64_IMAGE.test(str);
  };

  const watchedValues = useWatch({
    control,
    name: [
      "name",
      "summary",
      "email",
      "website",
      "helpline",
      "address",
      "facebookUrl",
      "twitterUrl",
    ],
  });

  useEffect(() => {
    const anyValueChanged = watchedValues.some((value) => value !== undefined);
    setIsValueChanges(anyValueChanged);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchedValues]);

  const prepareFormData = (data: any) => {
    const formData = new FormData();
    formData.append("name", data.name.trim());
    formData.append(
      "ownerUserId",
      selectedProgramOwner ? selectedProgramOwner.ownerUserId : ""
    );
    formData.append(
      "ownerName",
      selectedProgramOwner ? selectedProgramOwner.ownerName : ""
    );
    formData.append("summary", data.summary.trim());
    formData.append("email", data.email);
    formData.append("websiteUrl", data.website.trim());
    formData.append("phoneNumber", data.helpline);
    formData.append("address", data.address.trim());
    formData.append("isDirectFunding", isDirectFunding.toString());
    formData.append("fbUrl", data.facebookUrl ? data.facebookUrl?.trim() : "");
    formData.append(
      "twitterUrl",
      data.twitterUrl ? data.twitterUrl?.trim() : ""
    );
    return formData;
  };

  const appendBase64AndUrls = (
    formData: FormData,
    logo: string,
    banner: string,
    id: string | undefined,
    protocol: any
  ) => {
    if (!!id) {
      formData.append(
        "logoBase64",
        isBase64Image(logo) ? logo.replace(REGEX_BASE64_IMAGE, "") : ""
      );
      formData.append(
        "bannerBase64",
        isBase64Image(banner) ? banner.replace(REGEX_BASE64_IMAGE, "") : ""
      );
      formData.append("logoUrl", logoUrl);
      formData.append("bannerUrl", bannerUrl);
      formData.append(
        "protocolFile",
        typeof protocol !== "string" ? protocol : ""
      );
      formData.append("protocolFileName", protocolName);
      formData.append("protocolUrl", protocolUrl);
    } else {
      formData.append("protocolFile", protocol ? protocol : "");
      formData.append("logoBase64", logo.replace(REGEX_BASE64_IMAGE, ""));
      formData.append("bannerBase64", banner.replace(REGEX_BASE64_IMAGE, ""));
    }
  };

  const handleResponse = (response: any, successMessage: string) => {
    if (isSuccess(response.data.code)) {
      const toastData = {
        message: t(successMessage),
        icon: "/icons/toast/check.svg",
      };
      showToast(toast, toastData);
      back();
    } else {
      setErrorMsg(response.data.message);
      setShowModalError(true);
    }
    setSubmitted(false);
  };

  const onSubmit = (data: any) => {
    if (!handleValidate() && !submitted) {
      setSubmitted(true);
      const formData = prepareFormData(data);
      appendBase64AndUrls(formData, logo, banner, id, protocol);

      if (!!id) {
        ProgramService.editProgram(formData, id).then(
          (response) =>
            handleResponse(response, "programs.program-updated-successfully"),
          () => setSubmitted(false)
        );
      } else {
        ProgramService.createProgram(formData).then(
          (response) =>
            handleResponse(response, "programs.program-created-successfully"),
          () => setSubmitted(false)
        );
      }
    }
  };

  const showError = () => {
    const modal = {
      content: errorMsg,
      confirmText: t("common.ok"),
    } as PopupProps;
    return (
      <Popup
        {...modal}
        showPopup={showModalError}
        onChangeShowPopup={(e) => setShowModalError(e)}
      ></Popup>
    );
  };

  const handleBack = () => {
    if (isValueChanges) {
      setShowBack(true);
    } else {
      back();
    }
  };

  const showPopupBack = () => {
    const modal = {
      content: t("ticket-form.alert-content"),
      typeButton: "danger",
      confirmText: t("ticket-form.alert-exit"),
      cancelText: t("ticket-form.alert-cancel"),
    } as PopupProps;
    return (
      <Popup
        {...modal}
        showPopup={showBack}
        onChangeShowPopup={(e) => setShowBack(e)}
        confirmAction={back}
      ></Popup>
    );
  };

  useEffect(() => {
    ProgramService.getProgramOwner().then((response) => {
      const { result } = response.data;
      if (result) {
        const mappedData = result.map((item: any) => {
          return {
            label: item.merchantName,
            value: item.visipayId,
          };
        });
        setProgramOwner(mappedData);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (id) {
      ProgramService.getProgramDetails(id).then((response) => {
        const { result } = response.data;
        if (result) {
          setLogo(result.logoUrl);
          setBanner(result.bannerUrl);
          setLogoUrl(result.logoUrl);
          setBannerUrl(result.bannerUrl);
          setValue("name", result.name);
          setValue("summary", result.summary);
          setValue("email", result.email);
          setValue("website", result.websiteUrl);
          setValue("helpline", result.phoneNumber);
          setValue("facebookUrl", result.fbUrl || "");
          setValue("twitterUrl", result.twitterUrl || "");
          setHelpline(result.phoneNumber);
          setValue("address", result.address);
          setProtocolName(result.protocolFileName);
          setProtocol(result.protocolUrl);
          setProtocolUrl(result.protocolUrl);
          setSelectedProgramOwner({
            ownerName: result.ownerName,
            ownerUserId: result.ownerUserId,
          });
          setIsDirectFunding(result.isDirectFunding);
          setDefaultOption({
            label: result.ownerName,
            value: result.ownerUserId,
          });
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const header = () => {
    return (
      <Box
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Box flexDirection="row" alignItems="center">
          <Pressable onPress={handleBack}>
            <Image source={{ uri: "/icons/back.svg" }} w={6} h={6}></Image>
          </Pressable>
          <Text {...programTitleStyle(theme)}>{t("programs.program")}</Text>
        </Box>
        <Box>
          <AppButton
            label={
              !!id
                ? t("ticket-form.btn-submit-edit")
                : t("ticket-form.btn-submit-create")
            }
            onPress={handleSubmit(onSubmit)}
          />
        </Box>
      </Box>
    );
  };

  const information = () => {
    return (
      <Box {...informationStyle()}>
        <Box {...gap16}>
          <Box {...generalInformationTitleStyle(theme)}>
            <Text {...textStyle(theme)}>{t("programs.general-info")}</Text>
          </Box>
          <Box flexDirection="row" {...gap24}>
            <div style={programNameStyle}>
              <Text {...textStyle(theme)}>{t("programs.program-name")}</Text>
              <Controller
                name="name"
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field }) => (
                  <AppInput
                    placeholder={t("programs.enter-program-name")}
                    maxLength={250}
                    onChangeText={(text: string) => {
                      field.onChange(text);
                    }}
                    defaultValue={getValues("name")}
                    isInvalid={errors.name?.type === "required"}
                  />
                )}
              />
              {errors.name?.type === "required" && (
                <Text {...errorStyle(theme)}>
                  {t("validate.standard070", {
                    field: t("programs.program-name"),
                  })}
                </Text>
              )}
            </div>
            <div style={programOwnerStyle}>
              <Text {...textStyle(theme)}>{t("programs.program-owner")}</Text>
              <DropdownMenu
                disabled={!!id}
                data={programOwner}
                dropDownIcon={"/icons/dropdown-arrow.svg"}
                selectedIcon={"/icons/checked.svg"}
                placeholder={t("programs.select-program-owner")}
                onValueChange={(e) => {
                  setSelectedProgramOwner({
                    ownerName: e.label,
                    ownerUserId: e.value,
                  });
                  setIsValueChanges(true);
                }}
                defaultOption={defaultOption}
                hasError={!selectedProgramOwner && isSubmitted}
              />
              {!selectedProgramOwner && isSubmitted && (
                <div style={errorStyle(theme)}>
                  {t("validate.standard070", {
                    field: t("programs.program-owner"),
                  })}
                </div>
              )}
            </div>
          </Box>
          <div style={gap6DivStyle}>
            <Text {...textStyle(theme)}>{t("programs.summary")}</Text>
            <Controller
              name="summary"
              control={control}
              rules={{
                required: true,
              }}
              render={({ field }) => (
                <Textarea
                  defaultValue={getValues("summary")}
                  placeholder={t("programs.enter-summary")}
                  hasError={errors.summary?.type === "required"}
                  onValueChange={(e) => {
                    field.onChange(e);
                  }}
                />
              )}
            />
            {errors.summary?.type === "required" && (
              <Text {...errorStyle(theme)}>
                {t("validate.standard070", { field: t("programs.summary") })}
              </Text>
            )}
            <Box {...directlyStyle}>
              <input
                checked={isDirectFunding}
                type="checkbox"
                onChange={(e) => {
                  setIsDirectFunding(e.target.checked);
                  setIsValueChanges(true);
                }}
                style={checkboxStyle(theme)}
              />
              <Text>{t("programs.direct-funding-allowance")}</Text>
            </Box>
          </div>
          <Box flexDirection="row" {...gap24}>
            <Box {...gap6}>
              <Text {...textStyle(theme)}>{t("programs.logo-image")}</Text>
              <ImageCropper
                width={200}
                height={200}
                allowedTypes={["image/jpeg", "image/png", "image/jpg"]}
                dropLabel={t("programs.crop")}
                uploadLabel={t("programs.upload")}
                btnCancelLabel={t("lead-invitation.cancel")}
                btnDropLabel={t("programs.crop-and-save")}
                minCroppedWidth={428}
                minCroppedHeight={246}
                maxCroppedWidth={1284}
                maxCroppedHeight={738}
                errorMsg={{
                  standard008: t("validate.standard008", {
                    maxDimension: "1284x738px",
                  }),
                  standard067: t("validate.standard067", {
                    minDimension: "428x246px",
                  }),
                  standard070: t("validate.standard070", {
                    field: t("programs.logo-image"),
                  }),
                  standard081: t("validate.standard081", { maxSize: "5MB" }),
                  standard091: t("validate.standard091"),
                }}
                defaultErrMsg={logoErrMsg}
                maxSize={5}
                uploadIcon={"/icons/plus.svg"}
                closePopupIcon={"/icons/close.svg"}
                closeIcon={"/icons/close-image.png"}
                aspectRatio={1}
                defaultImageUrl={logo}
                onValueChange={(base64) => {
                  setLogo(base64);
                  setIsValueChanges(true);
                }}
              />
            </Box>
            <Box {...gap6}>
              <Text {...textStyle(theme)}>{t("programs.banner-image")}</Text>
              <ImageCropper
                width={320}
                height={200}
                allowedTypes={["image/jpeg", "image/png", "image/jpg"]}
                dropLabel={t("programs.crop")}
                uploadLabel={t("programs.upload")}
                btnCancelLabel={t("lead-invitation.cancel")}
                btnDropLabel={t("programs.crop-and-save")}
                minCroppedWidth={428}
                minCroppedHeight={246}
                maxCroppedWidth={1284}
                maxCroppedHeight={738}
                errorMsg={{
                  standard008: t("validate.standard008", {
                    maxDimension: "1284x738px",
                  }),
                  standard067: t("validate.standard067", {
                    minDimension: "428x246px",
                  }),
                  standard070: t("validate.standard070", {
                    field: t("programs.banner-image"),
                  }),
                  standard081: t("validate.standard081", { maxSize: "5MB" }),
                  standard091: t("validate.standard091"),
                }}
                maxSize={5}
                uploadIcon={"/icons/plus.svg"}
                closeIcon={"/icons/close-image.png"}
                closePopupIcon={"/icons/close.svg"}
                defaultErrMsg={bannerErrMsg}
                aspectRatio={16 / 9}
                defaultImageUrl={banner}
                onValueChange={(base64) => {
                  setBanner(base64);
                  setIsValueChanges(true);
                }}
              />
            </Box>
          </Box>
        </Box>
        <Box {...gap16}>
          <Box {...generalInformationTitleStyle(theme)}>
            <Text {...textStyle(theme)}>
              {t("programs.contact-information")}
            </Text>
          </Box>
          <Box flexDirection="row" {...gap24}>
            <div style={width50}>
              <Text {...textStyle(theme)}>{t("programs.email")}</Text>
              <Controller
                name="email"
                control={control}
                rules={{
                  required: true,
                  validate: validateMail,
                }}
                render={({ field }) => (
                  <AppInput
                    defaultValue={getValues("email")}
                    placeholder={t("programs.enter-email")}
                    maxLength={320}
                    onChangeText={(text: string) => {
                      field.onChange(text);
                    }}
                    isInvalid={
                      errors.email?.type === "required" ||
                      errors.email?.type === "validate"
                    }
                  />
                )}
              />
              {errors.email?.type === "required" && (
                <Text {...errorStyle(theme)}>
                  {t("validate.standard070", { field: t("programs.email") })}
                </Text>
              )}
              {errors.email?.type === "validate" && (
                <Text {...errorStyle(theme)}>
                  {errors.email?.message?.toString()}
                </Text>
              )}
            </div>
            <div style={width50}>
              <Text {...textStyle(theme)}>{t("programs.helpline")}</Text>
              <Controller
                name="helpline"
                control={control}
                rules={{
                  required: true,
                  validate: validateHelpline,
                }}
                render={({ field }) => (
                  <AppInput
                    placeholder={t("programs.enter-helpline")}
                    maxLength={14}
                    value={helpline}
                    onChangeText={(text: string) => {
                      const numericValue = text.replace(/\D/g, "");
                      if (numericValue.includes(text)) {
                        setHelpline(text);
                        field.onChange(text);
                      }
                    }}
                    isInvalid={
                      errors.helpline?.type === "required" ||
                      errors.helpline?.type === "validate"
                    }
                  />
                )}
              />
              {errors.helpline?.type === "required" && (
                <Text {...errorStyle(theme)}>
                  {t("validate.standard070", { field: t("programs.helpline") })}
                </Text>
              )}
              {errors.helpline?.type === "validate" && (
                <Text {...errorStyle(theme)}>
                  {errors.helpline?.message?.toString()}
                </Text>
              )}
            </div>
          </Box>
          <Box flexDirection="row" {...gap24}>
            <div style={width50}>
              <Text {...textStyle(theme)}>{t("programs.address")}</Text>
              <Controller
                name="address"
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field }) => (
                  <AppInput
                    placeholder={t("programs.enter-address")}
                    maxLength={250}
                    onChangeText={(text: string) => {
                      field.onChange(text);
                    }}
                    defaultValue={getValues("address")}
                    isInvalid={errors.address?.type === "required"}
                  />
                )}
              />
              {errors.address?.type === "required" && (
                <Text {...errorStyle(theme)}>
                  {t("validate.standard070", { field: t("programs.address") })}
                </Text>
              )}
            </div>
            <div style={width50}>
              <Text {...textStyle(theme)}>{t("programs.website")}</Text>
              <Controller
                name="website"
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field }) => (
                  <AppInput
                    placeholder={t("programs.enter-website-url")}
                    maxLength={250}
                    onChangeText={(text: string) => {
                      field.onChange(text);
                    }}
                    defaultValue={getValues("website")}
                    isInvalid={errors.website?.type === "required"}
                  />
                )}
              />
              {errors.website?.type === "required" && (
                <Text {...errorStyle(theme)}>
                  {t("validate.standard070", { field: t("programs.website") })}
                </Text>
              )}
            </div>
          </Box>
        </Box>
      </Box>
    );
  };

  const protocolComponent = () => {
    return (
      <Box {...protocolStyle()}>
        <Box {...generalInformationTitleStyle(theme)}>
          <Text {...textStyle(theme)}>{t("programs.protocol")}</Text>
        </Box>
        <Box width="100%">
          <UploadFile
            allowedTypes={["application/pdf"]}
            uploadIcon={"/icons/download-icon.svg"}
            closeIcon={"/icons/close.svg"}
            label={t("create-bulk-leads.uploadFileText")}
            maxSize={5}
            defaultErrMsg={protocolErrMsg}
            description={t("programs.upload-pdf-max-size")}
            errorMsg={{
              standard070: t("validate.standard070", {
                field: t("programs.protocol"),
              }),
              standard081: t("validate.standard081", { maxSize: "5MB" }),
              standard091: t("validate.standard091"),
            }}
            defaultFileName={protocolName}
            onValueChange={(file: File | null) => {
              setProtocol(file);
              setIsValueChanges(true);
            }}
          />
        </Box>
      </Box>
    );
  };

  const socialNetwork = () => {
    return (
      <Box {...socialNetworkStyle()}>
        <Box {...generalInformationTitleStyle(theme)}>
          <Text {...textStyle(theme)}>{t("programs.social-network")}</Text>
        </Box>
        <Box {...gap6}>
          <Box flexDirection="row" alignItems="center">
            <Text {...textStyle(theme)}>{t("programs.facebook")}</Text>
            <Text {...optionalStyle(theme)}>
              {" "}
              ({t("ticket-form.optional")})
            </Text>
          </Box>
          <Controller
            name="facebookUrl"
            control={control}
            render={({ field }) => (
              <AppInput
                placeholder={t("programs.enter-url")}
                maxLength={250}
                onChangeText={(text: string) => {
                  field.onChange(text);
                }}
                defaultValue={getValues("facebookUrl")}
              />
            )}
          />
        </Box>
        <Box {...gap6}>
          <Box flexDirection="row" alignItems="center">
            <Text {...textStyle(theme)}>{t("programs.x-twitter")}</Text>
            <Text {...optionalStyle(theme)}>
              {" "}
              ({t("ticket-form.optional")})
            </Text>
          </Box>
          <Controller
            name="twitterUrl"
            control={control}
            render={({ field }) => (
              <AppInput
                placeholder={t("programs.enter-url")}
                maxLength={250}
                onChangeText={(text: string) => {
                  field.onChange(text);
                }}
                defaultValue={getValues("twitterUrl")}
              />
            )}
          />
        </Box>
      </Box>
    );
  };

  return (
    <Box marginTop="-16px">
      {showPopupBack()}
      {showError()}
      {header()}
      <Box {...boxWrapper}>
        {information()}
        <div style={blockStyle}>
          {protocolComponent()}
          {socialNetwork()}
        </div>
      </Box>
      {!!id && !!selectedProgramOwner && (
        <Box marginTop="16px">
          <ProjectListing
            programId={id}
            selectedProgramOwner={selectedProgramOwner}
          />
        </Box>
      )}
    </Box>
  );
};

export default ProgramForm;
