
import ApiClient from "../api-client/apiClient";
import Api from "../../configs/api";

const TenantConfigService = {
  getTenantConfig: (configName: string) => {
    const queryParam = `?configName=${configName}`;
    const url = `${Api.GET_TENANT_CONFIG}${queryParam}`;
    return ApiClient().get(url);
  },

  getSupportedLanguages: () => {
    const url = Api.GET_SUPPORTED_LANGUAGES;
    return ApiClient().get(url);
  },

  getTenantConfiguration: (configName: string) => {
    const url = Api.GET_TENANT_CONFIGURATION;
    const params = {configName};
    return ApiClient().get(url, {params});
  },
};

export default TenantConfigService;
