import { Box, TabBar, Text } from "shared-components";
import { textHeader20 } from "../../styles/text";
import { useTheme } from "native-base";
import { useMemo, useState } from "react";
import UserListing from "../user-listing/UserListing";
import RoleListing from "../role-listing/RoleListing";
import { tabBar } from "../../styles/tabBar";
import { useTranslation } from "react-i18next";
import { PERMISSIONS } from "../../constants/permisstion";
import AuthorizationService from "../../auth/authorizationService";
import { useLocation } from "react-router-dom";

const UsersAndRoles = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const initialActiveTab = location.state?.activeTab || "USER";
  const tabBarItems = [
    {
      label: t("users-roles.user-management"),
      value: "USER",
      isActive: initialActiveTab === "USER",
      permissions: [PERMISSIONS.USERS_ROLES.USERS_LISTING],
    },
    {
      label: t("users-roles.role-management"),
      value: "ROLE",
      isActive: initialActiveTab === "ROLE",
      permissions: [PERMISSIONS.USERS_ROLES.ROLES_LISTING],
    },
  ];
  let allowedTabbarItems =
    AuthorizationService.getAllowedResources(tabBarItems);

  if (allowedTabbarItems.length === 1) {
    allowedTabbarItems[0].isActive = true;
  }

  const theme = useTheme();

  const [tabbar, setTabbar] = useState(allowedTabbarItems);

  const handleActiveTab = (value: string) => {
    const items = tabbar.map((item) => {
      return {
        ...item,
        isActive: value === item.value,
      };
    });
    setTabbar(items);
  };

  const activeTab = useMemo(() => {
    return tabbar.find((item) => item.isActive)?.value;
  }, [tabbar]);

  return (
    <Box marginTop="-24px">
      <Box height="64px" justifyContent="center">
        <Text {...textHeader20(theme)}>{t("users-roles.title")}</Text>
      </Box>
      <Box {...tabBar(theme)}>
        <TabBar tabItems={tabbar} onClick={(value) => handleActiveTab(value)} />
      </Box>
      {activeTab === "USER" ? <UserListing /> : <RoleListing />}
    </Box>
  );
};

export default UsersAndRoles;
