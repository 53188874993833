import React, { useMemo } from "react";
import AuthorizationService from "../../auth/authorizationService";

interface ProtectedContentProps {
  children?: React.ReactNode;
  alt?: React.ReactNode;
  permissions?: string | string[];
}

const ProtectedContent: React.FC<ProtectedContentProps> = ({
  children,
  alt,
  permissions,
}) => {
  const showContent = useMemo(() => {
    return AuthorizationService.hasPermissions(permissions, "every");
  }, []);
  return showContent ? <>{children}</> : <>{alt}</>;
};

export default ProtectedContent;
