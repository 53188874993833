import { textBody14, textHeader14 } from "../../styles/text";

export const tableStyle = (theme: any, isBorder?: boolean) => ({
  borderWidth: 1,
  borderColor: theme?.appPalette?.grey?.grey12,
  borderTopRightRadius: isBorder ? 8 : 0,
  borderTopLeftRadius: isBorder ? 8 : 0,
  borderBottomRightRadius: 8,
  borderBottomLeftRadius: 8,
  backgroundColor: theme?.appPalette?.grey.white
});

export const headerStyle = (theme: any) => ({
  display: "flex",
  flexDirection: "row" as any,
  justifyContent: "space-between",
  w: "max-content",
  minWidth: "100%",
  height: "56px",
  borderBottomWidth: 1,
  borderTopWidth: 1,
  borderColor: theme?.appPalette?.grey?.grey12,
  backgroundColor: theme?.appPalette?.grey?.white,
  pl: 3,
  position: "sticky" as any,
  top: 0,
  zIndex: 2,
});

export const bodyStyle = (theme: any) => ({
  display: "flex",
  flexDirection: "row" as any,
  justifyContent: "space-between",
  width: "100%",
  height: "56px",
  borderBottomWidth: 1,
  borderColor: theme?.appPalette?.grey?.grey12,
  pl: 3,
});

export const textHeader = (theme: any) => ({
  ...textHeader14(theme),
  lineHeight: "56px",
});

export const spinnerStyle = (theme: any) => ({
  color: theme.appPalette?.primary.primary100,
  top: "50%",
  left: "60%",
  transform: "translate(-60%, -50%)",
});

export const textBody = (theme: any) => ({
  ...textBody14(theme),
  lineHeight: "56px",
});

export const actionStyle = (theme: any) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: "56px",
  borderBottomWidth: 1,
  borderColor: theme?.appPalette?.grey?.grey12,
  backgroundColor: theme?.appPalette?.grey?.white,
  position: "sticky" as any,
  right: 0,
  style: {
    boxShadow: "-2px 0px 8px 0px #0000000D",
  } as any,
});

export const centerStyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}

export const noDataTextStyle = (theme: any) => ({
  color: theme.appPalette?.grey?.grey40,
  fontWeight: "600",
  fontSize: "16px",
});

export const noDataBoxStyle = () => ({
  top: "55%",
  left: "50%",
  style: {
    transform: "translate(-50%, -50%)",
  },
});

export const titleHeaderBox = () => ({
  direction: "row" as any,
  h: "56px",
  justifyContent: "end",
  alignItems: "center",
  px: 3,
  gap: '12px',
  py: 2,
});

export const titleStyle = (theme: any) => ({
  color: theme?.appPalette?.grey.grey80,
  fontWeight: 600,
  lineHeight: "24px",
  fontSize: "20px",
  ml: 3
});
