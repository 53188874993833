export const dotStyle = (theme: any) => ({
  width: '5px',
  height: '5px',
  top: '9px',
  right: '10px',
  borderRadius: '50%',
  backgroundColor: theme?.appPalette?.primary?.primary100,
});

export const filterStyle = (theme: any) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  w: '40px',
  h: '40px',
  paddingTop: '2px',
  cursor: 'pointer',
  _hover: {
    backgroundColor: theme?.appPalette?.grey?.grey4,
    borderRadius: '8px',
  },
});

export const phoneNumberStyle = (theme: any) => ({
  color: theme?.appPalette?.info,
  fontFamily: theme?.fontFamily,
  lineHeight: "56px",
})

export const textStyle = (theme: any) => ({
  fontFamily: theme?.fontFamily,
  color: theme?.appPalette?.grey.grey80,
  lineHeight: "56px"
})

