import ApiClient from "../api-client/apiClient";
import Api from "../../configs/api";
import { resolveTenant } from "../../tenantResolver";

const getAuthHeaders = () => {
  const tenantConfig = resolveTenant();
  const authData = JSON.parse(
    localStorage.getItem(
      `oidc.at:${tenantConfig.oidcConfiguration.authority}`
    ) ?? "{}"
  );
  return {
    token: authData.access_token,
  };
};

const ConsumerService = {
  list: (data: any) => {
    const body = {
      ...data,
      userType: ["CONSUMER"],
    };
    const headers = getAuthHeaders();
    const url = `${Api.GET_CONSUMER_LISTING}`;
    return ApiClient(headers).post(url, body);
  },
};

export default ConsumerService;
