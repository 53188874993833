import { Box, Pressable, Spinner, Text, useTheme } from "native-base";
import React, { useEffect, useRef, useState } from "react";
import { ReactSVG } from "react-svg";
import { textBody16, textNodata } from "../../styles/text";
import {
  boxContainerStyle,
  boxItemStyle,
  boxStyle,
  phoneStyle,
  spinnerStyle,
} from "./UserInformationDropdown.style";
import { Flex } from "native-base";
import ProfileService from "../../services/profile/profileService";
import { useTranslation } from "react-i18next";

export interface UserInformationDropdownProps {
  keySearch?: string;
  isFetchData?: boolean;
  setIsFetchData?: (isFetchData: boolean) => void;
  onValueChange?: (data: any) => void;
  maxHeight?: string;
}

const UserInformationDropdown: React.FC<UserInformationDropdownProps> = ({
  keySearch,
  isFetchData,
  setIsFetchData,
  onValueChange,
  maxHeight,
}) => {
  const { t } = useTranslation();
  const [data, setData] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isFetch, setIsFetch] = useState(false);
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const boxUserRef = useRef<HTMLDivElement | null>(null);
  const [pageIndex, setPageIndex] = useState(1);
  const [isScroll, setIsCroll] = useState(true);
  const [isMargin, setIsMargin] = useState(false);

  useEffect(() => {
    if (isFetchData) {
      setOpen(true);
      setData([]);
      setPageIndex(1);
      setIsFetch(isFetchData);
      setIsCroll(true);
    }
  }, [isFetchData]);

  useEffect(() => {
    if (isFetch && isScroll) {
      setIsLoading(true);
      const params = {
        keyword: keySearch,
        pageSize: 10,
        pageIndex: pageIndex,
        orderBy: "createdDate",
        orderDirection: "DESC",
        userType: ["MERCHANT", "CONSUMER"],
      };
      ProfileService.getUserProfileByUserType(params)
        .then((response) => {
          const { result } = response.data;
          if (result.data?.length > 0) {
            setData((prevData) => [...prevData, ...result.data]);
          }
          setIsCroll(pageIndex < result.totalPages);
        })
        .finally(() => {
          setIsLoading(false);
          setIsFetch(false);
          setIsFetchData && setIsFetchData(false);
          if (boxUserRef.current) {
            boxUserRef.current.scrollTop = boxUserRef.current.scrollHeight;
          }
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetch, pageIndex]);

  const handleMouseEnter = () => {
    setOpen(true);
  };

  const handleMouseLeave = () => {
    setOpen(false);
  };

  const handleScroll = () => {
    if (boxUserRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = boxUserRef.current;
      setIsMargin(scrollHeight > clientHeight);
      if (scrollTop + clientHeight >= scrollHeight) {
        setIsFetch(true);
        setPageIndex((prevState) => prevState + 1);
      }
    }
  };

  useEffect(() => {
    if (boxUserRef.current) {
      boxUserRef.current.addEventListener("scroll", handleScroll);
    }
    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      boxUserRef.current?.removeEventListener("scroll", handleScroll);
    };
  }, [open, data]);

  return (
    <>
      {open && (
        <Box
          {...boxContainerStyle}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          {data && data.length > 0 && (
            <Box {...boxStyle(maxHeight)} ref={boxUserRef}>
              {data.map((item: any, index: number) => (
                <Pressable
                  {...boxItemStyle(theme, data.length - 1 > index, isMargin)}
                  key={item.userId}
                  onPress={() => {
                    setOpen(false);
                    onValueChange && onValueChange(item);
                  }}
                >
                  <Text {...textBody16(theme)}>{item.fullName}</Text>
                  {!!item.phoneNumber && (
                    <Text {...phoneStyle(theme)}>{item.phoneNumber}</Text>
                  )}
                  {!!item.email && (
                    <Text {...phoneStyle(theme)}>{item.email}</Text>
                  )}
                </Pressable>
              ))}
            </Box>
          )}
          {isLoading && (
            <Box
              marginTop={data?.length > 0 ? "10px" : "37px"}
              marginBottom={data?.length > 0 ? "10px" : "0"}
            >
              <Spinner {...spinnerStyle(theme)} position="absolute" size="lg" />
            </Box>
          )}
          {!isLoading && data.length === 0 && (
            <Flex direction="column" alignItems="center" p={6}>
              <ReactSVG src={"/icons/no-data.svg"} />
              <Text {...textNodata(theme)}>{t("common.listing.no-data")}</Text>
            </Flex>
          )}
        </Box>
      )}
    </>
  );
};

export default UserInformationDropdown;
