export const sidebarStyle = (theme: any, widthSidebar: string) => {
  return {
    position: "relative" as any,
    zIndex: "auto",
    w: widthSidebar,
    h: "calc(100vh - 56px)",
    borderRightWidth: 1,
    borderRightColor: theme?.appPalette.grey?.grey12,
    display: "flex",
    style: {
      transitionProperty: "width",
      transitionDuration: "0.2s",
      transitionTimingFunction: "ease-out",
    } as any,
  };
};

export const hiddenSidebar = (theme: any, isHover: boolean) => {
  return {
    w: isHover ? "280px" : "24px",
    h: "calc(100vh - 56px)",
    backgroundColor: theme?.appPalette.grey?.white,
    borderRightWidth: 1,
    borderRightColor: theme?.appPalette.grey?.grey12,
    position: "absolute" as any,
    top: 0,
    zIndex: 2,
    style: {
      transitionProperty: "width",
      transitionDuration: "0.2s",
      transitionTimingFunction: "ease-out",
    } as any,
  };
};

export const containerStyle = (theme: any, widthSidebar: string) => ({
  padding: "24px",
  w: `calc(100vw - ${widthSidebar})`,
  h: "calc(100vh - 56px)",
  overflow: "auto",
  style: {
    transitionDuration: "0.3s",
    transitionTimingFunction: "ease-out",
  } as any,
  backgroundColor: theme?.appPalette?.grey.grey2,
});

export const iconExpandMenu = (isExpand: boolean) => ({
  position: "absolute" as any,
  height: "32px",
  top: "20px",
  right: "-15px",
  zIndex: 3,
  style: {
    transform: [{ rotate: isExpand ? "-180deg" : "0" }],
    transitionDuration: "0.3s",
    transitionTimingFunction: "ease-out",
  } as any,
});

