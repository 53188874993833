export const searchBoxStyle = (theme: any) => {
  return {
    fontFamily: theme.fontFamily,
    backgroundColor: 'white',
    height: "40px",
    fontSize: "16px",
    textOverflow: "ellipsis",
    _hover: {
      borderColor: theme.appPalette?.primary?.primary100,
    },
    _focus: {
      borderColor: theme.appPalette?.primary?.primary100,
      backgroundColor: theme.appPalette?.grey?.white,
      _hover: {
        borderColor: theme.appPalette?.primary?.primary100,
      },
    },
    _stack: {
      style: {},
    },
  };
};
