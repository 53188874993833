import { Box } from "shared-components";
import { useCallback, useState } from "react";
import { format } from "date-fns";
import { useTranslation } from "react-i18next";
import ClientService from "../../services/client/clientService";
import { Modal, Pressable, Text, useTheme } from "native-base";
import { handleFiltersHaveDateRange } from "../../utils/filters";
import CallService from "../../services/call/callService";
import { ResourceType } from "../../constants/resourceType";
import { showNotificationForVoiso } from "../../services/toast/toastService";
import { Header, TableAction } from "../../types/table";
import {
  dotStyle,
  filterStyle,
  phoneNumberStyle,
  textStyle,
} from "../lead-listing/LeadListing.style";
import { ReactSVG } from "react-svg";
import CoreTable from "../core-table/CoreTable";
import ClientListingFilter from "../client-listing-filter/ClientListingFilter";
import { useNavigate } from "react-router-dom";
import { LeadConstants } from "../../constants/lead";
import { PERMISSIONS } from "../../constants/permisstion";
import { DATE_FORMAT_MM_DD_YYYY } from "../../constants/formatDate";

const AppClientListing = () => {
  const { t } = useTranslation();
  const FORMAT_DATE = DATE_FORMAT_MM_DD_YYYY;
  const [isOpenFilter, setIsOpenFilter] = useState(false);
  const [selectedAssignee, setSelectedAssignee] = useState();
  const navigate = useNavigate();
  const [filters, setFilters] = useState({
    status: "",
    kycApprovalDate: {
      from: "",
      to: "",
    },
    assigneeId: "",
  });
  const [isFilterUsed, setIsFilterUsed] = useState(false);

  const filterProps = {
    statusOptions: [
      {
        label: "ALL",
        value: t("ticket-listing-filter.status-options.all"),
      },
      {
        label: "NEW",
        value: t("ticket-listing-filter.status-options.new"),
      },
      {
        label: "NO_ANSWER_1_3",
        value: t("lead-listing.filter.no-answer"),
      },
      {
        label: "CALL_AGAIN",
        value: t("lead-listing.filter.call-again"),
      },
      {
        label: "NO_FOUND",
        value: t("lead-listing.filter.no-found"),
      },
      {
        label: "UNDER_18",
        value: t("lead-listing.filter.under-18"),
      },
    ],
    textObject: {
      filter: t("client-listing.filter.title"),
      apply: t("client-listing.filter.apply"),
      clearAll: t("client-listing.filter.clear-all"),
      status: t("ticket-listing-filter.status"),
      kycApprovalDate: t("client-listing.filter.kyc-approval-date"),
      invalidFormatErrMsg: t("errors.standard002", {
        fieldName: t("client-listing.filter.kyc-approval-date"),
      }),
      earlierErrMsg: t("errors.standard093"),
      selectedFromErrMsg: t("errors.standard092", {
        fieldName: t("client-listing.filter.start-date"),
      }),
      selectedToErrMsg: t("errors.standard092", {
        fieldName: t("client-listing.filter.end-date"),
      }),
      selectedRangeOverErrMsg: t("errors.standard094"),
      assignee: t("prospect-listing.header-column.assignee"),
    },
    dropDownIcon: "/icons/arrow-down.svg",
    arrowIcon: "/icons/arrow-long.svg",
    selectedIcon: "/icons/checked.svg",
    closeFilterIcon: "/icons/filter-close.svg",
    calendarIcon: "/icons/calendar.svg",
    arrowUp: "/icons/mini-arrow-up.svg",
    arrowDown: "/icons/mini-arrow.svg",
    arrowLeft: "/icons/chevron-left.svg",
    arrowRight: "/icons/chevron-right.svg",
    onFiltersChanged: (filtersChanged: any) => {
      setFilters(filtersChanged);
      setIsOpenFilter(false);
      setIsFilterUsed(filters !== filtersChanged);
    },
  };

  const callVoiso = (clientPhoneNumber: string, item: any) => {
    CallService.callVoiso(
      clientPhoneNumber,
      ResourceType.CLIENT,
      item.clientId,
    ).catch((reason) => {
      if (reason.response.data.code === "1010022") {
        showNotificationForVoiso(t);
      }
    });
  };
  const formatDate = (timestamp: number) => {
    const date = new Date(timestamp);
    return <Text {...textStyle(theme)}>{format(date, FORMAT_DATE)}</Text>;
  };
  const formatPhoneNumber = (phoneNumber: string, item: any) => {
    return (
      <Pressable onPress={() => callVoiso(phoneNumber, item)}>
        <Text {...phoneNumberStyle(theme)}>{phoneNumber}</Text>
      </Pressable>
    );
  };

  const formatStatus = (statusValue?: string) => {
    const status = LeadConstants.status.find((st) => st.label === statusValue);
    return status ? <Text {...textStyle(theme)}>{t(status.value)}</Text> : "";
  };

  const headers: Header[] = [
    {
      value: "clientName",
      label: t("client-listing.header-column.client-name"),
      width: 246,
    },
    {
      value: "clientPhoneNumber",
      label: t("client-listing.header-column.client-phone-number"),
      format: formatPhoneNumber,
      width: 246,
      permissions: [PERMISSIONS.USERS_ROLES.SENSITIVE_DATA.VIEW_SENSITIVE_DATA],
    },
    {
      value: "clientEmail",
      label: t("client-listing.header-column.client-email"),
      width: 246,
      permissions: [PERMISSIONS.USERS_ROLES.SENSITIVE_DATA.VIEW_SENSITIVE_DATA],
    },
    {
      value: "userEmail",
      label: t("client-listing.header-column.user-email"),
      width: 246,
      permissions: [PERMISSIONS.USERS_ROLES.SENSITIVE_DATA.VIEW_SENSITIVE_DATA],
    },
    {
      value: "assigneeName",
      label: t("ticket-listing.header-column.assignee"),
      width: 237,
    },
    {
      value: "status",
      label: t("lead-listing.header-column.status"),
      width: 164,
      format: formatStatus,
    },
    {
      value: "kycApprovalDate",
      label: t("client-listing.header-column.kyc-approval-date"),
      width: 164,
      format: formatDate,
    },
    {
      value: "action",
      label: t("ticket-listing.header-column.action"),
      type: "ACTION",
      width: 100,
    },
  ];

  const theme = useTheme();

  const actionRow = () => {
    return (
      <>
        <Box>
          <Pressable
            testID="filter-icon"
            position="relative"
            {...filterStyle(theme)}
            onPress={() => {
              setIsOpenFilter(true);
            }}
          >
            <ReactSVG src="/icons/filter-icon.svg" />
            {isFilterUsed ? (
              <Box {...dotStyle(theme)} position="absolute" />
            ) : undefined}
          </Pressable>
        </Box>
      </>
    );
  };

  const fetchDatas = useCallback((params: any) => {
    return ClientService.list(params);
  }, []);

  const processedFilters = handleFiltersHaveDateRange(
    filters,
    "kycApprovalDate",
  );

  const actions: TableAction[] = [
    {
      icon: "/icons/edit.svg",
      label: t("common.edit"),
      value: "EDIT",
      permissions: [PERMISSIONS.CONTACTS.CLIENT.EDIT],
    },
  ];

  const handleAction = (code: string, data: any) => {
    switch (code) {
      case "EDIT":
        navigateProspectDetail(data);
        break;
      default:
        break;
    }
  };

  const navigateProspectDetail = (client: any) => {
    navigate(`/client/edit/${client.clientId}`);
  };

  return (
    <Box>
      <Modal isOpen={isOpenFilter} onClose={() => setIsOpenFilter(false)}>
        <Modal.Content
          maxHeight="100vh"
          marginLeft="auto"
          marginRight="0"
          maxWidth="460"
          borderRadius="0"
        >
          {isOpenFilter ? (
            <ClientListingFilter
              {...filterProps}
              initFilters={filters}
              selectedAssignee={selectedAssignee}
              setSelectedAssignee={setSelectedAssignee}
              onCloseFilter={() => setIsOpenFilter(false)}
            />
          ) : undefined}
        </Modal.Content>
      </Modal>
      <CoreTable
        headers={headers}
        filters={filters}
        processedFilters={processedFilters}
        fetchDatas={fetchDatas}
        actionRow={actionRow()}
        onClickAction={(code, data) => handleAction(code, data)}
        actions={actions}
        isBorder={true}
        searchTooltip={t("client-listing.search-tooltip")}
        title={t("client-listing.title")}
        isFilterUsed={isFilterUsed}
        setIsFilterUsed={setIsFilterUsed}
      />
    </Box>
  );
};

export default AppClientListing;
