import ApiClient from "../api-client/apiClient";
import Api from "../../configs/api";
import { resolveTenant } from "../../tenantResolver";
import { ResponseType } from "axios";

const getAuthHeaders = () => {
  const tenantConfig = resolveTenant();
  const authData = JSON.parse(
    localStorage.getItem(
      `oidc.at:${tenantConfig.oidcConfiguration.authority}`
    ) ?? "{}"
  );
  return {
    token: authData.access_token,
  };
};

const LeadService = {
  getLeadListing: (data: any) => {
    const headers = getAuthHeaders();
    const url = `${Api.LEAD}${Api.SEARCH}`;
    return ApiClient(headers).post(url, data);
  },

  getLead: (leadId: any) => {
    const headers = getAuthHeaders();
    const url = `${Api.LEAD}/${leadId}`;
    return ApiClient(headers).get(url);
  },

  editLead: (leadNumber: string | undefined, data: any) => {
    const url = `${Api.LEAD}/${leadNumber}`;
    const tenantConfig = resolveTenant();
    let authData = JSON.parse(
      localStorage.getItem(
        `oidc.at:${tenantConfig.oidcConfiguration.authority}`,
      ) ?? "{}",
    );
    const headers = {
      token: authData.access_token,
    };
    return ApiClient(headers).put(url, data);
  },

  getUploadHistoryListing: (data: any) => {
    const url = `${Api.LEAD}${Api.UPLOAD_HISTORY_LISTING}`;
    return ApiClient().post(url, data);
  },

  downloadBulkLead: (uploadHistoryId: string, type: string) => {
    const url = `${Api.LEAD}${Api.DOWNLOAD_UPLOAD_HISTORY}`;
    const body = { id: uploadHistoryId, recordType: type };
    return ApiClient().post(url, body);
  },

  create: (data: any) => {
    const tenantConfig = resolveTenant();
    let authData = JSON.parse(
      localStorage.getItem(
        `oidc.at:${tenantConfig.oidcConfiguration.authority}`,
      ) ?? "{}",
    );
    const headers = {
      token: authData.access_token,
    };
    return ApiClient(headers).post(Api.LEAD, data);
  },

  getCountries: () => {
    return ApiClient().get(Api.COUNTRIES);
  },

  convertLead: (data: any) => {
    const tenantConfig = resolveTenant();
    let authData = JSON.parse(
      localStorage.getItem(
        `oidc.at:${tenantConfig.oidcConfiguration.authority}`,
      ) ?? "{}",
    );
    const headers = {
      token: authData.access_token,
    };
    return ApiClient(headers).post(Api.CONVERT_LEAD, data);
  },

  downloadTemplate: () => {
    return ApiClient().post(`${Api.LEAD}${Api.DOWNLOAD_TEMPLATE}`);
  },

  createBulk: (data: any) => {
    const tenantConfig = resolveTenant();
    const url = `${Api.LEAD}${Api.BULK}`;
    let authData = JSON.parse(
      localStorage.getItem(
        `oidc.at:${tenantConfig.oidcConfiguration.authority}`,
      ) ?? "{}",
    );
    const headers = {
      "Content-Type": "multipart/form-data",
      token: authData.access_token,
    };
    return ApiClient(headers).post(url, data);
  },

  fetchComment: (leadId: string, pageIndex: number, pageSize: number) => {
    const params = {
      pageIndex,
      pageSize,
    };
    const url = `${Api.LEAD}/${leadId}${Api.COMMENT_SUB}`;
    return ApiClient().get(url, { params });
  },

  addComment: (leadId: string, message: string, token: string) => {
    const body = { message };
    const url = `${Api.LEAD}/${leadId}${Api.COMMENT_SUB}`;
    return ApiClient().post(url, body, {
      headers: {
        token,
      },
    });
  },
  getActivityLogHistory(id: string, params: any) {
    const url = `${Api.LEAD}/${id}${Api.LEAD_HISTORY}`;
    return ApiClient().get(url, { params });
  },
  invite: (data: any) => {
    const tenantConfig = resolveTenant();
    let authData = JSON.parse(
        localStorage.getItem(
            `oidc.at:${tenantConfig.oidcConfiguration.authority}`,
        ) ?? '{}',
    );
    const headers = {
      token: authData.access_token,
    };
    const url = `${Api.LEAD}${Api.INVITE_LEAD}`;
    return ApiClient(headers).post(url, data);
  },
  downloadCallRecord: (call_uuid: string) => {
    const url = `${Api.LEAD}/call/recording/${call_uuid}/download`;
    const responseType: ResponseType = "blob";
    return ApiClient({}, undefined, responseType).post(url);
  },
  getIslands: (countryId: string) => {
    const url = `${Api.ISLANDS}/${countryId}/citiesAndIslands`;
    return ApiClient().get(url);
  },
  createGuestContact: (data: any) => {
    const url = `${Api.TICKET}${Api.USER_INFORMATION_CONTACT}`;
    return ApiClient().post(url, data);
  }
};

export default LeadService;
