import { InterfaceBoxProps } from "native-base/lib/typescript/components/primitives/Box";
import { textHeader1, textNodata, errorText } from "../../styles/text";

export const boxContainerStyle = () => ({
  borderRadius: "8px",
  gap: "24px",
  padding: "24px",
  w: "100%",
});

export const shadowStyle = () => ({
  style: {
    shadowColor: "rgba(0, 0, 0, 0.20)",
    shadowOffset: { width: 0, height: 1 },
    shadowOpacity: 1,
    shadowRadius: 3,
  },
});

export const textHeaderStyle = (theme?: any) => ({
  width: "155px",
  w: "100%",
  height: "24px",
  fontWeight: 600,
  fontSize: "20px",
  lineHeight: "24px",
  fontFamily: theme?.fontFamily,
});

export const boxPropertyStyle = (error?: any) => ({
  // width: '372px',
  height: error ? "92px" : "68px",
  gap: "8px",
  zIndex: "auto",
});

export const boxPropertyInsideStyle = (): InterfaceBoxProps => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  w: "100%",
});

export const textPropertyStyle = (theme?: any) => ({
  ...textHeader1(theme),
  height: "20px",
});

export const textOptionalPropertyStyle = (theme?: any) => ({
  ...textNodata(theme),
  height: "16px",
  fontWeight: 400,
  marginLeft: "4px",
});

export const textErrorStyle = (theme?: any) => ({
  ...errorText(theme),
});
