import { endOfDay, parse, startOfDay } from "date-fns";

export const convertDateRangeValue = (value: any, fieldName: string) => {
  if (typeof value !== "string" && value?.from && value?.to) {
    const startTimestamp = startOfDay(
      parse(value.from, "MM/dd/yyyy", new Date())
    ).getTime();
    const endTimestamp = endOfDay(
      parse(value.to, "MM/dd/yyyy", new Date())
    ).getTime();
    return {
      fieldName,
      values: [
        {
          from: startTimestamp,
          to: endTimestamp,
        },
      ],
    };
  }
};

export const handleFiltersHaveDateRange = (filters: any, fieldName: string) => {
  let appliedFilter = [];
  for (const [key, value] of Object.entries(filters)) {
    if (key === fieldName) {
      const dateFilter = convertDateRangeValue(value, key);
      if (dateFilter) {
        appliedFilter.push(dateFilter);
      }
    } else if (value) {
      appliedFilter.push({
        fieldName: key,
        values: [value],
      });
    }
  }
  return appliedFilter;
};

export const handleFiltersProspectListing = (filters: any) => {
  let appliedFilter = [];

  for (const [key, value] of Object.entries(filters)) {
    if (key === "createdDate" && value && typeof value !== "string") {
      const { from, to } = value as { from: string; to: string };
      if (from && to) {
        const startTimestamp = startOfDay(
          parse(from, "MM/dd/yyyy", new Date())
        ).getTime();
        const endTimestamp = endOfDay(
          parse(to, "MM/dd/yyyy", new Date())
        ).getTime();
        appliedFilter.push({
          fieldName: key,
          values: [
            {
              from: startTimestamp,
              to: endTimestamp,
            },
          ],
        });
      }
    } else if (value) {
      appliedFilter.push({
        fieldName: key,
        values: [value],
      });
    }
  }

  return appliedFilter;
};

export const handleFiltersConsumerListing = (filters: any) => {
  let appliedFilter = [];

  for (const [key, value] of Object.entries(filters)) {
    if (key === "createdTime" && value && typeof value !== "string") {
      const { from, to } = value as { from: string; to: string };
      if (from) {
        const startTimestamp = startOfDay(
          parse(from, "MM/dd/yyyy", new Date())
        ).getTime();
        const value = {
          fieldName: "createdDateFrom",
          values: [startTimestamp],
          type: "FILTER",
        };
        appliedFilter.push(value);
      }
      if (to) {
        const endTimestamp = endOfDay(
          parse(to, "MM/dd/yyyy", new Date())
        ).getTime();
        const value = {
          fieldName: "createdDateTo",
          values: [endTimestamp],
          type: "FILTER",
        };
        appliedFilter.push(value);
      }
    } else if (value) {
      appliedFilter.push({
        fieldName: key,
        values: [value],
        type: ["fullName", "email", "phoneNumber"].includes(key)
          ? "SEARCH"
          : "FILTER",
      });
    }
  }

  return appliedFilter;
};

export const handleFiltersMerchantListing = (filters: any) => {
  let appliedFilter = [];

  for (const [key, value] of Object.entries(filters)) {
    if (key === "expiryDate" && value && typeof value !== "string") {
      const { from, to } = value as { from: string; to: string };
      if (from || to) {
        if (from) {
          const startTimestamp = startOfDay(
            parse(from, "MM/dd/yyyy", new Date())
          ).getTime();
          const value = {
            fieldName: "expiryDateFrom",
            values: [startTimestamp],
            type: "FILTER",
          };
          appliedFilter.push(value);
        }
        if (to) {
          const endTimestamp = endOfDay(
            parse(to, "MM/dd/yyyy", new Date())
          ).getTime();
          const value = {
            fieldName: "expiryDateTo",
            values: [endTimestamp],
            type: "FILTER",
          };
          appliedFilter.push(value);
        }
      }
    } else if (value) {
      appliedFilter.push({
        fieldName: key,
        values: [value],
        type: ["businessName", "email"].includes(key)
          ? "SEARCH"
          : "FILTER",
      });
    }
  }

  return appliedFilter;
};


export const handleFiltersTransactionListing = (filters: any) => {

  const { from, to } = filters['createdDate'] as { from: string; to: string };
  const startTimestamp = startOfDay(
      parse(from, "MM/dd/yyyy", new Date())
  ).getTime();
  const endTimestamp = endOfDay(
      parse(to, "MM/dd/yyyy", new Date())
  ).getTime();

  return {
    groupAccountType: filters['paymentMethod'],
    transactionStatus: filters['status'],
    type: filters['category'],
    startTimestamp: startTimestamp,
    endTimeStamp: endTimestamp,
    kycStatus: filters['remitterKycLevel'],
    toAmount: filters['toAmount'],
    fromAmount: filters['fromAmount'],
  };
};